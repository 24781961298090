import storage from "./storage";

export default function authHeader() {
  const accessToken = storage.get("access_token");
  if (accessToken) {
    return { Authorization: "Bearer " + accessToken };
    // for Node.js Express back-end
    // return { "x-access-token": user.accessToken };
  } else {
    return {};
  }
}
