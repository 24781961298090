import React, { useContext, useEffect, useRef, useState } from "react";
import imgKnowlegde from "../../assets/images/galaxy/img-1.png";
import { Spinner } from "react-bootstrap/esm";
import CarouselRecommended from "./component/carouselRecommeded";
import Explore from "./component/menuTabs";
import { useLanguage } from "../../provider/languageContext";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { MdOutlineFormatListBulleted } from "react-icons/md";
import { Loading, ModalDetail, ModalSuccess } from "../../components";
import axios from "axios";
import { ToastContainer, toast, Slide } from "react-toastify";
import { API_URL } from "../../utilities/url";
import storage from "../../services/storage";
import { CartContext } from "../../provider/cartContext";
import bookComp from "../../assets/images/book-comp.png";
import image_knowledge from "../../assets/images/knowledge.png";
import { LuBookCopy, LuBookLock, LuBookUp } from "react-icons/lu";
import { format_number } from "../../utilities/ext-function";
import { BiWorld } from "react-icons/bi";

const getSlidesConfig = (width) => {
  if (width > 3000) {
    return 50;
  } else if (width <= 3000 && width > 2500) {
    return 42;
  } else if (width <= 2500 && width > 2000) {
    return 35;
  } else if (width <= 2000 && width > 1500) {
    return 30;
  } else if (width <= 1500 && width > 1024) {
    return 25;
  } else if (width <= 1024 && width > 600) {
    return 20;
  } else if (width <= 600) {
    return 10;
  } else {
    return 50;
  }
};

const Knowledge = () => {
  const { messagesLang } = useLanguage();
  const { addItemToCart } = useContext(CartContext);
  const [openRecent, setOpenRecent] = useState(true);
  const [inputValue, setInputValue] = useState("");
  const [activeSearch, setActiveSearch] = useState(false);
  const [searchResult, setSearchResult] = useState("");
  const [dataFilterSearch, setDataFilterSearch] = useState(0);
  const [listItems, setListItems] = useState([]);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [slidesConfig, setSlidesConfig] = useState(
    getSlidesConfig(window.innerWidth)
  );

  const sliderRef = useRef(null);
  useEffect(() => {
    if (window.innerWidth < 1024) {
      setTimeout(() => {
        if (sliderRef.current) {
          sliderRef.current.slickGoTo(0, true);
        }
      }, 1500);
    }
  }, []);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    if (e.target.value === "") {
      setActiveSearch(false);
    }
    if (!activeSearch) {
      setSearchResult(e.target.value);
    }
  };
  const clearInput = () => {
    setActiveSearch(false);
    setInputValue("");
  };
  const handleSearch = () => {
    if (inputValue !== "") {
      setActiveSearch(true);
      setSearchResult(inputValue);
      setLoadingSearch(true);
      axios
        .post(
          API_URL + "get-knowledge-public-all",
          { title: inputValue, page: currentPage, limit: slidesConfig },
          {
            headers: {
              Authorization: `Bearer ${storage.get("access_token")}`,
            },
          }
        )
        .then((response) => {
          const filteredData = response.data.data.data;
          setListItems(filteredData);
          setDataFilterSearch(filteredData.length);
          setLoadingSearch(false);
        })
        .catch((error) => {
          toast.error(messagesLang.connection_error, {
            position: "top-center",
            theme: "colored",
            transition: Slide,
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
          });
          setLoadingSearch(false);
        });
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  /* Search */
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [loadingModal, setLoadingModal] = useState(false);

  const handleCardClick = (item) => {
    setSelectedItem(item);
    setLoadingModal(true);
    setTimeout(() => {
      setLoadingModal(false);
      setShowModal(true);
    }, 500);
  };

  const handleCloseSuccess = () => {
    setShowModal(false);
    setShowSuccessModal(false);
    setSelectedItem(null);
  };

  const handleClose = () => {
    setShowModal(false);
    setSelectedItem(null);
  };

  const handleKnowledge = (id) => {
    axios
      .post(
        API_URL + "add-knowledge-cart",
        { idt_knowledge: id },
        {
          headers: {
            Authorization: `Bearer ${storage.get("access_token")}`,
          },
        }
      )
      .then((response) => {
        if (response.data.success === true) {
          setShowModal(false);
          setShowSuccessModal(true);
          addItemToCart(response.data.data);
          setLoadingData(false);
        } else {
          toast.error(response.data.message, {
            position: "top-right",
            theme: "colored",
            transition: Slide,
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
          });
          setLoadingData(false);
        }
      })
      .catch((error) => {
        setLoadingData(false);
        console.log(error);
      });
  };

  const getPrice = (card) => {
    if (card.idt_knowledge !== undefined) {
      if (card.knowledge_public.length > 0) {
        if (card.knowledge_public[0].pricing_scheme === "paid") {
          return "Rp. " + format_number(card.knowledge_public[0].price_sell);
        } else if (card.knowledge_public[0].pricing_scheme === "rent") {
          return "Rp. " + format_number(card.knowledge_public[0].price_rent);
        } else {
          return "Free";
        }
      } else {
        return "Free";
      }
    } else {
      return `${card.knowledge_collection_map.length} Knowledge`;
    }
  };

  return (
    <div className="page-content">
      <ToastContainer toastClassName="toaster" />
      <div className="container-fluid">
        <div className="row">
          <img
            src={imgKnowlegde}
            className="img-banner object-fit-cover mb-3"
          />
        </div>
      </div>
      {/* <CarouselRecommended /> */}
      <div className="container-fluid w-85">
        <div className="row">
          <Explore />
        </div>
      </div>
    </div>
  );
};

export default Knowledge;
