import { useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

export default function ChartKnowledge() {
  const data = [
    {
      name: "Jan",
      pv: 300,
      amt: 2400,
    },
    {
      name: "Feb",
      pv: 1398,
      amt: 2210,
    },
    {
      name: "Mar",
      pv: 2000,
      amt: 2290,
    },
    {
      name: "Apr",
      pv: 3908,
      amt: 2000,
    },
    {
      name: "May",
      pv: 4800,
      amt: 2181,
    },
    {
      name: "Jun",
      pv: 3800,
      amt: 2500,
    },
    {
      name: "Jul",
      pv: 4300,
      amt: 2100,
    },
    {
      name: "Aug",
      pv: 4800,
      amt: 2181,
    },
    {
      name: "Sep",
      pv: 3800,
      amt: 2500,
    },
    {
      name: "Oct",
      pv: 4300,
      amt: 2100,
    },
    {
      name: "Nov",
      pv: 3800,
      amt: 2500,
    },
    {
      name: "Des",
      pv: 4300,
      amt: 2100,
    },
  ];

  return (
    <div>
      <ResponsiveContainer
        width="100%"
        height={300}
        style={{ marginLeft: "-15px" }}
      >
        <LineChart data={data}>
          {/* <CartesianGrid strokeDasharray="3 3" /> */}
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip contentStyle={{ color: "black" }} />
          {/* <Legend /> */}
          <Line
            type="monotone"
            dataKey="pv"
            stroke="#fb8500"
            activeDot={{ r: 4 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}
