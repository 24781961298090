import React, { useEffect, useRef, useState } from "react";
import storage from "../../services/storage";
import moment from "moment";
import { API_URL } from "../../utilities/url";
import { useNavigate } from "react-router-dom";
var $ = require("jquery");

const Archive = () => {
  // const isTablesIn = storage.getWithExpiry("ac-table");
  const tableRef = useRef(null);
  const loaderRef = useRef(null);

  useEffect(() => {
    // if (isTablesIn == undefined) {
    const table = $("#example").DataTable({
      dom:
        "<'row'<'col-sm-6'l><'col-sm-6'Bf>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-5'i><'col-sm-7'p>>",
      // processing: "true",
      // language: {
      //   processing: `<div class="spinner-border spinner-border-sm text-primary" role="status">
      //   <span class="visually-hidden"></span>
      //   </div>`,
      // },
      serverSide: true,
      bDestroy: true,
      ajax: {
        url: API_URL + "list-table-users",
        type: "post",
        headers: {
          Authorization: "Bearer " + storage.get("access_token"),
        },
        data: function (d) {
          return {
            draw: d.draw,
            start: d.start,
            length: d.length,
            search: d.search.value,
            orderBy: d.columns[d.order[0].column].data,
            orderDir: d.order[0].dir,
          };
        },
        error: function (xhr, error, code) {
          if (code === "Unauthorized") {
            for (let i = 0; i < localStorage.length; i++) {
              const key = localStorage.key(i);
              if (key !== "lang") {
                storage.remove("access_token");
                localStorage.removeItem(key);
              }
            }
            window.location.replace("login");
          }
        },
      },
      columns: [
        {
          data: "idt_user_profile",
          render: function (data, type, row) {
            var data = moment(data).format("DD-MM-YYYY");

            return data;
          },
        },
        { data: "name_first" },
        { data: "name_last" },
        {
          data: "idt_user",
          render: function (data, type, row) {
            var data = `<Moment>${data}</Moment>`;

            return data;
          },
        },
        {
          data: "idt_user",
          render: function (data, type, row) {
            var data = `<Moment>${data}</Moment>`;

            return data;
          },
        },
        {
          data: "idt_user",
          render: function (data, type, row) {
            var data = `<Moment>${data}</Moment>`;

            return data;
          },
        },
      ],
      initComplete: function (settings, json) {
        const searchInput = $("div.dataTables_filter input");
        searchInput.off(); // Remove previous event handlers
        searchInput.on("keypress", function (e) {
          if (e.which == 13) {
            // Check if Enter key is pressed
            table.search(this.value).draw();
          }
        });
      },
    });

    return () => {
      table.destroy(true);
    };
  }, []);
  // new DataTable("#example");
  return (
    <div className="page-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0">Archive</h4>
              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  {/* <li className="breadcrumb-item">
                    <a href="#">Chat</a>
                  </li> */}
                  <li className="breadcrumb-item active">Archive</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <h5 className="card-title mb-0">Basic Datatables</h5>
              </div>
              <div className="card-body table-responsive">
                <table
                  id="example"
                  ref={tableRef}
                  className="table table-bordered w-100"
                >
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Age</th>
                      <th>Start date</th>
                      <th>Salary</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* <div ref={loaderRef} className="loader-dt">
                      Loading...
                    </div> */}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Archive;
