import React, { useContext, useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useLanguage } from "../../../provider/languageContext";
import { CartContext } from "../../../provider/cartContext";
import { Spinner } from "react-bootstrap";
import { SliderRecommendedContext } from "../../../provider/sliderRecommendedContext";
import {
  Loading,
  ModalDetailCart,
  ModalDetailCollectionCart,
} from "../../../components";
import { format_number } from "../../../utilities/ext-function";
import image_knowledge from "../../../assets/images/knowledge.png";
import bookComp from "../../../assets/images/book-comp.png";
import { API_URL } from "../../../utilities/url";
import storage from "../../../services/storage";
import axios from "axios";

const CartList = () => {
  const { messagesLang } = useLanguage();
  const { loading, setLoading } = useContext(SliderRecommendedContext);
  const {
    cartItems,
    selectedItemIds,
    setSelectedItemIds,
    removeItemFromCart,
    separatePrice,
    loadingItems,
  } = useContext(CartContext);
  const [dataReview, setDataReview] = useState([]);
  const [totalDataReview, setTotalDataReview] = useState(0);
  const [starReview, setStarReview] = useState([]);
  const [selectedRatings, setSelectedRatings] = useState([]);
  const [pageReview, setPageReview] = useState(1);
  const [loadingReview, setLoadingReview] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const sortedItems = [...cartItems].sort(
    (a, b) => b.idt_knowledge_order_cart - a.idt_knowledge_order_cart
  );

  const handleSelectItem = (item) => {
    setSelectedItemIds((prevSelectedIds) =>
      prevSelectedIds.includes(item.idt_knowledge_order_cart)
        ? prevSelectedIds.filter(
            (idt_knowledge_order_cart) =>
              idt_knowledge_order_cart !== item.idt_knowledge_order_cart
          )
        : [...prevSelectedIds, item.idt_knowledge_order_cart]
    );
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedItemIds(
        cartItems.map((item) => item.idt_knowledge_order_cart)
      );
    } else {
      setSelectedItemIds([]);
    }
  };

  const [showModal, setShowModal] = useState(false);
  const [showModalC, setShowModalC] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [loadingModal, setLoadingModal] = useState(false);

  const handleCardClick = (item) => {
    setSelectedItem(item);
    setLoadingModal(true);
    if (item.idt_knowledge) {
      /* Get AVG Review */
      axios
        .post(
          API_URL + "get-knowledge-avg-review",
          {
            idt_knowledge: item.idt_knowledge,
          },
          {
            headers: {
              Authorization: `Bearer ${storage.get("access_token")}`,
            },
          }
        )
        .then((response) => {
          if (response.data.success === true) {
            setStarReview(response.data.data);
          }
        })
        .catch((error) => {
          console.log("An error occurred. Please try again later.");
        });

      /* Get Review */
      axios
        .post(
          API_URL + "get-knowledge-review",
          {
            idt_knowledge: item.idt_knowledge,
            rating: selectedRatings,
            limit: 10,
            page: pageReview,
          },
          {
            headers: {
              Authorization: `Bearer ${storage.get("access_token")}`,
            },
          }
        )
        .then((response) => {
          if (response.data.success === true) {
            setLoadingModal(false);
            setShowModal(true);
            setDataReview(response.data.data.data);
            setPageReview((prevPage) => prevPage + 1);
            setTotalDataReview((prevTotal) => {
              const updatedTotal = prevTotal + response.data.data.data.length;
              setHasMore(updatedTotal < response.data.data.meta.total_items);
              return updatedTotal;
            });
          } else {
            setLoadingModal(false);
          }
        })
        .catch((error) => {
          console.log("An error occurred. Please try again later.");
        });
    } else {
      /* Get AVG Review */
      axios
        .post(
          API_URL + "get-knowledge-avg-review",
          {
            idt_knowledge_collection: item.idt_knowledge_collection,
          },
          {
            headers: {
              Authorization: `Bearer ${storage.get("access_token")}`,
            },
          }
        )
        .then((response) => {
          if (response.data.success === true) {
            setStarReview(response.data.data);
          }
        })
        .catch((error) => {
          console.log("An error occurred. Please try again later.");
        });

      /* Get Review */
      axios
        .post(
          API_URL + "get-knowledge-review",
          {
            idt_knowledge_collection: item.idt_knowledge_collection,
            rating: selectedRatings,
            limit: 10,
            page: pageReview,
          },
          {
            headers: {
              Authorization: `Bearer ${storage.get("access_token")}`,
            },
          }
        )
        .then((response) => {
          if (response.data.success === true) {
            setLoadingModal(false);
            setShowModalC(true);
            setDataReview(response.data.data.data);
            setPageReview((prevPage) => prevPage + 1);
            setTotalDataReview((prevTotal) => {
              const updatedTotal = prevTotal + response.data.data.data.length;
              setHasMore(updatedTotal < response.data.data.meta.total_items);
              return updatedTotal;
            });
          } else {
            setLoadingModal(false);
          }
        })
        .catch((error) => {
          console.log("An error occurred. Please try again later.");
        });
    }
  };

  const handleClose = () => {
    setShowModal(false);
    setShowModalC(false);
    setSelectedItem(null);
    setDataReview([]);
    setStarReview([]);
    setSelectedRatings([]);
    setTotalDataReview(0);
    setPageReview(1);
    setLoadingReview(false);
    setHasMore(true);
  };

  const handleRatingChange = (rating, id, type) => {
    setSelectedRatings((prev) => {
      const updatedRatings = prev.includes(rating)
        ? prev.filter((r) => r !== rating)
        : [...prev, rating];

      // Panggil getReviewAPI dengan updatedRatings
      axios
        .post(
          API_URL + "get-knowledge-review",
          {
            idt_knowledge: type === "knowledge" ? id : null,
            idt_knowledge_collection: type === "collection" ? id : null,
            rating: updatedRatings,
            limit: 10,
            page: 1,
          },
          {
            headers: {
              Authorization: `Bearer ${storage.get("access_token")}`,
            },
          }
        )
        .then((response) => {
          if (response.data.success === true) {
            setDataReview(response.data.data.data);
            setPageReview(2);
            setTotalDataReview(() => {
              const updatedTotal = response.data.data.data.length;
              setHasMore(updatedTotal < response.data.data.meta.total_items);
              return updatedTotal;
            });
          }
          setLoadingReview(false);
        })
        .catch((error) => {
          console.log("An error occurred. Please try again later.");
          setLoadingReview(false);
        });

      return updatedRatings;
    });
  };

  const loadMoreReview = (id, type) => {
    if (!hasMore) return;
    setLoadingReview(true);
    axios
      .post(
        API_URL + "get-knowledge-review",
        {
          idt_knowledge: type === "knowledge" ? id : null,
          idt_knowledge_collection: type === "collection" ? id : null,
          rating: selectedRatings,
          limit: 10,
          page: pageReview,
        },
        {
          headers: {
            Authorization: `Bearer ${storage.get("access_token")}`,
          },
        }
      )
      .then((response) => {
        if (response.data.success === true) {
          setDataReview((prevData) => [
            ...prevData,
            ...response.data.data.data,
          ]);
          setPageReview((prevPage) => prevPage + 1);
          setTotalDataReview((prevTotal) => {
            const updatedTotal = prevTotal + response.data.data.data.length;
            setHasMore(updatedTotal < response.data.data.meta.total_items);
            return updatedTotal;
          });
        }
        setLoadingReview(false);
      })
      .catch((error) => {
        console.log("An error occurred. Please try again later.");
        setLoadingReview(false);
      });
  };

  const getPrice = (card) => {
    if (card.idt_knowledge !== undefined) {
      if (card.knowledge_public.length > 0) {
        if (card.knowledge_public[0].pricing_scheme === "paid") {
          return "Rp. " + format_number(card.knowledge_public[0].price_sell);
        } else if (card.knowledge_public[0].pricing_scheme === "rent") {
          return "Rp. " + format_number(card.knowledge_public[0].price_rent);
        } else {
          return "Free";
        }
      } else {
        return "Free";
      }
    } else {
      if (card.knowledge_collection_public.length > 0) {
        if (card.knowledge_collection_public[0].pricing_scheme === "paid") {
          return "Rp. " + format_number(card.knowledge_public[0].price_sell);
        } else if (
          card.knowledge_collection_public[0].pricing_scheme === "rent"
        ) {
          return (
            "Rp. " +
            format_number(card.knowledge_collection_public[0].price_rent)
          );
        } else {
          return "Free";
        }
      } else {
        return "Free";
      }
    }
  };

  return (
    <div>
      <hr />
      {cartItems.length > 0 ? (
        <div className="form-check form-check-orange mt-2 mb-3">
          <input
            className="form-check-input form-check-custom"
            type="checkbox"
            id="formCheck9"
            onChange={handleSelectAll}
            checked={selectedItemIds.length === cartItems.length}
          />
          <label className="form-check-label" for="formCheck9">
            {messagesLang.select_all} {`(${selectedItemIds.length})`}
          </label>
        </div>
      ) : null}
      {sortedItems.map((item, index) => (
        <div key={index} className="card product">
          <div className="card-body">
            <div className="row gy-2">
              <div className="col-sm-auto">
                <div className="form-check form-check-orange">
                  <input
                    className="form-check-input form-check-custom"
                    type="checkbox"
                    checked={selectedItemIds.includes(
                      item.idt_knowledge_order_cart
                    )}
                    onChange={() => handleSelectItem(item)}
                  />
                </div>
              </div>
              <div className="col-sm-auto d-none d-md-block mb-2">
                <div className="rounded">
                  <img
                    src={
                      item.knowledge
                        ? item.knowledge.knowledge_cover_pic.length > 0
                          ? item.knowledge.knowledge_cover_pic[0].pathurl
                          : image_knowledge
                        : item.knowledge_collection.url_coverpic
                        ? item.knowledge_collection.url_coverpic
                        : bookComp
                    }
                    alt={index}
                    className="img-fluid d-block image-product"
                  />
                </div>
              </div>
              <div className="col-sm d-none d-md-block">
                <h5 className="fs-14 text-truncate mb-2">
                  <a
                    onClick={() =>
                      handleCardClick(
                        item.knowledge
                          ? item.knowledge
                          : item.knowledge_collection
                      )
                    }
                    className="text-body cursor-pointer"
                  >
                    {item.knowledge
                      ? item.knowledge.title
                      : item.knowledge_collection.name}
                  </a>
                </h5>
                {item.knowledge ? (
                  <>
                    <ul className="list-inline text-muted mb-0">
                      <li className="list-inline-item">
                        Author :{" "}
                        <span className="fw-medium">
                          {item.knowledge.author}
                        </span>
                      </li>
                    </ul>
                    <ul className="list-inline text-muted mb-0">
                      <li className="list-inline-item">
                        Publisher :{" "}
                        <span className="fw-medium">
                          {item.knowledge.knowledge_publisher.name}
                        </span>
                      </li>
                    </ul>
                    <ul className="list-inline text-muted mb-0">
                      <li className="list-inline-item">
                        Category :{" "}
                        <span className="fw-medium">
                          {item.knowledge.knowledge_category_map.map(
                            (data, index) => {
                              return index === 0
                                ? data.category.name
                                : `, ${data.category.name}`;
                            }
                          )}
                        </span>
                      </li>
                    </ul>
                  </>
                ) : (
                  <ul className="list-inline text-muted mb-0">
                    <li className="list-inline-item">Collection</li>
                  </ul>
                )}
                <ul className="list-inline text-muted mb-0">
                  <li className="list-inline-item">
                    Uploaded by :{" "}
                    <span className="fw-medium">
                      {item.knowledge
                        ? item.knowledge.user.user_profile.name_first
                        : item.knowledge_collection.user.user_profile
                            .name_first}{" "}
                      {item.knowledge
                        ? item.knowledge.user.user_profile.name_last
                        : item.knowledge_collection.user.user_profile.name_last}
                    </span>
                  </li>
                </ul>
              </div>
              <div className="col-sm-auto d-none d-md-block">
                <div className="text-lg-end">
                  <p className="text-muted mb-1">{messagesLang.price}:</p>
                  <h5 className="fs-14">
                    <span id="ticket_price" className="product-price">
                      {/* {separatePrice(item.price)} */}
                      {getPrice(
                        item.knowledge
                          ? item.knowledge
                          : item.knowledge_collection
                      )}
                    </span>
                  </h5>
                </div>
              </div>

              <div className="d-flex d-md-none gap-3">
                <div>
                  <div className="rounded mb-2">
                    <img
                      src={
                        item.knowledge
                          ? item.knowledge.knowledge_cover_pic[0].pathurl
                          : item.knowledge_collection.url_coverpic
                      }
                      alt=""
                      className="img-fluid d-block image-product"
                      style={{}}
                    />
                  </div>
                  <h5 className="fs-12">
                    <span id="ticket_price" className="product-price">
                      {getPrice(
                        item.knowledge
                          ? item.knowledge
                          : item.knowledge_collection
                      )}
                    </span>
                  </h5>
                </div>
                <div>
                  <h5 className="fs-14 text-truncate mb-2">
                    <a
                      onClick={() =>
                        handleCardClick(
                          item.knowledge
                            ? item.knowledge
                            : item.knowledge_collection
                        )
                      }
                      className="text-body cursor-pointer"
                    >
                      {item.knowledge
                        ? item.knowledge.title
                        : item.knowledge_collection.name}
                    </a>
                  </h5>
                  {item.knowledge ? (
                    <>
                      <ul className="list-inline text-muted mb-0">
                        <li className="list-inline-item">
                          Author :{" "}
                          <span className="fw-medium">
                            {item.knowledge.author}
                          </span>
                        </li>
                      </ul>
                      <ul className="list-inline text-muted mb-0">
                        <li className="list-inline-item">
                          Publisher :{" "}
                          <span className="fw-medium">
                            {item.knowledge.knowledge_publisher.name}
                          </span>
                        </li>
                      </ul>
                      <ul className="list-inline text-muted mb-0">
                        <li className="list-inline-item">
                          Category :{" "}
                          <span className="fw-medium">
                            {item.knowledge.knowledge_category_map.map(
                              (data, index) => {
                                return index === 0
                                  ? data.category.name
                                  : `, ${data.category.name}`;
                              }
                            )}
                          </span>
                        </li>
                      </ul>
                    </>
                  ) : (
                    <ul className="list-inline text-muted mb-0">
                      <li className="list-inline-item">Collection</li>
                    </ul>
                  )}
                  <ul className="list-inline text-muted mb-0">
                    <li className="list-inline-item">
                      Uploaded by :{" "}
                      <span className="fw-medium">
                        {item.knowledge
                          ? item.knowledge.user.user_profile.name_first
                          : item.knowledge_collection.user.user_profile
                              .name_first}{" "}
                        {item.knowledge
                          ? item.knowledge.user.user_profile.name_last
                          : item.knowledge_collection.user.user_profile
                              .name_last}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="card-footer">
            <div className="row align-items-center gy-2">
              <div className="col-sm">
                {/* <div className="d-flex flex-wrap my-n1">
                  <div>
                    <button
                      onClick={() => removeItemFromCart(item.id)}
                      className="d-block btn btn-danger cursor-pointer text-white p-1 px-2"
                    >
                      <i className="ri-delete-bin-fill text-white align-bottom me-1"></i>{" "}
                      {messagesLang.remove}
                    </button>
                  </div>
                </div> */}
              </div>
              <div className="col-sm-auto">
                <div className="d-flex align-items-center gap-2 text-muted">
                  <button
                    onClick={() =>
                      removeItemFromCart(item.idt_knowledge_order_cart)
                    }
                    disabled={loadingItems.includes(
                      item.idt_knowledge_order_cart
                    )}
                    className="d-block btn btn-danger cursor-pointer text-white p-1 px-2"
                    style={{ width: "100px" }}
                  >
                    {loadingItems.includes(item.idt_knowledge_order_cart) ? (
                      <>
                        <Spinner
                          className="flex-shrink-0"
                          animation="border"
                          size="sm"
                        />
                      </>
                    ) : (
                      <>
                        <i className="ri-delete-bin-fill text-white align-bottom me-1"></i>
                        {messagesLang.remove}
                      </>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
      {loadingModal && <Loading />}
      {selectedItem ? (
        selectedItem.idt_knowledge ? (
          <ModalDetailCart
            show={showModal}
            handleClose={handleClose}
            item={selectedItem}
            review={dataReview}
            starReview={starReview}
            selectedRatings={selectedRatings}
            onRatingChange={handleRatingChange}
            loadMoreReview={loadMoreReview}
            loadingReview={loadingReview}
            hasMore={hasMore}
          />
        ) : (
          <ModalDetailCollectionCart
            show={showModalC}
            handleClose={handleClose}
            item={selectedItem}
            review={dataReview}
            starReview={starReview}
            selectedRatings={selectedRatings}
            onRatingChange={handleRatingChange}
            loadMoreReview={loadMoreReview}
            loadingReview={loadingReview}
            hasMore={hasMore}
          />
        )
      ) : null}
    </div>
  );
};

export default CartList;
