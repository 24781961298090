import React, { useState, useContext, useEffect } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import SimpleBar from "simplebar-react";
import { ThemeContext } from "../provider/themeContext";
import { ToastrContext } from "../provider/toastrContext";
import axios from "axios";
import { API_URL } from "../utilities/url";
import storage from "../services/storage";
import { LuBookPlus } from "react-icons/lu";
import { AssistantContext } from "../provider/assistantContext";
import CustomStyles from "./customStyles";
import CryptoJS from "crypto-js";

const ModalUpdateAccessKey = ({ show, handleClose, data, onReload }) => {
  const { assistantSessionList } = useContext(AssistantContext);
  const { theme } = useContext(ThemeContext);
  const { showSuccess, showError } = useContext(ToastrContext);
  const decryptUSR = CryptoJS.AES.decrypt(storage.get("user"), "user").toString(
    CryptoJS.enc.Utf8
  );
  const usr = JSON.parse(decryptUSR);
  const [selectedRadio, setSelectedRadio] = useState("knowledge");
  const [knowledge, setKnowledge] = useState([]);
  const [collection, setCollection] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (show) {
      document.body.classList.add("modal-open-custom");
      // Get Knowledge
      axios
        .get(API_URL + "get-knowledge-min", {
          headers: {
            Authorization: `Bearer ${storage.get("access_token")}`,
          },
        })
        .then((response) => {
          if (response.data.success === true) {
            const maps = response.data.data.map(function (d) {
              const map = {
                value: d.idt_knowledge,
                label: d.title,
              };
              return map;
            });
            setKnowledge(maps);
            if (data.idt_knowledge > 0) {
              const defaultKnowledge = maps.find(
                (p) => p.value === data.idt_knowledge
              );
              setValue("knowledge", defaultKnowledge);
            }
          }
          setValue("label", data.label);
          const assistantMaps = assistantSessionList.map(function (d) {
            const map = {
              value: d.idt_user_assistant,
              label: d.name,
            };
            return map;
          });
          const defaultAssistant = assistantMaps.find(
            (p) => p.value === data.idt_user_assistant
          );
          setValue("assistant", defaultAssistant);
        })
        .catch((error) => {
          setLoading(false);
        });

      // Get Knowledge Collection
      axios
        .get(API_URL + "get-collection-min", {
          headers: {
            Authorization: `Bearer ${storage.get("access_token")}`,
          },
        })
        .then((response) => {
          if (response.data.success === true) {
            const maps = response.data.data.map(function (d) {
              const map = {
                value: d.idt_knowledge_collection,
                label: d.name,
              };
              return map;
            });
            setCollection(maps);
            if (data.idt_knowledge_collection > 0) {
              const defaultCollection = maps.find(
                (p) => p.value === data.idt_knowledge_collection
              );
              setValue("collection", defaultCollection);
            }
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      setValue("label", null);
      setValue("knowledge", null);
      setValue("collection", null);
      setValue("assistant", null);
      document.body.classList.remove("modal-open-custom");
    }

    // Clean up effect when component is unmounted
    return () => {
      document.body.classList.remove("modal-open-custom");
    };
  }, [show]);

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
    clearErrors,
    setValue,
  } = useForm({
    reValidateMode: "onBlur",
  });

  const onSubmit = (dataForm) => {
    setLoading(true);
    const formData = {
      //   knowledge_id: selectedRadio === "knowledge" ? data.knowledge.value : 0,
      //   collection_id: selectedRadio === "collection" ? data.collection.value : 0,
      idt_knowledge_access_key: data.idt_knowledge_access_key,
      idt_user_assistant: dataForm.assistant.value,
      label: dataForm.label,
    };
    axios
      .post(API_URL + "update-knowledge-access-key", formData, {
        headers: {
          Authorization: `Bearer ${storage.get("access_token")}`,
        },
      })
      .then((response) => {
        if (response.data.success === true) {
          showSuccess("Success to add access key");
          onReload();
          handleClose();
          setLoading(false);
        } else {
          showError("Failed to add access key");
          setLoading(false);
        }
      })
      .catch((error) => {
        showError("An error occurred. Please try again later.");
        setLoading(false);
      });
  };

  const handleRadioChange = (value) => {
    setSelectedRadio(value);
    setValue("type", value);
    if (value === "knowledge") {
      setValue("collection", 0);
    } else {
      setValue("knowledge", 0);
    }
  };

  return (
    <>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        backdrop="static"
        dialogClassName="custom-modal-collection fade"
        onHide={handleClose}
      >
        <Modal.Header className="p-3 bg-light-dark" closeButton>
          <h5 className="modal-title">Update Access Key</h5>
        </Modal.Header>
        <SimpleBar className="modal-body knowledge">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-4">
                      <label className="form-label w-100">Label</label>
                      <input
                        type="text"
                        className="form-control"
                        name="label"
                        {...register("label", {
                          required: true,
                        })}
                      />
                      {errors.label && errors.label.type === "required" && (
                        <p className="errorMsg text-danger fs-7">
                          Label is required.
                        </p>
                      )}
                    </div>
                    {/* <div className="mb-4">
                      <label className="form-label w-100">Type</label>
                      <div className="radio-group radio-group-custom mt-2">
                        <div
                          className="form-check form-radio-warning mb-3"
                          onClick={() => handleRadioChange("knowledge")}
                        >
                          <input
                            className="form-check-input"
                            type="radio"
                            name="radio"
                            value="knowledge"
                            checked={selectedRadio === "knowledge"}
                          />
                          <label
                            className="form-check-label"
                            for="formradioRight5"
                          >
                            Knowledge
                          </label>
                        </div>
                        <div
                          className="form-check form-radio-warning mb-3"
                          onClick={() => handleRadioChange("collection")}
                        >
                          <input
                            className="form-check-input"
                            type="radio"
                            name="radio"
                            value="collection"
                            checked={selectedRadio === "collection"}
                          />
                          <label
                            className="form-check-label"
                            for="formradioRight5"
                          >
                            Collection
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="mb-4">
                      {selectedRadio === "knowledge" && (
                        <>
                          <label className="form-label w-100">
                            Knowledge Name
                          </label>
                          <Controller
                            name="knowledge"
                            control={control}
                            rules={{
                              required: "Knowledge is required.",
                            }}
                            render={({ field }) => (
                              <Select
                                {...field}
                                styles={CustomStyles(theme)}
                                className="custom-select"
                                maxMenuHeight={200}
                                options={knowledge}
                                onChange={(selected) => {
                                  setValue("knowledge", selected);
                                  clearErrors("knowledge");
                                }}
                                isSearchable={true}
                              />
                            )}
                          />
                          {errors.knowledge && (
                            <p className="errorMsg text-danger fs-7">
                              {errors.knowledge.message}
                            </p>
                          )}
                        </>
                      )}

                      {selectedRadio === "collection" && (
                        <>
                          <label className="form-label w-100">
                            Collection Name
                          </label>
                          <Controller
                            name="collection"
                            control={control}
                            rules={{
                              required: "Collection is required.",
                            }}
                            render={({ field }) => (
                              <Select
                                {...field}
                                styles={CustomStyles(theme)}
                                className="custom-select"
                                maxMenuHeight={200}
                                options={collection}
                                onChange={(selected) => {
                                  setValue("collection", selected);
                                  clearErrors("collection");
                                }}
                                isSearchable={true}
                              />
                            )}
                          />
                          {errors.collection && (
                            <p className="errorMsg text-danger fs-7">
                              {errors.collection.message}
                            </p>
                          )}
                        </>
                      )}
                    </div> */}
                    <div className="mb-4">
                      <label className="form-label w-100">Assistant Name</label>
                      <Controller
                        name="assistant"
                        control={control}
                        rules={{
                          required: "Assistant is required.",
                        }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            styles={CustomStyles(theme)}
                            className="custom-select"
                            maxMenuHeight={200}
                            options={assistantSessionList.map((tab) => ({
                              value: tab.idt_user_assistant,
                              label: tab.name,
                            }))}
                            onChange={(selected) => {
                              setValue("assistant", selected);
                              clearErrors("assistant");
                            }}
                            isSearchable={true}
                          />
                        )}
                      />
                      {errors.assistant && (
                        <p className="errorMsg text-danger fs-7">
                          {errors.assistant.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="hstack gap-2 justify-content-end">
                <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                  <Button
                    type="submit"
                    className="btn btn-primary w-sm"
                    disabled={loading ? "disabled" : ""}
                  >
                    {loading ? (
                      <>
                        <Spinner
                          className="flex-shrink-0"
                          animation="border"
                          size="sm"
                        />
                      </>
                    ) : (
                      "Update"
                    )}
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </SimpleBar>
      </Modal>
    </>
  );
};

export default ModalUpdateAccessKey;
