import { useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import bookComp from "../assets/images/book-comp.png";
import image_knowledge from "../assets/images/knowledge.png";
import { useLanguage } from "../provider/languageContext";
import { ThemeContext } from "../provider/themeContext";
import SimpleBar from "simplebar-react";
import ReactStars from "react-rating-stars-component";
import moment from "moment/moment";

const ModalDetailReview = ({ data }) => {
  const { messagesLang } = useLanguage();
  const { theme } = useContext(ThemeContext);
  const [show, setShow] = useState(false);
  const [mainImage, setMainImage] = useState(image_knowledge);
  const [activeThumbnail, setActiveThumbnail] = useState();
  const handleThumbnailClick = (image) => {
    setMainImage(image);
    setActiveThumbnail(image);
  };

  const handleClose = () => {
    setShow(false);
    reset();
  };
  const handleShow = () => {
    setShow(true);
  };

  const { register, reset } = useForm({
    reValidateMode: "onBlur",
  });

  return (
    <>
      <a className="link-orange cursor-pointer" onClick={handleShow}>
        {messagesLang.view_review}
      </a>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        backdrop="static"
        dialogClassName="custom-modal-collection fade"
        onHide={handleClose}
      >
        <Modal.Header className="p-3 bg-light-dark" closeButton>
          <h5 className="modal-title">{messagesLang.view_review}</h5>
        </Modal.Header>
        <SimpleBar className="modal-body knowledge">
          <div className="col-12">
            <div className="row">
              <div className="col-md-5">
                <div className="image-gallery">
                  <div className="main-image d-none d-sm-block">
                    <img
                      src={
                        data.idt_knowledge !== undefined
                          ? data.knowledge_cover_pic.length > 0
                            ? data.knowledge_cover_pic[0].pathurl
                            : image_knowledge
                          : data.url_coverpic
                          ? data.url_coverpic
                          : bookComp
                      }
                      className="object-fit-cover"
                      alt="Main"
                    />
                  </div>
                  {/* {data.knowledge_cover_pic > 1 ? (
                    <div className="thumbnail-images">
                      {data.knowledge_cover_pic.map((thumbnail, index) => (
                        <div
                          key={index}
                          className={`thumbnail-wrapper object-fit-cover d-none d-sm-block ${
                            activeThumbnail === thumbnail.pathurl
                              ? "active"
                              : ""
                          }`}
                          onClick={() =>
                            handleThumbnailClick(thumbnail.pathurl)
                          }
                        >
                          <img
                            src={thumbnail.pathurl}
                            alt={`Thumbnail ${index + 1}`}
                            className={
                              activeThumbnail === thumbnail.pathurl
                                ? "object-fit-cover active"
                                : "object-fit-cover"
                            }
                          />
                          <div className="thumbnail-overlay"></div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <></>
                  )} */}
                </div>
              </div>
              <div className="col-md-7">
                <h5 className="fs-15">
                  {data.idt_knowledge !== undefined ? data.title : data.name}
                </h5>
                {data.idt_knowledge !== undefined ? (
                  <>
                    <p className="text-muted mb-0">
                      Author: <span className="fw-medium">{data.author}</span>
                    </p>
                    <p className="text-muted mb-0">
                      Publisher:{" "}
                      <span className="fw-medium">
                        {data.knowledge_publisher.name}
                      </span>
                    </p>
                    <p className="text-muted mb-0">
                      Category:{" "}
                      <span className="fw-medium">
                        {data.knowledge_category_map.map((data, index) => {
                          return index === 0
                            ? data.category.name
                            : `, ${data.category.name}`;
                        })}
                      </span>
                    </p>
                  </>
                ) : (
                  <p className="text-muted mb-0">Collection</p>
                )}
                <p className="text-muted mb-0">
                  Uploaded By:{" "}
                  <span className="fw-medium">{`(${data.user.user_profile.name_first} 
                                      ${data.user.user_profile.name_last})`}</span>
                </p>
                <hr />
                <div className="w-100 mb-3">
                  <div className="d-flex justify-content-between">
                    <label className="form-label w-50 mb-0">Rating</label>
                    <p className="fs-11 mb-0">
                      {moment(
                        data.idt_knowledge != undefined
                          ? data.knowledge_review[0].ts_created
                          : data.knowledge_collection_review[0].ts_created
                      ).format("MMMM D, YYYY h:mm A")}
                    </p>
                  </div>
                  <ReactStars
                    count={5}
                    value={
                      data.idt_knowledge != undefined
                        ? data.knowledge_review[0].rating
                        : data.knowledge_collection_review[0].rating
                    }
                    size={24}
                    // isHalf={true}
                    emptyIcon={<i className="ri-star-fill"></i>}
                    // halfIcon={<i className="ri-star-half-fill"></i>}
                    fullIcon={<i className="ri-star-fill"></i>}
                    activeColor="#ffd700"
                    edit={false}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="mb-4">
                  <textarea
                    className="form-control"
                    name="review"
                    {...register("review", {
                      required: false,
                    })}
                    value={
                      data.idt_knowledge != undefined
                        ? data.knowledge_review[0].review
                        : data.knowledge_collection_review[0].review
                    }
                    readOnly="readonly"
                    style={{ minHeight: "100px", overflow: "hidden" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </SimpleBar>
      </Modal>
    </>
  );
};

export default ModalDetailReview;
