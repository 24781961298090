import React, { createContext, useState, useEffect } from "react";
import { API_URL } from "../utilities/url";
import axios from "axios";
import storage from "../services/storage";

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState([]);
  const [loadingItems, setLoadingItems] = useState([]);
  const [countOrderProcess, setCountOrderProcess] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [promoCode, setPromoCode] = useState(() => {
    const savedSelectedPromo = localStorage.getItem("selectedPromo");
    return savedSelectedPromo ? JSON.parse(savedSelectedPromo) : [];
  });
  const [selectedItemIds, setSelectedItemIds] = useState(() => {
    const savedSelectedItemIds = localStorage.getItem("selectedItemIds");
    return savedSelectedItemIds ? JSON.parse(savedSelectedItemIds) : [];
  });

  // Fetch items from API
  const loadDataCart = async () => {
    try {
      const response = await axios.post(
        API_URL + "get-knowledge-cart",
        { page: 1, limit: 100 },
        {
          headers: {
            Authorization: `Bearer ${storage.get("access_token")}`,
          },
        }
      );
      const data = await response.data.data.data;
      setCartItems(data);
    } catch (error) {
      console.log("Error fetching items:", error);
    }
  };

  useEffect(() => {
    loadDataCart();
  }, []);

  useEffect(() => {
    const selectedItems = cartItems.filter((item) =>
      selectedItemIds.includes(item.idt_knowledge)
    );
    // const total = selectedItems.reduce((acc, item) => acc + item.price, 0);
    const total = 0;
    setTotalPrice(total);
    localStorage.setItem("selectedItemIds", JSON.stringify(selectedItemIds));
    localStorage.setItem("selectedPromo", JSON.stringify(promoCode));
  }, [selectedItemIds, cartItems, promoCode]);

  const addItemToCart = (data) => {
    const updatedCartItems = [...cartItems, data];
    const sortedCartItems = updatedCartItems.sort(
      (a, b) => b.idt_knowledge_order_cart - a.idt_knowledge_order_cart
    );
    setCartItems(sortedCartItems);
  };

  const removeItemFromCart = (itemId) => {
    setLoadingItems((prevLoadingItems) => [...prevLoadingItems, itemId]);
    axios
      .post(
        API_URL + "remove-knowledge-cart",
        { idt_knowledge_order_cart: itemId },
        {
          headers: {
            Authorization: `Bearer ${storage.get("access_token")}`,
          },
        }
      )
      .then((response) => {
        if (response.data.success === true) {
          setCartItems(
            cartItems.filter((item) => item.idt_knowledge_order_cart !== itemId)
          );
          setSelectedItemIds(selectedItemIds.filter((item) => item !== itemId));
          setLoadingItems((prevLoadingItems) =>
            prevLoadingItems.filter((id) => id !== itemId)
          );
        } else {
          console.log(response.data.success);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const loadDataOrder = async () => {
    try {
      const response = await axios.get(
        API_URL + "count-knowledge-order-process",
        {
          headers: {
            Authorization: `Bearer ${storage.get("access_token")}`,
          },
        }
      );
      const data = await response.data.data;
      setCountOrderProcess(data);
    } catch (error) {
      console.log("Error loading data:", error);
    } finally {
    }
  };

  const separatePrice = (itemId) => {
    itemId = itemId.toString();
    var pattern = /(-?\d+)(\d{3})/;
    while (pattern.test(itemId)) itemId = itemId.replace(pattern, "$1,$2");
    return itemId;
  };

  return (
    <CartContext.Provider
      value={{
        cartItems,
        setCartItems,
        loadingItems,
        selectedItemIds,
        setSelectedItemIds,
        totalPrice,
        setTotalPrice,
        promoCode,
        setPromoCode,
        addItemToCart,
        removeItemFromCart,
        separatePrice,
        countOrderProcess,
        setCountOrderProcess,
        loadDataCart,
        loadDataOrder,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
