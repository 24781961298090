import moment from "moment";
import React from "react";

const Footer = () => {
  const year = moment().format("YYYY");
  return (
    <div>
      <footer className="footer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6 text-footer">{year} © Otobaca.</div>
            <div className="col-sm-6 text-footer">
              <div className="text-sm-end d-none d-sm-block">
                Powered by Otokata
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
