import React, { createContext, useRef, useEffect, useState } from "react";
import CryptoJS from "crypto-js";
import storage from "../services/storage";

export const WebSocketContext = createContext();

export const WebSocketProvider = ({ children }) => {
  const ws = useRef(null);
  const [dataSocket, setDataSocket] = useState(null);

  const decryptUSR = CryptoJS.AES.decrypt(storage.get("user"), "user").toString(
    CryptoJS.enc.Utf8
  );
  const usr = JSON.parse(decryptUSR);

  useEffect(() => {
    // Establish WebSocket connection
    ws.current = new WebSocket(
      `wss://otokata.id:8070/notification?user_id=${usr.idt_user}`
    );

    ws.current.onopen = () => {
      console.log("WebSocket connection established");
    };

    ws.current.onmessage = (event) => {
      const data = JSON.parse(event.data);
      setDataSocket(data);
      console.log(data);
    };

    ws.current.onclose = () => {
      console.log("WebSocket connection closed");
    };

    ws.current.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    // Cleanup WebSocket connection on component unmount
    return () => {
      ws.current.close();
    };
  }, [usr.idt_user]);

  return (
    <WebSocketContext.Provider value={{ ws: ws.current, dataSocket }}>
      {children}
    </WebSocketContext.Provider>
  );
};
