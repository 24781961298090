import React from "react";
import Select from "react-select";

const Pagination = ({
  cardsPerPage,
  totalCards,
  currentPage,
  onPageChange,
}) => {
  const totalPages = Math.ceil(totalCards / cardsPerPage);
  const pageNumbers = [];

  const getPageNumbers = () => {
    const maxVisiblePages = 3;
    let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
    let endPage = Math.min(
      totalPages,
      currentPage + Math.floor(maxVisiblePages / 2)
    );

    if (endPage - startPage + 1 < maxVisiblePages) {
      if (startPage === 1) {
        endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);
      } else if (endPage === totalPages) {
        startPage = Math.max(1, endPage - maxVisiblePages + 1);
      }
    }

    return { startPage, endPage };
  };

  const { startPage, endPage } = getPageNumbers();

  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  return totalPages > 0 ? (
    <div className="pagination-wrap hstack gap-2 justify-content-center mt-3 mb-3">
      <button
        className={
          currentPage === 1
            ? "pagination-prev disabled"
            : "pagination-prev cursor-pointer"
        }
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        Previous
      </button>

      <ul className="pagination listjs-pagination mb-0">
        {startPage > 1 && (
          <>
            <li>
              <button className="page" onClick={() => onPageChange(1)}>
                1
              </button>
            </li>
            {startPage > 2 && <span>...</span>}
          </>
        )}
        {pageNumbers.map((number, index) => (
          <li key={index} className={number === currentPage ? "active" : ""}>
            <button
              key={number}
              className={number === currentPage ? "page active" : "page"}
              onClick={() => onPageChange(number)}
            >
              {number}
            </button>
          </li>
        ))}
        {endPage < totalPages && (
          <>
            {endPage < totalPages - 1 && <span>...</span>}
            <li>
              <button
                className="page cursor-pointer"
                onClick={() => onPageChange(totalPages)}
              >
                {totalPages}
              </button>
            </li>
          </>
        )}
      </ul>
      <button
        className={
          currentPage === totalPages
            ? "pagination-next disabled"
            : "pagination-next cursor-pointer"
        }
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        Next
      </button>
    </div>
  ) : (
    <></>
  );
};

export default Pagination;
