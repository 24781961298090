import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, NavLink } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import { API_URL } from "../utilities/url";
import logoImg from "../assets/images/logo-dark.png";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplateNoReload,
  validateCaptcha,
  LoadCanvasTemplate,
} from "react-simple-captcha";
import { HiOutlineMail } from "react-icons/hi";
import { useLanguage } from "../provider/languageContext";
import HeaderLanguage from "./component/headerLanguage";

const ForgotPassword = () => {
  const { messagesLang } = useLanguage();
  const year = moment().format("YYYY");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertMessageSuccess, setAlertMessageSuccess] = useState("");
  const [hideAlert, setHideAlert] = useState(true);
  const [hideAlertSuccess, setHideAlertSuccess] = useState(true);

  useEffect(() => {
    // Set Captcha
    loadCaptchaEnginge(8, "grey", "white");
  }, [hideAlertSuccess]);

  /* Form Submit */
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    reValidateMode: "onBlur",
  });
  const onSubmit = (data) => {
    let captcha_value = document.getElementById("captcha").value;
    document.getElementById("submit").classList.add("disabled");
    if (validateCaptcha(captcha_value) == true) {
      axios
        .post(API_URL + "forgot-password", data)
        .then((response) => {
          if (response.data.success === true) {
            setHideAlertSuccess(false);
            setAlertMessageSuccess("Reset password has been sent to email.");
            document.getElementById("submit").classList.remove("disabled");
          } else if (response.data.message == "Email not sent") {
            setHideAlert(false);
            setAlertMessage("Cannot send to email.");
            document.getElementById("submit").classList.remove("disabled");
          } else {
            setHideAlert(false);
            setAlertMessage("Email has not exist.");
            document.getElementById("submit").classList.remove("disabled");
          }
        })
        .catch((error) => {
          document.getElementById("submit").classList.remove("disabled");
        });
    } else {
      setHideAlert(false);
      setAlertMessage("Captcha don't match.");
      document.getElementById("submit").classList.remove("disabled");
    }
  };
  return (
    <div className="auth-page-content pb-0">
      <div className="container">
        <div className="row">
          <div className="d-flex justify-content-between mt-sm-4 mb-sm-2 text-white-50">
            <Link to="/login">
              <img
                src={logoImg}
                alt=""
                height="40"
                className="d-none d-sm-block"
              />
            </Link>
            <div className="head-lang">
              <HeaderLanguage />
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-8 col-lg-6 col-xl-5">
            <div className="centered-container">
              <div className="card login rounded-4 mt-4">
                <div className="card-body p-4">
                  <div className="text-center mt-2">
                    <h5 className="text-light text-capitalize">
                      {messagesLang.forgot_password}
                    </h5>
                  </div>
                  <div className="p-2 mt-4">
                    <div
                      className={`alert alert-danger alert-dismissible fade show ${
                        hideAlert ? "d-none" : "d-block"
                      }`}
                      role="alert"
                    >
                      <button
                        onClick={() => setHideAlert(true)}
                        type="button"
                        className="btn-close"
                        aria-label="Close"
                      ></button>
                      <div className="alert-message">
                        <strong className="text-danger">{alertMessage}</strong>
                      </div>
                    </div>
                    <div
                      className={`alert alert-success alert-dismissible fade show ${
                        hideAlertSuccess ? "d-none" : "d-block"
                      }`}
                      role="alert"
                    >
                      <button
                        onClick={() => setHideAlertSuccess(true)}
                        type="button"
                        className="btn-close"
                        aria-label="Close"
                      ></button>
                      <div className="alert-message">
                        <strong className="text-success">
                          {alertMessageSuccess}
                        </strong>
                      </div>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="mb-3">
                        <label
                          htmlFor="username"
                          className="form-label text-light w-100"
                        >
                          Email
                        </label>
                        <div className="position-relative auth-pass-inputgroup mb-3">
                          <a className="btn btn-link position-absolute start-0 top-0 text-decoration-none text-muted">
                            <HiOutlineMail size={20} />
                          </a>
                          <input
                            type="text"
                            className="form-control ps-5"
                            name="email"
                            {...register("email", {
                              required: true,
                              pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                            })}
                          />
                          {errors.email && errors.email.type === "required" && (
                            <p className="errorMsg text-danger fs-7">
                              Email is required.
                            </p>
                          )}
                          {errors.email && errors.email.type === "pattern" && (
                            <p className="errorMsg text-danger fs-7">
                              Email is not valid.
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="mb-3 text-center">
                        <LoadCanvasTemplate />
                        <div className="col-md-12 col-lg-12 mt-2">
                          <div className="error-placeholder">
                            <input
                              id="captcha"
                              name="captcha"
                              {...register("captcha", {
                                required: true,
                              })}
                              type="text"
                              className="form-control w-75 mx-auto required"
                            />
                            {errors.captcha &&
                              errors.captcha.type === "required" && (
                                <p className="errorMsg text-danger fs-7">
                                  Captcha is required.
                                </p>
                              )}
                          </div>
                        </div>
                      </div>
                      <div className="mt-4">
                        <button
                          className="btn btn-success w-100"
                          type="submit"
                          id="submit"
                        >
                          {messagesLang.submit}
                        </button>
                      </div>
                    </form>
                    <div className="mt-2 text-center">
                      <div className="mt-4">
                        <p className="mb-0 text-light">
                          {messagesLang.already_have_account}?{" "}
                          <NavLink
                            to="/login"
                            className="fw-semibold text-orange text-decoration-underline link-underline-opacity-100-hover"
                          >
                            {" "}
                            {messagesLang.sign_in}{" "}
                          </NavLink>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
