import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useLanguage } from "../../provider/languageContext";
import { CartContext } from "../../provider/cartContext";
import CheckoutTab from "./component/checkoutTab";
import CryptoJS from "crypto-js";
import storage from "../../services/storage";
import { format_number } from "../../utilities/ext-function";
import image_knowledge from "../../assets/images/knowledge.png";
import bookComp from "../../assets/images/book-comp.png";

const Checkout = () => {
  const { messagesLang } = useLanguage();
  const navigate = useNavigate();
  const location = useLocation();
  const [checkoutData, setCheckoutData] = useState(null);
  const [totalPrice, setTotalPrice] = useState(null);
  const { cartItems, promoCode, selectedItemIds, separatePrice } =
    useContext(CartContext);

  useEffect(() => {
    const data = storage.get("cd");
    if (data) {
      const decryptedData = CryptoJS.AES.decrypt(data, "checkout").toString(
        CryptoJS.enc.Utf8
      );
      const dataSet = JSON.parse(decryptedData);
      if (dataSet.title === undefined) {
        const priceSell = cartItems
          .filter((item) => selectedItemIds.includes(item.id))
          .reduce(
            (acc, item) => acc + item.knowledge.knowledge_public[0].price_sell,
            0
          );
        const priceRent = cartItems
          .filter((item) => selectedItemIds.includes(item.id))
          .reduce(
            (acc, item) => acc + item.knowledge.knowledge_public[0].price_rent,
            0
          );
        setTotalPrice(priceSell + priceRent > 0 ? priceSell + priceRent : 0);
        setCheckoutData(JSON.parse(decryptedData));
      } else {
        const priceSell = JSON.parse(decryptedData).knowledge_public[0]
          .price_sell
          ? JSON.parse(decryptedData).knowledge_public[0].price_sell
          : 0;
        const priceRent = JSON.parse(decryptedData).knowledge_public[0]
          .price_rent
          ? (priceRent =
              JSON.parse(decryptedData).knowledge_public[0].price_rent)
          : 0;
        setTotalPrice(priceSell + priceRent > 0 ? priceSell + priceRent : 0);
        setCheckoutData(JSON.parse(decryptedData));
      }
    } else {
      navigate("/cart");
    }
    return () => {
      storage.remove("cd");
    };
  }, []);

  useEffect(() => {
    if (checkoutData) {
      storage.setUnlimited(
        "cd",
        CryptoJS.AES.encrypt(
          JSON.stringify(checkoutData),
          "checkout"
        ).toString()
      );
    }
  }, [checkoutData]);

  useEffect(() => {
    const handleRouteChange = () => {
      if (location.pathname !== "/checkout") {
        storage.remove("cd");
      }
    };

    handleRouteChange();

    return () => {
      handleRouteChange();
    };
  }, [location]);

  const getPrice = (card) => {
    if (card.idt_knowledge !== undefined) {
      if (card.knowledge_public.length > 0) {
        if (card.knowledge_public[0].pricing_scheme === "paid") {
          return "Rp. " + format_number(card.knowledge_public[0].price_sell);
        } else if (card.knowledge_public[0].pricing_scheme === "rent") {
          return "Rp. " + format_number(card.knowledge_public[0].price_rent);
        } else {
          return "Free";
        }
      } else {
        return "Free";
      }
    } else {
      return `${card.knowledge_collection_map.length} Knowledge`;
    }
  };

  return (
    <div className="page-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-8">
            <div className="card">
              <div className="card-body checkout-tab">
                <div>
                  <h5 className="mb-1">{messagesLang.payment_method}</h5>
                  <p className="text-muted mb-4">
                    {messagesLang.please_select_payment_method}
                  </p>
                </div>

                <div className="row g-12">
                  <div className="col-lg-12 col-sm-12">
                    <CheckoutTab item={checkoutData} total={totalPrice} />
                  </div>
                </div>

                {/* <div className="d-flex align-items-start gap-3 mt-4">
                    <Link
                      to="/cart"
                      className="btn btn-danger btn-label previestab"
                    >
                      <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>
                      Back to Cart
                    </Link>
                    <button
                      type="button"
                      className="btn btn-orange btn-label right ms-auto nexttab"
                      data-nexttab="pills-finish-tab"
                    >
                      <i className="ri-shopping-basket-line label-icon align-middle fs-16 ms-2"></i>
                      Complete Order
                    </button>
                  </div> */}
              </div>
            </div>
          </div>

          <div className="col-xl-4">
            <div className="card">
              <div className="card-header">
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <h5 className="card-title mb-0">
                      {messagesLang.order_summary}
                    </h5>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="table-responsive table-card">
                  <table className="table table-borderless align-middle mb-0">
                    <thead className="table-light text-muted">
                      <tr>
                        <th style={{ width: "90px", scope: "col" }}>Product</th>
                        <th scope="col">Product Info</th>
                        <th scope="col" className="text-end">
                          {messagesLang.price}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {checkoutData ? (
                        <>
                          {checkoutData.map((item, index) => (
                            <tr key={index}>
                              <td>
                                <div className="rounded">
                                  <img
                                    src={
                                      item.title !== undefined
                                        ? item.knowledge_cover_pic.length > 0
                                          ? item.knowledge_cover_pic[0].pathurl
                                          : image_knowledge
                                        : item.name !== undefined
                                        ? item.url_coverpic
                                          ? item.url_coverpic
                                          : bookComp
                                        : item.knowledge
                                        ? item.knowledge.knowledge_cover_pic
                                            .length > 0
                                          ? item.knowledge
                                              .knowledge_cover_pic[0].pathurl
                                          : image_knowledge
                                        : item.knowledge_collection.url_coverpic
                                        ? item.knowledge_collection.url_coverpic
                                        : bookComp
                                    }
                                    alt=""
                                    className="d-block image-product-xs"
                                  />
                                </div>
                              </td>
                              <td className="align-top">
                                <h5 className="fs-14 mb-1">
                                  <a href="#" className="text-body">
                                    {item.title !== undefined
                                      ? item.title
                                      : item.name !== undefined
                                      ? item.name
                                      : item.knowledge
                                      ? item.knowledge.name
                                      : item.knowledge_collection.name}
                                  </a>
                                </h5>
                                {item.knowledge ? (
                                  <>
                                    <p className="text-muted fs-12 mb-0">
                                      {item.knowledge.author}
                                    </p>
                                    <p className="text-muted fs-12 mb-0">
                                      {item.knowledge.knowledge_publisher.name}
                                    </p>
                                    <p className="text-muted fs-12 mb-0">
                                      {item.knowledge.knowledge_category_map.map(
                                        (data, index) => {
                                          return index === 0
                                            ? data.category.name
                                            : `, ${data.category.name}`;
                                        }
                                      )}
                                    </p>
                                  </>
                                ) : (
                                  <ul className="list-inline text-muted mb-0">
                                    <li className="list-inline-item">
                                      Collection
                                    </li>
                                  </ul>
                                )}
                                <p className="text-muted fs-12 mb-0">
                                  {item.title !== undefined
                                    ? `(${item.user.user_profile.name_first} 
                                      ${item.user.user_profile.name_last})`
                                    : item.name !== undefined
                                    ? `(${item.user.user_profile.name_first} 
                                      ${item.user.user_profile.name_last})`
                                    : item.knowledge
                                    ? `(${item.knowledge.user.user_profile.name_first} 
                                      ${item.knowledge.user.user_profile.name_last})`
                                    : `(${item.knowledge_collection.user.user_profile.name_first} 
                                      ${item.knowledge_collection.user.user_profile.name_last})`}
                                </p>
                              </td>
                              <td className="text-end">
                                {item.title !== undefined
                                  ? getPrice(item)
                                  : item.name !== undefined
                                  ? getPrice(item)
                                  : item.knowledge
                                  ? getPrice(item.knowledge)
                                  : getPrice(item.knowledge_collection)}
                              </td>
                            </tr>
                          ))}
                          <tr>
                            <td className="fw-semibold" colspan="2">
                              Sub Total :
                            </td>
                            <td className="fw-semibold text-end">
                              {separatePrice(totalPrice)}
                            </td>
                          </tr>
                          {promoCode.name === "" ? (
                            <tr>
                              <td colspan="2">
                                {messagesLang.discount}{" "}
                                <span className="text-muted">
                                  ({promoCode.name})
                                </span>{" "}
                                :{" "}
                              </td>
                              <td className="text-end">- {promoCode.value}</td>
                            </tr>
                          ) : (
                            <tr>
                              <td colspan="2">{messagesLang.discount} : </td>
                              <td className="text-end">-</td>
                            </tr>
                          )}
                          <tr>
                            <td colspan="2">{messagesLang.tax} (5%): </td>
                            <td className="text-end">
                              {separatePrice(totalPrice * 0.05)}
                            </td>
                          </tr>
                          <tr className="table-active">
                            <th colspan="2">Total (IDR) :</th>
                            <td className="text-end">
                              <span className="fw-semibold">
                                {separatePrice(totalPrice + totalPrice * 0.05)}
                              </span>
                            </td>
                          </tr>
                        </>
                      ) : (
                        <></>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
