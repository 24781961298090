import axios from "axios";
import React, { createContext, useEffect, useState } from "react";
import { API_URL } from "../utilities/url";
import storage from "../services/storage";

export const AssistantContext = createContext();

export const AssistantProvider = ({ children }) => {
  const [assistantList, setAssistantList] = useState([]);
  const [assistantSessionList, setAssistantSessionList] = useState([]);
  const [firstLoad, setFirstLoad] = useState(true);
  const [selectedAssistant, setSelectedAssistant] = useState(null);
  const [selectedSessionAssistant, setSelectedSessionAssistant] =
    useState(null);
  const [activeAssistant, setActiveAssistant] = useState(null);
  const [defaultAssistant, setDefaultAssistant] = useState(null);
  const [loadingAs, setLoadingAs] = useState(false);

  useEffect(() => {
    axios
      .post(
        API_URL + "get-personal-assistant-list",
        { page: 1, limit: 500 },
        {
          headers: {
            Authorization: `Bearer ${storage.get("access_token")}`,
          },
        }
      )
      .then((response) => {
        if (response.data.success === true) {
          setAssistantSessionList(
            response.data.data.data ? response.data.data.data : []
          );
          setDefaultAssistant(
            response.data.data.data
              .filter((item) => item.default !== null)
              .map((item) => item.idt_user_assistant)[0]
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const loadAssistant = async () => {
    try {
      setLoadingAs(true);
      const response = await axios.post(
        API_URL + "get-personal-assistant-list",
        { status: 0 },
        {
          headers: {
            Authorization: `Bearer ${storage.get("access_token")}`,
          },
        }
      );
      const data = await response.data.data;
      setAssistantList(data ? data : []);
      setLoadingAs(false);
    } catch (error) {
      console.log("Error loading data:", error);
      setLoadingAs(false);
    }
  };

  return (
    <AssistantContext.Provider
      value={{
        assistantList,
        setAssistantList,
        assistantSessionList,
        setAssistantSessionList,
        selectedAssistant,
        setSelectedAssistant,
        selectedSessionAssistant,
        setSelectedSessionAssistant,
        firstLoad,
        setFirstLoad,
        activeAssistant,
        setActiveAssistant,
        defaultAssistant,
        setDefaultAssistant,
        loadingAs,
        setLoadingAs,
        loadAssistant,
      }}
    >
      {children}
    </AssistantContext.Provider>
  );
};
