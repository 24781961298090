import React from "react";

const ProgressBar = ({ progress }) => {
  return (
    <div class="c-progress card bg-light overflow-hidden">
      <div className="progress bg-warning-subtle rounded-0">
        <div
          className="progress-bar bg-warning"
          role="progressbar"
          style={{ width: Math.round(progress) + "%" }}
          aria-valuenow={Math.round(progress)}
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
      <div className="d-flex justify-content-center">
        <p className="mt-1 text-light fs-12 fw-bold">
          Progress {Math.round(progress)}%
        </p>
      </div>
    </div>
  );
};

export default ProgressBar;
