import Skeleton from "react-loading-skeleton";

const CardSkeleton = () => (
  <div className="p-25">
    <div className="card card-list bg-light shadow-lg cursor-pointer">
      <div className="card-body">
        <div className="square-container">
          <Skeleton className="custom-skeleton" />
        </div>
        <Skeleton className="custom-skeleton" height={24} />
        <Skeleton className="custom-skeleton" height={15} />
        <Skeleton className="custom-skeleton" height={15} />
        <Skeleton className="custom-skeleton" height={15} />
      </div>
    </div>
  </div>
);

export default CardSkeleton;
