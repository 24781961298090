import React from "react";
import ReactStars from "react-rating-stars-component";
import { Button, Spinner } from "react-bootstrap";
import { useLanguage } from "../provider/languageContext";
import moment from "moment";

const ReviewKnowledge = ({
  reviews,
  loadMoreReviews,
  loadingReview,
  hasMore,
}) => {
  const { messagesLang } = useLanguage();
  const handleLoad = (data) => {
    loadMoreReviews(data);
  };

  return (
    <div>
      {reviews.length > 0 ? (
        reviews.map((review, index) => (
          <div key={index} className="card bg-light shadow-lg card-body">
            <div className="d-flex align-items-center">
              {/* <div className="flex-shrink-0">
                <FaUserCircle size={18} />
              </div> */}
              <div className="flex-grow-1">
                <p className="mb-0">
                  {review.user.user_profile.name_first}{" "}
                  {review.user.user_profile.name_last}
                </p>
                <div className="rating">
                  <ReactStars
                    key={review.idt_knowledge_review}
                    count={5}
                    value={review.rating}
                    size={20}
                    isHalf={true}
                    activeColor="#ffd700"
                    edit={false}
                  />
                  <span className="text-muted fs-11">
                    {moment(review.ts_created).format("MMMM D, YYYY h:mm A")}
                  </span>
                </div>
                <hr style={{ margin: "0.5rem 0" }} />
              </div>
            </div>
            <p className="card-text text-muted">{review.review}</p>
          </div>
        ))
      ) : (
        <p>{messagesLang.no_data_review}</p>
      )}
      {hasMore && (
        <Button
          className="btn-info"
          onClick={() =>
            handleLoad(
              reviews[0].idt_knowledge !== undefined
                ? reviews[0].idt_knowledge
                : reviews[0].idt_knowledge_collection,
              reviews[0].idt_knowledge !== undefined
                ? "knowledge"
                : "collection"
            )
          }
          disabled={loadingReview}
          style={{ width: "100px" }}
        >
          {loadingReview ? (
            <>
              <Spinner className="flex-shrink-0" animation="border" size="sm" />
            </>
          ) : (
            "Load More"
          )}
        </Button>
      )}
    </div>
  );
};

export default ReviewKnowledge;
