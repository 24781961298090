import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { SliderRecentContext } from "../../../provider/sliderRecentContext";
import { CardSkeleton } from "../../../components";
import { SessionContext } from "../../../provider/sessionContext";
import axios from "axios";
import { API_URL } from "../../../utilities/url";
import storage from "../../../services/storage";
import moment from "moment";
import { AssistantContext } from "../../../provider/assistantContext";

const CarouselSession = () => {
  const navigate = useNavigate();
  const { loading } = useContext(SliderRecentContext);
  const {
    sessionList,
    setSessionList,
    setSelectedSession,
    setActiveSession,
    setActiveMessage,
    setMessages,
  } = useContext(SessionContext);
  const { setSelectedSessionAssistant } = useContext(AssistantContext);
  useEffect(() => {
    if (sessionList.length < 1) {
      axios
        .post(
          API_URL + "get-session-list",
          { status: 0 },
          {
            headers: {
              Authorization: `Bearer ${storage.get("access_token")}`,
            },
          }
        )
        .then((response) => {
          if (response.data.success === true) {
            setSessionList(response.data.data ? response.data.data : []);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  const handleChat = async (item) => {
    setMessages([]);
    setActiveSession(item.idt_session);
    setSelectedSession(item);
    setSelectedSessionAssistant({
      value: item.assistant.idt_user_assistant,
      label: item.assistant.name,
    });
    setActiveMessage(true);
    // Get Message
    try {
      // Tunggu hingga state diatur sebelum melakukan permintaan API
      await new Promise((resolve) => setTimeout(resolve, 0));

      // Get Message
      const timestamp = Math.floor(Date.now() / 1000);
      const countMessage = 50;
      const response = await axios.post(
        `${API_URL}get-message-chat`,
        {
          limit: countMessage,
          idt_session: item.idt_session,
          timestamp: timestamp,
        },
        {
          headers: {
            Authorization: `Bearer ${storage.get("access_token")}`,
          },
        }
      );

      if (response.data.success === true) {
        setMessages(response.data.data ? response.data.data : []);
      }
    } catch (error) {
      console.log(error);
    } finally {
      // Matikan spinner
      navigate("/chat");
    }
  };

  return sessionList.length > 0 ? (
    <div className="container-fluid w-85">
      <div className="row">
        <div className="d-flex active collapsed">
          <div className="file-list-link fs-14">Recent Session</div>
          <div className="line-with-text2"></div>
        </div>
        <div className="w-100 know-recent">
          <div className="row">
            {sessionList.slice(0, 6).map((data, index) => (
              <div key={index} className="col-md-2 mt-2">
                <div
                  className="card card-sess-list shadow-lg cursor-pointer"
                  onClick={() => handleChat(data)}
                >
                  <div className="card-body">
                    <div className="text-start">
                      <p className="text-truncate fs-12 mb-1">
                        {moment(data.ts_lastactive).format("ddd DD/MM/YYYY")} |{" "}
                        {moment(data.ts_lastactive).format("HH:mm")}
                      </p>
                      <p className="text-truncate fs-12 mb-1">{data.title}</p>
                      <p className="text-truncate fs-12 mb-0">
                        {data.idt_knowledge && data.knowledge
                          ? data.knowledge.title
                          : data.idt_knowledge_collection &&
                            data.knowledge_collection
                          ? data.knowledge_collection.name
                          : data.idt_knowledge
                          ? "Deleted Knowledge"
                          : "Deleted Collection"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  ) : loading ? (
    <div className="container-fluid w-85">
      <div className="row">
        <div className="col-md-12 mt-2">
          <div className="card bg-light shadow-lg">
            <div className="card-body">
              <div className="text-start"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default CarouselSession;
