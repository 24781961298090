import axios from "axios";
import React, { createContext, useState } from "react";
import { API_URL } from "../utilities/url";
import storage from "../services/storage";

export const SessionContext = createContext();

export const SessionProvider = ({ children }) => {
  const [sessionList, setSessionList] = useState([]);
  const [firstLoad, setFirstLoad] = useState(true);
  const [selectedSession, setSelectedSession] = useState(null);
  const [loadingSess, setLoadingSess] = useState(false);
  const [typeSession, setTypeSession] = useState("personal");
  const [activeSession, setActiveSession] = useState(false);
  const [activeMessage, setActiveMessage] = useState(false);
  const [messages, setMessages] = useState([]);
  /* Card */
  const [listView, setListView] = useState("card");

  const loadSessionPersonal = async () => {
    try {
      setLoadingSess(true);
      const response = await axios.post(
        API_URL + "get-session-list",
        { status: 0 },
        {
          headers: {
            Authorization: `Bearer ${storage.get("access_token")}`,
          },
        }
      );
      const data = await response.data.data;
      setSessionList(data ? data : []);
      setLoadingSess(false);
    } catch (error) {
      console.log("Error loading data:", error);
      setLoadingSess(false);
    }
  };

  const addBookmark = (idSession, newBookmark) => {
    setSessionList((prevSession) => {
      return prevSession.map((session) => {
        if (session.idt_session === idSession) {
          return {
            ...session,
            session_bookmark: [...newBookmark, ...session.session_bookmark],
          };
        }
        return session;
      });
    });
    setSelectedSession((prevSession) => {
      if (prevSession.idt_session === idSession) {
        return {
          ...prevSession,
          session_bookmark: [...newBookmark, ...prevSession.session_bookmark],
        };
      }
      return prevSession;
    });
  };

  const removeBookmark = (idSession, bookmarkToRemove) => {
    setSessionList((prevSession) => {
      return prevSession.map((session) => {
        if (session.idt_session === idSession) {
          const updatedBookmarks = session.session_bookmark.filter(
            (bookmark) => {
              return bookmark.idt_message !== bookmarkToRemove;
            }
          );
          return {
            ...session,
            session_bookmark: updatedBookmarks,
          };
        }
        return session;
      });
    });
    setSelectedSession((prevSession) => {
      if (prevSession.idt_session === idSession) {
        const updatedBookmarks = prevSession.session_bookmark.filter(
          (bookmark) => bookmark.idt_message !== bookmarkToRemove
        );
        return {
          ...prevSession,
          session_bookmark: updatedBookmarks,
        };
      }
      return prevSession;
    });
  };

  return (
    <SessionContext.Provider
      value={{
        sessionList,
        setSessionList,
        selectedSession,
        setSelectedSession,
        firstLoad,
        setFirstLoad,
        activeSession,
        setActiveSession,
        loadingSess,
        setLoadingSess,
        typeSession,
        setTypeSession,
        activeMessage,
        setActiveMessage,
        messages,
        setMessages,
        addBookmark,
        removeBookmark,
        loadSessionPersonal,
        listView,
        setListView,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};
