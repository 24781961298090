import React, { useContext, useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  CardKnowledge,
  CardSkeleton,
  Loading,
  ModalDetail,
  ModalSuccess,
} from "../../../components";
import { SliderRecommendedContext } from "../../../provider/sliderRecommendedContext";
import Skeleton from "react-loading-skeleton";
import { useLanguage } from "../../../provider/languageContext";
import { API_URL } from "../../../utilities/url";
import axios from "axios";
import storage from "../../../services/storage";
import { toast, Slide } from "react-toastify";
import { CartContext } from "../../../provider/cartContext";
import CryptoJS from "crypto-js";
import { useNavigate } from "react-router-dom";

const NextArrow = (props) => {
  const { className, style, onClick, currentSlide, slideCount, slidesToShow } =
    props;
  if (currentSlide + slidesToShow >= slideCount) {
    return null;
  }
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        borderRadius: "50%",
      }}
      onClick={onClick}
    />
  );
};

const PrevArrow = (props) => {
  const { className, style, onClick, currentSlide } = props;
  if (currentSlide === 0) {
    return null;
  }
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        borderRadius: "50%",
      }}
      onClick={onClick}
    />
  );
};

const getSlidesConfig = (width) => {
  if (width > 3000) {
    return 10;
  } else if (width <= 3000 && width > 2500) {
    return 8;
  } else if (width <= 2500 && width > 2000) {
    return 7;
  } else if (width <= 2000 && width > 1500) {
    return 6;
  } else if (width <= 1500 && width > 1024) {
    return 5;
  } else if (width <= 1024 && width > 600) {
    return 4;
  } else if (width <= 600) {
    return 2;
  } else {
    return 10;
  }
};

const CarouselRecommended = () => {
  const {
    sliderData,
    currentSlide,
    setCurrentSlide,
    setCurrentPage,
    loading,
    loadData,
    firstLoad,
    setFirstLoad,
    addMoreData,
  } = useContext(SliderRecommendedContext);
  const { addItemToCart } = useContext(CartContext);
  const navigate = useNavigate();
  const { messagesLang } = useLanguage();
  const [slidesConfig, setSlidesConfig] = useState(
    getSlidesConfig(window.innerWidth)
  );
  const [hasLoaded, setHasLoaded] = useState(false);
  const sliderRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [dataReview, setDataReview] = useState([]);
  const [totalDataReview, setTotalDataReview] = useState(0);
  const [starReview, setStarReview] = useState([]);
  const [selectedRatings, setSelectedRatings] = useState([]);
  const [pageReview, setPageReview] = useState(1);
  const [loadingReview, setLoadingReview] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [loadingModal, setLoadingModal] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [loadingData2, setLoadingData2] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  useEffect(() => {
    // if (window.innerWidth < 1024) {
    //   setTimeout(() => {
    //     if (sliderRef.current) {
    //       sliderRef.current.slickGoTo(0, true);
    //     }
    //   }, 1500);
    // }
    const handleResize = () => {
      setSlidesConfig(getSlidesConfig(window.innerWidth));
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (!hasLoaded && firstLoad) {
      loadData();
      setHasLoaded(true);
      setFirstLoad(false);
    }
  }, [hasLoaded, sliderData, loadData]);

  useEffect(() => {
    if (sliderRef.current) {
      const track = sliderRef.current.querySelector(
        ".know-recommend .slick-track"
      );
      if (track) {
        if (sliderData.length < slidesConfig) {
          track.classList.add("slick-lock");
        } else {
          track.classList.remove("slick-lock");
        }
      }
    }
  }, [sliderData, slidesConfig]);

  const settings = {
    // dots: true,
    infinite: false,
    speed: 1000,
    slidesToShow: slidesConfig,
    slidesToScroll: slidesConfig,
    initialSlide: currentSlide,
    afterChange: (current) => {
      setCurrentSlide(current);
      if (current === sliderData.length - 1 && sliderData.length % 10 === 0) {
        setCurrentPage((prevPage) => prevPage + 1);
        addMoreData();
      }
    },
    nextArrow: <NextArrow slidesToShow={slidesConfig} />,
    prevArrow: <PrevArrow slidesToShow={slidesConfig} />,
  };

  const handleCardClick = (item) => {
    setSelectedItem(item);
    setLoadingModal(true);

    /* Get AVG Review */
    axios
      .post(
        API_URL + "get-knowledge-avg-review",
        {
          idt_knowledge: item.idt_knowledge,
        },
        {
          headers: {
            Authorization: `Bearer ${storage.get("access_token")}`,
          },
        }
      )
      .then((response) => {
        if (response.data.success === true) {
          setStarReview(response.data.data);
        }
      })
      .catch((error) => {
        console.log("An error occurred. Please try again later.");
      });

    /* Get Review */
    axios
      .post(
        API_URL + "get-knowledge-review",
        {
          idt_knowledge: item.idt_knowledge,
          rating: selectedRatings,
          limit: 10,
          page: pageReview,
        },
        {
          headers: {
            Authorization: `Bearer ${storage.get("access_token")}`,
          },
        }
      )
      .then((response) => {
        if (response.data.success === true) {
          setLoadingModal(false);
          setShowModal(true);
          setDataReview(response.data.data.data);
          setPageReview((prevPage) => prevPage + 1);
          setTotalDataReview((prevTotal) => {
            const updatedTotal = prevTotal + response.data.data.data.length;
            setHasMore(updatedTotal < response.data.data.meta.total_items);
            return updatedTotal;
          });
        } else {
          setLoadingModal(false);
        }
      })
      .catch((error) => {
        console.log("An error occurred. Please try again later.");
      });
  };

  const handleCloseSuccess = () => {
    setShowModal(false);
    setShowSuccessModal(false);
    setSelectedItem(null);
    setLoadingData(false);
    setLoadingData2(false);
  };

  const handleClose = () => {
    setShowModal(false);
    setSelectedItem(null);
    setLoadingData(false);
    setLoadingData2(false);
    setDataReview([]);
    setStarReview([]);
    setSelectedRatings([]);
    setTotalDataReview(0);
    setPageReview(1);
    setLoadingReview(false);
    setHasMore(true);
  };

  const handleKnowledge = (id, type) => {
    if (type === "get") {
      axios
        .post(
          API_URL + "get-knowledge-public-all",
          { id: id, page: 1, limit: 1 },
          {
            headers: {
              Authorization: `Bearer ${storage.get("access_token")}`,
            },
          }
        )
        .then((response) => {
          if (response.data.success === true) {
            setShowModal(false);
            setLoadingData2(false);
            storage.setUnlimited(
              "cd",
              CryptoJS.AES.encrypt(
                JSON.stringify(response.data.data.data),
                "checkout"
              ).toString()
            );
            navigate("/checkout");
          } else {
            toast.error(response.data.message, {
              position: "top-right",
              theme: "colored",
              transition: Slide,
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              draggable: true,
              progress: undefined,
            });
            setLoadingData2(false);
          }
        })
        .catch((error) => {
          setLoadingData2(false);
          console.log(error);
        });
    } else {
      axios
        .post(
          API_URL + "add-knowledge-cart",
          { idt_knowledge: id },
          {
            headers: {
              Authorization: `Bearer ${storage.get("access_token")}`,
            },
          }
        )
        .then((response) => {
          if (response.data.success === true) {
            setShowModal(false);
            setShowSuccessModal(true);
            addItemToCart(response.data.data);
            setLoadingData(false);
          } else {
            toast.error(response.data.message, {
              position: "top-right",
              theme: "colored",
              transition: Slide,
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              draggable: true,
              progress: undefined,
            });
            setLoadingData(false);
          }
        })
        .catch((error) => {
          setLoadingData(false);
          console.log(error);
        });
    }
  };

  const handleRatingChange = (rating, id, type) => {
    setSelectedRatings((prev) => {
      const updatedRatings = prev.includes(rating)
        ? prev.filter((r) => r !== rating)
        : [...prev, rating];

      // Panggil getReviewAPI dengan updatedRatings
      axios
        .post(
          API_URL + "get-knowledge-review",
          {
            idt_knowledge: type === "knowledge" ? id : null,
            idt_knowledge_collection: type === "collection" ? id : null,
            rating: updatedRatings,
            limit: 10,
            page: 1,
          },
          {
            headers: {
              Authorization: `Bearer ${storage.get("access_token")}`,
            },
          }
        )
        .then((response) => {
          if (response.data.success === true) {
            setDataReview(response.data.data.data);
            setPageReview(2);
            setTotalDataReview(() => {
              const updatedTotal = response.data.data.data.length;
              setHasMore(updatedTotal < response.data.data.meta.total_items);
              return updatedTotal;
            });
          }
          setLoadingReview(false);
        })
        .catch((error) => {
          console.log("An error occurred. Please try again later.");
          setLoadingReview(false);
        });

      return updatedRatings;
    });
  };

  const loadMoreReview = (id, type) => {
    if (!hasMore) return;
    setLoadingReview(true);
    axios
      .post(
        API_URL + "get-knowledge-review",
        {
          idt_knowledge: type === "knowledge" ? id : null,
          idt_knowledge_collection: type === "collection" ? id : null,
          rating: selectedRatings,
          limit: 10,
          page: pageReview,
        },
        {
          headers: {
            Authorization: `Bearer ${storage.get("access_token")}`,
          },
        }
      )
      .then((response) => {
        if (response.data.success === true) {
          setDataReview((prevData) => [
            ...prevData,
            ...response.data.data.data,
          ]);
          setPageReview((prevPage) => prevPage + 1);
          setTotalDataReview((prevTotal) => {
            const updatedTotal = prevTotal + response.data.data.data.length;
            setHasMore(updatedTotal < response.data.data.meta.total_items);
            return updatedTotal;
          });
        }
        setLoadingReview(false);
      })
      .catch((error) => {
        console.log("An error occurred. Please try again later.");
        setLoadingReview(false);
      });
  };

  return sliderData.length > 0 ? (
    <div className="container-fluid w-85">
      <div className="row">
        <div className="d-flex active collapsed">
          <div className="file-list-link fs-14">
            {messagesLang.recommended_for_you}
          </div>
          <div className="line-with-text2"></div>
        </div>
        <div className="w-100 know-recommend" ref={sliderRef}>
          <Slider ref={sliderRef} {...settings}>
            {loading
              ? sliderData.map((index) => <CardSkeleton key={index} />)
              : sliderData.map((card, index) => (
                  <div key={index} className="p-25">
                    <CardKnowledge item={card} onClick={handleCardClick} />
                  </div>
                ))}
          </Slider>
          {loadingModal && <Loading />}
          {selectedItem && (
            <ModalDetail
              show={showModal}
              handleClose={handleClose}
              item={selectedItem}
              review={dataReview}
              starReview={starReview}
              selectedRatings={selectedRatings}
              onRatingChange={handleRatingChange}
              loadMoreReview={loadMoreReview}
              loadingReview={loadingReview}
              hasMore={hasMore}
              onHandleKnowledge={handleKnowledge}
              loading={loadingData}
              setLoading={setLoadingData}
              loading2={loadingData2}
              setLoading2={setLoadingData2}
            />
          )}
          <ModalSuccess
            show={showSuccessModal}
            handleClose={handleCloseSuccess}
          />
        </div>
      </div>
    </div>
  ) : loading ? (
    <div className="container-fluid w-85">
      <div className="row">
        <Skeleton className="custom-skeleton hcl-skeleton mb-3" />
      </div>
    </div>
  ) : (
    <></>
  );
};

export default CarouselRecommended;
