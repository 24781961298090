import React, { useContext, useState } from "react";
import {
  Loading,
  ModalArchive,
  ModalDetail2,
  ModalDetailCollection,
  ModalPublish,
  ModalRemove,
  ModalRemove2,
  ModalUpdate,
  ModalUpdateCollection,
  ModalUpdateKnowledge,
  ModalViewSource,
} from "../../../components";
import { Dropdown, Spinner } from "react-bootstrap";
import { LuBookCopy, LuBookLock, LuBookUp } from "react-icons/lu";
import "react-toastify/dist/ReactToastify.css";
import { useLanguage } from "../../../provider/languageContext";
import bookComp from "../../../assets/images/book-comp.png";
import axios from "axios";
import { API_URL, API_URL_CORE } from "../../../utilities/url";
import { SessionContext } from "../../../provider/sessionContext";
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import storage from "../../../services/storage";
import { ToastrContext } from "../../../provider/toastrContext";
import { MdOutlineChat, MdOutlineRemoveRedEye } from "react-icons/md";
import image_knowledge from "../../../assets/images/knowledge.png";
import { format_number } from "../../../utilities/ext-function";
import { BiWorld } from "react-icons/bi";
import { AssistantContext } from "../../../provider/assistantContext";

const CardList = ({
  cards,
  onPublish,
  loading,
  selectedCards,
  onSelectedCardsChange,
  onSelectedCount,
  handleUpdate,
  handleArchive,
  handleDelete,
}) => {
  const {
    setMessages,
    setSessionList,
    setSelectedSession,
    setActiveSession,
    setActiveMessage,
    setTypeSession,
    loadSessionPersonal,
  } = useContext(SessionContext);
  const { defaultAssistant, setSelectedSessionAssistant } =
    useContext(AssistantContext);
  const { messagesLang } = useLanguage();
  const navigate = useNavigate();
  const [loadingModal, setLoadingModal] = useState(false);
  const { showError } = useContext(ToastrContext);
  const decryptUSR = CryptoJS.AES.decrypt(storage.get("user"), "user").toString(
    CryptoJS.enc.Utf8
  );
  const usr = JSON.parse(decryptUSR);
  const [showModalK, setShowModalK] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [dataReview, setDataReview] = useState([]);
  const [totalDataReview, setTotalDataReview] = useState(0);
  const [starReview, setStarReview] = useState([]);
  const [selectedRatings, setSelectedRatings] = useState([]);
  const [pageReview, setPageReview] = useState(1);
  const [loadingReview, setLoadingReview] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [showViewModal, setShowViewModal] = useState(false);
  const [showUpdateModalK, setShowUpdateModalK] = useState(false);
  const [showRemoveModalK, setShowRemoveModalK] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [showArchiveModalK, setShowArchiveModalK] = useState(false);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const handleCardClick = (item) => {
    onSelectedCardsChange((prevSelectedCards) => {
      let updatedSelectedCards;
      if (prevSelectedCards.includes(item)) {
        updatedSelectedCards = prevSelectedCards.filter((id) => id !== item);
      } else {
        updatedSelectedCards = [...prevSelectedCards, item];
      }

      // Memanggil onSelectedCount dengan jumlah baru
      onSelectedCount(updatedSelectedCards.length);

      return updatedSelectedCards;
    });
  };

  const handleDeleteClick = (id, type, message) => {
    handleDelete(id, type, message);
    setShowModalK(false);
    setShowModal(false);
    setShowRemoveModalK(false);
    setShowRemoveModal(false);
    setSelectedItem(null);
  };
  const handleArchiveClick = (id, type, message) => {
    handleArchive(id, type, message);
    setShowModal(false);
    setShowArchiveModalK(false);
    setShowArchiveModal(false);
    setSelectedItem(null);
  };
  const handleUpdateClick = (item, type) => {
    handleUpdate(item, type);
  };

  const handleShow = (data, type) => {
    setSelectedItem(data);
    setLoadingModal(true);
    if (type === "knowledge") {
      /* Get AVG Review */
      axios
        .post(
          API_URL + "get-knowledge-avg-review",
          {
            idt_knowledge: data.idt_knowledge,
          },
          {
            headers: {
              Authorization: `Bearer ${storage.get("access_token")}`,
            },
          }
        )
        .then((response) => {
          if (response.data.success === true) {
            setStarReview(response.data.data);
          }
        })
        .catch((error) => {
          console.log("An error occurred. Please try again later.");
        });

      /* Get Review */
      axios
        .post(
          API_URL + "get-knowledge-review",
          {
            idt_knowledge: data.idt_knowledge,
            rating: selectedRatings,
            limit: 10,
            page: pageReview,
          },
          {
            headers: {
              Authorization: `Bearer ${storage.get("access_token")}`,
            },
          }
        )
        .then((response) => {
          if (response.data.success === true) {
            setLoadingModal(false);
            setShowModal(false);
            setShowModalK(true);
            setDataReview(response.data.data.data);
            setPageReview((prevPage) => prevPage + 1);
            setTotalDataReview((prevTotal) => {
              const updatedTotal = prevTotal + response.data.data.data.length;
              setHasMore(updatedTotal < response.data.data.meta.total_items);
              return updatedTotal;
            });
          } else {
            setLoadingModal(false);
          }
        })
        .catch((error) => {
          console.log("An error occurred. Please try again later.");
        });
    } else {
      /* Get AVG Review */
      axios
        .post(
          API_URL + "get-knowledge-avg-review",
          {
            idt_knowledge_collection: data.idt_knowledge_collection,
          },
          {
            headers: {
              Authorization: `Bearer ${storage.get("access_token")}`,
            },
          }
        )
        .then((response) => {
          if (response.data.success === true) {
            setStarReview(response.data.data);
          }
        })
        .catch((error) => {
          console.log("An error occurred. Please try again later.");
        });

      /* Get Review */
      axios
        .post(
          API_URL + "get-knowledge-review",
          {
            idt_knowledge_collection: data.idt_knowledge_collection,
            rating: selectedRatings,
            limit: 10,
            page: pageReview,
          },
          {
            headers: {
              Authorization: `Bearer ${storage.get("access_token")}`,
            },
          }
        )
        .then((response) => {
          if (response.data.success === true) {
            setLoadingModal(false);
            setShowModalK(false);
            setShowModal(true);
            setDataReview(response.data.data.data);
            setPageReview((prevPage) => prevPage + 1);
            setTotalDataReview((prevTotal) => {
              const updatedTotal = prevTotal + response.data.data.data.length;
              setHasMore(updatedTotal < response.data.data.meta.total_items);
              return updatedTotal;
            });
          } else {
            setLoadingModal(false);
          }
        })
        .catch((error) => {
          console.log("An error occurred. Please try again later.");
        });
    }
  };

  const handleClose = () => {
    setSelectedItem(null);
    setShowModalK(false);
    setShowModal(false);
    setDataReview([]);
    setStarReview([]);
    setSelectedRatings([]);
    setTotalDataReview(0);
    setPageReview(1);
    setLoadingReview(false);
    setHasMore(true);
  };
  const handleView = () => {
    setShowModal(false);
    setShowViewModal(true);
  };
  const handleCloseView = () => {
    setShowModal(true);
    setShowViewModal(false);
  };
  /* Knowledge */
  const handleUpdateShowKnowledge = () => {
    setShowModalK(false);
    setShowUpdateModalK(true);
  };
  const handleCloseUpdateKnowledge = (type) => {
    if (type === undefined) {
      setShowModalK(true);
      setShowUpdateModalK(false);
    } else {
      setShowModalK(false);
      setShowUpdateModalK(false);
      setSelectedItem(null);
    }
  };
  const handleArchiveKnowledge = () => {
    setShowModalK(false);
    setShowArchiveModalK(true);
  };
  const handleCloseArchiveK = () => {
    setShowModalK(true);
    setShowArchiveModalK(false);
  };
  const handleRemoveKnowledge = () => {
    setShowModalK(false);
    setShowRemoveModalK(true);
  };
  const handleCloseRemoveK = () => {
    setShowModalK(true);
    setShowRemoveModalK(false);
  };
  /* Collection */
  const handleUpdateShow = () => {
    setShowModal(false);
    setShowUpdateModal(true);
  };
  const handleCloseUpdate = (type) => {
    if (type === undefined) {
      setShowModal(true);
      setShowUpdateModal(false);
    } else {
      setShowModal(false);
      setShowUpdateModal(false);
      setSelectedItem(null);
    }
  };
  const handleArchiveCollection = () => {
    setShowModal(false);
    setShowArchiveModal(true);
  };
  const handleCloseArchiveC = () => {
    setShowModal(true);
    setShowArchiveModal(false);
  };
  const handleRemove = () => {
    setShowModal(false);
    setShowRemoveModal(true);
  };
  const handleCloseRemove = () => {
    setShowModal(true);
    setShowRemoveModal(false);
  };

  const handleUpdatePublish = (data, type) => {
    onPublish(data, type);
  };

  const startChat = (id, option) => {
    axios
      .post(API_URL_CORE + "chat/session", {
        knowledge_id: option === "knowledge" ? id : null,
        collection_id: option === "collection" ? id : null,
        assistant_id: defaultAssistant,
        user_id: usr.idt_user,
      })
      .then((response) => {
        if (response.data.errormsg === "OK") {
          // Get For Context
          axios
            .post(
              API_URL + "get-session",
              { status: 0, idt_session: response.data.data.idt_session },
              {
                headers: {
                  Authorization: `Bearer ${storage.get("access_token")}`,
                },
              }
            )
            .then((resp) => {
              if (resp.data.success === true) {
                setSessionList([]);
                loadSessionPersonal();
                setTypeSession("personal");
                setMessages([]);
                // setSessionList((prevData) => [...resp.data.data, ...prevData]);
                setSelectedSessionAssistant({
                  value: resp.data.data[0].assistant.idt_user_assistant,
                  label: resp.data.data[0].assistant.name,
                });
                setSelectedSession(resp.data.data[0]);
                setActiveMessage(true);
                setActiveSession(resp.data.data[0].idt_session);
                navigate("/chat");
              }
            })
            .catch((error) => {
              showError(error);
            });
        } else {
          showError(response.data.errormsg);
        }
      })
      .catch((error) => {
        showError(error);
      });
  };

  const handleRatingChange = (rating, id, type) => {
    setSelectedRatings((prev) => {
      const updatedRatings = prev.includes(rating)
        ? prev.filter((r) => r !== rating)
        : [...prev, rating];

      // Panggil getReviewAPI dengan updatedRatings
      axios
        .post(
          API_URL + "get-knowledge-review",
          {
            idt_knowledge: type === "knowledge" ? id : null,
            idt_knowledge_collection: type === "collection" ? id : null,
            rating: updatedRatings,
            limit: 10,
            page: 1,
          },
          {
            headers: {
              Authorization: `Bearer ${storage.get("access_token")}`,
            },
          }
        )
        .then((response) => {
          if (response.data.success === true) {
            setDataReview(response.data.data.data);
            setPageReview(2);
            setTotalDataReview(() => {
              const updatedTotal = response.data.data.data.length;
              setHasMore(updatedTotal < response.data.data.meta.total_items);
              return updatedTotal;
            });
          }
          setLoadingReview(false);
        })
        .catch((error) => {
          console.log("An error occurred. Please try again later.");
          setLoadingReview(false);
        });

      return updatedRatings;
    });
  };

  const loadMoreReview = (id, type) => {
    if (!hasMore) return;
    setLoadingReview(true);
    axios
      .post(
        API_URL + "get-knowledge-review",
        {
          idt_knowledge: type === "knowledge" ? id : null,
          idt_knowledge_collection: type === "collection" ? id : null,
          rating: selectedRatings,
          limit: 10,
          page: pageReview,
        },
        {
          headers: {
            Authorization: `Bearer ${storage.get("access_token")}`,
          },
        }
      )
      .then((response) => {
        if (response.data.success === true) {
          setDataReview((prevData) => [
            ...prevData,
            ...response.data.data.data,
          ]);
          setPageReview((prevPage) => prevPage + 1);
          setTotalDataReview((prevTotal) => {
            const updatedTotal = prevTotal + response.data.data.data.length;
            setHasMore(updatedTotal < response.data.data.meta.total_items);
            return updatedTotal;
          });
        }
        setLoadingReview(false);
      })
      .catch((error) => {
        console.log("An error occurred. Please try again later.");
        setLoadingReview(false);
      });
  };

  const getPrice = (card) => {
    if (card.idt_knowledge !== undefined) {
      if (card.knowledge_public.length > 0) {
        if (card.knowledge_public[0].pricing_scheme === "paid") {
          return "Rp. " + format_number(card.knowledge_public[0].price_sell);
        } else if (card.knowledge_public[0].pricing_scheme === "rent") {
          return "Rp. " + format_number(card.knowledge_public[0].price_rent);
        } else {
          return "Free";
        }
      } else {
        return "Free";
      }
    } else {
      if (card.knowledge_collection_public.length > 0) {
        if (card.knowledge_collection_public[0].pricing_scheme === "paid") {
          return "Rp. " + format_number(card.knowledge_public[0].price_sell);
        } else if (
          card.knowledge_collection_public[0].pricing_scheme === "rent"
        ) {
          return (
            "Rp. " +
            format_number(card.knowledge_collection_public[0].price_rent)
          );
        } else {
          return "Free";
        }
      } else {
        return "Free";
      }
    }
  };

  return (
    <div className="row mt-2 mb-4">
      {!loading ? (
        cards.length > 0 ? (
          cards.map((card, index) => (
            <div key={index} className="custom-coloum folder-card">
              <div
                className={
                  card.idt_knowledge !== undefined
                    ? selectedCards.includes(card.idt_knowledge)
                      ? "card card-list shadow-lg selected"
                      : "card card-list shadow-lg"
                    : "card card-list shadow-lg"
                }
              >
                <div
                  onClick={() =>
                    card.idt_knowledge !== undefined
                      ? handleCardClick(card.idt_knowledge)
                      : null
                  }
                  className="card-body"
                >
                  <div className="text-start">
                    <div className="mb-2">
                      <div className="square-container">
                        <img
                          src={
                            card.idt_knowledge !== undefined
                              ? card.knowledge_cover_pic.length > 0
                                ? card.knowledge_cover_pic[0].pathurl
                                : image_knowledge
                              : card.url_coverpic
                              ? card.url_coverpic
                              : bookComp
                          }
                          className="rounded-3"
                          alt={index}
                        />
                      </div>
                    </div>
                    <div style={{ height: "36px" }}>
                      <h6 className="fs-12 fw-bold ellipsis-2-line knowledge-name">
                        {card.title !== undefined ? card.title : card.name}
                      </h6>
                    </div>
                  </div>
                  <p className="text-start fs-11 knowledge-name mb-0">
                    {card.type !== undefined ? (
                      card.type === "private" ? (
                        <>
                          <LuBookLock
                            size={11}
                            className="me-1"
                            style={{ marginTop: "-3px" }}
                          />
                          Personal Private
                        </>
                      ) : card.idt_user === usr.idt_user ? (
                        <>
                          <LuBookUp
                            size={11}
                            className="me-1"
                            style={{ marginTop: "-3px" }}
                          />
                          Personal Publish
                        </>
                      ) : (
                        <>
                          <BiWorld
                            size={11}
                            className="me-1"
                            style={{ marginTop: "-3px" }}
                          />{" "}
                          Public
                        </>
                      )
                    ) : (
                      <>
                        <LuBookCopy
                          size={11}
                          className="me-1"
                          style={{ marginTop: "-3px" }}
                        />
                        Collection
                      </>
                    )}
                  </p>
                  <p className="text-start fw-bold fs-12 knowledge-name mb-0">
                    {getPrice(card)}
                  </p>
                  <p className="text-start fs-12 knowledge-name mb-0">
                    {card.author !== undefined
                      ? card.author
                      : "Multiple Publisher"}
                  </p>
                  <p className="text-start fs-12 knowledge-name mb-0">
                    {card.knowledge_publisher !== undefined
                      ? card.knowledge_publisher.name
                      : null}
                  </p>
                </div>
                {/* Panel Button Setting*/}
                {card.idt_knowledge !== undefined ? (
                  !selectedCards.includes(card.idt_knowledge) ? (
                    <div className="panel-button d-flex">
                      <button
                        onClick={() =>
                          startChat(card.idt_knowledge, "knowledge")
                        }
                        className="btn btn-sm btn-ghost-primary-2 start-chat-btn card-btn-dropdown"
                      >
                        Start Chat
                      </button>
                      <Dropdown
                        className="card-btn-dropdown"
                        style={{
                          position: "absolute",
                          top: "8%",
                          right: "14%",
                        }}
                      >
                        <Dropdown.Toggle
                          className="btn btn-ghost-primary-2 btn-icon btn-xs dropdown rounded-4"
                          style={{
                            height: "calc(0.4rem + 0.8em + 5px)",
                            width: "calc(0.4rem + 0.8em + 5px)",
                          }}
                        >
                          <i className="ri-more-2-fill fs-14 align-bottom"></i>
                        </Dropdown.Toggle>

                        <Dropdown.Menu align="end">
                          {/* <Dropdown.Item
                            onClick={() =>
                              startChat(card.idt_knowledge, "knowledge")
                            }
                          >
                            <MdOutlineChat size={15} className="me-2" />
                            Chat with Knowledge
                          </Dropdown.Item> */}
                          <button
                            className="dropdown-item"
                            role="button"
                            variant="none"
                            onClick={() => handleShow(card, "knowledge")}
                          >
                            <MdOutlineRemoveRedEye size={15} className="me-2" />
                            Detail Knowledge
                          </button>
                          {card.type !== undefined ? (
                            card.type === "private" ? (
                              <ModalPublish
                                item={selectedItem ? selectedItem : card}
                                onUpdate={handleUpdatePublish}
                              />
                            ) : null
                          ) : null}
                          {/* <ModalUpdate
                            onUpdate={handleUpdateClick}
                            item={selectedItem ? selectedItem : card}
                          /> */}
                          {card.knowledge_library.length > 0 &&
                            card.knowledge_library[0].is_author === "Y" &&
                            card.type === "private" && (
                              <ModalRemove
                                onDelete={handleDeleteClick}
                                item={selectedItem ? selectedItem : card}
                              />
                            )}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  ) : (
                    <></>
                  )
                ) : (
                  <div className="panel-button d-flex">
                    <button
                      onClick={() =>
                        startChat(card.idt_knowledge_collection, "collection")
                      }
                      className="btn btn-sm btn-ghost-primary-2 start-chat-btn card-btn-dropdown"
                    >
                      Start Chat
                    </button>
                    <Dropdown
                      className="card-btn-dropdown"
                      style={{
                        position: "absolute",
                        top: "8%",
                        right: "14%",
                      }}
                    >
                      <Dropdown.Toggle
                        className="btn btn-ghost-primary-2 btn-icon btn-xs dropdown rounded-4"
                        style={{
                          height: "calc(0.4rem + 0.8em + 5px)",
                          width: "calc(0.4rem + 0.8em + 5px)",
                        }}
                      >
                        <i className="ri-more-2-fill fs-14 align-bottom"></i>
                      </Dropdown.Toggle>

                      <Dropdown.Menu align="end">
                        {/* <Dropdown.Item
                          onClick={() =>
                            startChat(
                              card.idt_knowledge_collection,
                              "collection"
                            )
                          }
                        >
                          <MdOutlineChat size={15} className="me-2" />
                          Chat with Collection
                        </Dropdown.Item> */}
                        <button
                          className="dropdown-item"
                          role="button"
                          variant="none"
                          onClick={() => handleShow(card, "collection")}
                        >
                          <MdOutlineRemoveRedEye size={15} className="me-2" />
                          Detail Collection
                        </button>
                        {card.type !== undefined ? (
                          card.type === "private" ? (
                            <ModalPublish
                              item={selectedItem ? selectedItem : card}
                              onUpdate={handleUpdatePublish}
                            />
                          ) : null
                        ) : null}
                        {card.knowledge_collection_library.length > 0 &&
                          card.knowledge_collection_library[0].is_author ===
                            "Y" &&
                          card.type === "private" && (
                            <ModalRemove
                              onDelete={handleDeleteClick}
                              item={card}
                            />
                          )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                )}
              </div>
            </div>
          ))
        ) : (
          <div
            className="text-center fs-16"
            style={{ marginTop: "60px", marginBottom: "120px" }}
          >
            {messagesLang.data_not_found}
          </div>
        )
      ) : (
        <div className="mt-2 mb-2" style={{ height: "315px" }}>
          <div className="loading-backdrop-2">
            <Spinner animation="border" />
          </div>
        </div>
      )}
      {loadingModal && <Loading />}
      {/* Secondary Modal */}
      {/* <ModalViewSource
        item={card.resource_url}
        show={showViewModal}
        handleClose={handleCloseView}
      /> */}
      {selectedItem && (
        <>
          {selectedItem.idt_knowledge === undefined ? (
            <>
              <ModalDetailCollection
                item={selectedItem}
                show={showModal}
                handleClose={handleClose}
                onHandleView={handleView}
                onHandleUpdate={handleUpdateShow}
                onHandleArchive={handleArchiveCollection}
                onHandleRemove={handleRemove}
                review={dataReview}
                starReview={starReview}
                selectedRatings={selectedRatings}
                onRatingChange={handleRatingChange}
                loadMoreReview={loadMoreReview}
                loadingReview={loadingReview}
                hasMore={hasMore}
              />
              <ModalUpdateCollection
                onUpdate={handleUpdate}
                handleClose={handleCloseUpdate}
                show={showUpdateModal}
                item={selectedItem}
              />
              <ModalArchive
                onArchive={handleArchiveClick}
                handleClose={handleCloseArchiveC}
                show={showArchiveModal}
                item={selectedItem}
              />
              <ModalRemove2
                onDelete={handleDeleteClick}
                handleClose={handleCloseRemove}
                show={showRemoveModal}
                item={selectedItem}
              />
            </>
          ) : (
            <>
              <ModalDetail2
                item={selectedItem}
                review={dataReview}
                starReview={starReview}
                selectedRatings={selectedRatings}
                onRatingChange={handleRatingChange}
                loadMoreReview={loadMoreReview}
                loadingReview={loadingReview}
                hasMore={hasMore}
                show={showModalK}
                handleClose={handleClose}
                onHandleView={handleView}
                onHandleUpdate={handleUpdateShowKnowledge}
                onHandleArchive={handleArchiveKnowledge}
                onHandleRemove={handleRemoveKnowledge}
              />
              <ModalUpdateKnowledge
                onUpdate={handleUpdate}
                handleClose={handleCloseUpdateKnowledge}
                show={showUpdateModalK}
                item={selectedItem}
              />
              <ModalArchive
                onArchive={handleArchiveClick}
                handleClose={handleCloseArchiveK}
                show={showArchiveModalK}
                item={selectedItem}
              />
              <ModalRemove2
                onDelete={handleDeleteClick}
                handleClose={handleCloseRemoveK}
                show={showRemoveModalK}
                item={selectedItem}
              />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default CardList;
