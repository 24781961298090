import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { RiEyeFill, RiEyeOffFill } from "react-icons/ri";
import axios from "axios";
import logoImg from "../assets/images/logo-dark.png";
import { API_URL } from "../utilities/url";
import { ModalExpired } from "../components";
import CryptoJS from "crypto-js";
import storage from "../services/storage";
import { useLanguage } from "../provider/languageContext";
import HeaderLanguage from "./component/headerLanguage";

var $ = require("jquery");

const CreatePassword = () => {
  const { messagesLang } = useLanguage();
  const year = moment().format("YYYY");
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname.split("/")[2];
  const [alertMessage, setAlertMessage] = useState("");
  const [hideAlert, setHideAlert] = useState(true);
  const [hidePassword, setHidePassword] = useState("password");
  const [isTokenExpired, setTokenExpired] = useState(false);

  useEffect(() => {
    // Validate State
    if (!location.state) {
      if (!storage.get("dtf")) {
        navigate("/register");
      } else {
        storage.set("dtf", storage.get("dtf"), 5);
      }
    } else {
      storage.set("dtf", location.state, 5);
    }
  }, []);
  /* Form Submit */
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({
    reValidateMode: "onBlur",
  });
  const validatePassword = (value) => {
    const hasUppercase = /[A-Z]/.test(value);
    const hasNumber = /\d/.test(value);
    const hasSpchar = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(value);
    if (!hasUppercase || !hasNumber || !hasSpchar) {
      return false;
    }
    return true;
  };
  const validateConfirmPassword = (value) => {
    const { new_password } = getValues();
    return value === new_password || "Password don't match.";
  };
  const handleChangePass = (event) => {
    const value = event.target.value;
    value.length >= 8
      ? $("#pass-length").removeClass("invalid").addClass("valid")
      : $("#pass-length").removeClass("valid").addClass("invalid");
    /[A-Z]/.test(value)
      ? $("#pass-upper").removeClass("invalid").addClass("valid")
      : $("#pass-upper").removeClass("valid").addClass("invalid");
    /\d/.test(value)
      ? $("#pass-number").removeClass("invalid").addClass("valid")
      : $("#pass-number").removeClass("valid").addClass("invalid");
    /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(value)
      ? $("#pass-spchar").removeClass("invalid").addClass("valid")
      : $("#pass-spchar").removeClass("valid").addClass("invalid");
  };
  const handleCloseModal = () => {
    setTokenExpired(false);
  };
  const onSubmit = (data) => {
    const dtf = storage.get("dtf");
    const formData = {
      email: CryptoJS.AES.decrypt(dtf.ec, "email").toString(CryptoJS.enc.Utf8),
      name_first: CryptoJS.AES.decrypt(dtf.fc, "firstname").toString(
        CryptoJS.enc.Utf8
      ),
      name_last: CryptoJS.AES.decrypt(dtf.lc, "lastname").toString(
        CryptoJS.enc.Utf8
      ),
      pic: CryptoJS.AES.decrypt(dtf.pc, "picture").toString(CryptoJS.enc.Utf8),
      password: data.password,
    };
    axios
      .post(API_URL + "register-with-google", formData)
      .then((response) => {
        if (response.data.success === true) {
          navigate("/activation-success");
        } else {
          setAlertMessage("Session has been expired.");
          setHideAlert(false);
        }
      })
      .catch((error) => {});
  };

  /* Function */
  const togglePassword = () => {
    if (hidePassword === "password") {
      setHidePassword("text");
      return;
    }
    setHidePassword("password");
  };
  return (
    <div className="auth-page-content pb-0">
      <div className="container">
        <div className="row">
          <div className="d-flex justify-content-between mt-sm-4 mb-sm-2 text-white-50">
            <Link to="/login">
              <img
                src={logoImg}
                alt=""
                height="40"
                className="d-none d-sm-block"
              />
            </Link>
            <div className="head-lang">
              <HeaderLanguage />
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-8 col-lg-6 col-xl-5">
            <div className="centered-container">
              <div className="card login rounded-4 mt-4">
                <div className="card-body p-4">
                  <div className="text-center mt-2">
                    <h5 className="text-white">
                      {messagesLang.create_password}
                    </h5>
                  </div>
                  <ModalExpired
                    show={isTokenExpired}
                    handleClose={handleCloseModal}
                  />
                  <div className="p-2 mt-4">
                    <div
                      className={`alert alert-danger alert-dismissible fade show ${
                        hideAlert ? "d-none" : "d-block"
                      }`}
                      role="alert"
                    >
                      <button
                        onClick={() => setHideAlert(true)}
                        type="button"
                        className="btn-close"
                        aria-label="Close"
                      ></button>
                      <div className="alert-message">
                        <strong className="text-danger">{alertMessage}</strong>
                      </div>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="mb-3">
                        <label className="form-label text-light w-100">
                          {messagesLang.new_password}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="new_password"
                          {...register("new_password", {
                            required: true,
                            minLength: 8,
                            validate: validatePassword,
                          })}
                          onChange={handleChangePass}
                        />
                        {errors.new_password &&
                          errors.new_password.type === "required" && (
                            <p className="errorMsg text-danger fs-7">
                              Password is required.
                            </p>
                          )}
                        {errors.new_password &&
                          errors.new_password.type === "minLength" && (
                            <p className="errorMsg text-danger fs-7">
                              Password min 8 character.
                            </p>
                          )}
                        {errors.new_password &&
                          errors.new_password.type === "validate" && (
                            <p className="errorMsg text-danger fs-7">
                              Password must contain at least uppercase letter ,
                              number and special character.
                            </p>
                          )}
                      </div>
                      <div className="mb-3">
                        <label className="form-label text-light w-100">
                          {messagesLang.confirm_password}
                        </label>
                        <div className="position-relative auth-pass-inputgroup mb-3">
                          <input
                            type={hidePassword}
                            name="password"
                            autoComplete="on"
                            className="form-control pe-5 password-input"
                            {...register("password", {
                              required: true,
                              validate: validateConfirmPassword,
                            })}
                          />
                          {errors.password &&
                            errors.password.type === "required" && (
                              <p className="errorMsg text-danger fs-7">
                                Password is required.
                              </p>
                            )}
                          {errors.password &&
                            errors.password.type === "validate" && (
                              <p className="errorMsg text-danger fs-7">
                                Password don't match.
                              </p>
                            )}
                          <a
                            className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                            onClick={togglePassword}
                          >
                            {hidePassword === "password" ? (
                              <RiEyeFill size={20} />
                            ) : (
                              <RiEyeOffFill size={20} />
                            )}
                            {/* <i className="ri-eye-fill align-middle"></i> */}
                          </a>
                        </div>
                      </div>
                      <div
                        id="password-contain"
                        style={{ display: "block" }}
                        className="p-3 bg-light mb-2 rounded"
                      >
                        <h5 className="fs-13">Password must contain:</h5>
                        <p id="pass-length" className="invalid fs-12 mb-2">
                          Minimum <b>8 characters</b>
                        </p>
                        <p id="pass-upper" className="invalid fs-12 mb-2">
                          At least <b>uppercase</b> letter (A-Z)
                        </p>
                        <p id="pass-number" className="invalid fs-12 mb-2">
                          A least <b>number</b> (0-9)
                        </p>
                        <p id="pass-spchar" className="invalid fs-12 mb-0">
                          A least <b>special character</b> (!@#$%^*|,./? etc.)
                        </p>
                      </div>
                      <div className="mt-4">
                        <button className="btn btn-orange w-100" type="submit">
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreatePassword;
