import React from "react";
import { Spinner } from "react-bootstrap";

const Loading = () => (
  <div className="loading-backdrop">
    <Spinner animation="border" />
  </div>
);

export default Loading;
