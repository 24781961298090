import React from "react";
import CarouselRecent from "../library/component/carouselRecent";
import CarouselRecommended from "../knowledge/component/carouselRecommeded";
import imgHome from "../../assets/images/galaxy/img-3.png";
import imgHome2 from "../../assets/images/galaxy/img-2.png";
import imgHome3 from "../../assets/images/galaxy/img-1.png";
import Slider from "react-slick";
import { ModalStartChat } from "../../components";
import ChartKnowledge from "./component/chartKnowledge";
import CarouselSession from "./component/carouselSession";

const Home = () => {
  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 10000,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    customPaging: (i) => <div className="custom-dot"></div>,
    appendDots: (dots) => (
      <div>
        <ul className="custom">{dots}</ul>
      </div>
    ),
  };

  return (
    <div className="page-content">
      <div className="container-fluid">
        <div className="row">
          <Slider {...settings}>
            <div>
              <img src={imgHome} className="img-banner object-fit-cover mb-3" />
            </div>
            <div>
              <img
                src={imgHome2}
                className="img-banner object-fit-cover mb-3"
              />
            </div>
            <div>
              <img
                src={imgHome3}
                className="img-banner object-fit-cover mb-3"
              />
            </div>
          </Slider>
        </div>
      </div>
      <CarouselSession />
      {/* <div className="container-fluid w-85">
        <div className="row">
          <ModalStartChat />
        </div>
      </div> */}
      <div className="container-fluid w-85">
        <div className="row mb-3">
          <div className="col-md-8">
            <div className="card">
              <div class="card-header border-0 align-items-center d-flex">
                <h4 class="card-title mb-0 flex-grow-1">Chat Usage</h4>
                {/* <div>
                  <button type="button" class="btn btn-soft-secondary btn-sm">
                    ALL
                  </button>
                  <button type="button" class="btn btn-soft-secondary btn-sm">
                    1M
                  </button>
                  <button type="button" class="btn btn-soft-secondary btn-sm">
                    6M
                  </button>
                  <button type="button" class="btn btn-soft-primary btn-sm">
                    1Y
                  </button>
                </div> */}
              </div>
              <div className="card-body">
                <ChartKnowledge />
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card">
              <div class="card-header border-0 align-items-center d-flex">
                <h4 class="card-title mb-0 flex-grow-1">Knowledge Uploaded</h4>
              </div>
              <div className="card-body">
                <h2 class="mb-0 flex-grow-1">100 MB / 200 MB</h2>
              </div>
            </div>
            <div className="card">
              <div class="card-header border-0 align-items-center d-flex">
                <h4 class="card-title mb-0 flex-grow-1">Chat Usage</h4>
              </div>
              <div className="card-body">
                <h2 class="mb-0 flex-grow-1">1000 Hr / 2000 Hr</h2>
              </div>
            </div>
            <div className="card">
              <div class="card-header border-0 align-items-center d-flex">
                <h4 class="card-title mb-0 flex-grow-1">
                  Shared Knowledge Usage
                </h4>
              </div>
              <div className="card-body">
                <h4 class="mb-0 flex-grow-1">
                  500 Interaction from 5 Knowledge
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CarouselRecent />
      <CarouselRecommended />
    </div>
  );
};

export default Home;
