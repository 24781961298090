import React, { useContext, useEffect, useRef, useState } from "react";
import avatar1 from "../../../assets/images/users/avatar-1.jpg";
import SimpleBar from "simplebar-react";
import { RiBookmarkFill, RiDeleteBinFill } from "react-icons/ri";
import SearchChatDropdown from "./searchChatDropdown";
import { Dropdown, Spinner } from "react-bootstrap";
import CryptoJS from "crypto-js";
import storage from "../../../services/storage";
import MarkdownRenderer from "./markdownRenderer";
import axios from "axios";
import { API_URL, API_URL_CORE } from "../../../utilities/url";
import { SessionContext } from "../../../provider/sessionContext";
import { LuBook, LuBookCopy } from "react-icons/lu";
import {
  Loading,
  ModalMessageAnalyze,
  ModalRemoveSession,
} from "../../../components";
import { HiChevronDoubleDown, HiOutlineChevronLeft } from "react-icons/hi";
import ai_light from "../../../assets/images/ai-light.gif";
import ai_dark from "../../../assets/images/ai-dark.gif";
import { ThemeContext } from "../../../provider/themeContext";
import { useLanguage } from "../../../provider/languageContext";
import moment from "moment";
import Select from "react-select";
import CustomStyles from "../../../components/customStyles";
import { AssistantContext } from "../../../provider/assistantContext";
import { ToastrContext } from "../../../provider/toastrContext";
import { IoMdArchive } from "react-icons/io";
import { throttle } from "lodash";

const PanelChat = () => {
  /* Declaration */
  const {
    sessionList,
    setSessionList,
    selectedSession,
    setSelectedSession,
    firstLoad,
    setFirstLoad,
    activeSession,
    setActiveSession,
    loadingSess,
    setLoadingSess,
    typeSession,
    setTypeSession,
    activeMessage,
    setActiveMessage,
    messages,
    setMessages,
    addBookmark,
    removeBookmark,
  } = useContext(SessionContext);
  const { theme } = useContext(ThemeContext);
  const { showSuccess, showError } = useContext(ToastrContext);
  const {
    assistantSessionList,
    selectedSessionAssistant,
    setSelectedSessionAssistant,
  } = useContext(AssistantContext);
  const { messagesLang } = useLanguage();
  const simpleBarRef = useRef(null);
  const decryptUSR = CryptoJS.AES.decrypt(storage.get("user"), "user").toString(
    CryptoJS.enc.Utf8
  );
  const usr = JSON.parse(decryptUSR);
  const [input, setInput] = useState("");
  const [isMobileChatActive, setIsMobileChatActive] = useState(false);
  const [searchItem, setSearchItem] = useState("");
  const [loadItem, setLoadItem] = useState(true);
  const [loadingChat, setloadingChat] = useState(false);
  const [loadingBot, setLoadingBot] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [showBookmark, setShowBookmark] = useState(false);
  const [stateBookmark, setStateBookmark] = useState(false);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const textareaRef = useRef(null);
  const sendRef = useRef(null);
  const [initialLoad, setInitialLoad] = useState(true);
  const [scrollTopBeforeLoad, setScrollTopBeforeLoad] = useState(0);
  const [contentHeightBeforeLoad, setContentHeightBeforeLoad] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const countMessage = 50;

  useEffect(() => {
    if (firstLoad) {
      if (typeSession === "personal") {
        callSessionPersonal();
      } else if (typeSession === "public") {
        callSessionPublic();
      }
    }
    return () => {
      setSearchChatTerm("");
      setSearchChatResults([]);
      setCurrentChatIndex(-1);
    };
  }, []);

  const callSessionPersonal = () => {
    setLoadingSess(true);
    axios
      .post(
        API_URL + "get-session-list",
        { status: 0 },
        {
          headers: {
            Authorization: `Bearer ${storage.get("access_token")}`,
          },
        }
      )
      .then((response) => {
        if (response.data.success === true) {
          setSessionList(response.data.data ? response.data.data : []);
          setFirstLoad(false);
        }
        setLoadingSess(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const callSessionPublic = () => {
    setLoadingSess(true);
    axios
      .post(
        API_URL + "get-session-public-list",
        { status: 0 },
        {
          headers: {
            Authorization: `Bearer ${storage.get("access_token")}`,
          },
        }
      )
      .then((response) => {
        if (response.data.success === true) {
          setSessionList(response.data.data ? response.data.data : []);
          setFirstLoad(false);
        }
        setLoadingSess(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleTypeSession = (data) => {
    if (typeSession !== data) {
      if (data === "personal") {
        callSessionPersonal();
      } else {
        callSessionPublic();
      }
      setActiveSession(false);
      setActiveMessage(false);
      setMessages([]);
      setTypeSession(data);
    }
  };

  /* Chat */
  const [searchChatTerm, setSearchChatTerm] = useState("");
  const [searchChatResults, setSearchChatResults] = useState([]);
  const [currentChatIndex, setCurrentChatIndex] = useState(-1);
  const debounceTimeout = useRef(null);
  const resultRefs = useRef([]);

  const debouncedHandleChatSearch = (term) => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    debounceTimeout.current = setTimeout(() => {
      handleChatSearch(term);
    }, 300); // 300ms delay
  };

  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      // Reset tinggi textarea untuk menghitung ulang tinggi baru
      textareaRef.current.style.height = "auto";
      // Set tinggi textarea berdasarkan tinggi konten
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  const handleChatSearch = (e) => {
    const term = e.target.value;
    setSearchChatTerm(term);

    if (term !== "") {
      const results =
        messages.length > 0
          ? messages
              .map((chat, index) => {
                if (chat.content.toLowerCase().includes(term.toLowerCase())) {
                  return { index, text: chat.content };
                }
                return null;
              })
              .filter((result) => result !== null)
          : [];

      setSearchChatResults(results);
      setCurrentChatIndex(0);
    } else {
      setSearchChatResults([]);
      setCurrentChatIndex(-1);
    }
  };

  const handleNavigation = (direction) => {
    if (direction === "down") {
      setCurrentChatIndex((prevIndex) =>
        Math.min(prevIndex + 1, searchChatResults.length - 1)
      );
    } else if (direction === "up") {
      setCurrentChatIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && searchChatResults.length > 0) {
      if (currentChatIndex + 1 === searchChatResults.length) {
        setCurrentChatIndex(0);
      } else {
        setCurrentChatIndex((prevIndex) =>
          Math.min(prevIndex + 1, searchChatResults.length - 1)
        );
      }
    }
  };

  const handleClearSearch = () => {
    setSearchChatTerm("");
    setSearchChatResults([]);
    setCurrentChatIndex(-1);
  };

  /* UseEffect */
  useEffect(() => {
    if (currentChatIndex !== -1 && searchChatResults.length > 0) {
      const currentRef =
        resultRefs.current[searchChatResults[currentChatIndex]?.index];
      if (currentRef) {
        currentRef.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  }, [currentChatIndex, searchChatResults]);

  /* UseEffect */
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  /* Function */
  const scrollToBottom = () => {
    if (simpleBarRef.current) {
      simpleBarRef.current.getScrollElement().scrollTop =
        simpleBarRef.current.getScrollElement().scrollHeight;
      setShowScrollButton(false);
    }
  };

  /* Button Scroll to Bottom */
  useEffect(() => {
    const handleScroll = () => {
      const { current } = simpleBarRef;
      if (current) {
        const scrollElement = current.getScrollElement();
        const isScrolledUp =
          scrollElement.scrollTop <
          scrollElement.scrollHeight - scrollElement.clientHeight - 400;
        setShowScrollButton(isScrolledUp);
      }
    };

    const { current } = simpleBarRef;
    if (current) {
      const scrollElement = current.getScrollElement();
      scrollElement.addEventListener("scroll", () => {
        handleScroll();
      });

      return () => {
        scrollElement.removeEventListener("scroll", () => {
          handleScroll();
        });
      };
    }
  }, [simpleBarRef]);

  const loadMoreMessages = async () => {
    if (!hasMore) return; // Jika loading atau tidak ada lagi pesan, hentikan

    // Simpan posisi scroll dan tinggi konten sebelum memuat data
    const scrollElement = simpleBarRef.current?.getScrollElement();
    if (scrollElement) {
      setScrollTopBeforeLoad(scrollElement.scrollTop);
      setContentHeightBeforeLoad(scrollElement.scrollHeight);
    }
    // Ambil pesan lebih banyak
    if (messages.length > 0 && messages[0]?.ts_created) {
      const t = messages[0]?.ts_created;
      const timestamp = Math.floor(new Date(t) / 1000);
      try {
        const res = await axios.post(
          `${API_URL}get-message-chat`,
          {
            limit: countMessage,
            idt_session: activeSession,
            timestamp: timestamp,
          },
          {
            headers: {
              Authorization: `Bearer ${storage.get("access_token")}`,
            },
          }
        );
        if (res.data.success === true) {
          const sortedMessages = res.data.data.sort(
            (a, b) => new Date(a.ts_created) - new Date(b.ts_created)
          );
          setMessages((prevMessages) => [...sortedMessages, ...prevMessages]);
        } else {
          setHasMore(false);
        }
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    }
  };

  const throttledLoadMoreMessages = throttle(loadMoreMessages, 1000);
  useEffect(() => {
    const handleScroll = () => {
      if (simpleBarRef.current) {
        const scrollElement = simpleBarRef.current.getScrollElement();
        const { scrollTop } = scrollElement;

        // Jika scrollTop mendekati 80px dari atas dan masih ada data untuk dimuat
        if (scrollTop <= 80 && hasMore) {
          throttledLoadMoreMessages();
        }
      }
    };

    const scrollElement = simpleBarRef.current?.getScrollElement();
    if (scrollElement) {
      scrollElement.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollElement) {
        scrollElement.removeEventListener("scroll", handleScroll);
      }
      throttledLoadMoreMessages.cancel(); // Hentikan throttle saat komponen di-unmount
    };
  }, [hasMore, messages]);

  useEffect(() => {
    // Scroll ke bawah hanya saat pemuatan awal
    const scrollElement = simpleBarRef.current?.getScrollElement();
    if (scrollElement && initialLoad) {
      const scrollHeight = scrollElement.scrollHeight;
      scrollElement.scrollTop = scrollHeight;

      setInitialLoad(false); // Set initialLoad menjadi false setelah pemuatan awal
    }
  }, [initialLoad]);

  useEffect(() => {
    // Scroll ke posisi yang disimpan setelah data baru dimuat
    if (!initialLoad) {
      const scrollElement = simpleBarRef.current?.getScrollElement();
      if (scrollElement) {
        const newContentHeight = scrollElement.scrollHeight;
        const scrollDelta = newContentHeight - contentHeightBeforeLoad;
        scrollElement.scrollTop = scrollTopBeforeLoad + scrollDelta;
      }
    }
  }, [messages]);

  const toggleMobileChatClass = async (item) => {
    if (activeSession !== item.idt_session) {
      setIsMobileChatActive(!isMobileChatActive);
      if (item.idt_session != stateBookmark) {
        setShowBookmark(null);
      }
      setSelectedSessionAssistant({
        value: item.assistant.idt_user_assistant,
        label: item.assistant.name,
      });
      setMessages([]);
      setActiveSession(item.idt_session);
      setSelectedSession(item);
      setLoadItem(false);
      setActiveMessage(true);
      setloadingChat(false);
      setShowSpinner(true);
      setScrollTopBeforeLoad(0);
      setContentHeightBeforeLoad(0);
      setHasMore(true);
      // Get Message
      const timestamp = Math.floor(Date.now() / 1000);
      try {
        // Tunggu hingga state diatur sebelum melakukan permintaan API
        await new Promise((resolve) => setTimeout(resolve, 0));

        // Get Message
        const response = await axios.post(
          `${API_URL}get-message-chat`,
          {
            limit: countMessage,
            idt_session: item.idt_session,
            timestamp: timestamp,
          },
          {
            headers: {
              Authorization: `Bearer ${storage.get("access_token")}`,
            },
          }
        );

        if (response.data.success === true) {
          setMessages(
            response.data.data
              ? response.data.data.sort(
                  (a, b) => new Date(a.ts_created) - new Date(b.ts_created)
                )
              : []
          );
        }
      } catch (error) {
        console.log(error);
      } finally {
        // Matikan spinner
        setShowSpinner(false);
      }
    }
  };

  const handleSetAssistant = (selected) => {
    axios
      .post(
        API_URL + "set-session-assistant",
        {
          idt_session: selectedSession.idt_session,
          idt_user_assistant: selected.value,
        },
        {
          headers: {
            Authorization: `Bearer ${storage.get("access_token")}`,
          },
        }
      )
      .then((response) => {
        if (response.data.success === true) {
          setSelectedSession((prevState) => ({
            ...prevState,
            assistant: {
              ...prevState.assistant,
              profile_pic: response.data.data.assistant.profile_pic,
            },
          }));
          setSelectedSessionAssistant(selected);
          const updated = sessionList.map((item) => {
            if (item.idt_session == selectedSession.idt_session) {
              return {
                ...item,
                assistant: {
                  ...item.default,
                  idt_user_assistant: selected.value,
                  name: selected.label,
                  profile_pic: response.data.data.assistant.profile_pic,
                },
              };
            }
            return item;
          });
          setSessionList(updated);
          showSuccess(response.data.message);
        } else {
          showError(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleSearch = (event) => {
    setSearchItem(event.target.value);
  };
  const toggleBookmark = () => {
    setShowBookmark((prevState) => !prevState);
    setStateBookmark(activeSession);
  };
  const filteredItems =
    sessionList.length > 0
      ? sessionList.filter(
          (item) =>
            item.title.toLowerCase().includes(searchItem.toLowerCase()) ||
            (item.knowledge_collection &&
              item.knowledge_collection.name
                .toLowerCase()
                .includes(searchItem.toLowerCase())) ||
            (item.knowledge &&
              item.knowledge.title
                .toLowerCase()
                .includes(searchItem.toLowerCase()))
        )
      : [];

  const handleSend = async (e) => {
    e.preventDefault();
    // const res = await sendMsgToOpenAI(input);
    const updatedMessages = [
      ...messages,
      {
        session_id: selectedSession.session_id,
        idt_message: null,
        sub_id: null,
        channel: "otobaca-web-app",
        content: textareaRef.current.value.trim().replace(/\n/g, "<br>"),
        media_type: "text",
        ts_created: getCurrentDateTime(),
        media_url: null,
        direction: "in",
      },
    ];
    setLoadingBot(true);
    setMessages(updatedMessages);

    //Init Content
    const contentValue = textareaRef.current.value
      .trim()
      .replace(/\n/g, "<br>");
    if (sendRef.current) {
      textareaRef.current.value = "";
      sendRef.current.disabled = true;
      adjustTextareaHeight();
    }

    // Send Message
    setScrollTopBeforeLoad(0);
    setContentHeightBeforeLoad(0);
    axios
      .post(API_URL_CORE + "chat/message", {
        session_id: selectedSession.session_id,
        partner_msg_id: null,
        sub_id: null,
        channel: "otobaca-web-app",
        content: contentValue,
        type: "text",
        media_url: null,
      })
      .then((response) => {
        if (response.data.errormsg === "OK") {
          setSelectedSession((prevState) => ({
            ...prevState,
            title: response.data.data.session_title,
          }));
          setSessionList((prevState) =>
            prevState.map((session) =>
              session.idt_session === selectedSession.idt_session
                ? { ...session, title: response.data.data.session_title }
                : session
            )
          );
          setSessionList((prevSession) => {
            const sessionIndex = prevSession.findIndex(
              (session) => session.idt_session === selectedSession.idt_session
            );
            if (sessionIndex === -1) return prevSession;
            const sessionToMove = {
              ...prevSession[sessionIndex],
              ts_lastactive: moment().format("YYYY-MM-DD HH:mm:ss"),
            };
            const newSessionList = prevSession.filter(
              (session) => session.idt_session !== selectedSession.idt_session
            );
            return [sessionToMove, ...newSessionList];
          });
          const finalMessage = updatedMessages.map((message) => {
            if (message.idt_message === null) {
              return {
                ...message,
                idt_message: response.data.data
                  ? response.data.data.idt_message_in
                  : null,
              };
            }
            return message;
          });
          setMessages(finalMessage);
          setMessages((prevMessages) => [
            ...prevMessages,
            response.data.data
              ? {
                  session_id: selectedSession.session_id,
                  idt_message: response.data.data.idt_message,
                  sub_id: null,
                  channel: "otobaca-web-app",
                  content: response.data.data.content,
                  media_type: response.data.data.media_type,
                  media_url: response.data.data.media_url,
                  direction: response.data.data.direction,
                  ts_created: getCurrentDateTime(),
                }
              : null,
          ]);
        }
        setLoadingBot(false);
      })
      .catch((error) => {
        console.log(error);
        setLoadingBot(false);
      });
  };

  const handleRemoveBookmark = (data) => {
    axios
      .post(
        API_URL + "remove-bookmark-chat",
        {
          idt_message: data,
        },
        {
          headers: {
            Authorization: `Bearer ${storage.get("access_token")}`,
          },
        }
      )
      .then((response) => {
        if (response.data.success === true) {
          removeBookmark(
            selectedSession.idt_session,
            response.data.data.idt_message
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleAddBookmark = (data) => {
    axios
      .post(
        API_URL + "add-bookmark-chat",
        {
          idt_session: selectedSession.idt_session,
          session_id: selectedSession.session_id,
          idt_message: data,
        },
        {
          headers: {
            Authorization: `Bearer ${storage.get("access_token")}`,
          },
        }
      )
      .then((response) => {
        if (response.data.success === true) {
          addBookmark(selectedSession.idt_session, [response.data.data]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCopyText = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        document.getElementById("copyClipBoard").classList.add("d-block");
        setTimeout(() => {
          document.getElementById("copyClipBoard").classList.remove("d-block");
        }, 1000);
      })
      .catch((err) => {
        console.log("Failed to copy text: ", err);
      });
  };

  const getCurrentDateTime = () => {
    const now = new Date();

    // Mendapatkan tahun, bulan, dan tanggal dengan format yyyy-mm-dd
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Januari dimulai dari 0
    const day = String(now.getDate()).padStart(2, "0");

    // Mendapatkan jam, menit, dan detik dengan format HH:ii:ss
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");

    // Menggabungkan semua dalam format yang diinginkan
    const dateTimeString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    return dateTimeString;
  };

  const renderMessages = () => {
    const renderedMessages = [];
    let previousDatestamp = null;

    messages.forEach((message, index) => {
      const currentDatestamp = moment(message.ts_created).isSame(
        moment(),
        "day"
      )
        ? "Today"
        : moment(message.ts_created).format("MMMM D, YYYY");

      // Sisipkan elemen tanggal jika berbeda dengan tanggal sebelumnya
      if (currentDatestamp !== previousDatestamp) {
        renderedMessages.push(
          <div
            key={`datestamp-${index}`}
            className="datestamp text-center mb-4 pe-none"
          >
            {currentDatestamp}
          </div>
        );
        previousDatestamp = currentDatestamp;
      }

      // Tambahkan pesan ke dalam daftar pesan yang akan dirender
      renderedMessages.push(
        <li
          className={
            message.direction === "out" ? "chat-list left" : "chat-list right"
          }
          key={`message-${index}`}
        >
          <div
            className="conversation-list"
            ref={(el) => (resultRefs.current[index] = el)}
          >
            <div className="chat-avatar">
              <img
                src={
                  message.direction === "out"
                    ? selectedSession.assistant.profile_pic
                      ? selectedSession.assistant.profile_pic
                      : avatar1
                    : usr.user_profile.photo_url
                    ? usr.user_profile.photo_url
                    : avatar1
                }
                alt={index}
              />
            </div>
            <div
              className="user-chat-content"
              id={`message-${message.idt_message}`}
            >
              <div className="ctext-wrap">
                <div className="ctext-wrap-content">
                  <div className="markdown overflow-auto">
                    <MarkdownRenderer
                      markdown={message.content}
                      term={searchChatTerm}
                      isCurrent={
                        searchChatResults[currentChatIndex]?.index === index
                      }
                    />
                  </div>
                  {/* <div className="text-end text-muted pe-none datestamp invisible">
                    {formatDate(message.ts_created)}
                  </div> */}
                  <div className="text-end text-muted pe-none timestamp mt-2 fs-11">
                    {moment(message.ts_created).format("h:mm A")}
                  </div>
                </div>
                <Dropdown
                  // show={toogleVisible}
                  // onToggle={toggleMessage}
                  className="dropdown align-self-start message-box-drop"
                >
                  <Dropdown.Toggle
                    // onClick={toggleMessage}
                    variant=""
                  >
                    <i className="ri-more-2-fill"></i>{" "}
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    className="dropdown-menu"
                    align={message.direction === "out" ? "start" : "end"}
                  >
                    <Dropdown.Item
                      onClick={() => handleCopyText(message.content)}
                    >
                      <i className="ri-file-copy-line me-2 text-muted align-bottom"></i>
                      Copy
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => handleAddBookmark(message.idt_message)}
                    >
                      <i className="ri-bookmark-line me-2 text-muted align-bottom"></i>
                      Bookmark
                    </Dropdown.Item>
                    {selectedSession.knowledge
                      ? selectedSession.knowledge.knowledge_library[0]
                          .is_author === "Y" &&
                        message.direction === "out" && (
                          <Dropdown.Item
                            onClick={() => handleShow(message.idt_message)}
                          >
                            <i className="ri-file-search-line me-2 text-muted align-bottom"></i>
                            Analyze
                          </Dropdown.Item>
                        )
                      : message.direction === "out" && (
                          <Dropdown.Item
                            onClick={() => handleShow(message.idt_message)}
                          >
                            <i className="ri-file-search-line me-2 text-muted align-bottom"></i>
                            Analyze
                          </Dropdown.Item>
                        )}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </li>
      );
    });

    return renderedMessages;
  };

  /* Modal Chunk Detail */
  const [responseAnalyze, setResponseAnalyze] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const handleShow = (data) => {
    /* Get Chunk */
    setLoadingModal(true);
    axios
      .post(
        API_URL + "get-response-analyze",
        {
          idt_message: data,
        },
        {
          headers: {
            Authorization: `Bearer ${storage.get("access_token")}`,
          },
        }
      )
      .then((response) => {
        if (response.data.success === true) {
          setLoadingModal(false);
          setShowModal(true);
          setResponseAnalyze(response.data.data);
        } else {
          setLoadingModal(false);
        }
      })
      .catch((error) => {
        console.log("An error occurred. Please try again later.");
      });
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleArchiveSession = async (data) => {
    try {
      const response = await axios.post(
        API_URL + "download-session-message",
        { idt_session: data },
        {
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${storage.get("access_token")}`,
          },
        }
      );
      if (response.status === 200) {
        const blob = new Blob([response.data], { type: "text/plain" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "session-chat-" + selectedSession.session_id + ".txt"; // Nama file yang akan diunduh
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
      } else {
        console.error("Failed to download file:", response.statusText);
      }
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  return (
    <div className="chat-wrapper d-lg-flex gap-1 mx-n4 mt-n4 p-1">
      <div className="chat-leftsidebar">
        <div className="px-4 pt-4 mb-3">
          <div className="search-box">
            <input
              type="text"
              className="form-control bg-light border-light"
              placeholder="Search here..."
              value={searchItem}
              onChange={handleSearch}
            />
            <i className="ri-search-2-line search-icon"></i>
          </div>
        </div>

        <ul
          className="nav nav-tabs nav-tabs-custom nav-success nav-justified"
          role="tablist"
        >
          <li className="nav-item">
            <a
              className={
                typeSession === "personal"
                  ? "nav-link active cursor-pointer fs-13"
                  : "nav-link cursor-pointer fs-13"
              }
              onClick={() => handleTypeSession("personal")}
              data-bs-toggle="tab"
              role="tab"
            >
              {messagesLang.personal_sessions}
            </a>
          </li>
          <li className="nav-item">
            <a
              className={
                typeSession === "public"
                  ? "nav-link active cursor-pointer fs-13"
                  : "nav-link cursor-pointer fs-13"
              }
              onClick={() => handleTypeSession("public")}
              data-bs-toggle="tab"
              role="tab"
            >
              {messagesLang.public_sessions}
            </a>
          </li>
        </ul>

        <div className="tab-content text-muted">
          <div className="tab-pane active" role="tabpanel">
            <SimpleBar className="chat-room-list pt-2">
              <div className="chat-message-list">
                <ul
                  className="list-unstyled chat-list chat-user-list"
                  id="userList"
                >
                  {loadingSess ? (
                    <div className="loading-session-list">
                      <Spinner animation="border" />
                    </div>
                  ) : filteredItems.length > 0 ? (
                    filteredItems.map((item, index) => (
                      <li
                        id={index}
                        data-name="direct-message"
                        key={index}
                        className={
                          item.idt_session === activeSession ||
                          (loadItem === true && index === true)
                            ? "active"
                            : ""
                        }
                      >
                        <a className="chats">
                          <div className="d-flex align-items-center">
                            <div
                              className="flex-shrink-0 chat-user-img online align-self-center me-2 ms-0"
                              onClick={() => toggleMobileChatClass(item)}
                            >
                              <div className="avatar-xxs">
                                {item.knowledge ? (
                                  <LuBook
                                    size={24}
                                    style={{ marginTop: "-2px" }}
                                  />
                                ) : (
                                  <LuBookCopy
                                    size={24}
                                    style={{ marginTop: "-2px" }}
                                  />
                                )}

                                {/* <img
                                    src={avatar1}
                                    className="rounded-circle img-fluid userprofile"
                                    alt=""
                                  />
                                  <span className="user-status"></span> */}
                              </div>
                            </div>
                            <div
                              className="flex-grow-1 overflow-hidden"
                              onClick={() => toggleMobileChatClass(item)}
                            >
                              <p
                                className="texted text-truncate fs-14"
                                style={{ marginBottom: "-3px" }}
                              >
                                {item.title}
                              </p>
                              <p className="text-truncate text-muted mb-0 fs-16">
                                <small>
                                  {item.idt_knowledge && item.knowledge
                                    ? item.knowledge.title
                                    : item.idt_knowledge_collection &&
                                      item.knowledge_collection
                                    ? item.knowledge_collection.name
                                    : item.idt_knowledge
                                    ? "Deleted Knowledge"
                                    : "Deleted Collection"}
                                </small>
                              </p>
                              <p className="text-truncate text-muted mb-0 fs-12">
                                <small>
                                  {moment(item.ts_lastactive).format(
                                    "MMMM D, YYYY h:mm A"
                                  )}
                                </small>
                              </p>
                            </div>
                            <div className="d-sm-block d-md-none">
                              <ModalRemoveSession item={item} />
                            </div>
                          </div>
                          <div className="d-none d-md-flex d-lg-flex justify-content-end mt-1">
                            {item.idt_session === activeSession ||
                            (loadItem === true && index === true) ? (
                              <>
                                {item.session_bookmark.length > 0 ? (
                                  <button
                                    onClick={() =>
                                      toggleBookmark(activeSession)
                                    }
                                    className="btn c-icon mt-n1 p-0"
                                    title="Bookmark"
                                  >
                                    <RiBookmarkFill size={20} />
                                  </button>
                                ) : null}
                                <button
                                  onClick={() =>
                                    handleArchiveSession(activeSession)
                                  }
                                  className="btn c-icon mt-n1 p-0"
                                  title="Archive"
                                >
                                  <IoMdArchive size={20} />
                                </button>
                                <ModalRemoveSession item={item} />
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                          {showBookmark &&
                            item.idt_session === activeSession && (
                              <div className="d-block mt-1">
                                <hr className="chat-divider" />
                                {item.session_bookmark.length > 0
                                  ? item.session_bookmark.map(
                                      (bookmark, index) => (
                                        <div
                                          key={index}
                                          className="chat-bookmark-list mb-2"
                                        >
                                          <div className="d-flex justify-content-between">
                                            <div>
                                              <RiBookmarkFill size={15} />
                                            </div>
                                            <div
                                              className="d-bookmark flex-grow-1"
                                              style={{ width: "0" }}
                                              onClick={() => {
                                                var element =
                                                  document.getElementById(
                                                    "message-" +
                                                      bookmark.idt_message
                                                  );
                                                if (element) {
                                                  element.scrollIntoView({
                                                    behavior: "smooth",
                                                  });
                                                }
                                              }}
                                            >
                                              <p className="ms-2 mb-0 text-truncate">
                                                {bookmark.message.content}
                                              </p>
                                            </div>
                                            <div>
                                              <button
                                                onClick={() =>
                                                  handleRemoveBookmark(
                                                    bookmark.idt_message
                                                  )
                                                }
                                                className="btn c-icon mt-n1 p-0"
                                              >
                                                <RiDeleteBinFill size={15} />
                                              </button>
                                            </div>
                                          </div>
                                          <hr className="chat-divider-2" />
                                        </div>
                                      )
                                    )
                                  : null}
                              </div>
                            )}
                        </a>
                      </li>
                    ))
                  ) : (
                    <p className="text-center p-2 fs-14 pe-none">
                      {messagesLang.no_data} {messagesLang.sessions}
                    </p>
                  )}
                </ul>
              </div>
            </SimpleBar>
          </div>
        </div>
      </div>
      <div
        className={
          isMobileChatActive
            ? "user-chat w-100 overflow-hidden user-chat-show"
            : "user-chat w-100 overflow-hidden"
        }
      >
        <div className="chat-content d-lg-flex">
          <div className="w-100 overflow-hidden position-relative">
            <div className="position-relative">
              <div className="position-relative" id="users-chat">
                {activeMessage === true ? (
                  <div className="p-3 user-chat-topbar">
                    <div className="row align-items-center">
                      <div className="col-sm-8 col-8">
                        <div className="d-flex align-items-center">
                          <div className="flex-shrink-0 d-block d-lg-none me-2">
                            <a
                              onClick={() => {
                                setIsMobileChatActive(!isMobileChatActive);
                                setActiveSession(false);
                                setActiveMessage(false);
                                setMessages([]);
                              }}
                              className="user-chat-remove p-1"
                            >
                              <HiOutlineChevronLeft size={22} />
                            </a>
                          </div>
                          <div className="flex-grow-1 overflow-hidden">
                            <div className="d-flex align-items-center">
                              <div className="flex-shrink-0 chat-user-img online user-own-img align-self-center me-3 ms-0">
                                {selectedSession.knowledge ? (
                                  <LuBook size={26} />
                                ) : (
                                  <LuBookCopy size={26} />
                                )}
                                <span className="user-status"></span>
                              </div>
                              <div className="flex-grow-1 overflow-hidden">
                                <p className="text-truncate mb-0 fs-14">
                                  {selectedSession
                                    ? selectedSession.title
                                    : null}
                                </p>
                                <p className="text-truncate text-muted fs-16 mb-0 userStatus">
                                  <small>
                                    {selectedSession.idt_knowledge &&
                                    selectedSession.knowledge
                                      ? selectedSession.knowledge.title
                                      : selectedSession.idt_knowledge_collection &&
                                        selectedSession.knowledge_collection
                                      ? selectedSession.knowledge_collection
                                          .name
                                      : selectedSession.session_id}
                                  </small>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-4 col-4">
                        <ul className="list-inline user-chat-nav text-end mb-0">
                          {selectedSession.session_bookmark.length > 0 ? (
                            <li className="list-inline-item m-0">
                              <div>
                                <Dropdown
                                  // show={toogleVisible}
                                  // onToggle={toggleMessage}
                                  className="align-self-start d-sm-block d-md-none"
                                >
                                  <Dropdown.Toggle
                                    className="dropdown d-flex btn-icon btn-topbar btn-ghost-warning dropdown-toggle"
                                    // onClick={toggleMessage}
                                    variant=""
                                  >
                                    <RiBookmarkFill size={18} />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu
                                    style={{
                                      width: "300px",
                                      position: "absolute",
                                      inset: "0px 0px auto auto",
                                      margin: "0px",
                                      transform:
                                        "translate3d(0.740741px, 39.2593px, 0px);",
                                    }}
                                  >
                                    {selectedSession.session_bookmark.map(
                                      (bookmark, index) => (
                                        <Dropdown.Item key={index}>
                                          <div className="d-flex justify-content-between">
                                            <div
                                              className="d-bookmark flex-grow-1"
                                              style={{ width: "0" }}
                                              onClick={() => {
                                                var element =
                                                  document.getElementById(
                                                    "message-" +
                                                      bookmark.idt_message
                                                  );
                                                if (element) {
                                                  element.scrollIntoView({
                                                    behavior: "smooth",
                                                  });
                                                }
                                              }}
                                            >
                                              <p className="ms-2 mb-0 text-truncate">
                                                {bookmark.message.content}
                                              </p>
                                            </div>
                                            <div>
                                              <button
                                                onClick={() =>
                                                  handleRemoveBookmark(
                                                    bookmark.idt_message
                                                  )
                                                }
                                                className="btn c-icon mt-n1 p-0"
                                              >
                                                <RiDeleteBinFill size={15} />
                                              </button>
                                            </div>
                                          </div>
                                          <hr className="chat-divider-2" />
                                        </Dropdown.Item>
                                      )
                                    )}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </li>
                          ) : null}
                          {(selectedSession.knowledge !== null ||
                            selectedSession.knowledge_collection !== null) && (
                            <li
                              className="position-relative list-inline-item m-0 me-2 text-start"
                              style={{ top: "-4px", width: "180px" }}
                            >
                              <Select
                                styles={CustomStyles(theme)}
                                className="custom-select"
                                maxMenuHeight={200}
                                options={assistantSessionList.map((tab) => ({
                                  value: tab.idt_user_assistant,
                                  label: tab.name,
                                }))}
                                value={selectedSessionAssistant}
                                onChange={(selected) => {
                                  handleSetAssistant(selected);
                                }}
                                isSearchable={true}
                              />
                            </li>
                          )}
                          <li className="list-inline-item m-0">
                            <SearchChatDropdown
                              onHandleNavigation={handleNavigation}
                              onSearchChatResults={searchChatResults}
                              onCurrentChatIndex={currentChatIndex}
                              onSearchChatTerm={searchChatTerm}
                              onHandleChatSearch={debouncedHandleChatSearch}
                              onHandleKeyDown={handleKeyDown}
                              onHandleClearSearch={handleClearSearch}
                            />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                ) : null}

                <SimpleBar
                  ref={simpleBarRef}
                  className="chat-conversation p-3 p-lg-4 "
                  style={{
                    height:
                      typeSession === "public" ||
                      (typeSession === "personal" &&
                        selectedSession &&
                        selectedSession.knowledge === null &&
                        selectedSession.knowledge_collection === null)
                        ? "calc(100vh - 216px)"
                        : null,
                  }}
                >
                  {showSpinner ? (
                    <div id="elmLoader">
                      <div className="spinner-border text-orange" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    <ul
                      className="list-unstyled chat-conversation-list"
                      id="users-conversation"
                    >
                      {renderMessages()}
                      {loadingBot && (
                        <li className="chat-list left">
                          <div className="conversation-list">
                            <div className="chat-avatar">
                              <img
                                src={
                                  selectedSession.assistant.profile_pic
                                    ? selectedSession.assistant.profile_pic
                                    : avatar1
                                }
                                alt="avatar1"
                              />
                            </div>
                            <div className="user-chat-content">
                              <div className="ctext-wrap">
                                <div
                                  className="ctext-wrap-content"
                                  style={{ padding: "6px" }}
                                >
                                  {/* <Spinner
                                      className="flex-shrink-0 custom-spinner me-2"
                                      animation="grow"
                                      size="xs"
                                    />
                                    <Spinner
                                      className="flex-shrink-0 custom-spinner me-2"
                                      animation="grow"
                                      size="xs"
                                    />
                                    <Spinner
                                      className="flex-shrink-0 custom-spinner"
                                      animation="grow"
                                      size="xs"
                                    /> */}
                                  <img
                                    src={theme === "light" ? ai_dark : ai_light}
                                    width={40}
                                    height={40}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      )}
                    </ul>
                  )}
                </SimpleBar>
                {showScrollButton && (
                  <button
                    className="btn btn-orange scroll-to-bottom-btn rounded-circle"
                    onClick={scrollToBottom}
                  >
                    <HiChevronDoubleDown size={24} />
                  </button>
                )}
                {/* {activeMessage === true &&
                    !showSpinner &&
                    (selectedSession.knowledge !== null ||
                      selectedSession.knowledge_collection !== null) && (
                      <div
                        className="chat-date bg-primary p-2 rounded-2"
                        ref={chatDateRef}
                        style={{
                          position: "absolute",
                          left: "50%",
                          transform: "translateX(-50%)",
                          top: "6rem",
                          zIndex: "999",
                          visibility: showDatestamp ? "visible" : "hidden",
                        }}
                      ></div>
                    )} */}
                <div
                  className="alert alert-info alert-dismissible copyclipboard-alert px-4 fade show "
                  id="copyClipBoard"
                  role="alert"
                >
                  Text copied
                </div>
              </div>

              {activeMessage === true &&
                typeSession === "personal" &&
                (selectedSession.knowledge !== null ||
                  selectedSession.knowledge_collection !== null) && (
                  <div className="chat-input-section p-3 p-lg-4">
                    <form id="chatinput-form" onSubmit={handleSend}>
                      <div className="row g-0 align-items-end">
                        {/* <div className="col-auto">
                          <div className="chat-input-links me-2">
                            <div className="links-list-item">
                              <button
                                type="button"
                                className="btn btn-link text-decoration-none emoji-btn"
                                id="emoji-btn"
                              >
                                <i className="bx bx-smile align-middle"></i>
                              </button>
                            </div>
                          </div>
                        </div> */}
                        <div className="col">
                          <textarea
                            placeholder="Please Enter a Message"
                            ref={textareaRef}
                            className="form-control chat-input bg-light border-light"
                            // value={input}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                if (
                                  !e.shiftKey &&
                                  textareaRef.current.value.trim() !== ""
                                ) {
                                  e.preventDefault();
                                  handleSend(e); // Kirim pesan jika "Enter" ditekan tanpa "Shift"
                                } else if (!e.shiftKey) {
                                  e.preventDefault(); // Prevent default untuk "Enter" tanpa konten
                                }
                              }
                            }}
                            onChange={(e) => {
                              adjustTextareaHeight();
                              if (sendRef.current) {
                                sendRef.current.disabled =
                                  textareaRef.current.value.trim() === "" ||
                                  loadingChat;
                              }
                            }}
                            rows={1}
                          />
                        </div>
                        <div className="col-auto">
                          <div className="chat-input-links ms-2">
                            <div className="links-list-item">
                              <button
                                ref={sendRef}
                                disabled
                                className="btn btn-warning chat-send waves-effect waves-light"
                              >
                                <i className="ri-send-plane-2-fill align-bottom"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                )}

              <div className="replyCard">
                <div className="card mb-0">
                  <div className="card-body py-3">
                    <div className="replymessage-block mb-0 d-flex align-items-start">
                      <div className="flex-grow-1">
                        <h5 className="conversation-name"></h5>
                        <p className="mb-0"></p>
                      </div>
                      <div className="flex-shrink-0">
                        <button
                          type="button"
                          id="close_toggle"
                          className="btn btn-sm btn-link mt-n2 me-n3 fs-18"
                        >
                          <i className="bx bx-x align-middle"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loadingModal && <Loading />}
      {/* Secondary Modal */}
      <ModalMessageAnalyze
        data={responseAnalyze}
        show={showModal}
        handleClose={handleClose}
      />
    </div>
  );
};

export default PanelChat;
