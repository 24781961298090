import { useContext, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { RiDeleteBinFill } from "react-icons/ri";
import { API_URL } from "../utilities/url";
import storage from "../services/storage";
import { AssistantContext } from "../provider/assistantContext";
import { SessionContext } from "../provider/sessionContext";
import axios from "axios";

const ModalRemoveAssistant = ({ item, onDropdown, setId, itemFirst }) => {
  const {
    assistantList,
    setAssistantList,
    setAssistantSessionList,
    selectedSessionAssistant,
    setSelectedSessionAssistant,
    setDefaultAssistant,
  } = useContext(AssistantContext);
  const { sessionList, setSessionList, selectedSession, setSelectedSession } =
    useContext(SessionContext);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClose = (e) => {
    e.stopPropagation();
    onDropdown(null);
    setShow(false);
    setLoading(false);
  };
  const handleShow = () => setShow(true);
  const handleDelete = (e) => {
    setLoading(true);
    axios
      .post(
        API_URL + "remove-personal-assistant",
        {
          idt_user_assistant: item.idt_user_assistant,
        },
        {
          headers: {
            Authorization: `Bearer ${storage.get("access_token")}`,
          },
        }
      )
      .then((response) => {
        if (response.data.success === true) {
          setLoading(false);
          const first = assistantList.find(
            (ass) => ass.idt_user_assistant === 1
          );
          if (sessionList.length > 0) {
            const updatedSessionList = sessionList.map((session) => {
              if (
                session.assistant.idt_user_assistant === item.idt_user_assistant
              ) {
                return {
                  ...session,
                  assistant: {
                    ...session.assistant,
                    idt_user_assistant: 1,
                    name: first.name,
                    profile_pic: first.profile_pic,
                  },
                };
              }
              return session;
            });
            setSessionList(updatedSessionList);
          }
          if (selectedSession) {
            setSelectedSession((prev) => {
              if (
                prev.assistant &&
                prev.assistant.idt_user_assistant === item.idt_user_assistant
              ) {
                return {
                  ...prev,
                  assistant: {
                    ...prev.assistant,
                    idt_user_assistant: 1,
                    name: first.name,
                    profile_pic: first.profile_pic,
                  },
                };
              }
              return prev;
            });
          }
          if (setId === item.idt_user_assistant) {
            setAssistantList((prevSession) => {
              const filteredList = prevSession.filter(
                (ass) => ass.idt_user_assistant !== item.idt_user_assistant
              );
              const updatedList = filteredList.map((assistant) => {
                if (assistant.idt_user_assistant === 1) {
                  if (item.default) {
                    setDefaultAssistant(1);
                    return {
                      ...assistant,
                      default: {
                        idt_user: item.idt_user,
                        idt_user_assistant: 1,
                      },
                    };
                  }
                }
                return assistant;
              });
              return updatedList;
            });
            itemFirst(
              assistantList.find((ass) => ass.idt_user_assistant === 1)
            );
          } else {
            if (item.default) {
              setAssistantList((prevSession) => {
                const filteredList = prevSession.filter(
                  (ass) => ass.idt_user_assistant !== item.idt_user_assistant
                );
                const updatedList = filteredList.map((assistant) => {
                  if (assistant.idt_user_assistant === 1) {
                    setDefaultAssistant(1);
                    return {
                      ...assistant,
                      default: {
                        idt_user: item.idt_user,
                        idt_user_assistant: 1,
                      },
                    };
                  }
                  return assistant;
                });
                return updatedList;
              });
            } else {
              setAssistantList((prevSession) => {
                return prevSession.filter(
                  (ass) => ass.idt_user_assistant !== item.idt_user_assistant
                );
              });
            }
          }
          setAssistantSessionList((prevSession) => {
            return prevSession.filter(
              (ass) => ass.idt_user_assistant !== item.idt_user_assistant
            );
          });
          if (selectedSessionAssistant.value === item.idt_user_assistant) {
            setSelectedSessionAssistant({
              value: first.idt_user_assistant,
              label: first.name,
            });
          }
          onDropdown(null);
          handleClose();
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  return (
    <>
      <a
        className="dropdown-item"
        onClick={(e) => {
          e.stopPropagation();
          handleShow();
        }}
      >
        Delete Assistant
      </a>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        backdrop="static"
        dialogClassName="fade"
        onClick={(e) => {
          handleClose(e);
        }}
      >
        <Modal.Header className="p-3 bg-danger-subtle" closeButton>
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ width: 80, height: 80 }}
            ></lord-icon>
            <div className="mt-1 pt-2 fs-15 mx-4 mx-sm-5">
              <h5 className="text-truncate">Assistant: {item.name}</h5>
              <p className="text-muted mx-4 mb-0">
                Are you sure you want to delete ?
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <Button className="btn w-sm" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              className="btn w-sm"
              variant="danger"
              disabled={loading}
              onClick={handleDelete}
            >
              {loading ? (
                <>
                  <Spinner
                    className="flex-shrink-0"
                    animation="border"
                    size="sm"
                  />
                </>
              ) : (
                "Remove"
              )}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalRemoveAssistant;
