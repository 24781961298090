import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import { RiEyeFill, RiEyeOffFill } from "react-icons/ri";
import axios from "axios";
import { API_URL } from "../utilities/url";
import logoImg from "../assets/images/logo-dark.png";
import authHeader from "../services/auth.header";
import CryptoJS from "crypto-js";
import { useLanguage } from "../provider/languageContext";
import HeaderLanguage from "./component/headerLanguage";

var $ = require("jquery");

const Register = () => {
  const { messagesLang } = useLanguage();
  const navigate = useNavigate();
  const location = useLocation();
  const [alertMessage, setAlertMessage] = useState("");
  const [hideAlert, setHideAlert] = useState(true);
  const [hidePassword, setHidePassword] = useState("password");
  const [hidePassword2, setHidePassword2] = useState("password");

  const [state, setState] = useState({
    email: "",
    password: "",
    confirm_password: "",
    firstname: "",
    lastname: "",
    dob: "",
    country: "",
    msisdn: "",
  });

  useEffect(() => {
    if (location.state) {
      delete location.state;
    }
  }, []);

  /* Form Submit */
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm({
    reValidateMode: "onBlur",
  });

  const password = useRef({});
  password.current = watch("password", "");
  const validatePassword = (value) => {
    const hasUppercase = /[A-Z]/.test(value);
    const hasNumber = /\d/.test(value);
    const hasSpchar = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(value);
    if (!hasUppercase || !hasNumber || !hasSpchar) {
      return false;
    }
    return true;
  };
  const validateConfirmPassword = (value) => {
    return value === password.current;
  };
  const handleChangePass = (event) => {
    const value = event.target.value;
    value.length >= 8
      ? $("#pass-length").removeClass("invalid").addClass("valid")
      : $("#pass-length").removeClass("valid").addClass("invalid");
    /[A-Z]/.test(value)
      ? $("#pass-upper").removeClass("invalid").addClass("valid")
      : $("#pass-upper").removeClass("valid").addClass("invalid");
    /\d/.test(value)
      ? $("#pass-number").removeClass("invalid").addClass("valid")
      : $("#pass-number").removeClass("valid").addClass("invalid");
    /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(value)
      ? $("#pass-spchar").removeClass("invalid").addClass("valid")
      : $("#pass-spchar").removeClass("valid").addClass("invalid");
  };
  const validateEmail = async (value) => {
    const response = await axios.post(API_URL + "exist_user_v2", {
      email: value,
    });
    const isEmailTaken = response.data;
    if (!isEmailTaken) {
      return false;
    }
    return true;
  };

  const onSubmit = (data) => {
    axios
      .post(API_URL + "register", data)
      .then((response) => {
        if (response.data.success === true) {
          navigate("/register-success");
        }
      })
      .catch((error) => {
        console.log("There was an error!", error);
      });
  };

  const registerGoogle = useGoogleLogin({
    onSuccess: async (response) => {
      await axios
        .get("https://www.googleapis.com/oauth2/v3/userinfo", {
          headers: {
            Authorization: `${response.token_type} ${response.access_token}`,
          },
        })
        .then((response) => {
          /* Post to API */
          axios
            .post(
              API_URL + "exist_user",
              { email: response.data.email },
              {
                headers: authHeader(),
              }
            )
            .then((res) => {
              if (res.data.success === true) {
                setAlertMessage("Email has been registered.");
                setHideAlert(false);
              } else {
                navigate("/create-password", {
                  state: {
                    ec: CryptoJS.AES.encrypt(
                      response.data.email,
                      "email"
                    ).toString(),
                    // password: data.password,
                    fc: CryptoJS.AES.encrypt(
                      response.data.given_name,
                      "firstname"
                    ).toString(),
                    lc: CryptoJS.AES.encrypt(
                      response.data.family_name,
                      "lastname"
                    ).toString(),
                    pc: CryptoJS.AES.encrypt(
                      response.data.picture,
                      "picture"
                    ).toString(),
                  },
                });
              }
            })
            .catch((error) => {
              console.log("There was an error!", error);
            });
        })
        .catch((error) => {
          console.log("There was an error!", error);
        });
    },
  });

  /* Function */
  const togglePassword = () => {
    if (hidePassword === "password") {
      setHidePassword("text");
      return;
    }
    setHidePassword("password");
  };
  const togglePassword2 = () => {
    if (hidePassword2 === "password") {
      setHidePassword2("text");
      return;
    }
    setHidePassword2("password");
  };

  const handlePaste = (e) => {
    e.preventDefault();
  };

  return (
    <div className="auth-page-content pb-0">
      <div className="container">
        <div className="row">
          <div className="d-flex justify-content-between mt-sm-4 mb-sm-2 text-white-50">
            <Link to="/login">
              <img
                src={logoImg}
                alt=""
                height="40"
                className="d-none d-sm-block"
              />
            </Link>
            <div className="head-lang">
              <HeaderLanguage />
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-8 col-lg-6 col-xl-5">
            <div className="card login rounded-4 mt-4">
              <div className="card-body p-4">
                <div className="text-center mt-2">
                  <h5 className="text-white">{messagesLang.create_account}</h5>
                </div>
                <div className="p-2 mt-4">
                  <div
                    className={`alert alert-danger alert-dismissible fade show ${
                      hideAlert ? "d-none" : "d-block"
                    }`}
                    role="alert"
                  >
                    <button
                      onClick={() => setHideAlert(true)}
                      type="button"
                      className="btn-close"
                      aria-label="Close"
                    ></button>
                    <div className="alert-message">
                      <strong className="text-danger">{alertMessage}</strong>
                    </div>
                  </div>
                  <div className="mt-3">
                    <a
                      onClick={() => registerGoogle()}
                      className="btn btn-secondary w-100 waves-effect waves-light"
                    >
                      <i className="ri-google-fill fs-14 me-2"></i>{" "}
                      {messagesLang.create_account_app_google}
                    </a>
                  </div>
                  <div className="separator-or mt-3 mb-5">
                    <h6 className="text-light">OR</h6>
                  </div>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-3">
                      <label className="form-label text-light">
                        {messagesLang.first_name}
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="name_first"
                        {...register("name_first", {
                          required: true,
                        })}
                      />
                      {errors.name_first &&
                        errors.name_first.type === "required" && (
                          <p className="errorMsg text-danger fs-7">
                            First Name is required.
                          </p>
                        )}
                    </div>
                    <div className="mb-3">
                      <label className="form-label text-light">
                        {messagesLang.last_name}
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="name_last"
                        {...register("name_last", {
                          required: true,
                        })}
                      />
                      {errors.name_last &&
                        errors.name_last.type === "required" && (
                          <p className="errorMsg text-danger fs-7">
                            Last Name is required.
                          </p>
                        )}
                    </div>
                    {/* <div className="mb-3">
                      <label className="form-label text-light">
                        Date of Birth<span className="text-danger">*</span>
                      </label>
                      <Controller
                        control={control}
                        name="dob"
                        defaultValue={[new Date().toISOString()]}
                        // rules={{ required: "DOB is required." }}
                        render={({ field }) => (
                          <DatePicker
                            {...field}
                            className="form-control"
                            // value={selectedDate}
                            options={{
                              dateFormat: "d-m-Y",
                              // Add more Flatpickr options as needed
                            }}
                          />
                        )}
                      />
                      {errors.dob && (
                        <p className="errorMsg text-danger fs-7" id="erdate">
                          {errors.dob.message}
                        </p>
                      )}
                    </div> */}
                    {/* <div className="mb-3">
                      <label className="form-label text-light">
                        Country<span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="country"
                        control={control}
                        rules={{ required: "Country is required." }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            styles={customStyles}
                            maxMenuHeight={200}
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: 0,
                              colors: {
                                ...theme.colors,
                                // primary25: "#fca311",
                                // primary50: "#fca311",
                                // primary: "#fca311",
                                // neutral80: "#000",
                              },
                            })}
                            options={state.country}
                            placeholder="Select Country"
                            value={selectedOption}
                            onChange={handleSelect}
                            isSearchable={true}
                            selected={selectedOption}
                          />
                        )}
                      />
                      {errors.country && (
                        <p className="errorMsg text-danger fs-7">
                          {errors.country.message}
                        </p>
                      )}
                    </div> */}
                    {/* <div className="mb-3">
                      <label className="form-label text-light">Phone Number</label>
                      <input
                        type="text"
                        name="msisdn"
                        value={state.msisdn}
                        className="form-control"
                        {...register("msisdn", {
                          required: true,
                          minLength: 8,
                        })}
                        onChange={handleChange}
                      />
                      {errors.msisdn && errors.msisdn.type === "required" && (
                        <p className="errorMsg text-danger fs-7">
                          Phone Number is required.
                        </p>
                      )}
                      {errors.msisdn && errors.msisdn.type === "minLength" && (
                        <p className="errorMsg text-danger fs-7">
                          Phone Number min 8 digit.
                        </p>
                      )}
                    </div> */}
                    <div className="mb-3">
                      <label className="form-label text-light">
                        Email<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="email"
                        {...register("email", {
                          required: true,
                          validate: validateEmail,
                          pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                        })}
                      />
                      {errors.email && errors.email.type === "required" && (
                        <p className="errorMsg text-danger fs-7">
                          Email is required.
                        </p>
                      )}
                      {errors.email && errors.email.type === "pattern" && (
                        <p className="errorMsg text-danger fs-7">
                          Email is not valid.
                        </p>
                      )}
                      {errors.email && errors.email.type === "validate" && (
                        <p className="errorMsg text-danger fs-7">
                          Email has already taken.
                        </p>
                      )}
                    </div>

                    <div className="mb-3">
                      <label className="form-label text-light">
                        {messagesLang.password}
                        <span className="text-danger">*</span>
                      </label>
                      <div className="position-relative auth-pass-inputgroup mb-3">
                        <input
                          type={hidePassword}
                          name="password"
                          autoComplete="on"
                          className="form-control pe-5 password-input"
                          {...register("password", {
                            required: true,
                            minLength: 8,
                            validate: validatePassword,
                          })}
                          onChange={handleChangePass}
                        />
                        {errors.password &&
                          errors.password.type === "required" && (
                            <p className="errorMsg text-danger fs-7">
                              Password is required.
                            </p>
                          )}
                        {errors.password &&
                          errors.password.type === "minLength" && (
                            <p className="errorMsg text-danger fs-7">
                              Password min 8 character.
                            </p>
                          )}
                        {errors.password &&
                          errors.password.type === "validate" && (
                            <p className="errorMsg text-danger fs-7">
                              Password must contain at least uppercase letter,
                              number and special character.
                            </p>
                          )}
                        <a
                          className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                          // href="#"
                          onClick={togglePassword}
                        >
                          {hidePassword === "password" ? (
                            <RiEyeFill size={20} />
                          ) : (
                            <RiEyeOffFill size={20} />
                          )}
                        </a>
                      </div>
                    </div>
                    <div className="mb-3">
                      <label
                        className="form-label text-light"
                        style={{ width: "100%" }}
                      >
                        {messagesLang.confirm_password}
                        <span className="text-danger">*</span>
                      </label>
                      <div className="position-relative auth-pass-inputgroup mb-3">
                        <input
                          type={hidePassword2}
                          name="cpassword"
                          autoComplete="off"
                          onPaste={handlePaste}
                          className="form-control pe-5 password-input"
                          {...register("cpassword", {
                            required: true,
                            minLength: 8,
                            validate: validateConfirmPassword,
                          })}
                        />
                        {errors.cpassword &&
                          errors.cpassword.type === "required" && (
                            <p className="errorMsg text-danger fs-7">
                              Password is required.
                            </p>
                          )}
                        {errors.cpassword &&
                          errors.cpassword.type === "minLength" && (
                            <p className="errorMsg text-danger fs-7">
                              Password min 8 character.
                            </p>
                          )}
                        {errors.cpassword &&
                          errors.cpassword.type === "validate" && (
                            <p className="errorMsg text-danger fs-7">
                              Password don't match.
                            </p>
                          )}
                        <a
                          className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                          // href="#"
                          onClick={togglePassword2}
                        >
                          {hidePassword2 === "password" ? (
                            <RiEyeFill size={20} />
                          ) : (
                            <RiEyeOffFill size={20} />
                          )}
                        </a>
                      </div>
                    </div>
                    <div
                      id="password-contain"
                      style={{ display: "block" }}
                      className="p-3 bg-light mb-2 rounded"
                    >
                      <h5 className="fs-13">
                        {messagesLang.password_must_contain}:
                      </h5>
                      <p id="pass-length" className="invalid fs-12 mb-2">
                        {messagesLang.minimum}{" "}
                        <b>8 {messagesLang.characters}</b>
                      </p>
                      <p id="pass-upper" className="invalid fs-12 mb-2">
                        {messagesLang.at_least_one}{" "}
                        <b>{messagesLang.uppercase_letter}</b> (A-Z)
                      </p>
                      <p id="pass-number" className="invalid fs-12 mb-2">
                        {messagesLang.at_least_one} <b>{messagesLang.number}</b>{" "}
                        (0-9)
                      </p>
                      <p id="pass-spchar" className="invalid fs-12 mb-0">
                        {messagesLang.at_least_one}{" "}
                        <b>{messagesLang.special_character}</b> (!@#$%^*|,./?
                        etc.)
                      </p>
                    </div>
                    <div className="mt-4">
                      <button className="btn btn-success w-100" type="submit">
                        {messagesLang.create}{" "}
                      </button>
                    </div>
                  </form>
                  <div className="mt-2 text-center">
                    {/* <div className="signin-other-title">
                          <h5 className="fs-13 mb-2 title">Sign In with</h5>
                        </div> */}
                    <div>
                      {/* <GoogleLogin
                            onSuccess={(credentialResponse) => {
                              var credentialResponseDecode = jwtDecode(
                                credentialResponse.credential
                              );
                              console.log(credentialResponseDecode);
                            }}
                            onError={() => {
                              console.log("Login Failed");
                            }}
                          /> */}
                    </div>
                    <div className="mt-4 text-center">
                      <p className="mb-0 text-light">
                        {messagesLang.already_have_account}?{" "}
                        <NavLink
                          to="/login"
                          className="fw-semibold text-orange text-decoration-underline link-underline-opacity-100-hover"
                        >
                          {" "}
                          {messagesLang.sign_in}{" "}
                        </NavLink>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
