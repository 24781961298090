// src/DetailModal.js
import React, { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { FaRegCircleCheck } from "react-icons/fa6";
import { Link } from "react-router-dom";

function ModalSuccess({ show, handleClose }) {
  useEffect(() => {
    if (show) {
      document.body.classList.add("modal-open-custom");
    } else {
      document.body.classList.remove("modal-open-custom");
    }

    // Clean up effect when component is unmounted
    return () => {
      document.body.classList.remove("modal-open-custom");
    };
  }, [show]);

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      backdrop="static"
      dialogClassName="modal-md fade"
    >
      {/* <Modal.Header className="p-3 bg-light-dark" closeButton></Modal.Header> */}
      <Modal.Body>
        <div className="mt-2 text-center">
          <div class="avatar-md m-auto">
            <span class="avatar-title bg-success-subtle rounded fs-3">
              <FaRegCircleCheck className="text-success" size={40} />
            </span>
          </div>
          <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
            <h4>You have selected knowledge</h4>
            <p className="text-muted mx-4 mb-0">
              Continue to searching other knowledge or continue to cart.
            </p>
          </div>
        </div>
        <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
          <Button className="btn w-sm" onClick={handleClose}>
            Continue to explore
          </Button>
          <Link to="/cart" className="btn btn-danger w-sm">
            Continue to cart
          </Link>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ModalSuccess;
