import axios from "axios";
import { useContext, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { API_URL } from "../utilities/url";
import storage from "../services/storage";
import bookComp from "../assets/images/book-comp.png";
import image_knowledge from "../assets/images/knowledge.png";
import { useLanguage } from "../provider/languageContext";
import { ThemeContext } from "../provider/themeContext";
import { ToastrContext } from "../provider/toastrContext";
import SimpleBar from "simplebar-react";
import ReactStars from "react-rating-stars-component";

const ModalAddReview = ({ data, onChangeData }) => {
  const { messagesLang } = useLanguage();
  const { showSuccess, showError } = useContext(ToastrContext);
  const { theme } = useContext(ThemeContext);
  const [show, setShow] = useState(false);
  const [mainImage, setMainImage] = useState(image_knowledge);
  const [loading, setLoading] = useState(0);
  const [loadingData, setLoadingData] = useState(0);
  const [activeThumbnail, setActiveThumbnail] = useState();
  const handleThumbnailClick = (image) => {
    setMainImage(image);
    setActiveThumbnail(image);
  };

  const [rating, setRating] = useState(0);
  const [errorRating, setErrorRating] = useState(false);

  const ratingChanged = (newRating) => {
    setRating(newRating);
    setErrorRating(false);
  };

  const handleClose = () => {
    setShow(false);
    setLoading(false);
    setRating(0);
    setErrorRating(false);
    reset();
  };
  const handleShow = () => {
    setShow(true);
    setLoadingData(true);
  };

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
    clearErrors,
  } = useForm({
    reValidateMode: "onBlur",
  });

  const handleAddReview = (dataset) => {
    if (rating === 0) {
      setErrorRating(true);
    } else {
      setLoading(true);
      setErrorRating(false);
      const formData = {
        idt_knowledge: data.idt_knowledge ? data.idt_knowledge : null,
        idt_knowledge_collection: data.idt_knowledge_collection
          ? data.idt_knowledge_collection
          : null,
        rating: rating,
        review: dataset.review,
      };
      axios
        .post(API_URL + "add-knowledge-review", formData, {
          headers: {
            Authorization: `Bearer ${storage.get("access_token")}`,
          },
        })
        .then((response) => {
          if (response.data.success === true) {
            handleClose();
            showSuccess(response.data.message);
            onChangeData(response.data.data);
            setLoading(false);
          } else {
            showError(response.data.message);
            setLoading(false);
          }
        })
        .catch((error) => {
          showError("An error occurred. Please try again later.");
          setLoading(false);
        });
    }
  };

  return (
    <>
      <a className="link-orange cursor-pointer" onClick={handleShow}>
        {messagesLang.add_review}
      </a>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        backdrop="static"
        dialogClassName="custom-modal-collection fade"
        onHide={handleClose}
      >
        <Modal.Header className="p-3 bg-light-dark" closeButton>
          <h5 className="modal-title">{messagesLang.add_review}</h5>
        </Modal.Header>
        <SimpleBar className="modal-body knowledge">
          <div className="col-12">
            <form onSubmit={handleSubmit(handleAddReview)}>
              <div className="row">
                <div className="col-md-5">
                  <div className="image-gallery">
                    <div className="main-image d-none d-sm-block">
                      <img
                        src={
                          data.idt_knowledge !== undefined
                            ? data.knowledge_cover_pic.length > 0
                              ? data.knowledge_cover_pic[0].pathurl
                              : image_knowledge
                            : data.url_coverpic
                            ? data.url_coverpic
                            : bookComp
                        }
                        className="object-fit-cover"
                        alt="Main"
                      />
                    </div>
                    {/* {data.knowledge_cover_pic > 1 ? (
                      <div className="thumbnail-images">
                        {data.knowledge_cover_pic.map((thumbnail, index) => (
                          <div
                            key={index}
                            className={`thumbnail-wrapper object-fit-cover d-none d-sm-block ${
                              activeThumbnail === thumbnail.pathurl
                                ? "active"
                                : ""
                            }`}
                            onClick={() =>
                              handleThumbnailClick(thumbnail.pathurl)
                            }
                          >
                            <img
                              src={thumbnail.pathurl}
                              alt={`Thumbnail ${index + 1}`}
                              className={
                                activeThumbnail === thumbnail.pathurl
                                  ? "object-fit-cover active"
                                  : "object-fit-cover"
                              }
                            />
                            <div className="thumbnail-overlay"></div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <></>
                    )} */}
                  </div>
                </div>
                <div className="col-md-7">
                  <h5 className="fs-15">
                    {data.idt_knowledge !== undefined ? data.title : data.name}
                  </h5>
                  {data.idt_knowledge !== undefined ? (
                    <>
                      <p className="text-muted mb-0">
                        Author: <span className="fw-medium">{data.author}</span>
                      </p>
                      <p className="text-muted mb-0">
                        Publisher:{" "}
                        <span className="fw-medium">
                          {data.knowledge_publisher.name}
                        </span>
                      </p>
                      <p className="text-muted mb-0">
                        Category:{" "}
                        <span className="fw-medium">
                          {data.knowledge_category_map.map((data, index) => {
                            return index === 0
                              ? data.category.name
                              : `, ${data.category.name}`;
                          })}
                        </span>
                      </p>
                    </>
                  ) : (
                    <p className="text-muted mb-0">Collection</p>
                  )}
                  <p className="text-muted mb-0">
                    Uploaded By:{" "}
                    <span className="fw-medium">{`(${data.user.user_profile.name_first} 
                                      ${data.user.user_profile.name_last})`}</span>
                  </p>
                  <hr />
                  <div className="w-100 mb-3">
                    <label className="form-label w-100 mb-0">Rating</label>
                    <ReactStars
                      count={5}
                      onChange={ratingChanged}
                      size={24}
                      // isHalf={true}
                      emptyIcon={<i className="ri-star-fill"></i>}
                      // halfIcon={<i className="ri-star-half-fill"></i>}
                      fullIcon={<i className="ri-star-fill"></i>}
                      activeColor="#ffd700"
                    />
                    {errorRating && (
                      <p className="errorMsg text-danger fs-7">
                        Rating is required.
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-4">
                    <textarea
                      className="form-control"
                      name="review"
                      {...register("review", {
                        required: false,
                      })}
                      style={{ minHeight: "100px", overflow: "hidden" }}
                    />
                  </div>
                  <div className="d-flex justify-content-end">
                    <Button
                      className="btn btn-primary w-sm"
                      type="submit"
                      disabled={loading}
                    >
                      {loading ? (
                        <>
                          <Spinner
                            className="flex-shrink-0"
                            animation="border"
                            size="sm"
                          />
                        </>
                      ) : (
                        "Submit"
                      )}
                    </Button>
                  </div>
                </div>
                {errors.collection && (
                  <p className="errorMsg text-danger fs-7 mb-0">
                    {errors.collection.message}
                  </p>
                )}
              </div>
            </form>
          </div>
        </SimpleBar>
      </Modal>
    </>
  );
};

export default ModalAddReview;
