import axios from "axios";
import { useContext, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { API_URL, API_URL_CORE } from "../utilities/url";
import storage from "../services/storage";
import { LuBookLock, LuBookUp } from "react-icons/lu";
import bookComp from "../assets/images/book-comp.png";
import { useLanguage } from "../provider/languageContext";
import { ToastrContext } from "../provider/toastrContext";
import image_knowledge from "../assets/images/knowledge.png";
import SimpleBar from "simplebar-react";
import { RxCross2 } from "react-icons/rx";
import { BiWorld } from "react-icons/bi";
import CryptoJS from "crypto-js";
import { FaUserAstronaut } from "react-icons/fa";
import { RiCameraFill } from "react-icons/ri";

const ModalUpdateCollection = ({ onUpdate, handleClose, show, item }) => {
  const { messagesLang } = useLanguage();
  const { showSuccess, showError } = useContext(ToastrContext);
  const decryptUSR = CryptoJS.AES.decrypt(storage.get("user"), "user").toString(
    CryptoJS.enc.Utf8
  );
  const usr = JSON.parse(decryptUSR);
  const [loading, setLoading] = useState(false);
  const [loadingImg, setLoadingImg] = useState(false);
  const [dataItem, setDataItem] = useState(item);
  const [state, setState] = useState({
    name: dataItem ? dataItem.name : item.name,
    description: dataItem ? dataItem.description : item.description,
  });

  const [imagePreview, setImagePreview] = useState(
    item.url_coverpic ? item.url_coverpic : bookComp
  );

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
      setValue("images", file);
    }
  };

  const handleGenImg = async (data) => {
    try {
      setLoadingImg(true);
      const response = await axios.post(API_URL_CORE + "image/cover", {
        knowledge_description: data.description,
        style: "abstract",
      });

      if (response.data.errormsg === "OK") {
        const base64String = response.data.data.images[0];
        setImagePreview(`data:image/png;base64,${base64String}`);
        const base64Data = base64String.split(",")[0];
        const byteCharacters = atob(base64Data);
        const byteNumbers = new Uint8Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const blob = new Blob([byteNumbers], { type: "image/png" });
        const file = new File([blob], "profile_pic.png", { type: "image/png" });
        setValue("images", file);
      }
      setLoadingImg(false);
    } catch (error) {
      console.error("Error generating image:", error);
      setImagePreview(null);
      setLoadingImg(false);
    }
  };

  const handleDeleteCard = (id) => {
    const updatedKnowledgeCollectionMap =
      dataItem.knowledge_collection_map.filter(
        (knowledge) => knowledge.knowledge.idt_knowledge !== id
      );
    setDataItem({
      ...dataItem,
      knowledge_collection_map: updatedKnowledgeCollectionMap,
    });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
    if (name === "description") {
      adjustTextareaHeight();
    }
    clearErrors(event.target.name);
  };

  const adjustTextareaHeight = () => {
    const elements = document.getElementsByName("description");
    const element = elements[1];
    element.style.height = "auto";
    element.style.height = `${element.scrollHeight}px`;
  };

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
    clearErrors,
    setValue,
  } = useForm({
    reValidateMode: "onBlur",
  });

  const handleUpdateCollection = (data) => {
    setLoading(true);
    const dataForm = {
      idt_knowledge_collection: dataItem.idt_knowledge_collection,
      name: data.name,
      description: data.description,
      images: data.images,
      ids: dataItem.knowledge_collection_map.map((item) => item.idt_knowledge),
    };
    axios
      .post(API_URL + "update-collection", dataForm, {
        headers: {
          Authorization: `Bearer ${storage.get("access_token")}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response.data.success === true) {
          handleClose("collection");
          showSuccess(response.data.message);
          const datas = {
            idt_knowledge_collection: dataItem.idt_knowledge_collection,
            name: data.name,
            description: data.description,
            url_coverpic: response.data.data.url_coverpic,
            ids: dataItem.knowledge_collection_map.map(
              (item) => item.idt_knowledge
            ),
          };
          onUpdate(datas, "collection");
          setLoading(false);
        } else {
          showError(response.data.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        showError("An error occurred. Please try again later.");
        setLoading(false);
      });
  };

  return (
    <>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        backdrop="static"
        dialogClassName="custom-modal-collection fade"
        onHide={handleClose}
      >
        <Modal.Header className="p-3 bg-light-dark" closeButton>
          <h5 className="modal-title">Update Collection</h5>
        </Modal.Header>
        <SimpleBar className="modal-body knowledge">
          <div className="col-12">
            <form onSubmit={handleSubmit(handleUpdateCollection)}>
              <div className="row">
                {dataItem.knowledge_collection_map ? (
                  dataItem.knowledge_collection_map.length > 0 ? (
                    <>
                      <div className="col-md-5">
                        <div className="profile-user position-relative d-flex justify-content-center mx-auto mb-2">
                          <img
                            src={imagePreview ? imagePreview : bookComp}
                            className="avatar-xl img-thumbnail user-profile-image"
                            alt="user-profile-image"
                            style={{
                              height: "250px",
                              width: "187.5px",
                              borderRadius: "15px",
                            }}
                          />
                        </div>
                        <div className="profile-user position-relative d-flex flex-column align-items-center mx-auto mb-4">
                          <div className="p-0 mb-2">
                            <input
                              id="profile-img-file-input"
                              name="images"
                              type="file"
                              className="profile-img-file-input"
                              accept="image/*"
                              onChange={handleImageChange}
                            />
                            <label
                              htmlFor="profile-img-file-input"
                              className="btn btn-primary m-auto w-lg"
                              style={{ width: "180px" }}
                            >
                              <span className="text-white">
                                <RiCameraFill className="me-2" size={14} />
                                Upload Image
                              </span>
                            </label>
                          </div>
                          <button
                            type="button"
                            className="btn btn-info w-lg"
                            onClick={handleSubmit(handleGenImg)}
                            disabled={loadingImg}
                            style={{ width: "180px" }}
                          >
                            {loadingImg ? (
                              <>
                                <Spinner
                                  className="flex-shrink-0"
                                  animation="border"
                                  size="sm"
                                />
                              </>
                            ) : (
                              <>
                                <FaUserAstronaut className="me-2" size={14} />
                                Generate AI Image
                              </>
                            )}
                          </button>
                        </div>
                      </div>
                      <div className="col-md-7">
                        <div className="d-block">
                          <div>
                            <label className="form-label w-100">
                              Collection Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="name"
                              value={state.name}
                              {...register("name", {
                                required: true,
                              })}
                              onChange={handleInputChange}
                            />
                            {errors.name && errors.name.type === "required" && (
                              <p className="errorMsg text-danger fs-7">
                                Collection is required.
                              </p>
                            )}
                          </div>
                          <div className="mt-2">
                            <label className="form-label">Description</label>
                            <textarea
                              className="form-control"
                              value={state.description}
                              name="description"
                              {...register("description", {
                                required: true,
                              })}
                              onChange={handleInputChange}
                              style={{
                                minHeight: "100px",
                                overflow: "hidden",
                              }}
                            />
                            {errors.description &&
                              errors.description.type === "required" && (
                                <p className="errorMsg text-danger fs-7">
                                  Description is required.
                                </p>
                              )}
                          </div>
                        </div>
                        <div className="d-flex justify-content-end">
                          <Button
                            className="btn btn-primary mt-3 w-sm"
                            disabled={loading}
                            type="submit"
                          >
                            {loading ? (
                              <>
                                <Spinner
                                  className="flex-shrink-0"
                                  animation="border"
                                  size="sm"
                                />
                              </>
                            ) : (
                              "Update"
                            )}
                          </Button>
                        </div>
                      </div>
                      {errors.collection && (
                        <p className="errorMsg text-danger fs-7 mb-0">
                          {errors.collection.message}
                        </p>
                      )}
                      <hr className="mt-4 mb-0" />
                      <p className="mt-3 mb-0 fw-bold">
                        List Collection (
                        {dataItem.knowledge_collection_map &&
                          dataItem.knowledge_collection_map.length}
                        )
                      </p>
                      {dataItem.knowledge_collection_map
                        ? dataItem.knowledge_collection_map.map(
                            (card, index) => (
                              <div
                                key={card.idt_knowledge}
                                className="custom-coloum-3 folder-card mt-3"
                              >
                                <div className="card card-list bg-light shadow-lg">
                                  <div className="card-body">
                                    <div className="text-start">
                                      <div className="mb-2">
                                        <div className="square-container">
                                          <img
                                            src={
                                              card.knowledge.knowledge_cover_pic
                                                .length > 0
                                                ? card.knowledge
                                                    .knowledge_cover_pic[0]
                                                    .pathurl
                                                : image_knowledge
                                            }
                                            className="rounded-3"
                                            alt={index}
                                          />
                                        </div>
                                      </div>
                                      <div style={{ height: "36px" }}>
                                        <h6 className="fs-12 fw-bold ellipsis-2-line knowledge-name">
                                          {card.knowledge.title}
                                        </h6>
                                      </div>
                                    </div>
                                    <p className="text-start fs-11 knowledge-name mb-0">
                                      {card.knowledge.type === "private" ? (
                                        <>
                                          <LuBookLock
                                            size={11}
                                            className="me-1"
                                            style={{ marginTop: "-3px" }}
                                          />
                                          Personal Private
                                        </>
                                      ) : card.idt_user === usr.idt_user ? (
                                        <>
                                          <LuBookUp
                                            size={11}
                                            className="me-1"
                                            style={{ marginTop: "-3px" }}
                                          />
                                          Personal Publish
                                        </>
                                      ) : (
                                        <>
                                          <BiWorld
                                            size={11}
                                            className="me-1"
                                            style={{ marginTop: "-3px" }}
                                          />{" "}
                                          Public
                                        </>
                                      )}
                                    </p>
                                    <p className="text-start fs-12 knowledge-name mb-0">
                                      {card.knowledge.author}
                                    </p>
                                    <p className="text-start fs-12 knowledge-name mb-0">
                                      {card.knowledge.knowledge_publisher.name}
                                    </p>
                                  </div>
                                  {/* Panel Button Setting*/}
                                  {dataItem.knowledge_collection_map.length >
                                  2 ? (
                                    <div className="panel-button d-flex">
                                      <button
                                        className="btn btn-danger card-btn-option scroll-to-bottom-btn rounded-circle"
                                        onClick={() =>
                                          handleDeleteCard(
                                            card.knowledge.idt_knowledge
                                          )
                                        }
                                        style={{
                                          top: "8%",
                                          right: "14%",
                                          height: "22px",
                                          width: "22px",
                                        }}
                                      >
                                        <RxCross2 size={14} />
                                      </button>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            )
                          )
                        : null}
                    </>
                  ) : (
                    <div
                      className="text-center fs-16"
                      style={{ marginTop: "60px", marginBottom: "120px" }}
                    >
                      {messagesLang.data_not_found}
                    </div>
                  )
                ) : (
                  <div className="mt-2 mb-2" style={{ height: "315px" }}>
                    <div className="loading-backdrop-2">
                      <Spinner animation="border" />
                    </div>
                  </div>
                )}
              </div>
            </form>
          </div>
        </SimpleBar>
      </Modal>
    </>
  );
};

export default ModalUpdateCollection;
