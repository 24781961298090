import React, { useState } from "react";
import { FaTimes } from "react-icons/fa";

const TagInput = ({ onTagsChange, tags, setTags, onDelete }) => {
  const [inputValue, setInputValue] = useState("");

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const trimmedValue = inputValue.trim();
      if (trimmedValue) {
        if (!tags.includes(trimmedValue)) {
          const newTags = [...tags, trimmedValue];
          setTags(newTags);
          const formattedTags = newTags.join(", ");
          onTagsChange(formattedTags);
          setInputValue("");
        }
      }
    }
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const removeTag = (index) => {
    const newTags = tags.filter((_, i) => i !== index);
    const formattedTags = newTags.join(", ");
    setTags(newTags);
    onTagsChange(formattedTags);
  };

  return (
    <div className="tag-input-container">
      <ul className="tags">
        {tags.map((tag, index) =>
          onDelete === true ? (
            <li key={index} className="tag bg-primary">
              {tag}
              <button className="btn btn-sm" onClick={() => removeTag(index)}>
                <FaTimes size={11} />
              </button>
            </li>
          ) : (
            <li key={index} className="tag2 bg-primary">
              {tag}
            </li>
          )
        )}
        {onDelete && (
          <li className="tag-input">
            <input
              type="text"
              value={inputValue}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              placeholder="Type and press Enter"
            />
          </li>
        )}
      </ul>
    </div>
  );
};

export default TagInput;
