// src/DetailModal.js
import React, { useEffect, useState } from "react";
import { Modal, ProgressBar } from "react-bootstrap";
import { useLanguage } from "../provider/languageContext";
import ReviewKnowledge from "./reviewKnowledge";
import SimpleBar from "simplebar-react";
import { HiOutlinePencilAlt, HiOutlineTrash } from "react-icons/hi";
import image_knowledge from "../assets/images/knowledge.png";
import { format_number } from "../utilities/ext-function";
import CryptoJS from "crypto-js";
import storage from "../services/storage";
import ReactStars from "react-rating-stars-component";
import { IoMdStar } from "react-icons/io";
import { MdOutlineArchive } from "react-icons/md";

function ModalDetail2({
  item,
  review,
  starReview,
  selectedRatings,
  onRatingChange,
  loadMoreReview,
  loadingReview,
  hasMore,
  show,
  handleClose,
  onHandleView,
  onHandleUpdate,
  onHandleArchive,
  onHandleRemove,
}) {
  const { messagesLang } = useLanguage();
  const [activeTab, setActiveTab] = useState("Tab1");
  const [mainImage, setMainImage] = useState(image_knowledge);
  const [activeThumbnail, setActiveThumbnail] = useState();
  const decryptUSR = CryptoJS.AES.decrypt(storage.get("user"), "user").toString(
    CryptoJS.enc.Utf8
  );
  const usr = JSON.parse(decryptUSR);
  const handleThumbnailClick = (image) => {
    setMainImage(image);
    setActiveThumbnail(image);
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Bulan dimulai dari 0
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };

  const handleView = () => {
    onHandleView();
  };

  const formatText = (text) => {
    return text.split("\n").map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  useEffect(() => {
    if (show) {
      document.body.classList.add("modal-open-custom");
    } else {
      document.body.classList.remove("modal-open-custom");
    }

    // Clean up effect when component is unmounted
    return () => {
      document.body.classList.remove("modal-open-custom");
    };
  }, [show]);

  const handleUpdate = (item) => {
    onHandleUpdate(item);
  };

  const handleArchive = (item) => {
    onHandleArchive(item);
  };

  const handleRemove = (item) => {
    onHandleRemove(item);
  };

  // Hitung total ulasan
  const totalReviews = starReview.reduce(
    (total, review) => total + review.count_review,
    0
  );

  // Hitung total skor
  const totalScore = starReview.reduce(
    (total, review) => total + review.rating * review.count_review,
    0
  );

  // Hitung rata-rata bintang
  const averageRating =
    totalReviews > 0 ? (totalScore / totalReviews).toFixed(1) : "0.0";

  // Buat array rating lengkap
  const allRatings = [5, 4, 3, 2, 1];

  // Hitung persentase untuk setiap rating
  const percentageData = allRatings.map((rating) => {
    const review = starReview.find((review) => review.rating === rating);
    const count = review ? review.count_review : 0;
    const percentage =
      totalReviews > 0 ? ((count / totalReviews) * 100).toFixed(1) : "0.0";
    return {
      rating,
      count,
      percentage,
    };
  });

  const getPrice = (card) => {
    if (card.idt_knowledge !== undefined) {
      if (card.knowledge_public.length > 0) {
        if (card.knowledge_public[0].pricing_scheme === "paid") {
          return "Rp. " + format_number(card.knowledge_public[0].price_sell);
        } else if (card.knowledge_public[0].pricing_scheme === "rent") {
          return "Rp. " + format_number(card.knowledge_public[0].price_rent);
        } else {
          return "Free";
        }
      } else {
        return "Free";
      }
    } else {
      return `${card.knowledge_collection_map.length} Knowledge`;
    }
  };

  const capitalizeWords = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  return (
    <>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        backdrop="static"
        dialogClassName="modal-lg fade"
        onHide={() => handleClose(item)}
      >
        <Modal.Header className="p-3 bg-light-dark" closeButton>
          <h5 className="modal-title">Detail Knowledge</h5>
        </Modal.Header>
        <SimpleBar className="modal-body knowledge">
          <div className="row">
            <div className="col-md-3 col-sm-12">
              <div className="image-gallery">
                <div className="main-image">
                  <img
                    src={
                      item.knowledge_cover_pic.length > 0
                        ? item.knowledge_cover_pic[0].pathurl
                        : image_knowledge
                    }
                    className="object-fit-cover"
                    alt="Main"
                  />
                </div>
                {item.knowledge_cover_pic > 1 ? (
                  <div className="thumbnail-images">
                    {item.knowledge_cover_pic.map((thumbnail, index) => (
                      <div
                        key={index}
                        className={`thumbnail-wrapper object-fit-cover d-none d-sm-block ${
                          activeThumbnail === thumbnail.pathurl ? "active" : ""
                        }`}
                        onClick={() => handleThumbnailClick(thumbnail.pathurl)}
                      >
                        <img
                          src={thumbnail.pathurl}
                          alt={`Thumbnail ${index + 1}`}
                          className={
                            activeThumbnail === thumbnail.pathurl
                              ? "object-fit-cover active"
                              : "object-fit-cover"
                          }
                        />
                        <div className="thumbnail-overlay"></div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div className="d-none d-md-block">
                <div className="pb-1">
                  <div className="bg-light px-2 py-2 rounded-2 mb-2">
                    <div className="d-flex align-items-center">
                      <div className="flex-grow-1">
                        <ReactStars
                          count={5}
                          value={averageRating}
                          size={20}
                          isHalf={true}
                          activeColor="#ffd700"
                          edit={false}
                        />
                      </div>
                      <div className="flex-shrink-0">
                        <h6 className="mb-0">{averageRating} of 5.0</h6>
                      </div>
                    </div>
                  </div>
                  <div className="text-center">
                    <div className="text-muted">
                      Total{" "}
                      <span className="fw-medium">
                        {format_number(totalReviews)}
                      </span>{" "}
                      {messagesLang.review}
                    </div>
                  </div>
                </div>
                <div className="mt-0">
                  {percentageData.map((data, index) => (
                    <div key={index} className="row align-items-center g-2">
                      <div className="col-auto">
                        <div className="ps-2">
                          <h6 className="mb-0">
                            {data.rating}{" "}
                            <IoMdStar
                              size={16}
                              style={{
                                marginTop: "-3px",
                                color: "rgb(255, 215, 0)",
                              }}
                            />
                          </h6>
                        </div>
                      </div>
                      <div className="col">
                        <div className="p-0">
                          {data.rating >= 3 && (
                            <ProgressBar
                              now={data.percentage}
                              variant="success"
                              className="progress animated-progress progress-sm"
                            />
                          )}
                          {data.rating === 2 && (
                            <ProgressBar
                              now={data.percentage}
                              variant="warning"
                              className="progress animated-progress progress-sm"
                            />
                          )}
                          {data.rating === 1 && (
                            <ProgressBar
                              now={data.percentage}
                              variant="danger"
                              className="progress animated-progress progress-sm"
                            />
                          )}
                        </div>
                      </div>
                      <div className="col-auto">
                        <div className="p-2">
                          <h6 className="mb-0 text-muted">{data.count}</h6>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-md-9 col-sm-12">
              <div className="card mb-0">
                <div className="card-header pt-0">
                  <p className="fs-16 fw-bold mb-0">{item.title}</p>
                  <p className="fs-18 fw-bold text-muted">{getPrice(item)}</p>
                  <div className="mt-3">
                    {item.knowledge_library.length > 0 &&
                      item.knowledge_library[0].is_author === "Y" && (
                        <>
                          <button
                            className="btn btn-md btn-primary me-2"
                            style={{ width: "130px" }}
                            onClick={() => handleUpdate(item)}
                          >
                            <HiOutlinePencilAlt size={15} className="me-2" />
                            Edit
                          </button>
                          {item.knowledge_library.length > 0 &&
                            item.knowledge_library[0].is_author === "Y" &&
                            item.type === "private" && (
                              <button
                                className="btn btn-md btn-danger me-2"
                                style={{ width: "130px" }}
                                onClick={() => handleRemove(item)}
                              >
                                <HiOutlineTrash size={15} className="me-2" />
                                Delete
                              </button>
                            )}
                          <button
                            className="btn btn-md btn-info me-2"
                            style={{ width: "130px" }}
                            onClick={() => handleArchive(item)}
                          >
                            <MdOutlineArchive size={15} className="me-2" />
                            Archive
                          </button>
                        </>
                      )}
                  </div>
                </div>
              </div>
              <div className="card mb-0">
                <div className="card-header">
                  <ul
                    className="nav nav-tabs-custom rounded card-header-tabs border-bottom-0"
                    role="tablist"
                  >
                    <li className="nav-item">
                      <a
                        className={
                          activeTab === "Tab1"
                            ? "nav-link cursor-pointer text-center fs-14 fw-bold active"
                            : "nav-link cursor-pointer text-center fs-14 fw-bold"
                        }
                        onClick={() => setActiveTab("Tab1")}
                      >
                        <i className="fas fa-home"></i> Info
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={
                          activeTab === "Tab2"
                            ? "nav-link cursor-pointer text-center fs-14 fw-bold active"
                            : "nav-link cursor-pointer text-center fs-14 fw-bold"
                        }
                        onClick={() => setActiveTab("Tab2")}
                      >
                        <i className="fas fa-home"></i>{" "}
                        {messagesLang.description}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={
                          activeTab === "Tab3"
                            ? "nav-link cursor-pointer text-center fs-14 fw-bold active"
                            : "nav-link cursor-pointer text-center fs-14 fw-bold"
                        }
                        onClick={() => setActiveTab("Tab3")}
                      >
                        <i className="far fa-user"></i> {messagesLang.review}
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="card-body p-4">
                  {activeTab === "Tab1" && (
                    <div className="form-table">
                      <SimpleBar style={{ height: "calc(100vh - 380px)" }}>
                        <div className="form-row">
                          <div className="form-label">Author</div>
                          <div className="form-space">:</div>
                          <div className="form-value">{item.author}</div>
                        </div>
                        <div className="form-row">
                          <div className="form-label">Publisher</div>
                          <div className="form-space">:</div>
                          <div className="form-value">
                            {item.knowledge_publisher.name}
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-label">ISBN</div>
                          <div className="form-space">:</div>
                          <div className="form-value">
                            {item.isbn ? item.isbn : "-"}
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-label">Category</div>
                          <div className="form-space">:</div>
                          <div className="form-value">
                            {item.knowledge_category_map.map((data, index) => {
                              return index === 0
                                ? data.category.name
                                : `, ${data.category.name}`;
                            })}
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-label">Date Created</div>
                          <div className="form-space">:</div>
                          <div className="form-value">
                            {formatDate(item.ts_created)}
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-label">Usage</div>
                          <div className="form-space">:</div>
                          <div className="form-value">1000 Sessions</div>
                        </div>
                        <div className="form-row">
                          <div className="form-label">Price</div>
                          <div className="form-space">:</div>
                          <div className="form-value">{getPrice(item)}</div>
                        </div>
                        <div className="form-row">
                          <div className="form-label">Type</div>
                          <div className="form-space">:</div>
                          <div className="form-value">
                            {capitalizeWords(item.type)}
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-label">Uploaded by</div>
                          <div className="form-space">:</div>
                          <div className="form-value">
                            {item.user.user_profile.name_first}{" "}
                            {item.user.user_profile.name_last}
                          </div>
                        </div>
                        {/* <div className="form-row">
                          <div className="form-label">File source</div>
                          <div className="form-space">:</div>
                          <div className="form-value">
                            <a
                              className="link-orange cursor-pointer"
                              onClick={() => handleView()}
                            >
                              View File
                            </a>
                          </div>
                        </div> */}
                      </SimpleBar>
                    </div>
                  )}
                  {activeTab === "Tab2" && (
                    <div>
                      <SimpleBar style={{ height: "calc(100vh - 380px)" }}>
                        <p>{formatText(item.description)}</p>
                      </SimpleBar>
                    </div>
                  )}
                  {activeTab === "Tab3" && (
                    <>
                      {review.length > 0 && (
                        <div className="mb-2">
                          <span className="me-2">Filter: </span>
                          {starReview.map((data, index) => (
                            <div
                              key="index"
                              class="form-check form-check-inline"
                            >
                              <input
                                class="form-check-input"
                                type="checkbox"
                                id={index}
                                value={data.rating}
                                checked={selectedRatings.includes(data.rating)}
                                onChange={() =>
                                  onRatingChange(
                                    data.rating,
                                    review[0].idt_knowledge,
                                    "knowledge"
                                  )
                                }
                              />
                              <label class="form-check-label" htmlFor={index}>
                                {data.rating} star
                              </label>
                            </div>
                          ))}
                        </div>
                      )}
                      <SimpleBar
                        style={{
                          height:
                            review.length > 0
                              ? "calc(100vh - 410px)"
                              : "calc(100vh - 380px)",
                        }}
                      >
                        <ReviewKnowledge
                          reviews={review}
                          loadMoreReviews={loadMoreReview}
                          loadingReview={loadingReview}
                          hasMore={hasMore}
                        />
                      </SimpleBar>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </SimpleBar>
      </Modal>
    </>
  );
}

export default ModalDetail2;
