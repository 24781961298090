import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import CustomStyles from "../../../components/customStyles";
import { ThemeContext } from "../../../provider/themeContext";
import { Dropdown } from "react-bootstrap";
import { useLanguage } from "../../../provider/languageContext";
import { MdFilterList } from "react-icons/md";
import { PiListBold } from "react-icons/pi";
import { RxDashboard } from "react-icons/rx";

const FilterOptions = ({
  cardsPerPage,
  activeTab,
  totalData,
  categories,
  subcategories,
  optionSort,
  selectedCategory,
  selectedSubcategory,
  selectedSorting,
  searchValue,
  selectedListView,
  onSearchChange,
  onSorting,
  onListView,
  onCategoryChange,
  onSubcategoryChange,
  onTabChange,
  onCardsPerPageChange,
  onResetCategory,
  onSubmitCategory,
}) => {
  const { theme } = useContext(ThemeContext);
  const { messagesLang } = useLanguage();
  const [activeSelectTab, setActiveSelectTab] = useState([]);
  const tabs = [
    { id: "knowledge", label: "All Knowledge" },
    { id: "collection", label: "All Collection" },
    { id: "authorize", label: "Authorized Knowledge" },
    { id: "popular", label: "Popular Knowledge" },
  ];

  useEffect(() => {
    const tabDefault = tabs.find((tab) => tab.id === activeTab);
    if (tabDefault) {
      setActiveSelectTab({
        value: tabDefault.id,
        label: tabDefault.label,
      });
    }
  }, []);

  const handleTabClick = (tabId) => {
    onTabChange(tabId);
  };

  const handleCategoryChange = (data) => {
    onCategoryChange(data);
  };

  const handleSubcategoryChange = (data) => {
    onSubcategoryChange(data);
  };

  const handleCardsPerPageChange = (e) => {
    onCardsPerPageChange(Number(e.target.value)); // Mengubah string menjadi number
  };

  const handleSubmitFilter = () => {
    onSubmitCategory();
  };

  const handleResetFilter = () => {
    onResetCategory();
  };

  const handleSorting = (data) => {
    onSorting(data);
  };

  const handleSearch = (e) => {
    onSearchChange(e.target.value);
  };

  const clearInput = () => {
    onSearchChange("");
  };

  return (
    <div className="filter-options">
      <div className="tabs mb-4 d-none d-md-block">
        <ul className="nav nav-tabs-custom rounded card-header-tabs d-flex justify-content-between border-bottom-0">
          {tabs.map((tab, index) => (
            <li key={index} className="nav-item flexis fs-16">
              <button
                className={
                  tab.id === activeTab
                    ? "nav-link w-100 tab active"
                    : "nav-link w-100 tab"
                }
                onClick={() => handleTabClick(tab.id)}
              >
                {tab.label}{" "}
                {tab.id === activeTab && totalData ? `(${totalData})` : null}
              </button>
            </li>
          ))}
        </ul>
      </div>
      <div className="select-container">
        <div className="row">
          <div className="col-md-12">
            <div className="row">
              <div className="d-sm-flex d-block" style={{ gap: "15px" }}>
                <div
                  className="mb-3 d-sm-block d-md-none"
                  style={{ flex: "0 25%" }}
                >
                  <Select
                    styles={CustomStyles(theme)}
                    className="custom-select"
                    maxMenuHeight={200}
                    options={tabs.map((tab) => ({
                      value: tab.id,
                      label: tab.label,
                    }))}
                    value={activeSelectTab}
                    onChange={(selected) => {
                      handleTabClick(selected.value);
                      setActiveSelectTab(selected);
                    }}
                    isSearchable={true}
                  />
                </div>

                {activeTab !== "collection" ? (
                  <>
                    <div className="mb-3">
                      <div className="app-search" style={{ padding: "0" }}>
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={messagesLang.search}
                            autoComplete="off"
                            style={{ width: "200px" }}
                            value={searchValue}
                            onChange={handleSearch}
                            // onKeyPress={handleKeyPress}
                          />
                          <span
                            // onClick={handleSearch}
                            className="mdi mdi-magnify search-widget-icon cursor-pointer"
                          ></span>
                          {searchValue && (
                            <span
                              onClick={clearInput}
                              className="mdi mdi-close-circle search-widget-icon search-widget-icon-close cursor-pointer"
                              id="search-close-options"
                            ></span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="mb-3">
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="none"
                          className="btn btn-upload-wd waves-effect waves-light btn-md sorted"
                        >
                          <div className="d-flex">
                            <MdFilterList size={17} className="me-2" />
                            {selectedSorting.label}
                          </div>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          {optionSort.map((option) => (
                            <Dropdown.Item
                              key={option.value}
                              onClick={() => handleSorting(option)}
                            >
                              {option.label}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <div className="mb-3" style={{ flex: "0 22%" }}>
                      <Select
                        styles={CustomStyles(theme)}
                        className="custom-select"
                        maxMenuHeight={200}
                        options={categories}
                        value={selectedCategory}
                        placeholder="Category"
                        onChange={(selected) => {
                          handleCategoryChange(selected);
                        }}
                        isSearchable={true}
                      />
                    </div>
                    <div className="mb-3" style={{ flex: "0 45%" }}>
                      <Select
                        styles={CustomStyles(theme)}
                        className="custom-select"
                        maxMenuHeight={200}
                        options={subcategories}
                        value={selectedSubcategory}
                        placeholder="Sub-Category"
                        onChange={(selected) => {
                          handleSubcategoryChange(selected);
                        }}
                        isMulti
                        isSearchable={true}
                      />
                    </div>
                    <div className="mb-3" style={{ flex: "0 10%" }}>
                      <div
                        className="btn-container d-flex"
                        style={{ gap: "15px" }}
                      >
                        {/* <button
                          className="btn btn-md btn-info w-100"
                          onClick={() => handleSubmitFilter()}
                          style={{ flex: "1" }}
                        >
                          Filter
                        </button> */}
                        <button
                          className="btn btn-md btn-danger w-100"
                          onClick={() => handleResetFilter()}
                          style={{ flex: "1" }}
                        >
                          Reset
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="mb-3">
                      <div className="app-search" style={{ padding: "0" }}>
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={messagesLang.search}
                            autoComplete="off"
                            style={{ width: "200px" }}
                            value={searchValue}
                            onChange={handleSearch}
                            // onKeyPress={handleKeyPress}
                          />
                          <span
                            // onClick={handleSearch}
                            className="mdi mdi-magnify search-widget-icon cursor-pointer"
                          ></span>
                          {searchValue && (
                            <span
                              onClick={clearInput}
                              className="mdi mdi-close-circle search-widget-icon search-widget-icon-close cursor-pointer"
                              id="search-close-options"
                            ></span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="mb-3">
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="none"
                          className="btn btn-upload-wd waves-effect waves-light btn-md sorted"
                        >
                          <div className="d-flex">
                            <MdFilterList size={17} className="me-2" />
                            {selectedSorting.label}
                          </div>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          {optionSort.map((option) => (
                            <Dropdown.Item
                              key={option.value}
                              onClick={() => handleSorting(option)}
                            >
                              {option.label}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <div className="d-flex">
                      <div className="me-3">
                        <nav
                          className="nav btn-group rounded-pill mb-2"
                          style={{ width: "100px" }}
                        >
                          <button
                            className={`btn waves-effect waves-light ${
                              theme === "dark"
                                ? "btn-outline-dark"
                                : "btn-outline-light"
                            } ${selectedListView === "card" ? "active" : ""}`}
                            onClick={() => onListView("card")}
                            style={{
                              borderColor: theme === "light" && "#ccc",
                              zIndex: "0",
                              height: "38px",
                            }}
                            data-bs-toggle="tab"
                          >
                            <RxDashboard />
                          </button>
                          <button
                            className={`btn waves-effect waves-light ${
                              theme === "dark"
                                ? "btn-outline-dark"
                                : "btn-outline-light"
                            } ${selectedListView === "table" ? "active" : ""}`}
                            onClick={() => onListView("table")}
                            style={{
                              borderColor: theme === "light" && "#ccc",
                              zIndex: "0",
                              height: "38px",
                            }}
                            data-bs-toggle="tab"
                          >
                            <PiListBold />
                          </button>
                        </nav>
                      </div>
                      <select
                        className="form-select"
                        onChange={handleCardsPerPageChange}
                      >
                        <option
                          value={25}
                          selected={cardsPerPage === 25 && "selected"}
                        >
                          25 rows
                        </option>
                        <option
                          value={50}
                          selected={cardsPerPage === 50 && "selected"}
                        >
                          50 rows
                        </option>
                        <option
                          value={100}
                          selected={cardsPerPage === 100 && "selected"}
                        >
                          100 rows
                        </option>
                      </select>
                    </div>
                  </>
                )}
              </div>
            </div>
            {activeTab !== "collection" && (
              <div className="row">
                <div className="d-flex">
                  <div className="me-3">
                    <nav
                      className="nav btn-group rounded-pill mb-2"
                      style={{ width: "100px" }}
                    >
                      <button
                        className={`btn waves-effect waves-light ${
                          theme === "dark"
                            ? "btn-outline-dark"
                            : "btn-outline-light"
                        } ${selectedListView === "card" ? "active" : ""}`}
                        onClick={() => onListView("card")}
                        style={{
                          borderColor: theme === "light" && "#ccc",
                          zIndex: "0",
                          height: "38px",
                        }}
                        data-bs-toggle="tab"
                      >
                        <RxDashboard />
                      </button>
                      <button
                        className={`btn waves-effect waves-light ${
                          theme === "dark"
                            ? "btn-outline-dark"
                            : "btn-outline-light"
                        } ${selectedListView === "table" ? "active" : ""}`}
                        onClick={() => onListView("table")}
                        style={{
                          borderColor: theme === "light" && "#ccc",
                          zIndex: "0",
                          height: "38px",
                        }}
                        data-bs-toggle="tab"
                      >
                        <PiListBold />
                      </button>
                    </nav>
                  </div>
                  <select
                    className="form-select"
                    onChange={handleCardsPerPageChange}
                  >
                    <option
                      value={25}
                      selected={cardsPerPage === 25 && "selected"}
                    >
                      25 rows
                    </option>
                    <option
                      value={50}
                      selected={cardsPerPage === 50 && "selected"}
                    >
                      50 rows
                    </option>
                    <option
                      value={100}
                      selected={cardsPerPage === 100 && "selected"}
                    >
                      100 rows
                    </option>
                  </select>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterOptions;
