import axios from "axios";
import { useContext, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { API_URL } from "../utilities/url";
import storage from "../services/storage";
import { LuBook, LuBookCopy, LuBookLock, LuBookUp } from "react-icons/lu";
import image_knowledge from "../assets/images/knowledge.png";
import { useLanguage } from "../provider/languageContext";
import CustomStyles from "./customStyles";
import { ThemeContext } from "../provider/themeContext";
import { ToastrContext } from "../provider/toastrContext";
import Select from "react-select";
import SimpleBar from "simplebar-react";
import { RxCross2 } from "react-icons/rx";

const ModalAddCollection = ({
  selected,
  onSelectedCardsChange,
  onSelectedCount,
  resetSelectedCards,
}) => {
  const { messagesLang } = useLanguage();
  const { showSuccess, showError } = useContext(ToastrContext);
  const { theme } = useContext(ThemeContext);
  const [collectionList, setCollectionList] = useState([]);
  const [show, setShow] = useState(false);
  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(0);
  const [loadingData, setLoadingData] = useState(0);

  const handleUpdate = (item) => {
    onSelectedCardsChange((selected) => {
      const updatedSelectedCards = selected.filter(
        (id) => id !== item.idt_knowledge
      );
      onSelectedCount(updatedSelectedCards.length);
      setCards((prevCards) =>
        prevCards.filter((card) => card.idt_knowledge !== item.idt_knowledge)
      );

      return updatedSelectedCards;
    });
  };

  const handleClose = () => {
    setShow(false);
    reset();
  };
  const handleShow = () => {
    setShow(true);
    setLoadingData(true);
    /* Get Collection */
    axios
      .post(
        API_URL + "get-collection",
        {
          limit: 50,
          page: 1,
        },
        {
          headers: {
            Authorization: `Bearer ${storage.get("access_token")}`,
          },
        }
      )
      .then((response) => {
        if (response.data.success === true) {
          const maps = response.data.data.data.map(function (d) {
            const map = {
              value: d.idt_knowledge_collection,
              label: d.name,
            };
            return map;
          });
          setCollectionList(maps);
        }
      })
      .catch((error) => {});

    /* Get Data */
    axios
      .post(
        API_URL + "get-knowledge-personal-all",
        {
          status: 2,
          id: selected,
          limit: 50,
          page: 1,
        },
        {
          headers: {
            Authorization: `Bearer ${storage.get("access_token")}`,
          },
        }
      )
      .then((response) => {
        if (response.data.success === true) {
          setCards(response.data.data.data);
          setLoadingData(false);
        }
      })
      .catch((error) => {});
  };

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
    clearErrors,
  } = useForm({
    reValidateMode: "onBlur",
  });

  const handleAddCollection = (data) => {
    setLoading(true);
    const dataForm = {
      idt_knowledge_collection: data.collection.value,
      ids: selected,
    };
    axios
      .post(API_URL + "add-collection", dataForm, {
        headers: {
          Authorization: `Bearer ${storage.get("access_token")}`,
        },
      })
      .then((response) => {
        if (response.data.success === true) {
          handleClose();
          showSuccess(response.data.message);
          resetSelectedCards();
          setLoading(false);
        } else {
          showError(response.data.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        showError("An error occurred. Please try again later.");
        setLoading(false);
      });
  };

  return (
    <>
      <Button
        className="dropdown-item"
        role="button"
        variant="none"
        onClick={handleShow}
      >
        <LuBook size={18} className="me-1" />
        Add to Collection
      </Button>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        backdrop="static"
        dialogClassName="custom-modal-collection fade"
        onHide={handleClose}
      >
        <Modal.Header className="p-3 bg-light-dark" closeButton>
          <h5 className="modal-title">Add Collection</h5>
        </Modal.Header>
        <SimpleBar className="modal-body knowledge">
          <div className="col-12">
            <div className="row">
              {!loadingData ? (
                cards.length > 0 ? (
                  <>
                    <form onSubmit={handleSubmit(handleAddCollection)}>
                      <div className="hstack gap-2 justify-content-end">
                        <div className="w-100">
                          <Controller
                            name="collection"
                            control={control}
                            rules={{
                              required: "Collection is required.",
                            }}
                            render={({ field }) => (
                              <Select
                                {...field}
                                styles={CustomStyles(theme)}
                                className="custom-select"
                                maxMenuHeight={200}
                                options={collectionList}
                                onChange={(selected) => {
                                  field.onChange(selected);
                                  clearErrors("collection");
                                }}
                                isSearchable={true}
                              />
                            )}
                          />
                        </div>
                        <div className="d-flex gap-2 justify-content-center">
                          <Button
                            className="btn btn-success w-sm"
                            type="submit"
                            disabled={loading}
                          >
                            {loading ? (
                              <>
                                <Spinner
                                  className="flex-shrink-0"
                                  animation="border"
                                  size="sm"
                                />
                              </>
                            ) : (
                              "Add"
                            )}
                          </Button>
                        </div>
                      </div>
                      {errors.collection && (
                        <p className="errorMsg text-danger fs-7 mb-0">
                          {errors.collection.message}
                        </p>
                      )}
                    </form>
                    <hr className="mt-4 mb-0" />
                    <p className="mt-3 mb-0 fw-bold">Selected Knowledge</p>
                    {cards.map((card, index) => (
                      <div
                        key={card.idt_knowledge}
                        className="custom-coloum-3 folder-card mt-3"
                      >
                        <div className="card card-list bg-light shadow-lg">
                          <div className="card-body">
                            <div className="text-start">
                              <div className="mb-2">
                                <div className="square-container">
                                  <img
                                    src={
                                      card.knowledge_cover_pic.length > 0
                                        ? card.knowledge_cover_pic[0].pathurl
                                        : image_knowledge
                                    }
                                    className="rounded-3"
                                    alt={index}
                                  />
                                </div>
                              </div>
                              <div style={{ height: "36px" }}>
                                <h6 className="fs-12 fw-bold ellipsis-2-line knowledge-name">
                                  {card.title !== undefined
                                    ? card.title
                                    : card.name}
                                </h6>
                              </div>
                            </div>
                            <p className="text-start fs-11 knowledge-name mb-0">
                              {card.type !== undefined ? (
                                card.type === "private" ? (
                                  <>
                                    <LuBookLock
                                      size={11}
                                      className="me-1"
                                      style={{ marginTop: "-3px" }}
                                    />
                                    Private
                                  </>
                                ) : (
                                  <>
                                    <LuBookUp
                                      size={11}
                                      className="me-1"
                                      style={{ marginTop: "-3px" }}
                                    />
                                    Publish
                                  </>
                                )
                              ) : (
                                <>
                                  <LuBookCopy
                                    size={11}
                                    className="me-1"
                                    style={{ marginTop: "-3px" }}
                                  />
                                  Collection
                                </>
                              )}
                            </p>
                            <p className="text-start fs-12 knowledge-name mb-0">
                              {card.author !== undefined
                                ? card.author
                                : "Multiple Publisher"}
                            </p>
                            <p className="text-start fs-12 knowledge-name mb-0">
                              {card.knowledge_publisher !== undefined
                                ? card.knowledge_publisher.name
                                : null}
                            </p>
                          </div>
                          {/* Panel Button Setting*/}
                          <div className="panel-button d-flex">
                            <button
                              className="btn btn-danger card-btn-option scroll-to-bottom-btn rounded-circle"
                              onClick={() => handleUpdate(card)}
                              style={{
                                top: "8%",
                                right: "14%",
                                height: "22px",
                                width: "22px",
                              }}
                            >
                              <RxCross2 size={14} />
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <div
                    className="text-center fs-16"
                    style={{ marginTop: "60px", marginBottom: "120px" }}
                  >
                    {messagesLang.data_not_found}
                  </div>
                )
              ) : (
                <div className="mt-2 mb-2" style={{ height: "315px" }}>
                  <div className="loading-backdrop-2">
                    <Spinner animation="border" />
                  </div>
                </div>
              )}
            </div>
          </div>
        </SimpleBar>
      </Modal>
    </>
  );
};

export default ModalAddCollection;
