import React, { useEffect, useState } from "react";
import logoImg from "../assets/images/logo-dark.png";
import OTPInput, { ResendOTP } from "otp-input-react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../utilities/url";

const OtpVerification = () => {
  //Navigate
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname.split("/")[2];
  const [OTP, setOTP] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [hideAlert, setHideAlert] = useState(true);
  const [isVerified, setIsVerified] = useState(false);
  const numberOTP = 5;

  useEffect(() => {
    axios
      .post(API_URL + "user-verification-check", { token: currentPath })
      .then((response) => {
        if (response.data.success === false) {
          navigate("/login");
        } else {
          setIsVerified(true); // Set verified to true to render the component
        }
      })
      .catch((error) => {
        console.log("There was an error!", error);
        setIsVerified(false);
      });
  }, []);

  const handleChange = (data) => {
    setOTP(data);
  };
  const isOtpComplete = OTP.length === numberOTP;
  const resendOTP = () => {
    axios
      .post(API_URL + "resend-otp", { token: currentPath })
      .then((response) => {
        if (response.data.success === false) {
          setHideAlert(false);
          setAlertMessage("Token not valid.");
        }
      })
      .catch((error) => {
        console.log("There was an error!", error);
      });
  };
  const onSubmit = () => {
    axios
      .post(API_URL + "user-verification", { token: currentPath, otp: OTP })
      .then((response) => {
        if (response.data.success === true) {
          navigate("/activation-success");
        } else if (response.data.message == "Token not valid") {
          setHideAlert(false);
          setAlertMessage("Token not valid.");
        } else {
          setHideAlert(false);
          setAlertMessage("OTP is wrong.");
        }
      })
      .catch((error) => {
        console.log("There was an error!", error);
      });
    // setHideAlert(false);
    // setAlertMessage("OTP is wrong.");
  };

  if (!isVerified) {
    return null; // or you can return a loading indicator here
  }

  return (
    <div className="auth-page-content pb-0">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="mt-sm-4 mb-sm-2 text-white-50">
              <div>
                <Link to="/login">
                  <img
                    src={logoImg}
                    alt=""
                    height="40"
                    className="d-none d-sm-block"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-8 col-lg-6 col-xl-5">
            <div className="centered-container">
              <div className="card login rounded-4 mt-4">
                <div className="card-body p-4">
                  <div className="text-center mt-4">
                    <h5 className="text-light">OTP Verification</h5>
                    <hr className="w-75 border border-1 border-dark mx-auto"></hr>
                  </div>
                  <div className="card-body">
                    <div className="text-center text-light col-md-10 mx-auto">
                      <p className="mb-2">
                        To verified your account, <br></br>Please enter 5 digit
                        code sent to email.
                      </p>
                      {/* <h5 className="p-0 text-orange">email@otokata.id</h5> */}
                    </div>
                    <div
                      className={`alert alert-danger alert-dismissible fade show mt-3 col-md-10 mx-auto ${
                        hideAlert ? "d-none" : "d-block"
                      }`}
                      role="alert"
                    >
                      <button
                        onClick={() => setHideAlert(true)}
                        type="button"
                        className="btn-close"
                        aria-label="Close"
                      ></button>
                      <div className="alert-message">
                        <strong className="text-danger">{alertMessage}</strong>
                      </div>
                    </div>
                    <div className="text-center mt-3" id="otp-verification">
                      <OTPInput
                        value={OTP}
                        onChange={handleChange}
                        //   onClick={handleChange}
                        autoFocus
                        OTPLength={numberOTP}
                        otpType="number"
                        disabled={false}
                        // secure
                      />
                      <ResendOTP onResendClick={resendOTP} />
                    </div>
                    <hr className="w-75 border border-1 border-dark mx-auto"></hr>
                    <div className="d-block col-md-10 mx-auto text-center">
                      <button
                        onClick={onSubmit}
                        disabled={!isOtpComplete}
                        className="btn btn-md btn-primary w-100"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtpVerification;
