const CustomStyles = (theme) => ({
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#f9c452 !important" : "000",
    color: state.isSelected ? "#000" : "#000",
    ":hover": {
      backgroundColor: "#ddd",
    },
    cursor: "pointer",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: theme === "light" ? "#ced4da" : "#212529",
    padding: 2,
  }),
  input: (provided) => ({
    ...provided,
    color: theme === "light" ? "#ced4da" : "#212529",
  }),
  control: (provided) => ({
    ...provided,
    borderRadius: 4,
    backgroundColor: "transparent",
    color: "#000",
    boxShadow:
      theme === "light" ? "0 0 0 1px solid #212529" : "0 0 0 1px solid #ced4da",
    borderColor:
      theme === "light" ? "#ced4da !important" : "#212529 !important",
  }),
  indicatorContainer: (provided) => ({
    ...provided,
    color: theme === "light" ? "#ced4da !important" : "#212529 !important",
    padding: 0,
    margin: -2,
    cursor: "pointer",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    color: theme === "light" ? "#ced4da !important" : "#212529 !important",
    padding: 0,
    margin: -2,
    cursor: "pointer",
  }),
  clearIndicator: (provided) => ({
    ...provided,
    color: theme === "light" ? "#ced4da !important" : "#212529 !important",
    cursor: "pointer",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: theme === "light" ? "#ced4da !important" : "#212529 !important",
    cursor: "pointer",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    width: "0px",
  }),
});

export default CustomStyles;
