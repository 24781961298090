import axios from "axios";
import React, { createContext, useState } from "react";
import storage from "../services/storage";
import { API_URL } from "../utilities/url";

export const SliderProcessingContext = createContext();

export const SliderProcessingProvider = ({ children }) => {
  const [sliderData, setSliderData] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItem, setTotalItem] = useState(0);
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(10);
  const [firstLoad, setFirstLoad] = useState(true);

  const loadData = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        API_URL + "get-knowledge-processing-job",
        { page: currentPage, limit: 10 },
        {
          headers: {
            Authorization: `Bearer ${storage.get("access_token")}`,
          },
        }
      );
      const data = await response.data.data.data;
      const meta = await response.data.data.meta;
      setSliderData(data);
      setTotalItem(meta.total_items);
    } catch (error) {
      console.log("Error loading data:", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const loadDataNoLoading = async () => {
    try {
      const response = await axios.post(
        API_URL + "get-knowledge-processing-job",
        { page: currentPage, limit: 10 },
        {
          headers: {
            Authorization: `Bearer ${storage.get("access_token")}`,
          },
        }
      );
      const data = await response.data.data.data;
      const meta = await response.data.data.meta;
      setSliderData(data);
      setTotalItem(meta.total_items);
    } catch (error) {
      console.log("Error loading data:", error);
    }
  };

  const addMoreData = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        API_URL + "get-knowledge-personal",
        { status: [0, 1], page: currentPage, limit: 10 },
        {
          headers: {
            Authorization: `Bearer ${storage.get("access_token")}`,
          },
        }
      );
      const data = await response.data.data.data;
      const meta = await response.data.data.meta;
      setSliderData((prevData) => [...prevData, ...data]);
      setTotalItem(meta.total_items);
    } catch (error) {
      console.log("Error adding more data:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <SliderProcessingContext.Provider
      value={{
        sliderData,
        setSliderData,
        currentSlide,
        setCurrentSlide,
        currentPage,
        setCurrentPage,
        totalItem,
        setTotalItem,
        loading,
        setLoading,
        limit,
        setLimit,
        firstLoad,
        setFirstLoad,
        loadData,
        loadDataNoLoading,
        addMoreData,
      }}
    >
      {children}
    </SliderProcessingContext.Provider>
  );
};
