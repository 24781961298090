import React, { useContext, useState } from "react";
import {
  ModalAddCollection,
  ModalAddKnowledge,
  ModalCreateCollection,
  ModalRemoveAll,
} from "../../components";
import { Button, Dropdown, ButtonGroup } from "react-bootstrap";
import MyLibrary from "./component/menuTabs";
import { useLanguage } from "../../provider/languageContext";
import axios from "axios";
import { toast, Slide, ToastContainer } from "react-toastify";
import CarouselProcessing from "./component/carouselProcessing";
import { API_URL, API_URL_CORE } from "../../utilities/url";
import storage from "../../services/storage";
import CryptoJS from "crypto-js";
import { ToastrContext } from "../../provider/toastrContext";
import { SessionContext } from "../../provider/sessionContext";
import { useNavigate } from "react-router-dom";
import { format_number } from "../../utilities/ext-function";
import { SliderProcessingContext } from "../../provider/sliderProcessingContext";
import { AssistantContext } from "../../provider/assistantContext";

const getSlidesConfig = (width) => {
  if (width > 3000) {
    return 50;
  } else if (width <= 3000 && width > 2500) {
    return 42;
  } else if (width <= 2500 && width > 2000) {
    return 35;
  } else if (width <= 2000 && width > 1500) {
    return 30;
  } else if (width <= 1500 && width > 1024) {
    return 25;
  } else if (width <= 1024 && width > 600) {
    return 20;
  } else if (width <= 600) {
    return 10;
  } else {
    return 50;
  }
};

const Library = () => {
  const {
    setMessages,
    setSessionList,
    setSelectedSession,
    setActiveSession,
    setActiveMessage,
  } = useContext(SessionContext);
  const { defaultAssistant, setSelectedSessionAssistant } =
    useContext(AssistantContext);
  const { loadData } = useContext(SliderProcessingContext);
  const { messagesLang } = useLanguage();
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState("");
  const [activeSearch, setActiveSearch] = useState(false);
  const [searchResult, setSearchResult] = useState("");
  const [dataFilterSearch, setDataFilterSearch] = useState(0);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const currentPage = 1;
  const [listItems, setListItems] = useState([]);
  const slidesConfig = getSlidesConfig(window.innerWidth);
  const { showError } = useContext(ToastrContext);
  const decryptUSR = CryptoJS.AES.decrypt(storage.get("user"), "user").toString(
    CryptoJS.enc.Utf8
  );
  const usr = JSON.parse(decryptUSR);
  const [showModal, setShowModal] = useState(false);
  const [dataPublish, setDataPublish] = useState("");
  const [showViewModal, setShowViewModal] = useState(false);
  const handleShow = (data, type) => {
    if (type === "knowledge") {
      setSelectedItem(data);
      setShowModal(false);
      setShowModalK(true);
    } else {
      setSelectedItem(data);
      setShowModalK(false);
      setShowModal(true);
    }
  };
  const handleClose = () => {
    setSelectedItem(null);
    setShowModalK(false);
    setShowModal(false);
  };
  const handleView = () => {
    setShowModal(false);
    setShowViewModal(true);
  };
  const handleCloseView = () => {
    setShowModal(true);
    setShowViewModal(false);
  };
  const startChat = (id, option) => {
    axios
      .post(API_URL_CORE + "chat/session", {
        knowledge_id: option === "knowledge" ? id : null,
        collection_id: option === "collection" ? id : null,
        assistant_id: defaultAssistant,
        user_id: usr.idt_user,
      })
      .then((response) => {
        if (response.data.errormsg === "OK") {
          // Get For Context
          axios
            .post(
              API_URL + "get-session",
              { status: 0, idt_session: response.data.data.idt_session },
              {
                headers: {
                  Authorization: `Bearer ${storage.get("access_token")}`,
                },
              }
            )
            .then((resp) => {
              if (resp.data.success === true) {
                setMessages([]);
                setSessionList((prevData) => [...resp.data.data, ...prevData]);
                setSelectedSessionAssistant({
                  value: resp.data.data[0].assistant.idt_user_assistant,
                  label: resp.data.data[0].assistant.name,
                });
                setSelectedSession(resp.data.data[0]);
                setActiveMessage(true);
                setActiveSession(resp.data.data[0].idt_session);
                navigate("/chat");
              }
            })
            .catch((error) => {
              showError(error);
            });
        } else {
          showError(response.data.errormsg);
        }
      })
      .catch((error) => {
        showError(error);
      });
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    if (e.target.value === "") {
      setActiveSearch(false);
    }
    if (!activeSearch) {
      setSearchResult(e.target.value);
    }
  };
  const clearInput = () => {
    setActiveSearch(false);
    setInputValue("");
  };
  const handleSearch = () => {
    if (inputValue !== "") {
      setActiveSearch(true);
      setSearchResult(inputValue);
      setLoadingSearch(true);
      axios
        .post(
          API_URL + "get-knowledge-personal-all",
          { title: inputValue, page: currentPage, limit: slidesConfig },
          {
            headers: {
              Authorization: `Bearer ${storage.get("access_token")}`,
            },
          }
        )
        .then((response) => {
          const filteredData = response.data.data.data;
          setListItems(filteredData);
          setDataFilterSearch(filteredData.length);
          setLoadingSearch(false);
        })
        .catch((error) => {
          toast.error(messagesLang.connection_error, {
            position: "top-center",
            theme: "colored",
            transition: Slide,
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
          });
          setLoadingSearch(false);
        });
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const [selectedItem, setSelectedItem] = useState(null);
  const [showModalK, setShowModalK] = useState(false);
  const [showUpdateModalK, setShowUpdateModalK] = useState(false);
  const [showRemoveModalK, setShowRemoveModalK] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState("all");
  const [selectedCount, setSelectedCount] = useState(0);
  const [selectedCards, setSelectedCards] = useState([]);
  const handleCardClick = (item) => {
    setSelectedCards((prevActiveSelectedCards) => {
      if (prevActiveSelectedCards.includes(item)) {
        // Remove linkId from activeLinks if it's already present
        return prevActiveSelectedCards.filter((id) => id !== item);
      } else {
        // Add linkId to activeLinks if it's not present
        return [...prevActiveSelectedCards, item];
      }
    });
  };

  const handleDelete = (item) => {
    if (item.length) {
      const updatedItems = listItems.filter(
        (item) => !selectedCards.includes(item.idt_knowledge)
      );
      setListItems(updatedItems);
      setSelectedCards([]);
    } else {
      const updatedItems = listItems.filter((items) => items.id !== item);
      setListItems(updatedItems);
      handleCardClick(item);
      setSelectedCards([]);
    }
  };

  /* Knowledge */
  const handleUpdateShowKnowledge = () => {
    setShowModalK(false);
    setShowUpdateModalK(true);
  };
  const handleCloseUpdateKnowledge = (type) => {
    if (type === undefined) {
      setShowModalK(true);
      setShowUpdateModalK(false);
    } else {
      setShowModalK(false);
      setShowUpdateModalK(false);
    }
  };
  const handleRemoveKnowledge = () => {
    setShowModalK(false);
    setShowRemoveModalK(true);
  };
  const handleCloseRemoveK = () => {
    setShowModalK(true);
    setShowRemoveModalK(false);
  };
  /* Collection */
  const handleUpdateShow = () => {
    setShowModal(false);
    setShowUpdateModal(true);
  };
  const handleCloseUpdate = (type) => {
    if (type === undefined) {
      setShowModal(true);
      setShowUpdateModal(false);
    } else {
      setShowModal(false);
      setShowUpdateModal(false);
      setSelectedItem(null);
    }
  };
  const handleRemove = () => {
    setShowModal(false);
    setShowRemoveModal(true);
  };
  const handleCloseRemove = () => {
    setShowModal(true);
    setShowRemoveModal(false);
  };

  const handleUpdate = (data, type) => {
    if (type === "collection") {
      const updatedDataItem = listItems.map((item) => {
        if (item.idt_knowledge_collection === data.idt_knowledge_collection) {
          return {
            ...item,
            ...data,
            idt_knowledge_collection: item.idt_knowledge_collection,
          };
        }
        return item;
      });
      setListItems(updatedDataItem);
    } else {
      if (data.status === 2) {
        const updatedDataItem = listItems.map((item) => {
          if (item.idt_knowledge === data.idt_knowledge) {
            return {
              ...item,
              ...data,
              idt_knowledge: item.idt_knowledge,
            };
          }
          return item;
        });
        setListItems(updatedDataItem);
      } else {
        const updatedDataItem = listItems.filter(
          (item) => item.idt_knowledge !== data.idt_knowledge
        );
        setListItems(updatedDataItem);
        loadData();
      }
    }

    // const updatedItems = listItems.filter((items) =>
    //   items.id === item.id ? (items.name = input) : items
    // );
    // setListItems(updatedItems);
  };

  const handleSelectedCardsChange = (updatedCards) => {
    setSelectedCards(updatedCards);
  };
  const onSelectedCount = (count) => {
    setSelectedCount(count);
  };
  const onSelectedMenu = (data) => {
    setSelectedMenu(data);
  };
  const resetSelectedCards = () => {
    setSelectedCards([]);
    setSelectedCount(0);
  };

  const getPrice = (card) => {
    if (card.idt_knowledge !== undefined) {
      if (card.knowledge_public.length > 0) {
        if (card.knowledge_public[0].pricing_scheme === "paid") {
          return "Rp. " + format_number(card.knowledge_public[0].price_sell);
        } else if (card.knowledge_public[0].pricing_scheme === "rent") {
          return "Rp. " + format_number(card.knowledge_public[0].price_rent);
        } else {
          return "Free";
        }
      } else {
        return "Free";
      }
    } else {
      return `${card.knowledge_collection_map.length} Knowledge`;
    }
  };

  return (
    <div className="page-content">
      <ToastContainer toastClassName="toaster" />
      <div
        id="page-topbar"
        className="bg-dash"
        style={{ top: "70px", zIndex: 1 }}
      >
        <div className="layout-width">
          <div className="navbar-header nav-fixed">
            <div className="col-md-12">
              <div className="d-flex justify-content-between">
                <div
                  className="text-orange fs-14"
                  // style={{ marginTop: "16px" }}
                >
                  {selectedMenu !== "collection" && (
                    <Dropdown as={ButtonGroup}>
                      <Button
                        className="rounded-pill-left btn-md"
                        style={{
                          backgroundColor: "#299cdb",
                          border: "1px solid #299cdb",
                          cursor: "default",
                          width: window.innerWidth <= 678 ? "110px" : "auto",
                        }}
                      >
                        {window.innerWidth <= 678
                          ? "Selected"
                          : "Selected Item"}{" "}
                        ({selectedCount})
                      </Button>
                      <Dropdown.Toggle
                        split
                        className={
                          selectedCount > 0
                            ? "btn btn-md btn-info"
                            : "btn btn-md btn-info disabled"
                        }
                      />
                      <Dropdown.Menu align="end">
                        {selectedCount > 1 ? (
                          <ModalCreateCollection
                            selected={selectedCards}
                            onSelectedCardsChange={handleSelectedCardsChange}
                            onSelectedCount={onSelectedCount}
                            resetSelectedCards={resetSelectedCards}
                          />
                        ) : null}
                        {selectedCount > 0 ? (
                          <ModalAddCollection
                            selected={selectedCards}
                            onSelectedCardsChange={handleSelectedCardsChange}
                            onSelectedCount={onSelectedCount}
                            resetSelectedCards={resetSelectedCards}
                          />
                        ) : null}
                        {/* <ModalRemoveAll
                          onDelete={handleDelete}
                          item={selectedCards}
                        /> */}
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                </div>
                <div
                  className="d-none d-sm-block"
                  // style={{ marginTop: "16px" }}
                >
                  <ModalAddKnowledge />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid" style={{ marginTop: "60px" }}></div>
      <CarouselProcessing />
      {/* <CarouselRecent /> */}
      <div className="container-fluid w-85">
        <div className="row">
          <MyLibrary
            dataPublish={dataPublish}
            selectedCards={selectedCards}
            onSelectedCardsChange={handleSelectedCardsChange}
            onSelectedCount={onSelectedCount}
            onSelectedMenu={onSelectedMenu}
            resetSelectedCards={resetSelectedCards}
          />
        </div>
      </div>
    </div>
  );
};

export default Library;
