import React from "react";

const ThemeSetting = () => {
  return (
    <div>
      <div id="preloader">
        <div id="status">
          <div className="spinner-border text-primary avatar-sm" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
      {/* <div className="customizer-setting d-none d-md-block">
        <div
          className="btn-info rounded-pill shadow-lg btn btn-icon btn-lg p-2"
          data-bs-toggle="offcanvas"
          data-bs-target="#theme-settings-offcanvas"
          aria-controls="theme-settings-offcanvas"
        >
          <i className="mdi mdi-spin mdi-cog-outline fs-22"></i>
        </div>
      </div> */}
      <div
        className="offcanvas offcanvas-end border-0"
        tabIndex={-1}
        id="theme-settings-offcanvas"
      >
        <div className="d-flex align-items-center bg-primary bg-gradient p-3 offcanvas-header">
          <h5 className="m-0 me-2 text-white">Theme Customizer</h5>
          <button
            type="button"
            className="btn-close btn-close-white ms-auto"
            id="customizerclose-btn"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body p-0">
          <div data-simplebar className="h-100">
            <div className="p-4">
              <h6 className="mb-0 fw-semibold text-uppercase">Color Scheme</h6>
              <p className="text-muted">Choose Light or Dark Scheme.</p>

              <div className="colorscheme-cardradio">
                <div className="row">
                  <div className="col-4">
                    <div className="form-check card-radio">
                      <input
                        className="form-check-input form-check-custom"
                        type="radio"
                        name="data-bs-theme"
                        id="layout-mode-light"
                        value="light"
                      />
                      <label
                        className="form-check-label p-0 avatar-md w-100"
                        htmlFor="layout-mode-light"
                      >
                        <span className="d-flex gap-1 h-100">
                          <span className="flex-shrink-0">
                            <span className="bg-light d-flex h-100 flex-column gap-1 p-1">
                              <span className="d-block p-1 px-2 bg-primary-subtle rounded mb-2"></span>
                              <span className="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
                              <span className="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
                              <span className="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
                            </span>
                          </span>
                          <span className="flex-grow-1">
                            <span className="d-flex h-100 flex-column">
                              <span className="bg-light d-block p-1"></span>
                              <span className="bg-light d-block p-1 mt-auto"></span>
                            </span>
                          </span>
                        </span>
                      </label>
                    </div>
                    <h5 className="fs-13 text-center mt-2">Light</h5>
                  </div>

                  <div className="col-4">
                    <div className="form-check card-radio dark">
                      <input
                        className="form-check-input form-check-custom"
                        type="radio"
                        name="data-bs-theme"
                        id="layout-mode-dark"
                        value="dark"
                      />
                      <label
                        className="form-check-label p-0 avatar-md w-100 bg-dark"
                        htmlFor="layout-mode-dark"
                      >
                        <span className="d-flex gap-1 h-100">
                          <span className="flex-shrink-0">
                            <span className="bg-white bg-opacity-10 d-flex h-100 flex-column gap-1 p-1">
                              <span className="d-block p-1 px-2 bg-white bg-opacity-10 rounded mb-2"></span>
                              <span className="d-block p-1 px-2 pb-0 bg-white bg-opacity-10"></span>
                              <span className="d-block p-1 px-2 pb-0 bg-white bg-opacity-10"></span>
                              <span className="d-block p-1 px-2 pb-0 bg-white bg-opacity-10"></span>
                            </span>
                          </span>
                          <span className="flex-grow-1">
                            <span className="d-flex h-100 flex-column">
                              <span className="bg-white bg-opacity-10 d-block p-1"></span>
                              <span className="bg-white bg-opacity-10 d-block p-1 mt-auto"></span>
                            </span>
                          </span>
                        </span>
                      </label>
                    </div>
                    <h5 className="fs-13 text-center mt-2">Dark</h5>
                  </div>
                </div>
              </div>

              <h6 className="mt-4 mb-0 fw-semibold text-uppercase">
                Topbar Color
              </h6>
              <p className="text-muted">Choose Light or Dark Topbar Color.</p>

              <div className="row">
                <div className="col-4">
                  <div className="form-check card-radio">
                    <input
                      className="form-check-input form-check-custom"
                      type="radio"
                      name="data-topbar"
                      id="topbar-color-light"
                      value="light"
                    />
                    <label
                      className="form-check-label p-0 avatar-md w-100"
                      htmlFor="topbar-color-light"
                    >
                      <span className="d-flex gap-1 h-100">
                        <span className="flex-shrink-0">
                          <span className="bg-light d-flex h-100 flex-column gap-1 p-1">
                            <span className="d-block p-1 px-2 bg-primary-subtle rounded mb-2"></span>
                            <span className="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
                            <span className="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
                            <span className="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
                          </span>
                        </span>
                        <span className="flex-grow-1">
                          <span className="d-flex h-100 flex-column">
                            <span className="bg-light d-block p-1"></span>
                            <span className="bg-light d-block p-1 mt-auto"></span>
                          </span>
                        </span>
                      </span>
                    </label>
                  </div>
                  <h5 className="fs-13 text-center mt-2">Light</h5>
                </div>
                <div className="col-4">
                  <div className="form-check card-radio">
                    <input
                      className="form-check-input form-check-custom"
                      type="radio"
                      name="data-topbar"
                      id="topbar-color-dark"
                      value="dark"
                    />
                    <label
                      className="form-check-label p-0 avatar-md w-100"
                      htmlFor="topbar-color-dark"
                    >
                      <span className="d-flex gap-1 h-100">
                        <span className="flex-shrink-0">
                          <span className="bg-light d-flex h-100 flex-column gap-1 p-1">
                            <span className="d-block p-1 px-2 bg-primary-subtle rounded mb-2"></span>
                            <span className="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
                            <span className="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
                            <span className="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
                          </span>
                        </span>
                        <span className="flex-grow-1">
                          <span className="d-flex h-100 flex-column">
                            <span className="bg-primary d-block p-1"></span>
                            <span className="bg-light d-block p-1 mt-auto"></span>
                          </span>
                        </span>
                      </span>
                    </label>
                  </div>
                  <h5 className="fs-13 text-center mt-2">Dark</h5>
                </div>
              </div>

              <div id="sidebar-size">
                <h6 className="mt-4 mb-0 fw-semibold text-uppercase">
                  Sidebar Size
                </h6>
                <p className="text-muted">Choose a size of Sidebar.</p>

                <div className="row">
                  <div className="col-4">
                    <div className="form-check sidebar-setting card-radio">
                      <input
                        className="form-check-input form-check-custom"
                        type="radio"
                        name="data-sidebar-size"
                        id="sidebar-size-default"
                        value="lg"
                      />
                      <label
                        className="form-check-label p-0 avatar-md w-100"
                        htmlFor="sidebar-size-default"
                      >
                        <span className="d-flex gap-1 h-100">
                          <span className="flex-shrink-0">
                            <span className="bg-light d-flex h-100 flex-column gap-1 p-1">
                              <span className="d-block p-1 px-2 bg-primary-subtle rounded mb-2"></span>
                              <span className="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
                              <span className="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
                              <span className="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
                            </span>
                          </span>
                          <span className="flex-grow-1">
                            <span className="d-flex h-100 flex-column">
                              <span className="bg-light d-block p-1"></span>
                              <span className="bg-light d-block p-1 mt-auto"></span>
                            </span>
                          </span>
                        </span>
                      </label>
                    </div>
                    <h5 className="fs-13 text-center mt-2">Default</h5>
                  </div>

                  <div className="col-4">
                    <div className="form-check sidebar-setting card-radio">
                      <input
                        className="form-check-input form-check-custom"
                        type="radio"
                        name="data-sidebar-size"
                        id="sidebar-size-compact"
                        value="md"
                      />
                      <label
                        className="form-check-label p-0 avatar-md w-100"
                        htmlFor="sidebar-size-compact"
                      >
                        <span className="d-flex gap-1 h-100">
                          <span className="flex-shrink-0">
                            <span className="bg-light d-flex h-100 flex-column gap-1 p-1">
                              <span className="d-block p-1 bg-primary-subtle rounded mb-2"></span>
                              <span className="d-block p-1 pb-0 bg-primary-subtle"></span>
                              <span className="d-block p-1 pb-0 bg-primary-subtle"></span>
                              <span className="d-block p-1 pb-0 bg-primary-subtle"></span>
                            </span>
                          </span>
                          <span className="flex-grow-1">
                            <span className="d-flex h-100 flex-column">
                              <span className="bg-light d-block p-1"></span>
                              <span className="bg-light d-block p-1 mt-auto"></span>
                            </span>
                          </span>
                        </span>
                      </label>
                    </div>
                    <h5 className="fs-13 text-center mt-2">Compact</h5>
                  </div>

                  <div className="col-4">
                    <div className="form-check sidebar-setting card-radio">
                      <input
                        className="form-check-input form-check-custom"
                        type="radio"
                        name="data-sidebar-size"
                        id="sidebar-size-small"
                        value="sm"
                      />
                      <label
                        className="form-check-label p-0 avatar-md w-100"
                        htmlFor="sidebar-size-small"
                      >
                        <span className="d-flex gap-1 h-100">
                          <span className="flex-shrink-0">
                            <span className="bg-light d-flex h-100 flex-column gap-1">
                              <span className="d-block p-1 bg-primary-subtle mb-2"></span>
                              <span className="d-block p-1 pb-0 bg-primary-subtle"></span>
                              <span className="d-block p-1 pb-0 bg-primary-subtle"></span>
                              <span className="d-block p-1 pb-0 bg-primary-subtle"></span>
                            </span>
                          </span>
                          <span className="flex-grow-1">
                            <span className="d-flex h-100 flex-column">
                              <span className="bg-light d-block p-1"></span>
                              <span className="bg-light d-block p-1 mt-auto"></span>
                            </span>
                          </span>
                        </span>
                      </label>
                    </div>
                    <h5 className="fs-13 text-center mt-2">
                      Small (Icon View)
                    </h5>
                  </div>

                  <div className="col-4">
                    <div className="form-check sidebar-setting card-radio">
                      <input
                        className="form-check-input form-check-custom"
                        type="radio"
                        name="data-sidebar-size"
                        id="sidebar-size-small-hover"
                        value="sm-hover"
                      />
                      <label
                        className="form-check-label p-0 avatar-md w-100"
                        htmlFor="sidebar-size-small-hover"
                      >
                        <span className="d-flex gap-1 h-100">
                          <span className="flex-shrink-0">
                            <span className="bg-light d-flex h-100 flex-column gap-1">
                              <span className="d-block p-1 bg-primary-subtle mb-2"></span>
                              <span className="d-block p-1 pb-0 bg-primary-subtle"></span>
                              <span className="d-block p-1 pb-0 bg-primary-subtle"></span>
                              <span className="d-block p-1 pb-0 bg-primary-subtle"></span>
                            </span>
                          </span>
                          <span className="flex-grow-1">
                            <span className="d-flex h-100 flex-column">
                              <span className="bg-light d-block p-1"></span>
                              <span className="bg-light d-block p-1 mt-auto"></span>
                            </span>
                          </span>
                        </span>
                      </label>
                    </div>
                    <h5 className="fs-13 text-center mt-2">Small Hover View</h5>
                  </div>
                </div>
              </div>

              <div id="sidebar-color">
                <h6 className="mt-4 mb-0 fw-semibold text-uppercase">
                  Sidebar Color
                </h6>
                <p className="text-muted">Choose a color of Sidebar.</p>

                <div className="row">
                  <div className="col-4">
                    <div
                      className="form-check sidebar-setting card-radio"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseBgGradient.show"
                    >
                      <input
                        className="form-check-input form-check-custom"
                        type="radio"
                        name="data-sidebar"
                        id="sidebar-color-light"
                        value="light"
                      />
                      <label
                        className="form-check-label p-0 avatar-md w-100"
                        htmlFor="sidebar-color-light"
                      >
                        <span className="d-flex gap-1 h-100">
                          <span className="flex-shrink-0">
                            <span className="bg-white border-end d-flex h-100 flex-column gap-1 p-1">
                              <span className="d-block p-1 px-2 bg-primary-subtle rounded mb-2"></span>
                              <span className="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
                              <span className="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
                              <span className="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
                            </span>
                          </span>
                          <span className="flex-grow-1">
                            <span className="d-flex h-100 flex-column">
                              <span className="bg-light d-block p-1"></span>
                              <span className="bg-light d-block p-1 mt-auto"></span>
                            </span>
                          </span>
                        </span>
                      </label>
                    </div>
                    <h5 className="fs-13 text-center mt-2">Light</h5>
                  </div>
                  <div className="col-4">
                    <div
                      className="form-check sidebar-setting card-radio"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseBgGradient.show"
                    >
                      <input
                        className="form-check-input form-check-custom"
                        type="radio"
                        name="data-sidebar"
                        id="sidebar-color-dark"
                        value="dark"
                      />
                      <label
                        className="form-check-label p-0 avatar-md w-100"
                        htmlFor="sidebar-color-dark"
                      >
                        <span className="d-flex gap-1 h-100">
                          <span className="flex-shrink-0">
                            <span className="bg-primary d-flex h-100 flex-column gap-1 p-1">
                              <span className="d-block p-1 px-2 bg-white bg-opacity-10 rounded mb-2"></span>
                              <span className="d-block p-1 px-2 pb-0 bg-white bg-opacity-10"></span>
                              <span className="d-block p-1 px-2 pb-0 bg-white bg-opacity-10"></span>
                              <span className="d-block p-1 px-2 pb-0 bg-white bg-opacity-10"></span>
                            </span>
                          </span>
                          <span className="flex-grow-1">
                            <span className="d-flex h-100 flex-column">
                              <span className="bg-light d-block p-1"></span>
                              <span className="bg-light d-block p-1 mt-auto"></span>
                            </span>
                          </span>
                        </span>
                      </label>
                    </div>
                    <h5 className="fs-13 text-center mt-2">Dark</h5>
                  </div>
                  <div className="col-4">
                    <button
                      className="btn btn-link avatar-md w-100 p-0 overflow-hidden border collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseBgGradient"
                      aria-expanded="false"
                      aria-controls="collapseBgGradient"
                    >
                      <span className="d-flex gap-1 h-100">
                        <span className="flex-shrink-0">
                          <span className="bg-vertical-gradient d-flex h-100 flex-column gap-1 p-1">
                            <span className="d-block p-1 px-2 bg-white bg-opacity-10 rounded mb-2"></span>
                            <span className="d-block p-1 px-2 pb-0 bg-white bg-opacity-10"></span>
                            <span className="d-block p-1 px-2 pb-0 bg-white bg-opacity-10"></span>
                            <span className="d-block p-1 px-2 pb-0 bg-white bg-opacity-10"></span>
                          </span>
                        </span>
                        <span className="flex-grow-1">
                          <span className="d-flex h-100 flex-column">
                            <span className="bg-light d-block p-1"></span>
                            <span className="bg-light d-block p-1 mt-auto"></span>
                          </span>
                        </span>
                      </span>
                    </button>
                    <h5 className="fs-13 text-center mt-2">Gradient</h5>
                  </div>
                </div>

                <div className="collapse" id="collapseBgGradient">
                  <div className="d-flex gap-2 flex-wrap img-switch p-2 px-3 bg-light rounded">
                    <div className="form-check sidebar-setting card-radio">
                      <input
                        className="form-check-input form-check-custom"
                        type="radio"
                        name="data-sidebar"
                        id="sidebar-color-gradient"
                        value="gradient"
                      />
                      <label
                        className="form-check-label p-0 avatar-xs rounded-circle"
                        htmlFor="sidebar-color-gradient"
                      >
                        <span className="avatar-title rounded-circle bg-vertical-gradient"></span>
                      </label>
                    </div>
                    <div className="form-check sidebar-setting card-radio">
                      <input
                        className="form-check-input form-check-custom"
                        type="radio"
                        name="data-sidebar"
                        id="sidebar-color-gradient-2"
                        value="gradient-2"
                      />
                      <label
                        className="form-check-label p-0 avatar-xs rounded-circle"
                        htmlFor="sidebar-color-gradient-2"
                      >
                        <span className="avatar-title rounded-circle bg-vertical-gradient-2"></span>
                      </label>
                    </div>
                    <div className="form-check sidebar-setting card-radio">
                      <input
                        className="form-check-input form-check-custom"
                        type="radio"
                        name="data-sidebar"
                        id="sidebar-color-gradient-3"
                        value="gradient-3"
                      />
                      <label
                        className="form-check-label p-0 avatar-xs rounded-circle"
                        htmlFor="sidebar-color-gradient-3"
                      >
                        <span className="avatar-title rounded-circle bg-vertical-gradient-3"></span>
                      </label>
                    </div>
                    <div className="form-check sidebar-setting card-radio">
                      <input
                        className="form-check-input form-check-custom"
                        type="radio"
                        name="data-sidebar"
                        id="sidebar-color-gradient-4"
                        value="gradient-4"
                      />
                      <label
                        className="form-check-label p-0 avatar-xs rounded-circle"
                        htmlFor="sidebar-color-gradient-4"
                      >
                        <span className="avatar-title rounded-circle bg-vertical-gradient-4"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div id="sidebar-img">
                <h6 className="mt-4 mb-0 fw-semibold text-uppercase">
                  Sidebar Images
                </h6>
                <p className="text-muted">Choose a image of Sidebar.</p>

                <div className="d-flex gap-2 flex-wrap img-switch">
                  <div className="form-check sidebar-setting card-radio">
                    <input
                      className="form-check-input form-check-custom"
                      type="radio"
                      name="data-sidebar-image"
                      id="sidebarimg-none"
                      value="none"
                    />
                    <label
                      className="form-check-label p-0 avatar-sm h-auto"
                      htmlFor="sidebarimg-none"
                    >
                      <span className="avatar-md w-auto bg-light d-flex align-items-center justify-content-center">
                        <i className="ri-close-fill fs-20"></i>
                      </span>
                    </label>
                  </div>

                  <div className="form-check sidebar-setting card-radio">
                    <input
                      className="form-check-input form-check-custom"
                      type="radio"
                      name="data-sidebar-image"
                      id="sidebarimg-01"
                      value="img-1"
                    />
                    <label
                      className="form-check-label p-0 avatar-sm h-auto"
                      htmlFor="sidebarimg-01"
                    >
                      <img
                        src="assets/images/sidebar/img-1.jpg"
                        alt=""
                        className="avatar-md w-auto object-fit-cover"
                      />
                    </label>
                  </div>

                  <div className="form-check sidebar-setting card-radio">
                    <input
                      className="form-check-input form-check-custom"
                      type="radio"
                      name="data-sidebar-image"
                      id="sidebarimg-02"
                      value="img-2"
                    />
                    <label
                      className="form-check-label p-0 avatar-sm h-auto"
                      htmlFor="sidebarimg-02"
                    >
                      <img
                        src="assets/images/sidebar/img-2.jpg"
                        alt=""
                        className="avatar-md w-auto object-fit-cover"
                      />
                    </label>
                  </div>
                  <div className="form-check sidebar-setting card-radio">
                    <input
                      className="form-check-input form-check-custom"
                      type="radio"
                      name="data-sidebar-image"
                      id="sidebarimg-03"
                      value="img-3"
                    />
                    <label
                      className="form-check-label p-0 avatar-sm h-auto"
                      htmlFor="sidebarimg-03"
                    >
                      <img
                        src="assets/images/sidebar/img-3.jpg"
                        alt=""
                        className="avatar-md w-auto object-fit-cover"
                      />
                    </label>
                  </div>
                  <div className="form-check sidebar-setting card-radio">
                    <input
                      className="form-check-input form-check-custom"
                      type="radio"
                      name="data-sidebar-image"
                      id="sidebarimg-04"
                      value="img-4"
                    />
                    <label
                      className="form-check-label p-0 avatar-sm h-auto"
                      htmlFor="sidebarimg-04"
                    >
                      <img
                        src="assets/images/sidebar/img-4.jpg"
                        alt=""
                        className="avatar-md w-auto object-fit-cover"
                      />
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="offcanvas-footer border-top p-3 text-center">
          <div className="row">
            <div className="col-6">
              <button
                type="button"
                className="btn btn-light w-100"
                id="reset-layout"
              >
                Reset
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThemeSetting;
