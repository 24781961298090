import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import {
  Routes,
  Route,
  useLocation,
  useNavigate,
  Navigate,
} from "react-router-dom";
import { Footer, Sidebar, Header, ThemeSetting } from "../layouts";
import {
  Home,
  Chat,
  Knowledge,
  Library,
  Archive,
  UserProfile,
  Cart,
  Checkout,
  FinishOrder,
  Order,
  OrderDetail,
  Assistant,
  Settings,
} from "../pages";
import {
  ActivationSuccess,
  CreatePassword,
  ForgotPassword,
  Login,
  OtpVerification,
  Register,
  RegisterFree,
  RegisterGoogle,
  RegisterSuccess,
  ResetPassword,
  ResetPasswordSuccess,
} from "../auth";
import storage from "../services/storage";
import { BgParticles } from "../components";
import {
  LanguageProvider,
  CartProvider,
  ThemeProvider,
  SliderRecommendedProvider,
  SliderRecentProvider,
  ToastrProvider,
  SliderProcessingProvider,
  SessionProvider,
  AssistantProvider,
  WebSocketProvider,
} from "../provider";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import { API_URL } from "./url";
import authHeader from "../services/auth.header";

/* ScrollToTop */
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

/* Function to get title from pathname */
const getTitle = (pathname) => {
  const pathSegments = pathname.split("/");
  const lastSegment = pathSegments[pathSegments.length - 1];
  return lastSegment ? `${capitalize(lastSegment)} - Otobaca` : "Login";
};

/* Helper function to capitalize a string */
const capitalize = (str) => {
  return str.replace(/\b\w/g, (char) => char.toUpperCase()).replace(/-/g, " ");
};

/* AuthExpiredPopup Component */
const AuthExpiredPopup = ({ onClose }) => (
  <div className="popup-overlay">
    <div className="popup-content">
      <h4 className="text-dark">Session Expired</h4>
      <p className="text-dark">Your session has expired. Please login again.</p>
      <button className="btn btn-primary btn-sm" onClick={onClose}>
        Login
      </button>
    </div>
  </div>
);

/* Main Router Component */
const Router = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const currentPath = location.pathname.split("/")[1];
  const isLoggedIn = !!storage.get("access_token");
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const isTokenExpired = () => {
    const token = storage.get("access_token");
    if (!token) return true;
    axios
      .get(API_URL + "auth", {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.data.success === true) {
          const payload = jwtDecode(token); // Decode JWT
          const expiry = payload.exp * 1000; // Convert to milliseconds
          return Date.now() > expiry;
        } else {
          setIsPopupVisible(true);
        }
      });
  };

  const handleClosePopup = () => {
    setIsPopupVisible(false);
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key !== "lang") {
        storage.remove("access_token");
        localStorage.removeItem(key);
      }
    }
    window.location.href = "/login";
  };

  useEffect(() => {
    /* Initializing */
    if (!storage.get("lang")) {
      storage.setUnlimited("lang", "en");
    }
    sessionStorage.setItem("data-bs-theme", "dark");
  }, []);

  useEffect(() => {
    if (isTokenExpired()) {
      setIsPopupVisible(true);
    }
  }, [navigate]);

  return (
    <>
      {!isLoggedIn ? (
        <AuthRouter currentPath={currentPath} />
      ) : (
        <>
          {isPopupVisible && <AuthExpiredPopup onClose={handleClosePopup} />}
          <MainRouter currentPath={currentPath} />
        </>
      )}
    </>
  );
};

/* Authentication Router for non-authenticated users */
const AuthRouter = ({ currentPath }) => {
  return (
    <>
      <Helmet>
        <title>{getTitle(currentPath)}</title> - Otobaca
      </Helmet>
      <div className="auth-page-wrapper">
        <div className="auth-one-bg-position auth-one-bg" id="auth-particles">
          <BgParticles />
        </div>
        <LanguageProvider>
          <Routes>
            <Route path="" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/register-free" element={<RegisterFree />} />
            <Route path="/register-with-google" element={<RegisterGoogle />} />
            <Route path="/register-success" element={<RegisterSuccess />} />
            <Route path="/activation-success" element={<ActivationSuccess />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/create-password" element={<CreatePassword />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />
            <Route
              path="/reset-password-success"
              element={<ResetPasswordSuccess />}
            />
            <Route
              path="/otp-verification/:token"
              element={<OtpVerification />}
            />
            <Route path="*" element={<Navigate to="/login" />} />
          </Routes>
        </LanguageProvider>
      </div>
    </>
  );
};

/* Main Router for authenticated users */
const MainRouter = ({ currentPath }) => {
  return (
    <>
      <Helmet>
        <title>{getTitle(currentPath)}</title>
        {/* Additional scripts or tags */}
        {/* <script src="assets/js/pages/chat.init.js" async="true"></script> */}
        <script
          src="assets/libs/bootstrap/js/bootstrap.bundle.min.js"
          async="true"
        ></script>
        <script src="assets/js/lord-icon-2.1.0.js" async="true"></script>
        <script src="assets/js/app.js" async="true"></script>
      </Helmet>
      <LanguageProvider>
        <ThemeProvider>
          <CartProvider>
            <SessionProvider>
              <AssistantProvider>
                <ToastrProvider>
                  <WebSocketProvider>
                    <ScrollToTop />
                    <div id="layout-wrapper">
                      <SliderRecommendedProvider>
                        <SliderRecentProvider>
                          <SliderProcessingProvider>
                            <Header />
                            <Sidebar />
                            <div className="main-content">
                              <Routes>
                                <Route path="" element={<Home />} />
                                <Route path="/home" element={<Home />} />
                                <Route path="/chat" element={<Chat />} />
                                <Route
                                  path="/my-library"
                                  element={<Library />}
                                />
                                <Route
                                  path="/personal-assistant"
                                  element={<Assistant />}
                                />
                                <Route
                                  path="/knowledge"
                                  element={<Knowledge />}
                                />
                                <Route path="/archive" element={<Archive />} />
                                <Route path="/cart" element={<Cart />} />
                                <Route
                                  path="/checkout"
                                  element={<Checkout />}
                                />
                                <Route
                                  path="/finish-order/:orderId"
                                  element={<FinishOrder />}
                                />
                                <Route path="/order-list" element={<Order />} />
                                <Route
                                  path="/order-detail/:orderId"
                                  element={<OrderDetail />}
                                />
                                <Route
                                  path="/user-profile"
                                  element={<UserProfile />}
                                />
                                <Route
                                  path="/settings"
                                  element={<Settings />}
                                />
                                <Route
                                  path="*"
                                  element={<Navigate to="/home" />}
                                />
                              </Routes>
                            </div>
                            <Footer />
                          </SliderProcessingProvider>
                        </SliderRecentProvider>
                      </SliderRecommendedProvider>
                      <ThemeSetting />
                    </div>
                  </WebSocketProvider>
                </ToastrProvider>
              </AssistantProvider>
            </SessionProvider>
          </CartProvider>
        </ThemeProvider>
      </LanguageProvider>
    </>
  );
};

export default Router;
