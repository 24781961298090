import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import id from "../../assets/images/flags/indonesia.svg";
import us from "../../assets/images/flags/us.svg";
import storage from "../../services/storage";
import { useLanguage } from "../../provider/languageContext";

const HeaderLanguage = () => {
  const { language } = useLanguage();
  const { changeLanguage } = useLanguage();
  return (
    <div>
      <Dropdown className="ms-1 topbar-head-dropdown header-item">
        <Dropdown.Toggle
          variant=""
          className="dropdown d-flex btn-icon btn-topbar btn-ghost-secondary rounded-circle"
        >
          <img
            src={language == "en" ? us : id}
            alt="Header Language"
            height="20"
            width="20"
            className="rounded"
          />
        </Dropdown.Toggle>

        <Dropdown.Menu className="dropdown-menu p-0" align="end">
          <Dropdown.Item as="button" onClick={() => changeLanguage("en")}>
            <img
              src={us}
              alt="user-image"
              className="me-2 rounded"
              height="20"
              width="20"
            />
            <span className="align-middle">English</span>
          </Dropdown.Item>
          <Dropdown.Item as="button" onClick={() => changeLanguage("id")}>
            <img
              src={id}
              alt="user-image"
              className="me-2 rounded"
              height="20"
              width="20"
            />
            <span className="align-middle">Indonesian</span>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default HeaderLanguage;
