import logo from "./logo.svg";
import "./App.css";
import "jquery";
import "datatables.net-bs5/css/dataTables.bootstrap5.css";
import "datatables.net-bs5/js/dataTables.bootstrap5.min.js";
import "./assets/css/bootstrap.min.css";
import "./assets/css/icons.min.css";
import "./assets/css/chat.min.css";
import "./assets/css/app.min.css";
import "./assets/css/custom.min.css";
import "react-loading-skeleton/dist/skeleton.css";
import { BrowserRouter } from "react-router-dom";
import Router from "./utilities/router";

require("jquery-validation");
require("smartwizard");
require("smartwizard/dist/css/smart_wizard_all.min.css");
require("bootstrap-datepicker");
require("bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css");

function App() {
  return (
    <BrowserRouter>
      <Router />
    </BrowserRouter>
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.js</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>
  );
}

export default App;
