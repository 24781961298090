import { React } from "react";
import { Link } from "react-router-dom";
import logoImg from "../assets/images/logo-dark.png";
import ck_success from "../assets/images/check-success.gif";
import HeaderLanguage from "./component/headerLanguage";
import { useLanguage } from "../provider/languageContext";

const RegisterPasswordSuccess = () => {
  const { messagesLang } = useLanguage();

  return (
    <div className="auth-page-content pb-0">
      <div className="container">
        <div className="row">
          <div className="d-flex justify-content-between mt-sm-4 mb-sm-2 text-white-50">
            <Link to="/login">
              <img
                src={logoImg}
                alt=""
                height="40"
                className="d-none d-sm-block"
              />
            </Link>
            <div className="head-lang">
              <HeaderLanguage />
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-8 col-lg-6 col-xl-5">
            <div className="centered-container">
              <div className="card login rounded-4 mt-5">
                <div className="card-body p-4">
                  <div className="text-center mt-2">
                    <h5 className="text-white">Reset Password Success</h5>
                    <div className="text-center">
                      <img
                        className="mt-3 mb-5"
                        src={ck_success}
                        alt="success"
                      />
                    </div>
                    <div className="text-center text-light col-md-10 mx-auto">
                      <p className="p-0">
                        {messagesLang.login_with_new_password}
                      </p>
                    </div>
                    <div className="text-center mt-7">
                      <Link to="/login" className="btn btn-md btn-success">
                        Login
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterPasswordSuccess;
