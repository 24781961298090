import React, { useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { Link, useNavigate } from "react-router-dom";
import { ThemeContext } from "../../../provider/themeContext";
import { CartContext } from "../../../provider/cartContext";
import CustomStyles from "../../../components/customStyles";
import { useLanguage } from "../../../provider/languageContext";
import { Button, Spinner } from "react-bootstrap";
import axios from "axios";
import { API_URL } from "../../../utilities/url";
import storage from "../../../services/storage";
import { ToastrContext } from "../../../provider/toastrContext";
import { SliderRecommendedContext } from "../../../provider/sliderRecommendedContext";

const CheckoutTab = ({ item, total }) => {
  const { messagesLang } = useLanguage();
  const navigate = useNavigate();
  const { loadData } = useContext(SliderRecommendedContext);
  const { loadDataCart } = useContext(CartContext);
  const [loadingData, setLoadingData] = useState(false);
  const { theme } = useContext(ThemeContext);
  const { showError } = useContext(ToastrContext);
  const options = [
    { value: "1", label: "BNI" },
    { value: "2", label: "BRI" },
    { value: "3", label: "BCA" },
  ];
  const [selectedOption, setSelectedOption] = useState();
  const handleSelect = (data) => {
    setSelectedOption(data);
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    reValidateMode: "onBlur",
  });

  const handleSubmitFree = () => {
    setLoadingData(true);
    const formData = new FormData();
    formData.append("discount_name", "");
    formData.append("discount_percent", 0);
    formData.append("discount_value", 0);
    formData.append("payment_method", "");
    formData.append("payment_name", "");
    formData.append("tax", total > 0 ? total * 0.05 : 0);
    formData.append("total_payment", total > 0 ? total + total * 0.05 : total);
    formData.append("status", 1);
    if (item[0].title !== undefined) {
      item.forEach((data, index) => {
        formData.append(
          `knowledge[${index}][idt_knowledge]`,
          data.idt_knowledge
        );
        formData.append(
          `knowledge[${index}][price_scheme]`,
          data.knowledge_public[0].pricing_scheme
        );
        if (data.knowledge_public[0].pricing_scheme === "paid") {
          const price = data.knowledge_public[0].price_sell;
          formData.append(`knowledge[${index}][price]`, price);
          formData.append(`knowledge[${index}][rent_period]`, 0);
        } else if (data.knowledge_public[0].pricing_scheme === "rent") {
          const price = data.knowledge_public[0].price_rent;
          formData.append(`knowledge[${index}][price]`, price);
          formData.append(
            `knowledge[${index}][rent_period]`,
            data.knowledge_public[0].rent_period
          );
        } else {
          const price = 0;
          formData.append(`knowledge[${index}][price]`, price);
          formData.append(`knowledge[${index}][rent_period]`, 0);
        }
      });
    } else if (item[0].name !== undefined) {
      item.forEach((data, index) => {
        formData.append(
          `knowledge_collection[${index}][idt_knowledge_collection]`,
          data.idt_knowledge_collection
        );
        formData.append(
          `knowledge_collection[${index}][price_scheme]`,
          data.knowledge_collection_public[0].pricing_scheme
        );
        if (data.knowledge_collection_public[0].pricing_scheme === "paid") {
          const price = data.knowledge_collection_public[0].price_sell;
          formData.append(`knowledge_collection[${index}][price]`, price);
          formData.append(`knowledge_collection[${index}][rent_period]`, 0);
        } else if (
          data.knowledge_collection_public[0].pricing_scheme === "rent"
        ) {
          const price = data.knowledge_collection_public[0].price_rent;
          formData.append(`knowledge_collection[${index}][price]`, price);
          formData.append(
            `knowledge_collection[${index}][rent_period]`,
            data.knowledge_collection_public[0].rent_period
          );
        } else {
          const price = 0;
          formData.append(`knowledge_collection[${index}][price]`, price);
          formData.append(`knowledge_collection[${index}][rent_period]`, 0);
        }
      });
    } else if (item[0].knowledge || item[0].knowledge_collection) {
      item.forEach((data, index) => {
        formData.append(`order_cart[${index}]`, data.idt_knowledge_order_cart);
        if (data.knowledge) {
          formData.append(
            `order_cart[${index}]`,
            data.idt_knowledge_order_cart
          );
          formData.append(
            `knowledge[${index}][idt_knowledge]`,
            data.knowledge.idt_knowledge
          );
          formData.append(
            `knowledge[${index}][price_scheme]`,
            data.knowledge.knowledge_public[0].pricing_scheme
          );
          if (data.knowledge.knowledge_public[0].pricing_scheme === "paid") {
            const price = data.knowledge.knowledge_public[0].price_sell;
            formData.append(`knowledge[${index}][price]`, price);
            formData.append(`knowledge[${index}][rent_period]`, 0);
          } else if (
            data.knowledge.knowledge_public[0].pricing_scheme === "rent"
          ) {
            const price = data.knowledge.knowledge_public[0].price_rent;
            formData.append(`knowledge[${index}][price]`, price);
            formData.append(
              `knowledge[${index}][rent_period]`,
              data.knowledge.knowledge_public[0].rent_period
            );
          } else {
            const price = 0;
            formData.append(`knowledge[${index}][price]`, price);
            formData.append(`knowledge[${index}][rent_period]`, 0);
          }
        } else {
          formData.append(
            `knowledge_collection[${index}][idt_knowledge_collection]`,
            data.knowledge_collection.idt_knowledge_collection
          );
          formData.append(
            `knowledge_collection[${index}][price_scheme]`,
            data.knowledge_collection.knowledge_collection_public[0]
              .pricing_scheme
          );
          if (
            data.knowledge_collection.knowledge_collection_public[0]
              .pricing_scheme === "paid"
          ) {
            const price =
              data.knowledge_collection.knowledge_collection_public[0]
                .price_sell;
            formData.append(`knowledge_collection[${index}][price]`, price);
            formData.append(`knowledge_collection[${index}][rent_period]`, 0);
          } else if (
            data.knowledge_collection.knowledge_collection_public[0]
              .pricing_scheme === "rent"
          ) {
            const price =
              data.knowledge_collection.knowledge_collection_public[0]
                .price_rent;
            formData.append(`knowledge_collection[${index}][price]`, price);
            formData.append(
              `knowledge_collection[${index}][rent_period]`,
              data.knowledge_collection.knowledge_collection_public[0]
                .rent_period
            );
          } else {
            const price = 0;
            formData.append(`knowledge_collection[${index}][price]`, price);
            formData.append(`knowledge_collection[${index}][rent_period]`, 0);
          }
        }
      });
    }

    axios
      .post(API_URL + "add-knowledge-order", formData, {
        headers: {
          Authorization: `Bearer ${storage.get("access_token")}`,
        },
      })
      .then((response) => {
        if (response.data.success === true) {
          const idOrder = response.data.data.id_order;
          loadDataCart();
          loadData();
          storage.setUnlimited("orderId", idOrder);
          storage.setUnlimited("selectedItemIds", []);
          navigate("/finish-order/" + idOrder);
        } else {
          showError(response.data.message);
        }
        setLoadingData(false);
      })
      .catch((error) => {
        console.log(error);
        setLoadingData(false);
      });
  };

  return (
    <>
      {total > 0 ? (
        <div className="card">
          <div className="card-header">
            <ul
              className="nav nav-tabs-custom rounded card-header-tabs border-bottom-0"
              role="tablist"
            >
              <li className="nav-item">
                <a
                  className="nav-link active"
                  data-bs-toggle="tab"
                  href="#bankDetails"
                  role="tab"
                >
                  <i className="fas fa-home"></i> VA Bank
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-bs-toggle="tab"
                  href="#creditDetails"
                  role="tab"
                >
                  <i className="fas fa-home"></i> Credit / Debit Card
                </a>
              </li>
            </ul>
          </div>
          <div className="card-body p-3">
            <div className="tab-content">
              <div className="tab-pane active" id="bankDetails" role="tabpanel">
                <form>
                  <div className="card mb-0">
                    <div className="row mb-2">
                      <div className="col-md-6">
                        <label for="cc-name">Bank Name</label>
                        <Controller
                          name="category"
                          control={control}
                          // rules={{ required: "Country is required." }}
                          render={({ field }) => (
                            <Select
                              {...field}
                              styles={CustomStyles(theme)}
                              className="custom-select"
                              maxMenuHeight={200}
                              options={options}
                              placeholder="Select Bank"
                              value={selectedOption}
                              onChange={handleSelect}
                              isSearchable={true}
                              selected={selectedOption}
                            />
                          )}
                        />
                      </div>
                      <div className="col-md-6">
                        <label for="cc-name">No. VA</label>
                        <input
                          type="text"
                          className="form-control"
                          id="cc-number"
                          placeholder="xxxx xxxx xxxx xxxx"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-start gap-3 mt-4">
                    {item ? (
                      item[0].title === undefined ? (
                        <Link to="/cart" className="btn btn-danger btn-label">
                          <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>
                          {messagesLang.back_to} {messagesLang.cart}
                        </Link>
                      ) : null
                    ) : null}
                    <button
                      type="button"
                      className="btn-primary btn-label right ms-auto"
                      data-nexttab="pills-finish-tab"
                    >
                      <i className="ri-shopping-basket-line label-icon align-middle fs-16 ms-2"></i>
                      {messagesLang.complete_order}
                    </button>
                  </div>
                </form>
              </div>
              <div className="tab-pane " id="creditDetails" role="tabpanel">
                <form>
                  <div className="card mb-0">
                    <div className="row mb-2">
                      <div className="col-md-6">
                        <label for="cc-name">Name on card</label>
                        <input
                          type="text"
                          className="form-control"
                          id="cc-name"
                          placeholder="Enter name"
                        />
                        <small className="text-muted">
                          Full name as displayed on card
                        </small>
                      </div>
                      <div className="col-md-6">
                        <label for="cc-number">Credit card number</label>
                        <input
                          type="text"
                          className="form-control"
                          id="cc-number"
                          placeholder="xxxx xxxx xxxx xxxx"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <label for="cc-expiration">Expiration</label>
                        <input
                          type="text"
                          className="form-control"
                          id="cc-expiration"
                          placeholder="MM/YY"
                        />
                      </div>

                      <div className="col-md-6">
                        <label for="cc-cvv">CVV</label>
                        <input
                          type="text"
                          className="form-control"
                          id="cc-cvv"
                          placeholder="xxx"
                        />
                      </div>
                    </div>
                    <div className="text-muted mt-2 fst-italic">
                      <i data-feather="lock" className="text-muted icon-xs"></i>{" "}
                      Your transaction is secured with SSL encryption
                    </div>
                  </div>

                  <div className="d-flex align-items-start gap-3 mt-4">
                    {item ? (
                      item[0].title === undefined ? (
                        <Link to="/cart" className="btn btn-danger btn-label">
                          <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>
                          {messagesLang.back_to} {messagesLang.cart}
                        </Link>
                      ) : null
                    ) : null}
                    <button
                      type="button"
                      className="btn-primary btn-label right ms-auto"
                      data-nexttab="pills-finish-tab"
                    >
                      <i className="ri-shopping-basket-line label-icon align-middle fs-16 ms-2"></i>
                      {messagesLang.complete_order}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="text-center pt-5 pb-3">
          <form onSubmit={handleSubmit(handleSubmitFree)}>
            <h5>
              {messagesLang.your_order_detail} - {0} ({messagesLang.free})
            </h5>
            <div className="d-flex align-items-start gap-3 mt-5">
              {item ? (
                item[0].title === undefined ? (
                  <Link to="/cart" className="btn btn-danger btn-label">
                    <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>
                    {messagesLang.back_to} {messagesLang.cart}
                  </Link>
                ) : null
              ) : null}
              {!loadingData ? (
                <Button
                  type="submit"
                  className="btn-primary btn-label right ms-auto"
                  style={{ width: "170px" }}
                >
                  <i className="ri-shopping-basket-line label-icon align-middle fs-16 ms-2"></i>
                  {messagesLang.complete_order}
                </Button>
              ) : (
                <Button
                  className="btn-primary btn-label right ms-auto"
                  style={{ width: "170px" }}
                >
                  <i className="ri-shopping-basket-line label-icon align-middle fs-16 ms-2"></i>
                  <Spinner
                    className="flex-shrink-0"
                    animation="border"
                    size="sm"
                  />
                </Button>
              )}
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default CheckoutTab;
