import React, { createContext, useState, useContext, useEffect } from "react";

// Import translation files
import en from "../utilities/en.json";
import id from "../utilities/id.json";
import storage from "../services/storage";

const LanguageContext = createContext();

const translations = {
  en: en,
  id: id,
};

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(storage.get("lang") || "en");
  const [messagesLang, setMessagesLang] = useState(translations[language]);

  const changeLanguage = (newLanguage) => {
    storage.setUnlimited("lang", newLanguage);
    setLanguage(newLanguage);
    setMessagesLang(translations[newLanguage]);
  };

  useEffect(() => {
    setMessagesLang(translations[language]);
  }, [language]);

  return (
    <LanguageContext.Provider
      value={{ language, changeLanguage, messagesLang }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);
