import { useContext, useEffect, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { ThemeContext } from "../provider/themeContext";
import { ToastrContext } from "../provider/toastrContext";
import Select, { components } from "react-select";
import SimpleBar from "simplebar-react";
import CustomStyles from "./customStyles";
import { IoPricetags } from "react-icons/io5";
import { LuBookUp } from "react-icons/lu";
import axios from "axios";
import { API_URL } from "../utilities/url";
import storage from "../services/storage";

const ModalPublish = ({ onUpdate, item }) => {
  const { showSuccess, showError } = useContext(ToastrContext);
  const { theme } = useContext(ThemeContext);
  const [selectedPaid, setSelectedPaid] = useState([]);
  const [selectedRadio, setSelectedRadio] = useState(null);
  const [loading, setLoading] = useState(false);
  const [term, setTerm] = useState(false);
  const [price, setPrice] = useState(null);
  const [show, setShow] = useState(false);
  const options = [
    { value: "free", label: "Free" },
    { value: "paid", label: "Paid" },
    // { value: "rent", label: "Rent" },
  ];
  const handleClose = () => {
    setShow(false);
    setValue("type_pricing", null);
    setValue("idt_knowledge", null);
    setValue("idt_knowledge_collection", null);
    setValue("pricing_scheme", options[0]);
  };
  const handleShow = () => {
    setShow(true);
    setValue("type_pricing", null);
    setValue("idt_knowledge", item.idt_knowledge);
    setValue("idt_knowledge_collection", item.idt_knowledge_collection);
    setValue("pricing_scheme", options[0]);
    setSelectedPaid(options[0]);
  };

  const SingleValue = ({ children, ...props }) => (
    <components.SingleValue {...props}>
      <IoPricetags className="me-2 cursor-pointer" />
      {children}
    </components.SingleValue>
  );

  const handleChange = (selectedOption) => {
    setSelectedPaid(selectedOption);
    if (selectedOption.value === "free") {
      clearErrors("price");
      setValue("price", null);
      setPrice(null);
      setValue("type_pricing", "unlimited");
    } else if (selectedOption.value === "paid") {
      setValue("price", null);
      setPrice(null);
      setSelectedRadio("unlimited");
      setValue("type_pricing", "unlimited");
    } else {
      setValue("price", null);
      setPrice(null);
      setSelectedRadio("monthly");
      setValue("type_pricing", "monthly");
    }
  };
  const handleRadioChange = (value) => {
    setSelectedRadio(value);
    setValue("type_pricing", value);
  };
  const handleTermChange = () => {
    clearErrors("term");
    setTerm((prevChecked) => !prevChecked);
  };
  const formatPrice = (value) => {
    const number = value.replace(/\D/g, "");
    return number.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };
  const handlePriceChange = (event) => {
    const formattedPrice = formatPrice(event.target.value);
    setPrice(formattedPrice);
    clearErrors("price");
  };

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
    clearErrors,
    setValue,
  } = useForm({
    reValidateMode: "onBlur",
  });

  const handleUpdate = (data) => {
    setLoading(true);
    axios
      .post(API_URL + "add-publish-knowledge", data, {
        headers: {
          Authorization: `Bearer ${storage.get("access_token")}`,
        },
      })
      .then((response) => {
        if (response.data.success === true) {
          showSuccess(response.data.message);
          onUpdate(response.data.data, "knowledge");
          handleClose();
          setLoading(false);
        } else {
          showError(response.data.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        showError("An error occurred. Please try again later.");
        setLoading(false);
      });
  };

  const toc = `Syarat dan Ketentuan Penggunaan Aplikasi

Selamat datang di aplikasi kami. Dengan mengunduh, mengakses, atau menggunakan aplikasi kami ("Aplikasi"), Anda setuju untuk terikat dengan syarat dan ketentuan penggunaan berikut ("Syarat dan Ketentuan"). Harap baca Syarat dan Ketentuan ini dengan cermat sebelum menggunakan Aplikasi. Jika Anda tidak setuju dengan Syarat dan Ketentuan ini, harap jangan menggunakan Aplikasi ini.

1. Definisi
Aplikasi: Aplikasi yang menyediakan fitur interaksi dengan AI berdasarkan pengetahuan atau sumber tertentu berupa PDF, teks, atau dokumen Word yang diunggah oleh pengguna.
Pengguna: Individu atau entitas yang mengunduh, mengakses, atau menggunakan Aplikasi untuk mengunggah, membagikan, atau mengakses pengetahuan.
Layanan: Fitur dan fungsi yang disediakan oleh Aplikasi, termasuk tetapi tidak terbatas pada interaksi dengan AI dan pengelolaan konten pengetahuan.

2. Penerimaan Syarat
Dengan mengakses atau menggunakan Aplikasi, Anda menyatakan bahwa Anda telah membaca, memahami, dan setuju untuk terikat oleh Syarat dan Ketentuan ini.

3. Perubahan pada Syarat dan Ketentuan
Kami berhak untuk mengubah Syarat dan Ketentuan ini kapan saja. Perubahan akan diberlakukan segera setelah kami memposting versi terbaru di Aplikasi. Penggunaan Anda yang berkelanjutan atas Aplikasi setelah perubahan tersebut merupakan penerimaan Anda terhadap Syarat dan Ketentuan yang telah diubah.

4. Deskripsi Layanan
Aplikasi ini memungkinkan pengguna untuk mengunggah, membagikan, dan mengakses pengetahuan dalam bentuk dokumen PDF, teks, atau Word. Layanan ini dirancang untuk membantu pengguna dalam berbagi pengetahuan dan informasi baik secara berbayar maupun gratis.

5. Penggunaan yang Diizinkan
Pengguna hanya diizinkan untuk menggunakan Aplikasi ini untuk tujuan sah sesuai dengan hukum yang berlaku. Pengguna tidak diperkenankan menggunakan Aplikasi untuk tujuan ilegal atau tidak etis.

6. Unggahan Pengguna
Jenis Unggahan: Pengguna dapat mengunggah dokumen berupa PDF, teks, atau Word yang akan digunakan oleh AI untuk memberikan jawaban atau saran dan dibagikan kepada pengguna lain.
Tanggung Jawab Pengguna: Pengguna bertanggung jawab penuh atas isi dari dokumen yang diunggah dan memastikan bahwa dokumen tersebut tidak melanggar hukum, hak cipta, atau hak-hak pihak ketiga lainnya.
Penyimpanan dan Keamanan: Kami akan melakukan yang terbaik untuk melindungi data yang diunggah pengguna, namun kami tidak dapat menjamin keamanan penuh dari data tersebut.
Pembagian Konten: Pengguna dapat memilih untuk membagikan konten secara gratis atau berbayar. Pengguna yang membagikan konten secara berbayar harus mematuhi aturan dan ketentuan pembayaran yang berlaku.

7. Hak Kekayaan Intelektual
Kepemilikan: Pengguna yang mengunggah konten tetap memegang hak cipta atas konten yang diunggah.
Lisensi Penggunaan: Dengan mengunggah konten, pengguna memberikan lisensi kepada kami untuk menggunakan, menampilkan, dan membagikan konten tersebut sesuai dengan pengaturan privasi dan pembagian yang dipilih pengguna.

8. Keterbatasan Tanggung Jawab
Keakuratan Informasi: AI dalam Aplikasi ini berusaha memberikan informasi yang akurat berdasarkan dokumen yang diunggah, namun kami tidak menjamin keakuratan atau kelengkapan informasi tersebut.
Penggunaan Informasi: Pengguna bertanggung jawab penuh atas penggunaan informasi yang diberikan oleh AI dan setuju bahwa informasi tersebut bukan merupakan nasihat hukum atau akademis resmi.

9. Penangguhan dan Penghentian
Kami berhak untuk menangguhkan atau menghentikan akses pengguna ke Aplikasi jika pengguna melanggar Syarat dan Ketentuan ini atau jika kami memutuskan untuk menghentikan layanan Aplikasi.

10. Hukum yang Berlaku
Syarat dan Ketentuan ini diatur oleh dan ditafsirkan sesuai dengan hukum negara Indonesia. Setiap sengketa yang timbul sehubungan dengan Syarat dan Ketentuan ini akan diselesaikan di pengadilan yang berwenang di Indonesia.

11. Kontak
Jika Anda memiliki pertanyaan tentang Syarat dan Ketentuan ini, silakan hubungi kami di [alamat email atau nomor telepon yang sesuai].

Terima kasih telah menggunakan Aplikasi kami. Kami berharap Aplikasi ini dapat membantu Anda dalam berbagi dan mengakses pengetahuan.`;

  return (
    <>
      <Button
        className="dropdown-item"
        role="button"
        variant="none"
        onClick={handleShow}
      >
        <LuBookUp size={15} className="me-2" />
        Publish {item.idt_knowledge ? "Knowledge" : "Collection"}
      </Button>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        backdrop="static"
        dialogClassName="fade modal-lg"
        onHide={handleClose}
      >
        <Modal.Header className="p-3 bg-light-dark" closeButton>
          <h5 className="modal-title">
            Publish {item.idt_knowledge ? "Knowledge" : "Collection"}
          </h5>
        </Modal.Header>
        <form onSubmit={handleSubmit(handleUpdate)}>
          <SimpleBar
            className="modal-body knowledge"
            style={{ height: "calc(100vh - 210px)" }}
          >
            <p className="mb-2">
              Tentukan harga pembelian knowledge yang akan ditentukan.
            </p>
            <div className="row">
              <div className="col-6">
                <div className="mb-2">
                  <Controller
                    name="pricing_scheme"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        styles={CustomStyles(theme)}
                        className="custom-select"
                        components={{ SingleValue }}
                        maxMenuHeight={200}
                        value={selectedPaid}
                        options={options}
                        onChange={(selected) => {
                          field.onChange(selected);
                          handleChange(selected);
                        }}
                        isSearchable={false}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            {selectedPaid && selectedPaid.value === "rent" && (
              <>
                <div className="row">
                  <div className="col-md-6">
                    {/* <label className="form-label w-100">Select Plan</label> */}
                    <div className="radio-group radio-group-custom mt-2">
                      <div
                        className="form-check form-radio-warning mb-3"
                        onClick={() => handleRadioChange("monthly")}
                      >
                        <input
                          className="form-check-input"
                          type="radio"
                          name="radio"
                          value="monthly"
                          checked={selectedRadio === "monthly"}
                        />
                        <label
                          className="form-check-label"
                          for="formradioRight5"
                        >
                          Monthly
                        </label>
                      </div>
                      <div
                        className="form-check form-radio-warning mb-3"
                        onClick={() => handleRadioChange("annual")}
                      >
                        <input
                          className="form-check-input"
                          type="radio"
                          name="radio"
                          value="annual"
                          checked={selectedRadio === "annual"}
                        />
                        <label
                          className="form-check-label"
                          for="formradioRight5"
                        >
                          Annual
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="mb-2">
                      {/* <label className="form-label w-100">Price</label> */}
                      <input
                        type="text"
                        className="form-control"
                        name="price"
                        placeholder="Price"
                        value={price}
                        {...register("price", {
                          required: true,
                        })}
                        onChange={handlePriceChange}
                      />
                      {errors.price && errors.price.type === "required" && (
                        <p className="errorMsg text-danger fs-7">
                          Price is required.
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
            {selectedPaid && selectedPaid.value === "paid" && (
              <>
                <div className="row">
                  <div className="col-6">
                    <div className="mb-2">
                      {/* <label className="form-label w-100">Price</label> */}
                      <input
                        type="text"
                        className="form-control"
                        name="price"
                        placeholder="Price"
                        value={price}
                        {...register("price", {
                          required: true,
                        })}
                        onChange={handlePriceChange}
                      />
                      {errors.price && errors.price.type === "required" && (
                        <p className="errorMsg text-danger fs-7">
                          Price is required.
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="row">
              <div className="col-md-12">
                <div className="form-check form-check-success mt-3 mb-3">
                  <input
                    className={`form-check-input ${
                      errors.term ? "is-invalid" : ""
                    }`}
                    type="checkbox"
                    name="term"
                    checked={term}
                    {...register("term", {
                      required: true,
                    })}
                    onChange={handleTermChange}
                  />
                  <label
                    className="form-check-label"
                    style={{ cursor: "default" }}
                  >
                    I agree to the terms and conditions
                  </label>
                </div>
              </div>
              <div className="col-12">
                <SimpleBar
                  style={{
                    height:
                      selectedPaid.value === "paid"
                        ? "calc(100vh - 470px)"
                        : "calc(100vh - 380px)",
                  }}
                >
                  <textarea
                    className="form-control"
                    value={toc}
                    disabled
                    style={{
                      resize: "none",
                      height:
                        selectedPaid.value === "paid"
                          ? "calc(100vh - 470px)"
                          : "calc(100vh - 380px)",
                    }}
                  />
                </SimpleBar>
              </div>
            </div>
          </SimpleBar>
          <Modal.Footer>
            <div className="hstack gap-2 justify-content-end">
              <div className="d-flex gap-2 justify-content-center mb-2">
                <Button
                  type="submit"
                  className="btn btn-primary w-sm"
                  disabled={loading ? "disabled" : ""}
                >
                  {loading ? (
                    <>
                      <Spinner
                        className="flex-shrink-0"
                        animation="border"
                        size="sm"
                      />
                    </>
                  ) : (
                    "Publish Now"
                  )}
                </Button>
              </div>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default ModalPublish;
