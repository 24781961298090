import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { RiEyeFill, RiEyeOffFill } from "react-icons/ri";
import axios from "axios";
import { API_URL } from "../utilities/url";
import logoImg from "../assets/images/logo-dark.png";
import Select from "react-select";
import { HiOutlineUser } from "react-icons/hi";
import { HiOutlineDocumentMagnifyingGlass } from "react-icons/hi2";
import { RiContactsBookLine } from "react-icons/ri";
import storage from "../services/storage";

var $ = require("jquery");

const RegisterFree = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [hidePassword, setHidePassword] = useState("password");
  const [hidePassword2, setHidePassword2] = useState("password");

  useEffect(() => {
    // Validate State
    if (!location.state) {
      if (!storage.get("id-subs")) {
        navigate("/register");
      } else {
        storage.set("id-subs", storage.get("id-subs"), 5);
      }
    } else {
      storage.set("id-subs", location.state.id, 5);
    }

    // Datepicker
    $(".dpicker")
      .datepicker({
        format: "dd-mm-yyyy",
        autoclose: true,
        todayHighlight: true,
        endDate: "+0d",
      })
      .on("changeDate", function (e) {
        $("#wizard-dob").val(e.target.value);
        $(this).valid();
      });

    // Validation Wizard
    var $validationForm = $("#smartwizard-validation");
    $.validator.addMethod(
      "regex_phone",
      function (value, element, regexp) {
        if (regexp.constructor != RegExp) regexp = new RegExp(regexp);
        else if (regexp.global) regexp.lastIndex = 0;
        return this.optional(element) || regexp.test(value);
      },
      "Please enter a valid phone number."
    );
    $validationForm.validate({
      errorPlacement: function errorPlacement(error, element) {
        $(element)
          .parents(".error-placeholder")
          .append(error.addClass("invalid-feedback small d-block"));
      },
      highlight: function (element) {
        $(element).addClass("is-invalid");
      },
      unhighlight: function (element) {
        $(element).removeClass("is-invalid");
      },
      onfocusout: function (element) {
        return $(element).valid();
      },
      rules: {
        email: {
          remote: {
            url: API_URL + "exist_user_v2",
            type: "post",
            data: {
              email: function () {
                return $("[name='email']").val();
              },
            },
          },
        },
        confirm_password: {
          equalTo: 'input[name="password"]',
        },
        msisdn: {
          regex_phone: /^\+?[1-9][0-9]{7,14}$/,
          minlength: 9,
        },
        password: {
          minlength: 8,
        },
      },
      messages: {
        email: {
          required: "Email is required.",
          remote: "Email is already taken.",
        },
        password: {
          required: "Password is required.",
        },
        confirm_password: {
          required: "Confirm Password is required.",
        },
        name_first: {
          required: "First Name is required.",
        },
        name_last: {
          required: "Last Name is required.",
        },
        dob: {
          required: "DOB is required.",
        },
      },
    });

    // Init Wizard
    $validationForm
      .smartWizard({
        theme: "arrows",
        autoAdjustHeight: false,
        backButtonSupport: false,
        useURLhash: false,
        showStepURLhash: false,
        toolbar: {
          extraHtml:
            '<button class="btn sw-btn-finish sw-btn btn-success d-none" id="btn-finished" type="button">Finish</button>', // Extra html to show on toolbar
        },
        anchor: {
          enableNavigation: true, // Enable/Disable anchor navigation
          enableNavigationAlways: false, // Activates all anchors clickable always
          enableDoneState: true, // Add done state on visited steps
        },
      })
      .on("showStep", function (e, anchorObject, stepNumber, stepDirection) {
        if (stepNumber === 2) {
          $(".sw-btn-finish").removeClass("d-none");
          $(".sw-btn-next").hide();
        } else {
          $(".sw-btn-finish").addClass("d-none");
          $(".sw-btn-next").show();
        }
        if (stepNumber === 0) {
          $(".sw-btn-prev").addClass("d-none");
        } else {
          $(".sw-btn-prev").removeClass("d-none");
          $(".sw-btn-prev").addClass("float-start");
        }
      })
      .on("leaveStep", function (e, anchorObject, stepNumber, stepDirection) {
        if (stepDirection <= 2) {
          return $validationForm.valid();
        }
        return true;
      });
    $validationForm.find(".sw-btn-finish").on("click", function () {
      if (!$validationForm.valid()) {
        return;
      }
      const data = {
        idt_product_subscription_plan: storage.get("id-subs"),
        email: $("[name='email']").val(),
        password: $("[name='password']").val(),
        name_first: $("[name='firstname']").val(),
        name_last: $("[name='firstname']").val(),
        dob: $("[name='dob']").val(),
        country: $("[name='country']").val(),
        msisdn: $("[name='msisdn']").val(),
      };
      axios
        .post(API_URL + "register", data)
        .then((response) => {
          if (response.data.success === true) {
            navigate("/register-success");
          }
        })
        .catch((error) => {
          console.log("There was an error!", error);
        });
    });

    // Get Country + Phone
    axios
      .get(API_URL + "countries")
      .then((res) => {
        if (res.data.success === true) {
          const maps = res.data.data.map(function (d) {
            const map = {
              value: d.idt_md_country,
              label: d.name,
              phone: "+" + d.phonecode,
            };
            if (d.name === "Indonesia") {
              setSelectedOption(map);
              setState((prevState) => ({
                ...prevState,
                ["msisdn"]: "+" + d.phonecode,
              }));
            }
            return map;
          });
          setState((prevState) => ({
            ...prevState,
            ["country"]: maps,
          }));
        } else {
          console.log(res.data.success);
        }
      })
      .catch((error) => {
        console.log("There was an error!", error);
      });
  }, []);

  // Set Data
  const [selectedOption, setSelectedOption] = useState();
  const handleSelect = (data) => {
    setSelectedOption(data);
    setState((prevState) => ({
      ...prevState,
      ["msisdn"]: data.phone,
    }));
  };
  const [state, setState] = useState({
    email: "",
    password: "",
    confirm_password: "",
    firstname: "",
    lastname: "",
    dob: "",
    country: "",
    msisdn: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const togglePassword = () => {
    if (hidePassword === "password") {
      setHidePassword("text");
      return;
    }
    setHidePassword("password");
  };
  const togglePassword2 = () => {
    if (hidePassword2 === "password") {
      setHidePassword2("text");
      return;
    }
    setHidePassword2("password");
  };
  const customStyles = {
    control: (base) => ({
      ...base,
      height: 37,
      borderRadius: 4,
      minHeight: 37,
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      padding: 0,
      margin: -2,
    }),
  };

  return (
    <div className="auth-page-content pb-0">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="mt-sm-4 mb-sm-2 text-white-50">
              <div>
                <Link to="/register">
                  <img
                    src={logoImg}
                    alt=""
                    height="40"
                    className="d-none d-sm-block"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center mt-5">
          <div className="col-md-10 col-lg-10 col-xl-10">
            <div className="card login rounded-4">
              {/* <div className="card-header">
                  <h4 className="card-title mb-0">Arrow Nav Steps</h4>
                </div> */}
              <div className="card-body">
                <form
                  id="smartwizard-validation"
                  className="wizard wizard-primary border-0 mb-0"
                >
                  <ul className="nav rounded-3 d-none d-md-flex d-lg-flex">
                    <li className="nav-item">
                      <a className="nav-link">
                        <div className="num p-2">
                          <HiOutlineUser />
                          <small className="ms-2 fs-13">Account</small>
                        </div>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link">
                        <div className="num p-2">
                          <RiContactsBookLine />
                          <small className="ms-2 fs-13">Personal</small>
                        </div>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link">
                        <div className="num p-2">
                          <HiOutlineDocumentMagnifyingGlass />
                          <small className="ms-2 fs-13">Review</small>
                        </div>
                      </a>
                    </li>
                  </ul>

                  <div className="tab-content">
                    <div id="step-1" className="tab-pane" role="tabpanel">
                      <div className="row">
                        <div className="col-sm-12 col-md-5 mx-auto">
                          <div className="mt-2 mb-4">
                            <h5 className="text-center d-block d-sm-none">
                              Account
                            </h5>
                          </div>
                          <div className="mb-3 error-placeholder">
                            <label className="form-label text-light">
                              Email
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              name="email"
                              type="text"
                              value={state.email}
                              className="form-control required email"
                              onChange={handleChange}
                            />
                          </div>
                          <div className="mb-3 error-placeholder">
                            <label className="form-label text-light">
                              Password
                              <span className="text-danger">*</span>
                            </label>
                            <div className="position-relative auth-pass-inputgroup">
                              <input
                                name="password"
                                type={hidePassword}
                                value={state.password}
                                className="form-control pe-5 required"
                                onChange={handleChange}
                              />
                              <a
                                className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                                onClick={togglePassword}
                              >
                                {hidePassword === "password" ? (
                                  <RiEyeFill size={20} />
                                ) : (
                                  <RiEyeOffFill size={20} />
                                )}
                              </a>
                            </div>
                          </div>
                          <div className="mb-0 error-placeholder">
                            <label className="form-label text-light">
                              Confirm Password
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              name="confirm_password"
                              type="password"
                              value={state.confirm_password}
                              className="form-control required"
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="step-2" className="tab-pane" role="tabpanel">
                      <div className="row">
                        <div className="col-sm-12 col-md-10 mx-auto">
                          <div className="mt-2 mb-4">
                            <h5 className="text-center d-block d-sm-none">
                              Personal
                            </h5>
                          </div>
                          <div className="row">
                            <div className="col-sm-12 col-md-5 mx-auto">
                              <div className="mb-3 error-placeholder">
                                <label className="form-label text-light">
                                  First name
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  name="firstname"
                                  type="text"
                                  value={state.firstname}
                                  className="form-control required"
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="mb-3 error-placeholder">
                                <label className="form-label text-light">
                                  Last name
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  name="lastname"
                                  type="text"
                                  value={state.lastname}
                                  className="form-control required"
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="mb-3 error-placeholder">
                                <label className="form-label text-light">
                                  Date of Birth
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  name="dob"
                                  type="text"
                                  className="form-control dpicker required"
                                  readOnly="readonly"
                                />
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-5 mx-auto">
                              <div className="mb-3 error-placeholder">
                                <label className="form-label text-light">
                                  Country
                                  <span className="text-danger">*</span>
                                </label>
                                <Select
                                  name="country"
                                  options={state.country}
                                  placeholder="Select Country"
                                  value={selectedOption}
                                  onChange={handleSelect}
                                  isSearchable={true}
                                  selected={selectedOption}
                                  styles={customStyles}
                                  maxMenuHeight={145}
                                  theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                      ...theme.colors,
                                      // primary25: "#fca311",
                                      // primary50: "#fca311",
                                      primary: "#fca311",
                                      // neutral80: "#000",
                                    },
                                  })}
                                />
                              </div>
                              <div className="mb-0 error-placeholder">
                                <label className="form-label text-light">
                                  Phone Number
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  name="msisdn"
                                  type="text"
                                  value={state.msisdn}
                                  className="form-control required"
                                  pattern="^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$"
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="step-3" className="tab-pane" role="tabpanel">
                      <div className="row">
                        <div className="mt-2 mb-4">
                          <h5 className="text-center d-block d-sm-none">
                            Review
                          </h5>
                        </div>
                        <div className="col-sm-12 col-md-5 mx-auto">
                          <div className="mb-3 error-placeholder">
                            <label className="form-label text-light">
                              First name
                            </label>
                            <input
                              id="wizard-firstname"
                              type="text"
                              value={state.firstname}
                              className="form-control"
                              readOnly="readonly"
                            />
                          </div>
                          <div className="mb-3 error-placeholder">
                            <label className="form-label text-light">
                              Last name
                            </label>
                            <input
                              id="wizard-lastname"
                              type="text"
                              value={state.lastname}
                              className="form-control"
                              readOnly="readonly"
                            />
                          </div>
                          <div className="mb-3 error-placeholder">
                            <label className="form-label text-light">
                              Date of Birth
                            </label>
                            <input
                              id="wizard-dob"
                              type="text"
                              className="form-control"
                              readOnly="readonly"
                            />
                          </div>
                          <div className="mb-3 error-placeholder">
                            <label className="form-label text-light">
                              Country
                            </label>
                            <input
                              id="wizard-country"
                              type="text"
                              value={selectedOption ? selectedOption.label : ""}
                              className="form-control"
                              readOnly="readonly"
                            />
                          </div>
                          <div className="mb-0 error-placeholder">
                            <label className="form-label text-light">
                              Phone Number
                            </label>
                            <input
                              id="wizard-phone"
                              type="text"
                              value={state.msisdn}
                              className="form-control"
                              readOnly="readonly"
                            />
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-5 mx-auto">
                          <div className="mb-3 error-placeholder">
                            <label className="form-label text-light">
                              Email
                            </label>
                            <input
                              id="wizard-email"
                              type="text"
                              value={state.email}
                              className="form-control"
                              readOnly="readonly"
                            />
                          </div>
                          <div className="mb-3 error-placeholder">
                            <label className="form-label text-light">
                              Password
                            </label>
                            <div className="position-relative auth-pass-inputgroup">
                              <input
                                id="wizard-password"
                                type={hidePassword2}
                                value={state.password}
                                className="form-control pe-5"
                                readOnly="readonly"
                              />
                              <a
                                className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                                onClick={togglePassword2}
                              >
                                {hidePassword2 === "password" ? (
                                  <RiEyeFill size={20} />
                                ) : (
                                  <RiEyeOffFill size={20} />
                                )}
                              </a>
                            </div>
                          </div>
                          <div className="mt-3 mb-3 error-placeholder">
                            <label className="form-check">
                              <input
                                type="checkbox"
                                name="wizard-aggrement"
                                className="form-check-input form-check-custom required"
                              />
                              <span className="form-check-label text-light">
                                I agree with Terms and Conditions
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="step-arrow-nav mb-4">
                      <ul
                        className="nav nav-pills custom-nav nav-justified"
                        role="tablist"
                      >
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link active"
                            id="steparrow-gen-info-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#steparrow-gen-info"
                            type="button"
                            role="tab"
                            aria-controls="steparrow-gen-info"
                            aria-selected="true"
                            data-position="0"
                          >
                            General
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link"
                            id="steparrow-description-info-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#steparrow-description-info"
                            type="button"
                            role="tab"
                            aria-controls="steparrow-description-info"
                            aria-selected="false"
                            data-position="1"
                            tabindex="-1"
                          >
                            Description
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link"
                            id="pills-experience-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-experience"
                            type="button"
                            role="tab"
                            aria-controls="pills-experience"
                            aria-selected="false"
                            data-position="2"
                            tabindex="-1"
                          >
                            Finish
                          </button>
                        </li>
                      </ul>
                    </div>

                    <div className="tab-content">
                      <div
                        className="tab-pane fade active show"
                        id="steparrow-gen-info"
                        role="tabpanel"
                        aria-labelledby="steparrow-gen-info-tab"
                      >
                        <div>
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label
                                  className="form-label"
                                  for="steparrow-gen-info-email-input"
                                >
                                  Email
                                </label>
                                <input
                                  type="email"
                                  className="form-control"
                                  id="steparrow-gen-info-email-input"
                                  placeholder="Enter email"
                                  required=""
                                />
                                <div className="invalid-feedback">
                                  Please enter an email address
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label
                                  className="form-label"
                                  for="steparrow-gen-info-username-input"
                                >
                                  User Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="steparrow-gen-info-username-input"
                                  placeholder="Enter user name"
                                  required=""
                                />
                                <div className="invalid-feedback">
                                  Please enter a user name
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="mb-3">
                            <label
                              className="form-label"
                              for="steparrow-gen-info-password-input"
                            >
                              Password
                            </label>
                            <input
                              type="password"
                              className="form-control"
                              id="steparrow-gen-info-password-input"
                              placeholder="Enter password"
                              required=""
                            />
                            <div className="invalid-feedback">
                              Please enter a password
                            </div>
                          </div>
                          <div>
                            <label
                              className="form-label"
                              for="steparrow-gen-info-confirm-password-input"
                            >
                              Confirm Password
                            </label>
                            <input
                              type="password"
                              className="form-control"
                              id="steparrow-gen-info-confirm-password-input"
                              placeholder="Enter confirm password"
                              required=""
                            />
                            <div className="invalid-feedback">
                              Please enter a confirm password
                            </div>
                          </div>
                        </div>
                        <div className="d-flex align-items-start gap-3 mt-4">
                          <button
                            type="button"
                            className="btn btn-success btn-label right ms-auto nexttab nexttab"
                            data-nexttab="steparrow-description-info-tab"
                          >
                            <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                            Go to more info
                          </button>
                        </div>
                      </div>

                      <div
                        className="tab-pane fade"
                        id="steparrow-description-info"
                        role="tabpanel"
                        aria-labelledby="steparrow-description-info-tab"
                      >
                        <div>
                          <div className="mb-3">
                            <label for="formFile" className="form-label">
                              Upload Image
                            </label>
                            <input
                              className="form-control"
                              type="file"
                              id="formFile"
                            />
                          </div>
                          <div>
                            <label
                              className="form-label"
                              for="des-info-description-input"
                            >
                              Description
                            </label>
                            <textarea
                              className="form-control"
                              placeholder="Enter Description"
                              id="des-info-description-input"
                              rows="3"
                              required=""
                            ></textarea>
                            <div className="invalid-feedback">
                              Please enter a description
                            </div>
                          </div>
                        </div>
                        <div className="d-flex align-items-start gap-3 mt-4">
                          <button
                            type="button"
                            className="btn btn-light btn-label previestab"
                            data-previous="steparrow-gen-info-tab"
                          >
                            <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>{" "}
                            Back to General
                          </button>
                          <button
                            type="button"
                            className="btn btn-success btn-label right ms-auto nexttab nexttab"
                            data-nexttab="pills-experience-tab"
                          >
                            <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                            Submit
                          </button>
                        </div>
                      </div>

                      <div
                        className="tab-pane fade"
                        id="pills-experience"
                        role="tabpanel"
                        aria-labelledby="pills-experience-tab"
                      >
                        <div className="text-center">
                          <div className="avatar-md mt-5 mb-4 mx-auto">
                            <div className="avatar-title bg-light text-success display-4 rounded-circle">
                              <i className="ri-checkbox-circle-fill"></i>
                            </div>
                          </div>
                          <h5>Well Done !</h5>
                          <p className="text-muted">
                            You have Successfully Signed Up
                          </p>
                        </div>
                      </div>
                    </div> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterFree;
