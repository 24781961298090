import React from "react";
import bookComp from "../assets/images/book-comp.png";
import image_knowledge from "../assets/images/knowledge.png";
import { LuBookCopy } from "react-icons/lu";
import { format_number } from "../utilities/ext-function";
import { BiWorld } from "react-icons/bi";

const CardKnowledge = ({ item, onClick }) => {
  const getPrice = (card) => {
    if (card.idt_knowledge !== undefined) {
      if (card.knowledge_public.length > 0) {
        if (card.knowledge_public[0].pricing_scheme === "paid") {
          return "Rp. " + format_number(card.knowledge_public[0].price_sell);
        } else if (card.knowledge_public[0].pricing_scheme === "rent") {
          return "Rp. " + format_number(card.knowledge_public[0].price_rent);
        } else {
          return "Free";
        }
      } else {
        return "Free";
      }
    } else {
      return `${card.knowledge_collection_map.length} Knowledge`;
    }
  };

  return (
    <div
      onClick={() => onClick(item)}
      className="card card-list shadow-lg cursor-pointer"
    >
      <div className="card-body">
        <div className="text-start">
          <div className="mb-2">
            <div className="square-container">
              <img
                src={
                  item.idt_knowledge !== undefined
                    ? item.knowledge_cover_pic.length > 0
                      ? item.knowledge_cover_pic[0].pathurl
                      : image_knowledge
                    : bookComp
                }
                className="rounded-3"
                alt="knowledge"
              />
            </div>
          </div>
          <div style={{ height: "36px" }}>
            <h6 className="fs-12 fw-bold ellipsis-2-line knowledge-name">
              {item.title !== undefined ? item.title : item.name}
            </h6>
          </div>
          <p className="text-start fs-11 knowledge-name mb-0">
            {item.type !== undefined ? (
              <>
                <BiWorld
                  size={11}
                  className="me-1"
                  style={{ marginTop: "-3px" }}
                />
                Public
              </>
            ) : (
              <>
                <LuBookCopy
                  size={11}
                  className="me-1"
                  style={{ marginTop: "-3px" }}
                />
                Collection
              </>
            )}
          </p>
          <p className="text-start fw-bold fs-12 knowledge-name mb-0">
            {getPrice(item)}
          </p>
          <p className="text-start fs-12 knowledge-name mb-0">
            {item.author !== undefined ? item.author : "Multiple Publisher"}
          </p>
          <p className="text-start fs-12 knowledge-name mb-0">
            {item.knowledge_publisher !== undefined
              ? item.knowledge_publisher.name
              : null}
          </p>
        </div>
        <div className="panel-button d-flex">
          <div className="flex-grow-1 h-0">
            <button
              type="button"
              className="btn avatar-xs mt-n1 p-0 favourite-btn"
              style={{
                position: "absolute",
                top: "6%",
                left: "12%",
              }}
            >
              <span className="avatar-title bg-transparent fs-30">
                <i className="ri-bookmark-fill"></i>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardKnowledge;
