import React from "react";
import PanelChat from "./component/panelChat";
import { ToastContainer } from "react-toastify";

const Chat = () => {
  return (
    <div className="page-content">
      <ToastContainer toastClassName="toaster" />
      <div className="container-fluid">
        <PanelChat />
      </div>
    </div>
  );
};

export default Chat;
