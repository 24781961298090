import React, { useState, useEffect, useContext } from "react";
import FilterOptions from "./filterOptions";
import CardList from "./cardList";
import Pagination from "./pagination";
import { API_URL } from "../../../utilities/url";
import storage from "../../../services/storage";
import axios from "axios";
import { SessionContext } from "../../../provider/sessionContext";
import TableList from "./tableList";

const MenuTabs = () => {
  const { listView, setListView } = useContext(SessionContext);
  const [activeTab, setActiveTab] = useState("knowledge");
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [cardsPerPage, setCardsPerPage] = useState(25);
  const [totalData, setTotalData] = useState(null);
  const [cardsData, setCardsData] = useState([]);
  const [reloadData, setReloadData] = useState(false);
  const [searchValue, setSearchValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const optionsSort = [
    {
      value: "a-asc",
      label: "Alphabetical A-Z",
    },
    {
      value: "a-desc",
      label: "Alphabetical Z-A",
    },
    {
      value: "l-desc",
      label: "Last Created",
    },
  ];
  const [selectedSorting, setSelectedSorting] = useState(() => {
    const sorted = storage.get("sortedData");
    return sorted ? sorted : optionsSort[0];
  });

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    if (selectedCategory) {
      fetchSubcategories(selectedCategory);
    } else {
      setSubcategories([]);
    }
  }, [selectedCategory]);

  useEffect(() => {
    let timeoutId;
    setLoading(true);
    if (searchValue !== "") {
      timeoutId = setTimeout(() => {
        fetchData();
      }, 500);
    } else {
      fetchData();
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [
    activeTab,
    currentPage,
    cardsPerPage,
    reloadData,
    searchValue,
    selectedSorting,
    selectedCategory,
    selectedSubcategory,
  ]);

  const fetchCategories = async () => {
    axios
      .get(API_URL + "get-knowledge-category", {
        headers: {
          Authorization: `Bearer ${storage.get("access_token")}`,
        },
      })
      .then((response) => {
        if (response.data.success === true) {
          const maps = response.data.data.map(function (d) {
            const map = {
              value: d.idt_knowledge_category,
              label: d.name,
            };
            return map;
          });
          setCategories(maps);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchSubcategories = async (category) => {
    axios
      .get(`${API_URL}get-knowledge-subcategory?category=${category.value}`, {
        headers: {
          Authorization: `Bearer ${storage.get("access_token")}`,
        },
      })
      .then((response) => {
        if (response.data.success === true) {
          const maps = response.data.data.map(function (d) {
            const map = {
              value: d.idt_knowledge_category,
              label: d.name,
            };
            return map;
          });
          setSubcategories(maps);
        }
      })
      .catch((error) => {});
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      let apiUrl = API_URL;
      // Menambahkan parameter filter berdasarkan tab yang aktif
      switch (activeTab) {
        case "knowledge":
          apiUrl += "get-knowledge-public-all";
          break;
        case "collection":
          apiUrl += "get-collection-public";
          break;
        case "authorize":
          apiUrl += "get-knowledge-public-all";
          break;
        case "popular":
          apiUrl += "get-knowledge-public-all";
          break;
        default:
          apiUrl += "get-knowledge-public-all";
      }
      const params = {
        categories: selectedCategory ? selectedCategory : "all",
        subcategories:
          selectedSubcategory.length > 0
            ? selectedSubcategory.map((item) => item.value)
            : "all",
        title: searchValue,
        sort: selectedSorting.value,
        status: "all",
        page: currentPage,
        limit: cardsPerPage,
      };
      const response = await axios.post(apiUrl, params, {
        headers: {
          Authorization: `Bearer ${storage.get("access_token")}`,
        },
      });
      const data = await response.data.data.data;
      const meta = await response.data.data.meta;
      setCardsData(data);
      setTotalData(meta.total_items);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
    setLoading(false);
  };

  const handleCategoryChange = (category) => {
    if (selectedCategory !== category) {
      setSelectedCategory(category);
      setSelectedSubcategory([]);
    }
  };

  const handleSubcategoryChange = (subcategory) => {
    setSelectedSubcategory(subcategory);
  };

  const handleCardsPerPageChange = (cardsPerPage) => {
    setCardsPerPage(cardsPerPage);
    setCurrentPage(1); // Reset halaman saat mengubah jumlah data per halaman
  };

  const handleTabChange = (tabId) => {
    setTotalData(null);
    setCurrentPage(1);
    setActiveTab(tabId);
  };

  const handleSorting = (data) => {
    setSelectedSorting(data);
    storage.setUnlimited("sortedData", data);
  };

  const handleSearchChange = (data) => {
    setSearchValue(data);
  };

  const handleSubmitFilter = () => {
    fetchData();
  };

  const handleResetFilter = () => {
    setSelectedCategory("");
    setSearchValue(null);
    setSelectedSubcategory([]);
    setReloadData((prevState) => !prevState);
  };

  const handleListView = (data) => {
    setListView(data);
  };

  return (
    <div>
      {/* Komponen FilterOptions untuk select box category, subcategory, dan show items per page */}
      <FilterOptions
        cardsPerPage={cardsPerPage}
        activeTab={activeTab}
        totalData={totalData}
        categories={categories}
        subcategories={subcategories}
        optionSort={optionsSort}
        selectedCategory={selectedCategory}
        selectedSubcategory={selectedSubcategory}
        selectedSorting={selectedSorting}
        searchValue={searchValue}
        selectedListView={listView}
        onSearchChange={handleSearchChange}
        onCategoryChange={handleCategoryChange}
        onSubcategoryChange={handleSubcategoryChange}
        onSorting={handleSorting}
        onListView={handleListView}
        onTabChange={handleTabChange}
        onSubmitCategory={handleSubmitFilter}
        onResetCategory={handleResetFilter}
        onCardsPerPageChange={handleCardsPerPageChange} // Mengirimkan prop untuk mengubah jumlah data per halaman
      />

      {/* Komponen CardList untuk menampilkan daftar kartu */}
      {listView === "card" ? (
        <CardList cards={cardsData} loading={loading} />
      ) : (
        <TableList cards={cardsData} loading={loading} />
      )}

      {/* Komponen Pagination untuk navigasi halaman */}
      <Pagination
        cardsPerPage={cardsPerPage}
        totalCards={totalData}
        currentPage={currentPage}
        onPageChange={setCurrentPage}
      />
    </div>
  );
};

export default MenuTabs;
