import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import storage from "../services/storage";
import { API_URL } from "../utilities/url";
import { ToastrContext } from "../provider/toastrContext";
import ai_light from "../assets/images/r-light.gif";
import ai_dark from "../assets/images/r-dark.gif";
import { ThemeContext } from "../provider/themeContext";

const ModalArchive = ({ onArchive, handleClose, item, show }) => {
  const { showError } = useContext(ToastrContext);
  const { theme } = useContext(ThemeContext);
  const [loading, setLoading] = useState(0);

  useEffect(() => {
    if (show) {
      document.body.classList.add("modal-open-custom");
    } else {
      document.body.classList.remove("modal-open-custom");
    }

    // Clean up effect when component is unmounted
    return () => {
      document.body.classList.remove("modal-open-custom");
    };
  }, [show]);

  const handleArchieve = () => {
    setLoading(true);
    if (item.idt_knowledge !== undefined) {
      axios
        .post(
          API_URL + "add-archive",
          { idt_knowledge: item.idt_knowledge },
          {
            headers: {
              Authorization: `Bearer ${storage.get("access_token")}`,
            },
          }
        )
        .then((response) => {
          if (response.data.success === true) {
            onArchive(item.idt_knowledge, "knowledge", response.data.message);
          } else {
            showError(response.data.message);
          }
          setLoading(false);
          handleClose();
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } else {
      axios
        .post(
          API_URL + "add-archive",
          { idt_collection: item.idt_knowledge_collection },
          {
            headers: {
              Authorization: `Bearer ${storage.get("access_token")}`,
            },
          }
        )
        .then((response) => {
          if (response.data.success === true) {
            onArchive(
              item.idt_knowledge_collection,
              "collection",
              response.data.message
            );
          } else {
            showError(response.data.message);
          }
          setLoading(false);
          handleClose();
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    }
  };

  return (
    <>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        backdrop="static"
        dialogClassName="fade"
        onHide={handleClose}
      >
        <Modal.Header className="p-3 bg-danger-subtle" closeButton />
        <Modal.Body className="bg-ai">
          <div className="mt-2 text-center">
            <img src={theme === "light" ? ai_dark : ai_light} />
            {/* <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ width: 80, height: 80 }}
            ></lord-icon> */}
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h5>
                {item.idt_knowledge !== undefined ? item.title : item.name}
              </h5>
              <p className="text-muted mx-4 mb-0">
                Are you sure to archive{" "}
                {item.idt_knowledge !== undefined ? "knowledge" : "collection"}{" "}
                ?
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <Button className="btn w-sm" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              className="btn w-sm"
              variant="info"
              onClick={handleArchieve}
              disabled={loading}
            >
              {loading ? (
                <>
                  <Spinner
                    className="flex-shrink-0"
                    animation="border"
                    size="sm"
                  />
                </>
              ) : (
                "Yes, Archive It!"
              )}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalArchive;
