import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useLanguage } from "../../provider/languageContext";
import { CartContext } from "../../provider/cartContext";
import CartList from "./component/cartList";
import { useForm } from "react-hook-form";
import storage from "../../services/storage";
import axios from "axios";
import { API_URL } from "../../utilities/url";
import CryptoJS from "crypto-js";

const Cart = () => {
  const { messagesLang } = useLanguage();
  const navigate = useNavigate();
  const {
    cartItems,
    selectedItemIds,
    promoCode,
    setPromoCode,
    totalPrice,
    separatePrice,
  } = useContext(CartContext);
  const [isDisabled, setIsDisabled] = useState(false);
  const [textCode, setTextCode] = useState("");

  useEffect(() => {
    if (promoCode.length > 0) {
      setIsDisabled(true);
      setTextCode(promoCode[0].name);
    }
    if (cartItems.length < 0) {
      storage.setUnlimited("selectedItemIds", []);
    }
  }, []);

  /* Form Submit */
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm();
  const onSubmit = (data) => {
    if (data.promo === "OTOBACA24") {
      clearErrors("promo");
      setPromoCode([
        {
          name: data.promo,
          value: 10000,
          percent: null,
        },
      ]);
      setIsDisabled(true);
    } else {
      if (data.promo.trim() !== "") {
        setError("promo", {
          type: "manual",
          message: "Invalid promo code.",
        });
      }
    }
  };

  const handleChange = (event) => {
    setTextCode(event.target.value);
  };

  const cancelPromo = (e) => {
    e.preventDefault();
    reset();
    setPromoCode([]);
    setIsDisabled(false);
  };

  const handleCheckout = () => {
    axios
      .post(
        API_URL + "get-knowledge-cart",
        {
          id: selectedItemIds,
          page: 1,
          limit: 50,
        },
        {
          headers: {
            Authorization: `Bearer ${storage.get("access_token")}`,
          },
        }
      )
      .then((response) => {
        if (response.data.success === true) {
          storage.setUnlimited(
            "cd",
            CryptoJS.AES.encrypt(
              JSON.stringify(response.data.data.data),
              "checkout"
            ).toString()
          );
          navigate("/checkout");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="page-content">
      <div className="container-fluid">
        <div className="row mb-3">
          <div className="col-xl-8">
            <div className="row align-items-center gy-3 mb-3">
              <div className="col-sm">
                <div>
                  <h5 className="fs-14 mb-0">{messagesLang.my_cart}</h5>
                </div>
              </div>
              <div className="col-sm-auto">
                <Link
                  to="/knowledge"
                  className="link-orange fw-bold text-decoration-underline"
                >
                  {messagesLang.continue_shopping}
                </Link>
              </div>
            </div>

            <CartList />
          </div>

          <div className="col-xl-4">
            <div className="sticky-side-div">
              <div className="card">
                <div className="card-header border-bottom-dashed">
                  <h5 className="card-title mb-0">
                    {messagesLang.order_summary}
                  </h5>
                </div>
                {cartItems.length > 0 ? (
                  totalPrice > 0 ? (
                    <div className="card-header bg-light-subtle border-bottom-dashed">
                      <div className="text-center">
                        <h6 className="mb-2">
                          <span className="fw-semibold">
                            {messagesLang.have_promo_code}
                          </span>
                        </h6>
                      </div>
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="hstack gap-3 px-3 mx-n3">
                          <input
                            className="form-control me-auto"
                            name="promo"
                            {...register("promo")}
                            type="text"
                            value={textCode}
                            onChange={handleChange}
                            placeholder={messagesLang.enter_coupon}
                            disabled={isDisabled}
                          />
                          {promoCode.length > 0 ? (
                            <button
                              type="button"
                              onClick={cancelPromo}
                              className="btn btn-danger w-xs"
                            >
                              {messagesLang.cancel}
                            </button>
                          ) : (
                            <button
                              type="submit"
                              className="btn btn-orange w-xs"
                            >
                              Apply
                            </button>
                          )}
                        </div>
                        {errors.promo && errors.promo.type === "manual" && (
                          <p className="errorMsg text-danger fs-7">
                            {errors.promo.message}
                          </p>
                        )}
                      </form>
                    </div>
                  ) : null
                ) : null}
                <div className="card-body pt-2">
                  <div className="table-responsive">
                    <table className="table table-borderless mb-0">
                      <tbody>
                        <tr>
                          <td>Sub Total :</td>
                          <td className="text-end" id="cart-subtotal">
                            {separatePrice(totalPrice)}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {messagesLang.discount}{" "}
                            <span className="text-muted">(OTOBACA24)</span> :{" "}
                          </td>
                          <td className="text-end" id="cart-discount">
                            - 0
                          </td>
                        </tr>
                        <tr>
                          <td>{messagesLang.tax} (5%) : </td>
                          <td className="text-end" id="cart-tax">
                            {separatePrice(totalPrice * 0.05)}
                          </td>
                        </tr>
                        <tr className="table-active">
                          <th>Total (IDR) :</th>
                          <td className="text-end">
                            <span className="fw-semibold" id="cart-total">
                              {separatePrice(totalPrice + totalPrice * 0.05)}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="text-start mt-3">
                    {selectedItemIds.length > 0 ? (
                      <button
                        onClick={() => handleCheckout()}
                        className="btn btn-success btn-label right ms-auto w-100"
                      >
                        <i className="ri-arrow-right-line label-icon align-bottom fs-16 ms-2"></i>{" "}
                        Checkout
                      </button>
                    ) : null}
                    {/* {cartItems.length > 0 ? (
                      totalPrice > 0 ? (
                        <Link
                          to="/checkout"
                          className="btn btn-success btn-label right ms-auto"
                        >
                          <i className="ri-arrow-right-line label-icon align-bottom fs-16 ms-2"></i>{" "}
                          Checkout
                        </Link>
                      ) : (
                        <Link
                          to="/finish-order"
                          className="btn btn-success btn-label right ms-auto"
                        >
                          <i className="ri-arrow-right-line label-icon align-bottom fs-16 ms-2"></i>{" "}
                          Checkout
                        </Link>
                      )
                    ) : null} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cart;
