class Storage {
  set(key, value, ttl) {
    const now = new Date();
    const item = {
      value: value,
      expiry: now.getTime() + ttl,
    };
    localStorage.setItem(key, JSON.stringify(item));
  }
  setUnlimited(key, value) {
    const item = {
      value: value,
    };
    localStorage.setItem(key, JSON.stringify(item));
  }
  get(key) {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) {
      localStorage.removeItem(key);
      return null;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();
    if (item.expiry) {
      if (now.getTime() > item.expiry * 60000) {
        localStorage.removeItem(key);
        return null;
      }
    }
    return item.value;
  }
  remove(key) {
    localStorage.removeItem(key);
  }
}

export default new Storage();
