import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { NavLink, useNavigate } from "react-router-dom";
import moment from "moment";
import { useGoogleLogin } from "@react-oauth/google";
import { RiEyeFill, RiEyeOffFill } from "react-icons/ri";
import { HiOutlineMail } from "react-icons/hi";
import { PiLockKeyFill } from "react-icons/pi";
import axios from "axios";
import logoImg from "../assets/images/logo-dark.png";
import { API_URL } from "../utilities/url";
import authHeader from "../services/auth.header";
import storage from "../services/storage";
import CryptoJS from "crypto-js";
import { useLanguage } from "../provider/languageContext";
import HeaderLanguage from "./component/headerLanguage";

const Login = () => {
  const { messagesLang } = useLanguage();
  const year = moment().format("YYYY");
  const [alertMessage, setAlertMessage] = useState("");
  const [hideAlert, setHideAlert] = useState(true);
  const [hideSpiner, setHideSpiner] = useState(true);
  const [hidePassword, setHidePassword] = useState("password");

  /* Form Submit */
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    reValidateMode: "onBlur",
  });
  const onSubmit = (data) => {
    axios
      .post(API_URL + "login", data)
      .then((response) => {
        if (response.data.success === true) {
          const res = response.data.data;
          storage.set(
            "user",
            CryptoJS.AES.encrypt(JSON.stringify(res.user), "user").toString()
          );
          storage.set("access_token", res.access_token);
          window.location.replace("home");
        } else if (response.data.message == "Email hasn't been verified") {
          setAlertMessage(
            "Email hasn't been verified, Please check email to verify."
          );
          setHideAlert(false);
        } else {
          setAlertMessage("Incorrect email or password.");
          setHideAlert(false);
          setHideSpiner(true);
        }
      })
      .catch((error) => {
        setHideSpiner(true);
      });
  };
  const loginGoogle = useGoogleLogin({
    onSuccess: async (response) => {
      await axios
        .get("https://www.googleapis.com/oauth2/v3/userinfo", {
          headers: {
            Authorization: `${response.token_type} ${response.access_token}`,
          },
        })
        .then((response) => {
          /* Post to API */
          axios
            .post(
              API_URL + "login-with-google",
              { email: response.data.email },
              { headers: authHeader() }
            )
            .then((response) => {
              if (response.data.success === true) {
                const res = response.data.data;
                storage.set(
                  "user",
                  CryptoJS.AES.encrypt(
                    JSON.stringify(res.user),
                    "user"
                  ).toString()
                );
                storage.set("access_token", res.access_token);
                window.location.replace("home");
              } else {
                setAlertMessage("Email account not registered.");
                setHideAlert(false);
              }
            })
            .catch((error) => {
              console.log("There was an error!", error);
            });
        })
        .catch((error) => {
          console.log("There was an error!", error);
        });
    },
  });

  /* Function */
  const togglePassword = () => {
    if (hidePassword === "password") {
      setHidePassword("text");
      return;
    }
    setHidePassword("password");
  };
  return (
    <div className="auth-page-content pb-0">
      <div className="container">
        <div className="row">
          <div className="d-flex justify-content-between mt-sm-4 mb-sm-2 text-white-50">
            <img
              src={logoImg}
              alt=""
              height="40"
              className="d-none d-sm-block"
            />
            <div className="head-lang">
              <HeaderLanguage />
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-8 col-lg-6 col-xl-5">
            <div className="centered-container">
              <div className="card login rounded-4 mt-4">
                <div className="card-body p-4">
                  <div className="text-center mt-2">
                    <h5 className="text-white">{messagesLang.sign_in_app}</h5>
                  </div>
                  <div className="p-2 mt-4">
                    <div
                      className={`alert alert-danger alert-dismissible fade show ${
                        hideAlert ? "d-none" : "d-block"
                      }`}
                      role="alert"
                    >
                      <button
                        onClick={() => setHideAlert(true)}
                        type="button"
                        className="btn-close"
                        aria-label="Close"
                      ></button>
                      <div className="alert-message">
                        <strong className="text-danger">{alertMessage}</strong>
                      </div>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="mb-3">
                        <label className="form-label text-light">Email</label>
                        <div className="position-relative auth-pass-inputgroup mb-3">
                          <a className="btn btn-link position-absolute start-0 top-0 text-decoration-none text-muted">
                            <HiOutlineMail size={20} />
                          </a>
                          <input
                            type="text"
                            className="form-control ps-5"
                            name="email"
                            {...register("email", {
                              required: true,
                              pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                            })}
                          />
                          {errors.email && errors.email.type === "required" && (
                            <p className="errorMsg text-danger fs-7">
                              Email is required.
                            </p>
                          )}
                          {errors.email && errors.email.type === "pattern" && (
                            <p className="errorMsg text-danger fs-7">
                              Email is not valid.
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="mb-2">
                        <label className="form-label text-light">
                          {messagesLang.password}
                        </label>
                        <div className="position-relative auth-pass-inputgroup mb-2">
                          <a className="btn btn-link position-absolute start-0 top-0 text-decoration-none text-muted">
                            <PiLockKeyFill size={20} />
                          </a>
                          <input
                            type={hidePassword}
                            name="password"
                            autoComplete="on"
                            className="form-control ps-5 pe-5 password-input"
                            {...register("password", {
                              required: true,
                              minLength: 8,
                            })}
                          />
                          {errors.password &&
                            errors.password.type === "required" && (
                              <p className="errorMsg text-danger fs-7">
                                Password is required.
                              </p>
                            )}
                          {errors.password &&
                            errors.password.type === "minLength" && (
                              <p className="errorMsg text-danger fs-7">
                                Password min 8 character.
                              </p>
                            )}
                          <a
                            className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                            onClick={togglePassword}
                          >
                            {hidePassword === "password" ? (
                              <RiEyeFill size={20} />
                            ) : (
                              <RiEyeOffFill size={20} />
                            )}
                          </a>
                        </div>

                        <div className="float-end mb-3">
                          <NavLink to="/forgot-password" className="text-muted">
                            {messagesLang.forgot_password} ?
                          </NavLink>
                        </div>
                      </div>
                      {/* <div className="form-check">
                          <input
                            className="form-check-input form-check-custom"
                            type="checkbox"
                            value=""
                            id="auth-remember-check"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="auth-remember-check"
                          >
                            Remember me
                          </label>
                        </div> */}
                      <div className="mt-4">
                        <button className="btn btn-success w-100" type="submit">
                          {messagesLang.sign_in}
                        </button>
                      </div>
                    </form>
                    <div className="mt-2 text-center">
                      <div>
                        <a
                          onClick={() => loginGoogle()}
                          className="btn btn-danger w-100 waves-effect waves-light mb-2"
                        >
                          <i className="ri-google-fill fs-14 me-2"></i>{" "}
                          {messagesLang.sign_in_app_google}
                        </a>
                      </div>
                      <div className="mt-4 text-center">
                        <p className="mb-0 text-light">
                          {messagesLang.dont_have_account}{" "}
                          <NavLink
                            to="/register"
                            className="fw-semibold text-orange text-decoration-underline link-underline-opacity-100-hover"
                          >
                            {" "}
                            {messagesLang.create_account}{" "}
                          </NavLink>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
