import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import { Button } from "@chatscope/chat-ui-kit-react";
import { RiEyeFill, RiEyeOffFill } from "react-icons/ri";
import axios from "axios";
import { API_URL } from "../utilities/url";
import authHeader from "../services/auth.header";

const RegisterGoogle = () => {
  const year = moment().format("YYYY");
  const navigate = useNavigate();
  const [alertMessage, setAlertMessage] = useState("");
  const [hideAlert, setHideAlert] = useState(true);
  const [hideSpiner, setHideSpiner] = useState(true);
  const [hidePassword, setHidePassword] = useState("password");
  const [state, setState] = useState({
    email: "",
    password: "",
  });
  /* Form Submit */
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    reValidateMode: "onBlur",
  });
  const onSubmit = (data) => {
    axios
      .post(API_URL + "login", data)
      .then((response) => {
        if (response.data.success === true) {
          const res = response.data.data;
          localStorage.setItem("user", JSON.stringify(res.user));
          localStorage.setItem("access_token", res.access_token);
          window.location.replace("chat");
        } else {
          setAlertMessage("Incorrect email or password.");
          setHideAlert(false);
          setHideSpiner(true);
        }
      })
      .catch((error) => {
        setHideSpiner(true);
      });
    // console.log(data);
    // localStorage.setItem("user", "d");
    // localStorage.setItem("access_token", "ddd");
    // window.location.replace("chat");
  };
  const loginGoogle = useGoogleLogin({
    onSuccess: async (response) => {
      await axios
        .get("https://www.googleapis.com/oauth2/v3/userinfo", {
          headers: {
            Authorization: `${response.token_type} ${response.access_token}`,
          },
        })
        .then((response) => {
          /* Post to API */
          axios
            .post(
              API_URL + "login-with-google",
              { email: response.data.email },
              { headers: authHeader() }
            )
            .then((response) => {
              if (response.data.success === true) {
                const res = response.data.data;
                localStorage.setItem("user", JSON.stringify(res.user));
                localStorage.setItem("access_token", res.access_token);
                window.location.replace("chat");
              } else {
                setAlertMessage("Email account not registered.");
                setHideAlert(false);
              }
            })
            .catch((error) => {
              console.log("There was an error!", error);
            });
          // localStorage.setItem("access_token", response.access_token);
          // window.location.replace("chat");
          // navigate("/chat");
        })
        .catch((error) => {
          console.log("There was an error!", error);
        });
    },
  });
  const registerGoogle = useGoogleLogin({
    onSuccess: async (response) => {
      await axios
        .get("https://www.googleapis.com/oauth2/v3/userinfo", {
          headers: {
            Authorization: `${response.token_type} ${response.access_token}`,
          },
        })
        .then((res) => {
          console.log(res);
          alert("register");
          // localStorage.setItem("user", JSON.stringify(res.user));
          // localStorage.setItem("access_token", response.access_token);
          // navigate("/chat");
        })
        .catch((error) => {
          console.log("There was an error!", error);
        });
    },
  });

  /* Function */
  const togglePassword = () => {
    if (hidePassword === "password") {
      setHidePassword("text");
      return;
    }
    setHidePassword("password");
  };
  return (
    <div>
      <div className="auth-page-wrapper">
        <div className="auth-one-bg-position auth-one-bg" id="auth-particles">
          <div className="bg-overlay"></div>
          <div className="shape">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              viewBox="0 0 1440 120"
            >
              <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z"></path>
            </svg>
          </div>
        </div>
        <div className="auth-page-content pb-0">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8 col-lg-6 col-xl-5">
                <div className="card mt-5">
                  <div className="card-body p-4">
                    <div className="text-center mt-2">
                      <h5 className="text-primary">Register Otobaca.</h5>
                    </div>
                    <div className="p-2 mt-4">
                      <div
                        className={`alert alert-danger alert-dismissible fade show ${
                          hideAlert ? "d-none" : "d-block"
                        }`}
                        role="alert"
                      >
                        <button
                          onClick={() => setHideAlert(true)}
                          type="button"
                          className="btn-close"
                          aria-label="Close"
                        ></button>
                        <div className="alert-message">
                          <strong className="text-danger">
                            {alertMessage}
                          </strong>
                        </div>
                      </div>
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="mb-3">
                          <label htmlFor="username" className="form-label">
                            Email
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="email"
                            {...register("email", {
                              required: true,
                              pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                            })}
                          />
                          {errors.email && errors.email.type === "required" && (
                            <p className="errorMsg text-danger fs-7">
                              Email is required.
                            </p>
                          )}
                          {errors.email && errors.email.type === "pattern" && (
                            <p className="errorMsg text-danger fs-7">
                              Email is not valid.
                            </p>
                          )}
                        </div>

                        <div className="mb-3">
                          <div className="float-end">
                            <a href="#" className="text-muted">
                              Forgot password?
                            </a>
                          </div>
                          <label
                            className="form-label"
                            htmlFor="password-input"
                          >
                            Password
                          </label>
                          <div className="position-relative auth-pass-inputgroup mb-3">
                            <input
                              type={hidePassword}
                              name="email"
                              className="form-control pe-5 password-input"
                              {...register("password", {
                                required: true,
                                minLength: 6,
                              })}
                            />
                            {errors.password &&
                              errors.password.type === "required" && (
                                <p className="errorMsg text-danger fs-7">
                                  Password is required.
                                </p>
                              )}
                            {errors.password &&
                              errors.password.type === "minLength" && (
                                <p className="errorMsg text-danger fs-7">
                                  Password min 6 character.
                                </p>
                              )}
                            <a
                              className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                              href="#"
                              onClick={togglePassword}
                            >
                              {hidePassword === "password" ? (
                                <RiEyeFill size={20} />
                              ) : (
                                <RiEyeOffFill size={20} />
                              )}
                              {/* <i className="ri-eye-fill align-middle"></i> */}
                            </a>
                          </div>
                        </div>
                        {/* <div className="form-check">
                          <input
                            className="form-check-input form-check-custom"
                            type="checkbox"
                            value=""
                            id="auth-remember-check"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="auth-remember-check"
                          >
                            Remember me
                          </label>
                        </div> */}
                        <div className="mt-4">
                          <button
                            className="btn btn-success w-100"
                            type="submit"
                          >
                            Sign In
                          </button>
                        </div>
                      </form>
                      <div className="mt-2 text-center">
                        {/* <div className="signin-other-title">
                          <h5 className="fs-13 mb-2 title">Sign In with</h5>
                        </div> */}
                        <div>
                          {/* <GoogleLogin
                            onSuccess={(credentialResponse) => {
                              var credentialResponseDecode = jwtDecode(
                                credentialResponse.credential
                              );
                              console.log(credentialResponseDecode);
                            }}
                            onError={() => {
                              console.log("Login Failed");
                            }}
                          /> */}
                          <a
                            onClick={() => loginGoogle()}
                            className="btn btn-danger w-100 waves-effect waves-light mb-2"
                          >
                            <i className="ri-google-fill fs-14 me-2"></i> Sign
                            In with Google
                          </a>
                          <a
                            onClick={() => registerGoogle()}
                            className="btn btn-primary w-100 waves-effect waves-light"
                          >
                            <i className="ri-google-fill fs-14 me-2"></i> Sign
                            Up with Google
                          </a>
                          {/* <button
                            onClick={() => loginGoogle()}
                            className="btn btn-danger btn-icon waves-effect waves-light me-2"
                          >
                            <i className="ri-google-fill fs-16"></i>
                          </button> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-4 text-center">
                  <p className="mb-0">
                    Don't have an account ?{" "}
                    <a
                      href="#"
                      className="fw-semibold text-primary text-decoration-underline"
                    >
                      {" "}
                      Signup{" "}
                    </a>{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer className="footer">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="text-center">
                  <p className="mb-0 text-muted">
                    {year} &copy; Otobaca. Powered by Otokata
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default RegisterGoogle;
