import React, { useContext, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useLanguage } from "../../provider/languageContext";
import { CartContext } from "../../provider/cartContext";
import storage from "../../services/storage";

const FinishOrder = () => {
  const { messagesLang } = useLanguage();
  const { setCartItems, selectedItemIds, setSelectedItemIds } =
    useContext(CartContext);
  const navigate = useNavigate();
  const { orderId } = useParams();

  useEffect(() => {
    setCartItems((prevItems) =>
      prevItems.filter((item) => !selectedItemIds.includes(item.id))
    );
    setSelectedItemIds([]);
    if (!storage.get("orderId")) {
      navigate("/home");
    }
    storage.remove("orderId");
  }, []);

  const handleLink = () => {
    storage.setUnlimited("orderId", orderId);
    navigate("/order-detail/" + orderId);
  };

  return (
    <div className="page-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="text-center py-5">
              <div className="mb-4">
                <lord-icon
                  src="https://cdn.lordicon.com/lupuorrc.json"
                  trigger="loop"
                  colors="primary:#f2a935,secondary:#0ab39c"
                  // colors="primary:#0ab39c,secondary:#405189"
                  style={{ width: "150px", height: "150px" }}
                ></lord-icon>
              </div>
              <h5>
                {messagesLang.thank_you} {messagesLang.your_order_is_complete}
              </h5>
              <p className="text-muted">
                {messagesLang.receive_order_confirmation}.
              </p>

              <h3 className="fw-semibold">
                Order ID:{" "}
                <a
                  onClick={() => handleLink()}
                  className="link-orange cursor-pointer text-decoration-underline"
                >
                  {orderId}
                </a>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinishOrder;
