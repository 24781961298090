import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import remarkMath from "remark-math";
import rehypeKatex from "rehype-katex";
import rehypeHighlight from "rehype-highlight";
import rehypeRaw from "rehype-raw";
import "katex/dist/katex.min.css";
import "highlight.js/styles/github.css";

const MarkdownRenderer = ({ markdown, term, isCurrent }) => {
  const highlightTerm = (text, data) => {
    if (!term) return text;
    const escapedTerm = term.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    const regex = new RegExp(`(${escapedTerm})(?![^<]*>|[^<>]*</)`, "gi");
    return text.replace(
      regex,
      (match) =>
        `<span style="background-color: ${
          isCurrent ? "#f7b84b" : "transparent"
        }; color: ${isCurrent ? "black" : "inherit"};">${match}</span>`
    );
  };

  const processMarkdown = (markdown, term, isCurrent) => {
    // Pertama, identifikasi dan simpan LaTeX dengan placeholder
    const latexPattern =
      /(\$\$[^$]*\$\$|\$[^$]*\$|\\\[([^\]]+?)\\\]|\\\(([^\)]+?)\\\]|\\text\{[^}]*\})/g;
    let placeholderIndex = 0;
    const latexMatches = [];

    // Ganti LaTeX dengan placeholder dan simpan LaTeX asli
    let processedMarkdown = markdown
      .replace(/\\\[([^\]]+?)\\\]/g, "$$$1$$") // Replace \[...\] with $...$
      .replace(/\\\(([^\)]+?)\\\)/g, "$$$1$$")
      .replace(latexPattern, (match) => {
        const placeholder = `__LA_TEX_PLACEHOLDER_${placeholderIndex}__`;
        latexMatches.push({ placeholder, match });
        placeholderIndex += 1;
        return placeholder;
      });
    // Terapkan highlighting pada bagian non-LaTeX
    processedMarkdown = highlightTerm(processedMarkdown, term, isCurrent);

    // Kembalikan placeholder dengan LaTeX asli
    latexMatches.forEach(({ placeholder, match }) => {
      processedMarkdown = processedMarkdown.replace(
        new RegExp(placeholder, "g"),
        match
      );
    });

    return processedMarkdown;
  };

  const processedMarkdown = processMarkdown(markdown);

  return (
    <ReactMarkdown
      children={processedMarkdown}
      remarkPlugins={[remarkMath, remarkGfm]}
      rehypePlugins={[rehypeRaw, rehypeKatex, rehypeHighlight]}
    />
  );
};

export default MarkdownRenderer;
