// src/DetailModal.js
import React, { useEffect, useState } from "react";
import { Button, Modal, ProgressBar, Spinner } from "react-bootstrap";
import { useLanguage } from "../provider/languageContext";
import { LuBookCopy, LuBookLock, LuBookUp } from "react-icons/lu";
import bookComp from "../assets/images/book-comp.png";
import SimpleBar from "simplebar-react";
import image_knowledge from "../assets/images/knowledge.png";
import ReviewKnowledge from "./reviewKnowledge";
import { IoMdStar } from "react-icons/io";
import { format_number } from "../utilities/ext-function";
import ReactStars from "react-rating-stars-component";

function ModalDetailCollectionPublic({
  item,
  show,
  handleClose,
  loading,
  setLoading,
  loading2,
  setLoading2,
  onHandleCollection,
  review,
  starReview,
  selectedRatings,
  onRatingChange,
  loadMoreReview,
  loadingReview,
  hasMore,
}) {
  const { messagesLang } = useLanguage();
  const [activeTab, setActiveTab] = useState("Tab1");

  const formatText = (text) => {
    return text.split("\n").map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  //   const handleView = () => {
  //     onHandleView();
  //   };

  //   const handleUpdate = (item) => {
  //     onHandleUpdate(item);
  //   };

  //   const handleArchive = (item) => {
  //     onHandleArchive(item);
  //   };

  //   const handleRemove = (item) => {
  //     onHandleRemove(item);
  //   };

  // Hitung total ulasan
  const totalReviews = starReview.reduce(
    (total, review) => total + review.count_review,
    0
  );

  // Hitung total skor
  const totalScore = starReview.reduce(
    (total, review) => total + review.rating * review.count_review,
    0
  );

  // Hitung rata-rata bintang
  const averageRating =
    totalReviews > 0 ? (totalScore / totalReviews).toFixed(1) : "0.0";

  // Buat array rating lengkap
  const allRatings = [5, 4, 3, 2, 1];

  // Hitung persentase untuk setiap rating
  const percentageData = allRatings.map((rating) => {
    const review = starReview.find((review) => review.rating === rating);
    const count = review ? review.count_review : 0;
    const percentage =
      totalReviews > 0 ? ((count / totalReviews) * 100).toFixed(1) : "0.0";
    return {
      rating,
      count,
      percentage,
    };
  });

  useEffect(() => {
    if (show) {
      document.body.classList.add("modal-open-custom");
    } else {
      document.body.classList.remove("modal-open-custom");
    }

    // Clean up effect when component is unmounted
    return () => {
      document.body.classList.remove("modal-open-custom");
    };
  }, [show]);

  const handleCollection = (data, type) => {
    if (type === "get") {
      setLoading2(true);
    } else {
      setLoading(true);
    }
    onHandleCollection(data, type);
  };

  const getPrice = (card) => {
    if (card.idt_knowledge !== undefined) {
      if (card.knowledge_public.length > 0) {
        if (card.knowledge_public[0].pricing_scheme === "paid") {
          return "Rp. " + format_number(card.knowledge_public[0].price_sell);
        } else if (card.knowledge_public[0].pricing_scheme === "rent") {
          return "Rp. " + format_number(card.knowledge_public[0].price_rent);
        } else {
          return "Free";
        }
      } else {
        return "Free";
      }
    } else {
      if (card.knowledge_collection_public.length > 0) {
        if (card.knowledge_collection_public[0].pricing_scheme === "paid") {
          return "Rp. " + format_number(card.knowledge_public[0].price_sell);
        } else if (
          card.knowledge_collection_public[0].pricing_scheme === "rent"
        ) {
          return (
            "Rp. " +
            format_number(card.knowledge_collection_public[0].price_rent)
          );
        } else {
          return "Free";
        }
      } else {
        return "Free";
      }
    }
  };

  return (
    <>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        backdrop="static"
        dialogClassName="modal-lg fade"
        onHide={handleClose}
      >
        <Modal.Header className="p-3 bg-light-dark" closeButton>
          <h5 className="modal-title">Detail Collection</h5>
        </Modal.Header>
        <Modal.Body className="knowledge overflow-hidden">
          <div className="row mb-4">
            <div className="col-md-3 col-sm-12">
              <div className="image-gallery">
                <div className="main-image d-none d-sm-block">
                  <img
                    src={item.url_coverpic ? item.url_coverpic : bookComp}
                    className="object-fit-cover"
                    alt="Main"
                  />
                </div>
                {/* {card.knowledge_cover_pic > 1 ? (
                  <div className="thumbnail-images">
                    {card.knowledge_cover_pic.map((thumbnail, index) => (
                      <div
                        key={index}
                        className={`thumbnail-wrapper object-fit-cover d-none d-sm-block ${
                          activeThumbnail === thumbnail.pathurl ? "active" : ""
                        }`}
                        onClick={() => handleThumbnailClick(thumbnail.pathurl)}
                      >
                        <img
                          src={thumbnail.pathurl}
                          alt={`Thumbnail ${index + 1}`}
                          className={
                            activeThumbnail === thumbnail.pathurl
                              ? "object-fit-cover active"
                              : "object-fit-cover"
                          }
                        />
                        <div className="thumbnail-overlay"></div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <></>
                )} */}
              </div>
              <div className="d-none d-md-block">
                <div className="pb-1">
                  <div className="bg-light px-2 py-2 rounded-2 mb-2">
                    <div className="d-flex align-items-center">
                      <div className="flex-grow-1">
                        <ReactStars
                          count={5}
                          value={averageRating}
                          size={20}
                          isHalf={true}
                          activeColor="#ffd700"
                          edit={false}
                        />
                      </div>
                      <div className="flex-shrink-0">
                        <h6 className="mb-0">{averageRating} of 5.0</h6>
                      </div>
                    </div>
                  </div>
                  <div className="text-center">
                    <div className="text-muted">
                      Total{" "}
                      <span className="fw-medium">
                        {format_number(totalReviews)}
                      </span>{" "}
                      {messagesLang.review}
                    </div>
                  </div>
                </div>
                <div className="mt-0">
                  {percentageData.map((data, index) => (
                    <div key={index} className="row align-items-center g-2">
                      <div className="col-auto">
                        <div className="ps-2">
                          <h6 className="mb-0">
                            {data.rating}{" "}
                            <IoMdStar
                              size={16}
                              style={{
                                marginTop: "-3px",
                                color: "rgb(255, 215, 0)",
                              }}
                            />
                          </h6>
                        </div>
                      </div>
                      <div className="col">
                        <div className="p-0">
                          {data.rating >= 3 && (
                            <ProgressBar
                              now={data.percentage}
                              variant="success"
                              className="progress animated-progress progress-sm"
                            />
                          )}
                          {data.rating === 2 && (
                            <ProgressBar
                              now={data.percentage}
                              variant="warning"
                              className="progress animated-progress progress-sm"
                            />
                          )}
                          {data.rating === 1 && (
                            <ProgressBar
                              now={data.percentage}
                              variant="danger"
                              className="progress animated-progress progress-sm"
                            />
                          )}
                        </div>
                      </div>
                      <div className="col-auto">
                        <div className="p-2">
                          <h6 className="mb-0 text-muted">{data.count}</h6>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-md-9 col-sm-12">
              <div className="card mb-0">
                <div className="card-header pt-0">
                  <p className="fs-16 fw-bold mb-0">{item.name}</p>
                  <p className="fs-18 fw-bold text-muted">{getPrice(item)}</p>
                  <div className="mt-3">
                    {!loading2 ? (
                      <Button
                        className="btn-primary me-2"
                        style={{ width: "130px" }}
                        onClick={() =>
                          handleCollection(item.idt_knowledge_collection, "get")
                        }
                      >
                        Get Collection
                      </Button>
                    ) : (
                      <Button
                        className="btn-primary me-2"
                        style={{ width: "130px" }}
                      >
                        <Spinner
                          className="flex-shrink-0"
                          animation="border"
                          size="sm"
                        />
                      </Button>
                    )}
                    {!loading ? (
                      <Button
                        className="btn-danger"
                        style={{ width: "130px" }}
                        onClick={() =>
                          handleCollection(
                            item.idt_knowledge_collection,
                            "cart"
                          )
                        }
                      >
                        Add to Cart
                      </Button>
                    ) : (
                      <Button className="btn-danger" style={{ width: "130px" }}>
                        <Spinner
                          className="flex-shrink-0"
                          animation="border"
                          size="sm"
                        />
                      </Button>
                    )}
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-header">
                  <ul
                    className="nav nav-tabs-custom rounded card-header-tabs border-bottom-0"
                    role="tablist"
                  >
                    <li className="nav-item">
                      <a
                        className={
                          activeTab == "Tab1"
                            ? "nav-link cursor-pointer text-center fs-14 fw-bold active"
                            : "nav-link cursor-pointer text-center fs-14 fw-bold"
                        }
                        onClick={() => setActiveTab("Tab1")}
                      >
                        <i className="fas fa-home"></i> List Collection (
                        {item.knowledge_collection_map
                          ? item.knowledge_collection_map.length
                          : 0}
                        )
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={
                          activeTab == "Tab2"
                            ? "nav-link cursor-pointer text-center fs-14 fw-bold active"
                            : "nav-link cursor-pointer text-center fs-14 fw-bold"
                        }
                        onClick={() => setActiveTab("Tab2")}
                      >
                        <i className="fas fa-home"></i>{" "}
                        {messagesLang.description}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={
                          activeTab == "Tab3"
                            ? "nav-link cursor-pointer text-center fs-14 fw-bold active"
                            : "nav-link cursor-pointer text-center fs-14 fw-bold"
                        }
                        onClick={() => setActiveTab("Tab3")}
                      >
                        <i className="far fa-user"></i> {messagesLang.review}
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="card-body p-4">
                  {activeTab === "Tab1" && (
                    <div className="form-table">
                      <SimpleBar
                        style={{
                          height: "calc(100vh - 380px)",
                          overflowX: "hidden",
                        }}
                      >
                        <div className="row">
                          {item.knowledge_collection_map
                            ? item.knowledge_collection_map.map(
                                (card, index) => (
                                  <div
                                    key={index}
                                    className="custom-coloum-3 folder-card"
                                  >
                                    <div className="card card-list bg-light shadow-lg">
                                      <div className="card-body">
                                        <div className="text-start">
                                          <div className="mb-2">
                                            <div className="square-container">
                                              <img
                                                src={
                                                  card.knowledge
                                                    .knowledge_cover_pic
                                                    .length > 0
                                                    ? card.knowledge
                                                        .knowledge_cover_pic[0]
                                                        .pathurl
                                                    : image_knowledge
                                                }
                                                className="rounded-3"
                                                alt={index}
                                              />
                                            </div>
                                          </div>
                                          <div style={{ height: "36px" }}>
                                            <h6 className="fs-12 fw-bold ellipsis-2-line knowledge-name">
                                              {card.knowledge.title !==
                                              undefined
                                                ? card.knowledge.title
                                                : card.name}
                                            </h6>
                                          </div>
                                        </div>
                                        <p className="text-start fs-11 knowledge-name mb-0">
                                          {card.knowledge.type !== undefined ? (
                                            card.knowledge.type ===
                                            "private" ? (
                                              <>
                                                <LuBookLock
                                                  size={11}
                                                  className="me-1"
                                                  style={{ marginTop: "-3px" }}
                                                />
                                                Private
                                              </>
                                            ) : (
                                              <>
                                                <LuBookUp
                                                  size={11}
                                                  className="me-1"
                                                  style={{ marginTop: "-3px" }}
                                                />
                                                Publish
                                              </>
                                            )
                                          ) : (
                                            <>
                                              <LuBookCopy
                                                size={11}
                                                className="me-1"
                                                style={{ marginTop: "-3px" }}
                                              />
                                              Collection
                                            </>
                                          )}
                                        </p>
                                        <p className="text-start fs-12 knowledge-name mb-0">
                                          {card.knowledge.author !== undefined
                                            ? card.knowledge.author
                                            : "Multiple Publisher"}
                                        </p>
                                        <p className="text-start fs-12 knowledge-name mb-0">
                                          {card.knowledge
                                            .knowledge_publisher !== undefined
                                            ? card.knowledge.knowledge_publisher
                                                .name
                                            : null}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                )
                              )
                            : null}
                        </div>
                        {/* <div className="form-row">
                          <div className="form-label">Publisher</div>
                          <div className="form-space">:</div>
                          <div className="form-value">
                            {card.knowledge_publisher.name}
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-label">Category</div>
                          <div className="form-space">:</div>
                          <div className="form-value">
                            {card.knowledge_category_map.map((data, index) => {
                              return index === 0
                                ? data.category.name
                                : `, ${data.category.name}`;
                            })}
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-label">Date Created</div>
                          <div className="form-space">:</div>
                          <div className="form-value">
                            {formatDate(card.ts_created)}
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-label">Usage</div>
                          <div className="form-space">:</div>
                          <div className="form-value">1000 Sessions</div>
                        </div>
                        <div className="form-row">
                          <div className="form-label">Price</div>
                          <div className="form-space">:</div>
                          <div className="form-value">
                            Rp. 50.000 unlimited / Rp. 10.000 per month
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-label">Uploaded by</div>
                          <div className="form-space">:</div>
                          <div className="form-value">
                            {card.user.user_profile.name_first}{" "}
                            {card.user.user_profile.name_last}
                          </div>
                        </div> */}
                        {/* <div className="form-row">
                          <div className="form-label">File source</div>
                          <div className="form-space">:</div>
                          <div className="form-value">
                            <a
                              className="link-orange cursor-pointer"
                              onClick={() => handleView()}
                            >
                              View File
                            </a>
                          </div>
                        </div> */}
                      </SimpleBar>
                    </div>
                  )}
                  {activeTab === "Tab2" && (
                    <div>
                      <SimpleBar style={{ height: "calc(100vh - 380px)" }}>
                        <p>{formatText(item.description)}</p>
                      </SimpleBar>
                    </div>
                  )}
                  {activeTab === "Tab3" && (
                    <>
                      {review.length > 0 && (
                        <div className="mb-2">
                          <span className="me-2">Filter: </span>
                          {starReview.map((data, index) => (
                            <div
                              key="index"
                              class="form-check form-check-inline"
                            >
                              <input
                                class="form-check-input"
                                type="checkbox"
                                id={index}
                                value={data.rating}
                                checked={selectedRatings.includes(data.rating)}
                                onChange={() =>
                                  onRatingChange(
                                    data.rating,
                                    review[0].idt_knowledge_collection,
                                    "collection"
                                  )
                                }
                              />
                              <label class="form-check-label" htmlFor={index}>
                                {data.rating} star
                              </label>
                            </div>
                          ))}
                        </div>
                      )}
                      <SimpleBar
                        style={{
                          height:
                            review.length > 0
                              ? "calc(100vh - 410px)"
                              : "calc(100vh - 380px)",
                        }}
                      >
                        <ReviewKnowledge
                          reviews={review}
                          loadMoreReviews={loadMoreReview}
                          loadingReview={loadingReview}
                          hasMore={hasMore}
                        />
                      </SimpleBar>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ModalDetailCollectionPublic;
