import { useState, useEffect, useContext } from "react";
import { Button, Dropdown, Modal, Spinner } from "react-bootstrap";
import { RiChatNewFill } from "react-icons/ri";
import { useLanguage } from "../provider/languageContext";
import SimpleBar from "simplebar-react";
import axios from "axios";
import { toast, Slide } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import CustomStyles from "./customStyles";
import { ThemeContext } from "../provider/themeContext";
import { API_URL, API_URL_CORE } from "../utilities/url";
import storage from "../services/storage";
import CryptoJS from "crypto-js";
import { SessionContext } from "../provider/sessionContext";
import { LuBookCopy, LuBookLock, LuBookUp } from "react-icons/lu";
import bookComp from "../assets/images/book-comp.png";
import image_knowledge from "../assets/images/knowledge.png";
import Pagination from "../pages/library/component/pagination";
import { BiWorld } from "react-icons/bi";
import { AssistantContext } from "../provider/assistantContext";
import { MdFilterList } from "react-icons/md";

const getSlidesConfig = (width) => {
  if (width > 3000) {
    return 50;
  } else if (width <= 3000 && width > 2500) {
    return 42;
  } else if (width <= 2500 && width > 2000) {
    return 35;
  } else if (width <= 2000 && width > 1500) {
    return 30;
  } else if (width <= 1500 && width > 1024) {
    return 25;
  } else if (width <= 1024 && width > 600) {
    return 20;
  } else if (width <= 600) {
    return 10;
  } else {
    return 50;
  }
};

const ModalStartChat = ({ type }) => {
  const {
    setMessages,
    setSessionList,
    setSelectedSession,
    setActiveSession,
    setActiveMessage,
    setTypeSession,
    loadSessionPersonal,
  } = useContext(SessionContext);
  const { defaultAssistant, setSelectedSessionAssistant } =
    useContext(AssistantContext);
  const { messagesLang } = useLanguage();
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState([]);
  const [searchValue, setSearchValue] = useState(null);
  const [cardsPerPage, setCardsPerPage] = useState(24);
  const [totalData, setTotalData] = useState(null);
  const [cardsData, setCardsData] = useState([]);
  const [reloadData, setReloadData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingSelected, setLoadingSelected] = useState(false);
  const [slidesConfig, setSlidesConfig] = useState(
    getSlidesConfig(window.innerWidth)
  );
  const decryptUSR = CryptoJS.AES.decrypt(storage.get("user"), "user").toString(
    CryptoJS.enc.Utf8
  );
  const usr = JSON.parse(decryptUSR);

  /* Tab */
  const tabs = [
    { id: "all", label: "All Knowledge" },
    { id: "collection", label: "Collection" },
    { id: "personal", label: "Personal" },
    { id: "public", label: "Public" },
  ];
  const optionSort = [
    {
      value: "a-asc",
      label: "Alphabetical A-Z",
    },
    {
      value: "a-desc",
      label: "Alphabetical Z-A",
    },
    {
      value: "l-desc",
      label: "Last Created",
    },
  ];
  const [selectedSorting, setSelectedSorting] = useState(() => {
    const sorted = storage.get("sortedData");
    return sorted ? sorted : optionSort[0];
  });
  const [activeTab, setActiveTab] = useState("all");
  const [currentPage, setCurrentPage] = useState(1);
  const [show, setShow] = useState(false);
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    if (show) {
      fetchCategories();
    }
  }, [show]);

  useEffect(() => {
    if (selectedCategory) {
      fetchSubcategories(selectedCategory);
    } else {
      setSubcategories([]);
    }
  }, [selectedCategory]);

  useEffect(() => {
    let timeoutId;
    setLoading(true);
    if (searchValue !== "") {
      timeoutId = setTimeout(() => {
        if (show) {
          fetchData();
        }
      }, 500);
    } else {
      if (show) {
        fetchData();
      }
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [
    show,
    activeTab,
    currentPage,
    cardsPerPage,
    reloadData,
    searchValue,
    selectedSorting,
    selectedCategory,
    selectedSubcategory,
  ]);

  const fetchCategories = async () => {
    axios
      .get(API_URL + "get-knowledge-category", {
        headers: {
          Authorization: `Bearer ${storage.get("access_token")}`,
        },
      })
      .then((response) => {
        if (response.data.success === true) {
          const maps = response.data.data.map(function (d) {
            const map = {
              value: d.idt_knowledge_category,
              label: d.name,
            };
            return map;
          });
          setCategories(maps);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchSubcategories = async (category) => {
    axios
      .get(`${API_URL}get-knowledge-subcategory?category=${category.value}`, {
        headers: {
          Authorization: `Bearer ${storage.get("access_token")}`,
        },
      })
      .then((response) => {
        if (response.data.success === true) {
          const maps = response.data.data.map(function (d) {
            const map = {
              value: d.idt_knowledge_category,
              label: d.name,
            };
            return map;
          });
          setSubcategories(maps);
        }
      })
      .catch((error) => {});
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      let apiUrl = API_URL;
      switch (activeTab) {
        case "all":
          apiUrl += "get-knowledge-personal-all";
          break;
        case "collection":
          apiUrl += "get-collection";
          break;
        case "personal":
          apiUrl += "get-knowledge-personal";
          break;
        case "public":
          apiUrl += "get-knowledge-personal-public";
          break;
      }
      const params = {
        categories: selectedCategory ? selectedCategory : "all",
        subcategories:
          selectedSubcategory.length > 0
            ? selectedSubcategory.map((item) => item.value)
            : "all",
        title: searchValue,
        sort: selectedSorting.value,
        status: 2,
        page: currentPage,
        limit: cardsPerPage,
      };
      const response = await axios.post(apiUrl, params, {
        headers: {
          Authorization: `Bearer ${storage.get("access_token")}`,
        },
      });
      const data = await response.data.data.data;
      const meta = await response.data.data.meta;
      setCardsData(data);
      setTotalData(meta.total_items);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
    setLoading(false);
  };
  const handleCategoryChange = (category) => {
    if (selectedCategory !== category) {
      setSelectedCategory(category);
      setSelectedSubcategory([]);
    }
  };
  const handleSubcategoryChange = (subcategory) => {
    setSelectedSubcategory(subcategory);
  };
  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };
  const handleSubmitFilter = () => {
    fetchData();
  };
  const handleResetFilter = () => {
    setSelectedCategory("");
    setSelectedSubcategory([]);
    setReloadData((prevState) => !prevState);
  };
  const handleClick = (id, option) => {
    setLoadingSelected(true);
    axios
      .post(API_URL_CORE + "chat/session", {
        knowledge_id: option === "knowledge" ? id : null,
        collection_id: option === "collection" ? id : null,
        assistant_id: defaultAssistant,
        user_id: usr.idt_user,
      })
      .then((response) => {
        if (response.data.errormsg == "OK") {
          // Get For Context
          axios
            .post(
              API_URL + "get-session",
              { status: 0, idt_session: response.data.data.idt_session },
              {
                headers: {
                  Authorization: `Bearer ${storage.get("access_token")}`,
                },
              }
            )
            .then((resp) => {
              if (resp.data.success === true) {
                loadSessionPersonal();
                setTypeSession("personal");
                setMessages([]);
                // setSessionList((prevData) => [...resp.data.data, ...prevData]);
                setSelectedSessionAssistant({
                  value: resp.data.data[0].assistant.idt_user_assistant,
                  label: resp.data.data[0].assistant.name,
                });
                setSelectedSession(resp.data.data[0]);
                setLoadingSelected(false);
                setActiveMessage(true);
                setActiveSession(resp.data.data[0].idt_session);
                handleClose();
                navigate("/chat");
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          setLoadingSelected(false);
          toast.error(response.data.errormsg, {
            position: "top-right",
            theme: "colored",
            transition: Slide,
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoadingSelected(false);
        // Handle error di sini
      });
  };

  const handleClose = () => {
    setShow(false);
    setLoadingSelected(false);
  };
  const handleShow = () => setShow(true);

  const handleSorting = (data) => {
    setSelectedSorting(data);
    storage.setUnlimited("sortedData", data);
  };

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const clearInput = () => {
    setSearchValue("");
  };

  return (
    <>
      {type ? (
        <Button
          className="btn btn-primary waves-effect waves-light"
          onClick={handleShow}
        >
          <RiChatNewFill size={18} className="me-1" />
          Start New Chat
        </Button>
      ) : (
        <button onClick={handleShow} className="btn btn-upload-wd mb-4">
          <div>
            <RiChatNewFill className="pt-2" size={36} />
            <p className="pt-2 fs-16">Start New Chat</p>
          </div>
        </button>
      )}
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        backdrop="static"
        dialogClassName="modal-xl fade"
        onHide={handleClose}
      >
        <Modal.Header className="p-3 bg-light-dark" closeButton>
          <h5 className="modal-title">{messagesLang.my_library}</h5>
        </Modal.Header>
        <SimpleBar className="modal-body knowledge">
          {loadingSelected ? (
            <div className="loading-backdrop-modal">
              <Spinner animation="border" />
            </div>
          ) : null}
          <div className="container-fluid">
            <div className="layout-width">
              <div
                className="navbar-header nav-fixed d-flex justify-content-end"
                style={{ height: "auto", padding: "0" }}
              ></div>
            </div>
          </div>
          <div className="container-fluid mt-3">
            <div className="row">
              <div className="tabs">
                <ul className="nav nav-tabs-custom rounded card-header-tabs d-flex justify-content-between border-bottom-0">
                  {tabs.map((tab, index) => (
                    <li key={index} className="nav-item flexis fs-16">
                      <button
                        className={
                          tab.id === activeTab
                            ? "nav-link w-100 tab active"
                            : "nav-link w-100 tab"
                        }
                        onClick={() => handleTabClick(tab.id)}
                      >
                        {tab.label}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="select-container mt-4">
                <div className="row">
                  <div className="col-md-12">
                    <div className="row">
                      <div
                        className="d-sm-flex d-block"
                        style={{ gap: "15px" }}
                      >
                        <div
                          className="mb-3 d-sm-block d-md-none"
                          style={{ flex: "0 25%" }}
                        >
                          <Select
                            styles={CustomStyles(theme)}
                            className="custom-select"
                            maxMenuHeight={200}
                            options={tabs.map((tab) => ({
                              value: tab.id,
                              label: tab.label,
                            }))}
                            value={activeTab}
                            onChange={(selected) => {
                              handleTabClick(selected.value);
                              setActiveTab(selected);
                            }}
                            isSearchable={true}
                          />
                        </div>

                        {activeTab !== "collection" ? (
                          <>
                            <div className="mb-3">
                              <div
                                className="app-search"
                                style={{ padding: "0" }}
                              >
                                <div className="position-relative">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder={messagesLang.search}
                                    autoComplete="off"
                                    style={{ width: "200px" }}
                                    value={searchValue}
                                    onChange={handleSearch}
                                    // onKeyPress={handleKeyPress}
                                  />
                                  <span
                                    // onClick={handleSearch}
                                    className="mdi mdi-magnify search-widget-icon cursor-pointer"
                                  ></span>
                                  {searchValue && (
                                    <span
                                      onClick={clearInput}
                                      className="mdi mdi-close-circle search-widget-icon search-widget-icon-close cursor-pointer"
                                      id="search-close-options"
                                    ></span>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="mb-3">
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant="none"
                                  className="btn btn-upload-wd waves-effect waves-light btn-md sorted"
                                >
                                  <div className="d-flex">
                                    <MdFilterList size={17} className="me-2" />
                                    {selectedSorting.label}
                                  </div>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  {optionSort.map((option) => (
                                    <Dropdown.Item
                                      key={option.value}
                                      onClick={() => handleSorting(option)}
                                    >
                                      {option.label}
                                    </Dropdown.Item>
                                  ))}
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                            <div className="mb-3" style={{ flex: "0 22%" }}>
                              <Select
                                styles={CustomStyles(theme)}
                                className="custom-select"
                                maxMenuHeight={200}
                                options={categories}
                                value={selectedCategory}
                                placeholder="Category"
                                onChange={(selected) => {
                                  handleCategoryChange(selected);
                                }}
                                isSearchable={true}
                              />
                            </div>
                            <div className="mb-3" style={{ flex: "0 45%" }}>
                              <Select
                                styles={CustomStyles(theme)}
                                className="custom-select"
                                maxMenuHeight={200}
                                options={subcategories}
                                value={selectedSubcategory}
                                placeholder="Sub-Category"
                                onChange={(selected) => {
                                  handleSubcategoryChange(selected);
                                }}
                                isMulti
                                isSearchable={true}
                              />
                            </div>
                            <div className="mb-3" style={{ flex: "0 10%" }}>
                              <div
                                className="btn-container d-flex"
                                style={{ gap: "15px" }}
                              >
                                <button
                                  className="btn btn-md btn-danger w-100"
                                  onClick={() => handleResetFilter()}
                                  style={{ flex: "1" }}
                                >
                                  Reset
                                </button>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="mb-3">
                              <div
                                className="app-search"
                                style={{ padding: "0" }}
                              >
                                <div className="position-relative">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder={messagesLang.search}
                                    autoComplete="off"
                                    style={{ width: "200px" }}
                                    value={searchValue}
                                    onChange={handleSearch}
                                    // onKeyPress={handleKeyPress}
                                  />
                                  <span
                                    // onClick={handleSearch}
                                    className="mdi mdi-magnify search-widget-icon cursor-pointer"
                                  ></span>
                                  {searchValue && (
                                    <span
                                      onClick={clearInput}
                                      className="mdi mdi-close-circle search-widget-icon search-widget-icon-close cursor-pointer"
                                      id="search-close-options"
                                    ></span>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="mb-3">
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant="none"
                                  className="btn btn-upload-wd waves-effect waves-light btn-md sorted"
                                >
                                  <div className="d-flex">
                                    <MdFilterList size={17} className="me-2" />
                                    {selectedSorting.label}
                                  </div>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  {optionSort.map((option) => (
                                    <Dropdown.Item
                                      key={option.value}
                                      onClick={() => handleSorting(option)}
                                    >
                                      {option.label}
                                    </Dropdown.Item>
                                  ))}
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                {!loading ? (
                  cardsData.length > 0 ? (
                    cardsData.map((item, index) => (
                      <div className="custom-coloum-2 folder-card" key={index}>
                        <a
                          onClick={() =>
                            handleClick(
                              item.idt_knowledge
                                ? item.idt_knowledge
                                : item.idt_knowledge_collection,
                              item.idt_knowledge ? "knowledge" : "collection"
                            )
                          }
                        >
                          <div className="card card-list bg-light shadow-lg">
                            <div className="card-body">
                              <div className="text-start">
                                <div className="mb-2">
                                  <div className="square-container">
                                    <img
                                      src={
                                        item.idt_knowledge !== undefined
                                          ? item.knowledge_cover_pic.length > 0
                                            ? item.knowledge_cover_pic[0]
                                                .pathurl
                                            : image_knowledge
                                          : item.url_coverpic
                                          ? item.url_coverpic
                                          : bookComp
                                      }
                                      className="rounded-3"
                                      alt={index}
                                    />
                                  </div>
                                </div>
                                <div style={{ height: "36px" }}>
                                  <h6 className="fs-12 fw-bold ellipsis-2-line knowledge-name">
                                    {item.title !== undefined
                                      ? item.title
                                      : item.name}
                                  </h6>
                                </div>
                              </div>
                              <p className="text-start fs-11 knowledge-name mb-0">
                                {item.type !== undefined ? (
                                  item.type === "private" ? (
                                    <>
                                      <LuBookLock
                                        size={11}
                                        className="me-1"
                                        style={{ marginTop: "-3px" }}
                                      />
                                      Personal Private
                                    </>
                                  ) : item.idt_user === usr.idt_user ? (
                                    <>
                                      <LuBookUp
                                        size={11}
                                        className="me-1"
                                        style={{ marginTop: "-3px" }}
                                      />
                                      Personal Publish
                                    </>
                                  ) : (
                                    <>
                                      <BiWorld
                                        size={11}
                                        className="me-1"
                                        style={{ marginTop: "-3px" }}
                                      />{" "}
                                      Public
                                    </>
                                  )
                                ) : (
                                  <>
                                    <LuBookCopy
                                      size={11}
                                      className="me-1"
                                      style={{ marginTop: "-3px" }}
                                    />
                                    Collection
                                  </>
                                )}
                              </p>
                              <p className="text-start fs-12 knowledge-name mb-0">
                                {item.author !== undefined
                                  ? item.author
                                  : "Multiple Publisher"}
                              </p>
                              <p className="text-start fs-12 knowledge-name mb-0">
                                {item.knowledge_publisher !== undefined
                                  ? item.knowledge_publisher.name
                                  : null}
                              </p>
                            </div>
                          </div>
                        </a>
                      </div>
                    ))
                  ) : (
                    <div
                      className="text-center fs-16"
                      style={{ marginTop: "60px", marginBottom: "120px" }}
                    >
                      {messagesLang.data_not_found}
                    </div>
                  )
                ) : (
                  <div className="mt-2 mb-2" style={{ height: "315px" }}>
                    <div className="loading-backdrop-2">
                      <Spinner animation="border" />
                    </div>
                  </div>
                )}
              </div>
              <Pagination
                cardsPerPage={cardsPerPage}
                totalCards={totalData}
                currentPage={currentPage}
                onPageChange={setCurrentPage}
              />
            </div>
          </div>
        </SimpleBar>
      </Modal>
    </>
  );
};

export default ModalStartChat;
