import React, { useContext, useState } from "react";
import { Dropdown } from "react-bootstrap";
import avatar1 from "../../assets/images/users/avatar-1.jpg";
import { Link } from "react-router-dom";
import storage from "../../services/storage";
import CryptoJS from "crypto-js";
import { useLanguage } from "../../provider/languageContext";
import { CartContext } from "../../provider/cartContext";

const HeaderAccount = () => {
  const { messagesLang } = useLanguage();
  const { cartItems, countOrderProcess } = useContext(CartContext);
  const [isOpen, setIsOpen] = useState(false);
  const handleToggle = (isOpen) => {
    setIsOpen(isOpen);
  };
  const handleSelect = () => {
    setIsOpen(false);
  };
  const decryptUSR = CryptoJS.AES.decrypt(storage.get("user"), "user").toString(
    CryptoJS.enc.Utf8
  );
  const usr = JSON.parse(decryptUSR);
  const Logout = () => {
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key !== "lang") {
        storage.remove("access_token");
        storage.remove(key);
      }
    }
    window.location.href = "/login";
  };
  return (
    <div>
      <Dropdown
        show={isOpen}
        onToggle={handleToggle}
        onSelect={handleSelect}
        className="ms-sm-3 header-item topbar-user"
      >
        <Dropdown.Toggle variant="" className="dropdown d-flex">
          <span className="d-flex align-items-center">
            <img
              className="rounded-circle object-fit-cover header-profile-user"
              src={
                usr.user_profile.photo_url
                  ? usr.user_profile.photo_url
                  : avatar1
              }
              alt="Header Avatar"
            />
            <span className="text-start ms-xl-2">
              <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">
                {usr.username}
              </span>
              <span className="d-none d-xl-block ms-1 fs-12 user-name-sub-text">
                {/* {usr.email} */}
              </span>
            </span>
          </span>
        </Dropdown.Toggle>

        <Dropdown.Menu className="dropdown-menu" align="end">
          <h6 className="dropdown-header">
            Welcome, {usr.user_profile.name_first} !!
          </h6>
          <Dropdown.Divider></Dropdown.Divider>
          <Dropdown.Item as={Link} to="/user-profile">
            <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle">{messagesLang.profile}</span>
          </Dropdown.Item>
          <Dropdown.Item as={Link} to="/cart" className="d-md-none d-sm-block">
            <i className="bx bx-cart-alt text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle">{messagesLang.my_cart}</span>
            {cartItems.length > 0 ? (
              <span className="badge bg-success text-white ms-2">
                {cartItems.length}
              </span>
            ) : null}
          </Dropdown.Item>
          <Dropdown.Item as={Link} to="/order-list">
            <i className="ri-store-2-fill text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle">{messagesLang.order_list}</span>
            {countOrderProcess > 0 && (
              <span className="badge bg-primary text-white ms-2">
                {countOrderProcess}
              </span>
            )}
          </Dropdown.Item>
          <Dropdown.Item as={Link} to="#" className="d-md-none d-sm-block">
            <i className="bx bx-bell text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle">{messagesLang.notifications}</span>
            <span className="badge bg-danger text-white ms-2">3</span>
          </Dropdown.Item>
          {/* <Dropdown.Item href="#">
            <i className="mdi mdi-wallet text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle">
              Balance : <b>$5971.67</b>
            </span>
          </Dropdown.Item> */}
          <Dropdown.Item as={Link} to="/settings">
            <i className="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle">{messagesLang.settings}</span>
          </Dropdown.Item>
          <a
            className="dropdown-item pe-auto"
            role="button"
            onClick={() => Logout()}
          >
            <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle" data-key="t-logout">
              {messagesLang.logout}
            </span>
          </a>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default HeaderAccount;
