import { React, useEffect, useState } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { API_URL } from "../utilities/url";
import { format_number } from "../utilities/ext-function";
import axios from "axios";
import logoImg from "../assets/images/logo-dark.png";
import Skeleton from "react-loading-skeleton";
import storage from "../services/storage";

import "bootstrap/dist/js/bootstrap.bundle.min.js";

var $ = require("jquery");

const Register2 = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [toogle, setToogle] = useState("month-tab");
  const [state, setState] = useState({
    dataset: "",
  });

  useEffect(() => {
    $(".annual").addClass("d-none").removeClass("d-block");
    // Validate State
    storage.remove("id-subs");
    if (location.state) {
      delete location.state;
    }
    axios
      .get(API_URL + "product-subscription")
      .then((response) => {
        if (response.data.success === true) {
          setState((prevState) => ({
            ...prevState,
            ["dataset"]: response.data.data,
          }));
        }
      })
      .catch((error) => {
        console.log("There was an error!", error);
      });
  }, []);

  const handleChange = (event) => {
    const { id } = event.target;
    setToogle(id);
    if (id == "month-tab") {
      $(".month").addClass("d-block").removeClass("d-none");
      $(".annual").addClass("d-none").removeClass("d-block");
    } else {
      $(".month").addClass("d-none").removeClass("d-block");
      $(".annual").addClass("d-block").removeClass("d-none");
    }
  };

  return (
    <div className="auth-page-content pb-0">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="mt-sm-4 mb-sm-2 text-white-50">
              <div>
                <Link to="/login">
                  <img
                    src={logoImg}
                    alt=""
                    height="40"
                    className="d-none d-sm-block"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center mt-4">
          <div className="col-md-12 col-lg-12 col-xl-12">
            <div className="row justify-content-center">
              <div className="col-lg-5">
                <div className="text-center mb-4">
                  <h4 className="fw-semibold text-white mb-4 fs-22">
                    We have a plan for everyone
                  </h4>
                  {/* <p className="text-muted mb-4 fs-15">
                      Simple pricing. No hidden fees. Advanced features for you
                      business.
                    </p> */}

                  <div className="d-inline-flex">
                    <ul
                      className="nav nav-pills arrow-navtabs plan-nav rounded mb-3 p-1"
                      id="pills-tab"
                      role="tablist"
                    >
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link toogle-pricing fw-semibold active"
                          id="month-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#month"
                          type="button"
                          role="tab"
                          aria-selected="true"
                          onClick={handleChange}
                        >
                          Monthly
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link toogle-pricing fw-semibold"
                          id="annual-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#annual"
                          type="button"
                          role="tab"
                          aria-selected="false"
                          onClick={handleChange}
                        >
                          Annually
                          {/* <span className="badge bg-success ms-2">25% Off</span> */}
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xxl-4 col-lg-6">
                <div className="card plan-pricing rounded-4 pricing-box">
                  <div className="card-body m-2 p-4">
                    {state.dataset.length > 0 ? (
                      state.dataset.map((d) =>
                        d.idt_product_subscription === 1 ? (
                          <>
                            <div className="d-flex align-items-center mb-3">
                              <div className="flex-grow-1">
                                <h5 className="mb-0 fw-semibold">{d.name}</h5>
                              </div>
                              <div className="ms-auto"></div>
                            </div>
                            <div className="mb-4">
                              <h2 className="month">
                                {format_number(
                                  d.subscription_price_plan[0]
                                    .price_monthly_offering
                                )}
                                <small className="fs-13 text-muted">
                                  {" "}
                                  / Month
                                </small>
                              </h2>
                              <h2 className="annual mb-2 d-none">
                                {format_number(
                                  d.subscription_price_plan[0]
                                    .price_monthly_offering
                                )}
                                <small className="fs-13 text-muted">
                                  {" "}
                                  / Year
                                </small>
                              </h2>
                            </div>
                            <ul className="list-unstyled vstack gap-3">
                              {JSON.parse(d.description).map(
                                (dd, index) =>
                                  index < 6 && (
                                    <li key={index}>
                                      <div className="d-flex">
                                        {dd.type === "boolean" ? (
                                          dd.value === true ? (
                                            <>
                                              <div className="flex-shrink-0 text-success me-1">
                                                <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                              </div>
                                              <div className="flex-grow-1">
                                                <b>{dd.value}</b> {dd.name}
                                              </div>
                                            </>
                                          ) : (
                                            <>
                                              <div className="flex-shrink-0 text-danger me-1">
                                                <i className="ri-close-circle-fill fs-15 align-middle"></i>
                                              </div>
                                              <div className="flex-grow-1">
                                                <b>{dd.value}</b> {dd.name}
                                              </div>
                                            </>
                                          )
                                        ) : (
                                          <>
                                            <div className="flex-shrink-0 text-success me-1">
                                              <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                            </div>
                                            <div className="flex-grow-1">
                                              <b>{dd.value}</b> {dd.name}
                                            </div>
                                          </>
                                        )}
                                      </div>
                                    </li>
                                  )
                              )}
                            </ul>
                            <div className="mt-3 pt-2">
                              <Link
                                to=""
                                onClick={(e) => {
                                  e.preventDefault();
                                  navigate("/register-free", {
                                    state: {
                                      id: d.subscription_price_plan[0]
                                        .idt_product_subscription_price_plan,
                                    },
                                  });
                                }}
                                className="btn btn-orange w-100"
                              >
                                Select
                              </Link>
                            </div>
                          </>
                        ) : null
                      )
                    ) : (
                      <>
                        <Skeleton height={28} className="mb-3" />
                        <Skeleton height={29} count={7} className="mb-1" />
                        <Skeleton height={38} className="mt-3 w-100" />
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-xxl-4 col-lg-6">
                <div className="card plan-pricing rounded-4 pricing-box">
                  <div className="card-body m-2 p-4">
                    {state.dataset.length > 0 ? (
                      state.dataset.map((d) =>
                        d.idt_product_subscription === 2 ? (
                          <>
                            <div className="d-flex align-items-center mb-3">
                              <div className="flex-grow-1">
                                <h5 className="mb-0 fw-semibold">{d.name}</h5>
                              </div>
                              <div className="ms-auto"></div>
                            </div>
                            <div className="mb-4">
                              <h2 className="month mb-0">
                                {format_number(
                                  d.subscription_price_plan[0]
                                    .price_monthly_offering *
                                    d.subscription_price_plan[0].plan_length
                                )}
                                <small className="fs-13 text-muted">
                                  {" "}
                                  / {
                                    d.subscription_price_plan[0].plan_length
                                  }{" "}
                                  Month
                                </small>
                              </h2>
                              <h2 className="annual mb-0 d-none">
                                {format_number(
                                  d.subscription_price_plan[1]
                                    .price_monthly_offering *
                                    d.subscription_price_plan[1].plan_length
                                )}
                                <small className="fs-13 text-muted">
                                  {" "}
                                  / Year
                                </small>
                              </h2>
                            </div>
                            <ul className="list-unstyled vstack gap-3">
                              {JSON.parse(d.description).map(
                                (dd, index) =>
                                  index < 6 && (
                                    <li key={index}>
                                      <div className="d-flex">
                                        {dd.type === "boolean" ? (
                                          dd.value === true ? (
                                            <>
                                              <div className="flex-shrink-0 text-success me-1">
                                                <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                              </div>
                                              <div className="flex-grow-1">
                                                <b>{dd.value}</b> {dd.name}
                                              </div>
                                            </>
                                          ) : (
                                            <>
                                              <div className="flex-shrink-0 text-danger me-1">
                                                <i className="ri-close-circle-fill fs-15 align-middle"></i>
                                              </div>
                                              <div className="flex-grow-1">
                                                <b>{dd.value}</b> {dd.name}
                                              </div>
                                            </>
                                          )
                                        ) : (
                                          <>
                                            <div className="flex-shrink-0 text-success me-1">
                                              <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                            </div>
                                            <div className="flex-grow-1">
                                              <b>{dd.value}</b> {dd.name}
                                            </div>
                                          </>
                                        )}
                                      </div>
                                    </li>
                                  )
                              )}
                            </ul>
                            <div className="mt-3 pt-2">
                              <Link
                                to=""
                                onClick={(e) => {
                                  e.preventDefault();
                                  if (toogle == "month-tab") {
                                    navigate("/register-free", {
                                      state: {
                                        id: d.subscription_price_plan[0]
                                          .idt_product_subscription_price_plan,
                                      },
                                    });
                                  } else {
                                    navigate("/register-free", {
                                      state: {
                                        id: d.subscription_price_plan[1]
                                          .idt_product_subscription_price_plan,
                                      },
                                    });
                                  }
                                }}
                                className="btn btn-orange w-100"
                              >
                                Select
                              </Link>
                            </div>
                          </>
                        ) : null
                      )
                    ) : (
                      <>
                        <Skeleton height={28} className="mb-3" />
                        <Skeleton height={29} count={7} className="mb-1" />
                        <Skeleton height={38} className="mt-3 w-100" />
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-xxl-4 col-lg-6">
                <div className="card plan-pricing rounded-4 pricing-box">
                  <div className="card-body m-2 p-4">
                    {state.dataset.length > 0 ? (
                      state.dataset.map((d) =>
                        d.idt_product_subscription === 3 ? (
                          <>
                            <div className="d-flex align-items-center mb-3">
                              <div className="flex-grow-1">
                                <h5 className="mb-0 fw-semibold">{d.name}</h5>
                              </div>
                              <div className="ms-auto"></div>
                            </div>
                            <div className="mb-4">
                              <h2 className="month mb-0">
                                {format_number(
                                  d.subscription_price_plan[0]
                                    .price_monthly_offering *
                                    d.subscription_price_plan[0].plan_length
                                )}
                                <small className="fs-13 text-muted">
                                  {" "}
                                  / {
                                    d.subscription_price_plan[0].plan_length
                                  }{" "}
                                  Month
                                </small>
                              </h2>
                              <h2 className="annual mb-0 d-none">
                                {format_number(
                                  d.subscription_price_plan[1]
                                    .price_monthly_offering *
                                    d.subscription_price_plan[1].plan_length
                                )}
                                <small className="fs-13 text-muted">
                                  {" "}
                                  / Year
                                </small>
                              </h2>
                            </div>
                            <ul className="list-unstyled vstack gap-3">
                              {JSON.parse(d.description).map(
                                (dd, index) =>
                                  index < 6 && (
                                    <li key={index}>
                                      <div className="d-flex">
                                        {dd.type === "boolean" ? (
                                          dd.value === true ? (
                                            <>
                                              <div className="flex-shrink-0 text-success me-1">
                                                <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                              </div>
                                              <div className="flex-grow-1">
                                                <b>{dd.value}</b> {dd.name}
                                              </div>
                                            </>
                                          ) : (
                                            <>
                                              <div className="flex-shrink-0 text-danger me-1">
                                                <i className="ri-close-circle-fill fs-15 align-middle"></i>
                                              </div>
                                              <div className="flex-grow-1">
                                                <b>{dd.value}</b> {dd.name}
                                              </div>
                                            </>
                                          )
                                        ) : (
                                          <>
                                            <div className="flex-shrink-0 text-success me-1">
                                              <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                            </div>
                                            <div className="flex-grow-1">
                                              <b>{dd.value}</b> {dd.name}
                                            </div>
                                          </>
                                        )}
                                      </div>
                                    </li>
                                  )
                              )}
                            </ul>

                            <div className="mt-3 pt-2">
                              <Link
                                to=""
                                onClick={(e) => {
                                  e.preventDefault();
                                  if (toogle == "month-tab") {
                                    navigate("/register-free", {
                                      state: {
                                        id: d.subscription_price_plan[0]
                                          .idt_product_subscription_price_plan,
                                      },
                                    });
                                  } else {
                                    navigate("/register-free", {
                                      state: {
                                        id: d.subscription_price_plan[1]
                                          .idt_product_subscription_price_plan,
                                      },
                                    });
                                  }
                                }}
                                className="btn btn-orange w-100"
                              >
                                Select
                              </Link>
                            </div>
                          </>
                        ) : null
                      )
                    ) : (
                      <>
                        <Skeleton height={28} className="mb-3" />
                        <Skeleton height={29} count={7} className="mb-1" />
                        <Skeleton height={38} className="mt-3 w-100" />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register2;
