import React from "react";
import { Button, Dropdown } from "react-bootstrap";
import SimpleBar from "simplebar-react";
import avatar1 from "../../assets/images/users/avatar-1.jpg";
import { useLanguage } from "../../provider/languageContext";

const HeaderNotification = () => {
  const { messagesLang } = useLanguage();
  return (
    <div>
      <Dropdown className="topbar-head-dropdown ms-1 header-item">
        <Dropdown.Toggle
          variant=""
          className="dropdown d-flex btn-icon btn-topbar btn-ghost-secondary rounded-circle"
        >
          <i className="bx bx-bell fs-22"></i>
          <span className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">
            3<span className="visually-hidden">unread messages</span>
          </span>
        </Dropdown.Toggle>

        <Dropdown.Menu className="dropdown-menu-lg dropdown-menu-end p-0">
          <div className="dropdown-head bg-danger rounded-top">
            <div className="p-3">
              <div className="row align-items-center">
                <div className="col">
                  <h6 className="m-0 fs-14 fw-semibold text-white">
                    {" "}
                    {messagesLang.notifications}{" "}
                  </h6>
                </div>
                <div className="col-auto dropdown-tabs">
                  <span className="badge bg-light-subtle text-body fs-11">
                    {" "}
                    3 {messagesLang.new}
                  </span>
                </div>
              </div>
            </div>

            <div className="px-2 pt-2">
              <ul
                className="nav nav-tabs dropdown-tabs nav-tabs-custom-notif"
                data-dropdown-tabs="true"
                id="notificationItemsTab"
                role="tablist"
              >
                <li className="nav-item waves-effect waves-light">
                  <a
                    className="nav-link active"
                    data-bs-toggle="tab"
                    href="#all-noti-tab"
                    role="tab"
                    aria-selected="true"
                  >
                    {messagesLang.messages}
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div
            className="tab-content position-relative"
            id="notificationItemsTabContent"
          >
            <div
              className="tab-pane fade show active py-2 ps-2"
              id="all-noti-tab"
              role="tabpanel"
            >
              <SimpleBar style={{ maxHeight: "300px" }} className="pe-2">
                <div className="text-reset notification-item d-block dropdown-item position-relative">
                  <div className="d-flex">
                    <div className="avatar-xs me-3 flex-shrink-0">
                      <span className="avatar-title bg-info-subtle text-info rounded-circle fs-16">
                        <i className="bx bx-badge-check"></i>
                      </span>
                    </div>
                    <div className="flex-grow-1">
                      <a href="#!" className="stretched-link">
                        <h6 className="mt-0 mb-2 lh-base">
                          Your <b>Elite</b> author Graphic Optimization{" "}
                          <span className="text-secondary">reward</span> is
                          ready!
                        </h6>
                      </a>
                      <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                        <span>
                          <i className="mdi mdi-clock-outline"></i> Just 30 sec
                          ago
                        </span>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="text-reset notification-item d-block dropdown-item position-relative">
                  <div className="d-flex">
                    <img
                      src={avatar1}
                      className="me-3 rounded-circle avatar-xs flex-shrink-0"
                      alt="user-pic"
                    />
                    <div className="flex-grow-1">
                      <a href="#!" className="stretched-link">
                        <h6 className="mt-0 mb-1 fs-13 fw-semibold">
                          Angela Bernier
                        </h6>
                      </a>
                      <div className="fs-13 text-muted">
                        <p className="mb-1">
                          Answered to your comment on the cash flow forecast's
                          graph 🔔.
                        </p>
                      </div>
                      <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                        <span>
                          <i className="mdi mdi-clock-outline"></i> 48 min ago
                        </span>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="text-reset notification-item d-block dropdown-item position-relative">
                  <div className="d-flex">
                    <div className="avatar-xs me-3 flex-shrink-0">
                      <span className="avatar-title bg-danger-subtle text-danger rounded-circle fs-16">
                        <i className="bx bx-message-square-dots"></i>
                      </span>
                    </div>
                    <div className="flex-grow-1">
                      <a href="#!" className="stretched-link">
                        <h6 className="mt-0 mb-2 fs-13 lh-base">
                          You have received <b className="text-success">20</b>{" "}
                          new messages in the conversation
                        </h6>
                      </a>
                      <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                        <span>
                          <i className="mdi mdi-clock-outline"></i> 2 hrs ago
                        </span>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="text-reset notification-item d-block dropdown-item position-relative">
                  <div className="d-flex">
                    <img
                      src={avatar1}
                      className="me-3 rounded-circle avatar-xs flex-shrink-0"
                      alt="user-pic"
                    />
                    <div className="flex-grow-1">
                      <a href="#!" className="stretched-link">
                        <h6 className="mt-0 mb-1 fs-13 fw-semibold">
                          Maureen Gibson
                        </h6>
                      </a>
                      <div className="fs-13 text-muted">
                        <p className="mb-1">
                          We talked about a project on linkedin.
                        </p>
                      </div>
                      <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                        <span>
                          <i className="mdi mdi-clock-outline"></i> 4 hrs ago
                        </span>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="my-3 text-center view-all">
                  <button
                    type="button"
                    className="btn btn-soft-success waves-effect waves-light"
                  >
                    View All Notifications{" "}
                    <i className="ri-arrow-right-line align-middle"></i>
                  </button>
                </div>
              </SimpleBar>
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>

      {/* removeNotificationModal */}
      <div
        id="removeNotificationModal"
        className="modal fade zoomIn"
        tabIndex={-1}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <Button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="NotificationModalbtn-close"
              ></Button>
            </div>
            <div className="modal-body">
              <div className="mt-2 text-center">
                <lord-icon
                  src="https://cdn.lordicon.com/gsqxdxog.json"
                  trigger="loop"
                  colors="primary:#f7b84b,secondary:#f06548"
                  style={{ width: 100, height: 100 }}
                ></lord-icon>
                <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                  <h4>Are you sure ?</h4>
                  <p className="text-muted mx-4 mb-0">
                    Are you sure you want to remove this Notification ?
                  </p>
                </div>
              </div>
              <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                <Button
                  type="button"
                  className="btn w-sm btn-light"
                  data-bs-dismiss="modal"
                >
                  Close
                </Button>
                <Button
                  type="button"
                  className="btn w-sm btn-danger"
                  id="delete-notification"
                >
                  Yes, Delete It!
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderNotification;
