import React, { useState, useEffect, useContext } from "react";
import FilterOptions from "./filterOptions";
import CardList from "./cardList";
import TableList from "./tableList";
import Pagination from "./pagination";
import { API_URL } from "../../../utilities/url";
import storage from "../../../services/storage";
import axios from "axios";
import { SliderProcessingContext } from "../../../provider/sliderProcessingContext";
import { SessionContext } from "../../../provider/sessionContext";
import { ToastrContext } from "../../../provider/toastrContext";
import { WebSocketContext } from "../../../provider/webSocketContext";

const MenuTabs = ({
  selectedCards,
  onSelectedCardsChange,
  onSelectedCount,
  onSelectedMenu,
  resetSelectedCards,
}) => {
  const {
    sessionList,
    setSessionList,
    selectedSession,
    setSelectedSession,
    listView,
    setListView,
  } = useContext(SessionContext);
  const { showSuccess } = useContext(ToastrContext);
  const { loadData } = useContext(SliderProcessingContext);
  const { ws, dataSocket } = useContext(WebSocketContext);
  const [activeTab, setActiveTab] = useState("all");
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [cardsPerPage, setCardsPerPage] = useState(25);
  const [totalData, setTotalData] = useState(null);
  const [cardsData, setCardsData] = useState([]);
  const [reloadData, setReloadData] = useState(false);
  const [searchValue, setSearchValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const optionsSort = [
    {
      value: "a-asc",
      label: "Alphabetical A-Z",
    },
    {
      value: "a-desc",
      label: "Alphabetical Z-A",
    },
    {
      value: "l-desc",
      label: "Last Created",
    },
  ];
  const [selectedSorting, setSelectedSorting] = useState(() => {
    const sorted = storage.get("sortedData");
    return sorted ? sorted : optionsSort[0];
  });

  useEffect(() => {
    if (ws && dataSocket) {
      const data = dataSocket;
      if (data.title !== undefined) {
        fetchData();
      }
    }
  }, [ws, dataSocket]);

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    if (selectedCategory) {
      fetchSubcategories(selectedCategory);
    } else {
      setSubcategories([]);
    }
  }, [selectedCategory]);

  useEffect(() => {
    let timeoutId;
    setLoading(true);
    if (searchValue !== "") {
      timeoutId = setTimeout(() => {
        fetchData();
      }, 500);
    } else {
      fetchData();
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [
    activeTab,
    currentPage,
    cardsPerPage,
    reloadData,
    searchValue,
    selectedSorting,
    selectedCategory,
    selectedSubcategory,
  ]);

  const fetchCategories = async () => {
    axios
      .get(API_URL + "get-knowledge-category", {
        headers: {
          Authorization: `Bearer ${storage.get("access_token")}`,
        },
      })
      .then((response) => {
        if (response.data.success === true) {
          const maps = response.data.data.map(function (d) {
            const map = {
              value: d.idt_knowledge_category,
              label: d.name,
            };
            return map;
          });
          setCategories(maps);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchSubcategories = async (category) => {
    axios
      .get(`${API_URL}get-knowledge-subcategory?category=${category.value}`, {
        headers: {
          Authorization: `Bearer ${storage.get("access_token")}`,
        },
      })
      .then((response) => {
        if (response.data.success === true) {
          const maps = response.data.data.map(function (d) {
            const map = {
              value: d.idt_knowledge_category,
              label: d.name,
            };
            return map;
          });
          setSubcategories(maps);
        }
      })
      .catch((error) => {});
  };

  const fetchData = async () => {
    try {
      let apiUrl = API_URL;
      switch (activeTab) {
        case "all":
          apiUrl += "get-knowledge-personal-all";
          break;
        case "collection":
          apiUrl += "get-collection";
          break;
        case "personal":
          apiUrl += "get-knowledge-personal";
          break;
        case "public":
          apiUrl += "get-knowledge-personal-public";
          break;
        default:
          apiUrl += "get-knowledge-personal-all";
      }
      const params = {
        categories: selectedCategory ? selectedCategory : "all",
        subcategories:
          selectedSubcategory.length > 0
            ? selectedSubcategory.map((item) => item.value)
            : "all",
        title: searchValue,
        sort: selectedSorting.value,
        status: 2,
        page: currentPage,
        limit: cardsPerPage,
      };
      const response = await axios.post(apiUrl, params, {
        headers: {
          Authorization: `Bearer ${storage.get("access_token")}`,
        },
      });
      const data = await response.data.data.data;
      const meta = await response.data.data.meta;
      setCardsData(data);
      setTotalData(meta.total_items);
    } catch (error) {
      console.log("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCategoryChange = (category) => {
    if (selectedCategory !== category) {
      setSelectedCategory(category);
      setSelectedSubcategory([]);
    }
  };

  const handleSubcategoryChange = (subcategory) => {
    setSelectedSubcategory(subcategory);
  };

  const handleCardsPerPageChange = (cardsPerPage) => {
    setCardsPerPage(cardsPerPage);
    setCurrentPage(1); // Reset halaman saat mengubah jumlah data per halaman
  };

  const handleTabChange = (tabId) => {
    setTotalData(null);
    setCurrentPage(1);
    setActiveTab(tabId);
    onSelectedMenu(tabId);
    resetSelectedCards();
  };

  const handleSorting = (data) => {
    setSelectedSorting(data);
    storage.setUnlimited("sortedData", data);
  };

  const handleSearchChange = (data) => {
    setSearchValue(data);
  };

  const handleSubmitFilter = () => {
    fetchData();
  };

  const handleResetFilter = () => {
    setSelectedCategory("");
    setSearchValue(null);
    setSelectedSubcategory([]);
    setReloadData((prevState) => !prevState);
  };

  const handleListView = (data) => {
    setListView(data);
  };

  const handleDelete = (id, type, message) => {
    if (type === "knowledge") {
      if (selectedSession && selectedSession.idt_knowledge === id) {
        setSelectedSession((prevSession) => ({
          ...prevSession,
          knowledge: null,
        }));
        setSessionList((prevSessions) =>
          prevSessions.map((session) =>
            session.idt_knowledge === id
              ? { ...session, knowledge: null }
              : session
          )
        );
      } else if (sessionList.length > 0) {
        setSessionList((prevSessions) =>
          prevSessions.map((session) =>
            session.idt_knowledge === id
              ? { ...session, knowledge: null }
              : session
          )
        );
      }
    } else {
      if (selectedSession && selectedSession.idt_knowledge_collection === id) {
        setSelectedSession((prevSession) => ({
          ...prevSession,
          knowledge_collection: null,
        }));
        setSessionList((prevSessions) =>
          prevSessions.map((session) =>
            session.idt_knowledge_collection === id
              ? { ...session, knowledge_collection: null }
              : session
          )
        );
      } else if (sessionList.length > 0) {
        setSessionList((prevSessions) =>
          prevSessions.map((session) =>
            session.idt_knowledge_collection === id
              ? { ...session, knowledge_collection: null }
              : session
          )
        );
      }
    }
    fetchData();
    showSuccess(message);
    // if (type === "knowledge") {
    //   const filteredCards = cardsData.filter(
    //     (card) => card.idt_knowledge !== id
    //   );
    //   setCardsData(filteredCards);
    //   setTotalData(filteredCards.length);
    // } else {
    //   const filteredCards = cardsData.filter(
    //     (card) => card.idt_knowledge_collection !== id
    //   );
    //   setCardsData(filteredCards);
    //   setTotalData(filteredCards.length);
    // }
  };

  const handleArchive = (id, type, message) => {
    if (type === "knowledge") {
      if (selectedSession && selectedSession.idt_knowledge === id) {
        setSelectedSession((prevSession) => ({
          ...prevSession,
          knowledge: null,
        }));
        setSessionList((prevSessions) =>
          prevSessions.map((session) =>
            session.idt_knowledge === id
              ? { ...session, knowledge: null }
              : session
          )
        );
      } else if (sessionList.length > 0) {
        setSessionList((prevSessions) =>
          prevSessions.map((session) =>
            session.idt_knowledge === id
              ? { ...session, knowledge: null }
              : session
          )
        );
      }
    } else {
      if (selectedSession && selectedSession.idt_knowledge_collection === id) {
        setSelectedSession((prevSession) => ({
          ...prevSession,
          knowledge_collection: null,
        }));
        setSessionList((prevSessions) =>
          prevSessions.map((session) =>
            session.idt_knowledge_collection === id
              ? { ...session, knowledge_collection: null }
              : session
          )
        );
      } else if (sessionList.length > 0) {
        setSessionList((prevSessions) =>
          prevSessions.map((session) =>
            session.idt_knowledge_collection === id
              ? { ...session, knowledge_collection: null }
              : session
          )
        );
      }
    }
    fetchData();
    showSuccess(message);
  };

  const handleUpdate = (data, type) => {
    if (type === "collection") {
      const updatedDataItem = cardsData.map((item) => {
        if (item.idt_knowledge_collection === data.idt_knowledge_collection) {
          return {
            ...item,
            name: data.name,
            description: data.description,
            url_coverpic: data.url_coverpic,
            knowledge_collection_map: item.knowledge_collection_map.filter(
              (subItem) => data.ids.includes(subItem.idt_knowledge)
            ),
          };
        }
        return item;
      });
      setCardsData(updatedDataItem);
    } else {
      if (data.status === 2) {
        const updatedDataItem = cardsData.map((item) => {
          if (item.idt_knowledge === data.idt_knowledge) {
            return {
              ...item,
              ...data,
              idt_knowledge: item.idt_knowledge,
            };
          }
          return item;
        });
        setCardsData(updatedDataItem);
      } else {
        const updatedDataItem = cardsData.filter(
          (item) => item.idt_knowledge !== data.idt_knowledge
        );
        setCardsData(updatedDataItem);
        loadData();
      }
    }
  };

  const handlePublish = (data, type) => {
    if (type === "collection") {
      const updatedDataItem = cardsData.map((item) => {
        if (item.idt_knowledge_collection === data.idt_knowledge_collection) {
          return {
            ...item,
            ...data,
            idt_knowledge_collection: item.idt_knowledge_collection,
          };
        }
        return item;
      });
      setCardsData(updatedDataItem);
    } else {
      const updatedDataItem = cardsData.map((item) => {
        if (item.idt_knowledge === data.idt_knowledge) {
          return {
            ...item,
            ...data,
            idt_knowledge: item.idt_knowledge,
          };
        }
        return item;
      });
      setCardsData(updatedDataItem);
    }
  };

  return (
    <div>
      {/* Komponen FilterOptions untuk select box category, subcategory, dan show items per page */}
      <FilterOptions
        cardsPerPage={cardsPerPage}
        activeTab={activeTab}
        totalData={totalData}
        categories={categories}
        subcategories={subcategories}
        optionSort={optionsSort}
        selectedCategory={selectedCategory}
        selectedSubcategory={selectedSubcategory}
        selectedSorting={selectedSorting}
        searchValue={searchValue}
        selectedListView={listView}
        onSearchChange={handleSearchChange}
        onCategoryChange={handleCategoryChange}
        onSubcategoryChange={handleSubcategoryChange}
        onSorting={handleSorting}
        onListView={handleListView}
        onTabChange={handleTabChange}
        onSubmitCategory={handleSubmitFilter}
        onResetCategory={handleResetFilter}
        onCardsPerPageChange={handleCardsPerPageChange} // Mengirimkan prop untuk mengubah jumlah data per halaman
      />

      {/* Komponen CardList untuk menampilkan daftar kartu */}
      {listView === "card" ? (
        <CardList
          cards={cardsData}
          onPublish={handlePublish}
          loading={loading}
          selectedCards={selectedCards}
          onSelectedCardsChange={onSelectedCardsChange}
          onSelectedCount={onSelectedCount}
          handleUpdate={handleUpdate}
          handleArchive={handleArchive}
          handleDelete={handleDelete}
        />
      ) : (
        <TableList
          cards={cardsData}
          onPublish={handlePublish}
          loading={loading}
          selectedCards={selectedCards}
          onSelectedCardsChange={onSelectedCardsChange}
          onSelectedCount={onSelectedCount}
          handleUpdate={handleUpdate}
          handleArchive={handleArchive}
          handleDelete={handleDelete}
        />
      )}

      {/* Komponen Pagination untuk navigasi halaman */}
      <Pagination
        cardsPerPage={cardsPerPage}
        totalCards={totalData}
        currentPage={currentPage}
        onPageChange={setCurrentPage}
      />
    </div>
  );
};

export default MenuTabs;
