import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useLanguage } from "../provider/languageContext";
import { ThemeContext } from "../provider/themeContext";
import { ToastrContext } from "../provider/toastrContext";
import SimpleBar from "simplebar-react";
import MarkdownRenderer from "../pages/chat/component/markdownRenderer";

const TableRow = ({
  item,
  expandedRow,
  handleReferenceClick,
  index,
  isPretty,
  setIsPretty,
}) => {
  const { messagesLang } = useLanguage();
  const isExpanded = expandedRow === index;

  const handleTogglePretty = () => {
    setIsPretty(!isPretty);
  };

  return (
    <>
      <tr>
        <th scope="row">{item.id}</th>
        <td>{item.title}</td>
        <td
          style={{
            cursor: "pointer",
            color: "#fca311",
            textDecoration: "none",
          }}
          onClick={(e) => handleReferenceClick(e, index)}
        >
          {isExpanded
            ? messagesLang.hide_reference
            : messagesLang.show_reference}
        </td>
        <td>{item.score}</td>
      </tr>
      {isExpanded && (
        <>
          <tr>
            <td colSpan="4">
              <button
                className="btn btn-primary btn-sm"
                onClick={handleTogglePretty}
              >
                {isPretty ? "Show Raw" : "Show Pretty"}
              </button>
            </td>
          </tr>
          <tr>
            <td colSpan="4">
              {isPretty ? (
                <div className="markdown">
                  <MarkdownRenderer markdown={item.text} term="" isCurrent="" />
                </div>
              ) : (
                <p>{item.text}</p>
              )}
            </td>
          </tr>
        </>
      )}
    </>
  );
};

const ModalMessageAnalyze = ({ data, show, handleClose }) => {
  const { messagesLang } = useLanguage();
  const { showSuccess, showError } = useContext(ToastrContext);
  const { theme } = useContext(ThemeContext);
  const [expandedRow, setExpandedRow] = useState(null);
  const [isPretty, setIsPretty] = useState(true);

  useEffect(() => {
    if (show) {
      document.body.classList.add("modal-open-custom");
    } else {
      setExpandedRow(null);
      document.body.classList.remove("modal-open-custom");
    }

    return () => {
      document.body.classList.remove("modal-open-custom");
    };
  }, [show]);

  const handleExpand = (index) => {
    setExpandedRow(expandedRow === index ? null : index);
  };

  const handleReferenceClick = (e, index) => {
    e.stopPropagation();
    handleExpand(index);
  };

  return (
    <>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        backdrop="static"
        dialogClassName="modal-lg fade"
        onHide={handleClose}
      >
        <Modal.Header className="p-3 bg-light-dark" closeButton>
          <h5 className="modal-title">{messagesLang.response_analysis}</h5>
        </Modal.Header>
        <SimpleBar
          className="modal-body knowledge"
          style={{ height: "calc(100vh - 120px)" }}
        >
          <div className="col-12">
            <div className="table-responsive">
              <table className="table table-striped-columns mb-0">
                <thead className="table-light">
                  <tr>
                    <th scope="col">ID {messagesLang.reference}</th>
                    <th scope="col">Knowledge</th>
                    <th scope="col">{messagesLang.reference} Chunk</th>
                    <th scope="col">Score</th>
                  </tr>
                </thead>
                <tbody>
                  {data ? (
                    data.map((item, index) => (
                      <TableRow
                        key={index}
                        item={item}
                        expandedRow={expandedRow}
                        handleReferenceClick={handleReferenceClick}
                        index={index}
                        isPretty={isPretty}
                        setIsPretty={setIsPretty}
                      />
                    ))
                  ) : (
                    <tr>
                      <td colSpan="4" className="text-center">
                        No Data
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </SimpleBar>
      </Modal>
    </>
  );
};

export default ModalMessageAnalyze;
