import React, { useState } from "react";
import avatar1 from "../../assets/images/users/avatar-1.jpg";
import axios from "axios";
import { API_URL } from "../../utilities/url";
import CryptoJS from "crypto-js";
import storage from "../../services/storage";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useForm } from "react-hook-form";

const UserProfile = () => {
  const navigate = useNavigate();
  const decryptUSR = CryptoJS.AES.decrypt(storage.get("user"), "user").toString(
    CryptoJS.enc.Utf8
  );
  const usr = JSON.parse(decryptUSR);
  const [imagePreview, setImagePreview] = useState(usr.user_profile.photo_url);
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [formData, setFormData] = useState({
    username: usr.username,
    email: usr.email,
    password: "",
    confirm_password: "",
    name_first: usr.user_profile.name_first,
    name_last: usr.user_profile.name_last,
    dob: usr.user_profile.dob,
    country: "",
    msisdn: "",
  });
  const validatePassword = (value) => {
    const hasUppercase = /[A-Z]/.test(value);
    const hasNumber = /\d/.test(value);
    const hasSpchar = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(value);
    if (!hasUppercase || !hasNumber || !hasSpchar) {
      return false;
    }
    return true;
  };
  const validateUsername = (value) => {
    const hasValid = /^[a-zA-Z0-9.-_]*$/.test(value);
    if (!hasValid) {
      return false;
    }
    return true;
  };
  const validateEmail = async (value) => {
    setDisabledBtn(true);
    const response = await axios.post(
      API_URL + "exist_user_v3",
      {
        idt_user: usr.idt_user,
        email: value,
      },
      {
        headers: {
          Authorization: `Bearer ${storage.get("access_token")}`,
        },
      }
    );
    const isEmailTaken = response.data;
    if (!isEmailTaken) {
      return false;
    }
    setDisabledBtn(false);
    return true;
  };
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
        // Create a FormData object
        const formData = new FormData();
        formData.append("idt_user", usr.idt_user);
        formData.append("photo_url", file);

        axios
          .post(API_URL + "update-image-profile", formData, {
            headers: {
              Authorization: `Bearer ${storage.get("access_token")}`,
            },
          })
          .then((response) => {
            if (response.data.success === true) {
              const res = response.data.data;
              storage.remove("user");
              storage.set(
                "user",
                CryptoJS.AES.encrypt(JSON.stringify(res), "user").toString()
              );
              navigate("/user-profile");
              toast.success("Image has been updated", {
                position: "top-right",
                theme: "colored",
                transition: Slide,
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                draggable: true,
                progress: undefined,
              });
            } else {
              toast.error("Image failed to update.", {
                position: "top-right",
                theme: "colored",
                transition: Slide,
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                draggable: true,
                progress: undefined,
              });
            }
          })
          .catch((error) => {});
      };
      reader.readAsDataURL(file);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  /* Form Submit */
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    reValidateMode: "onBlur",
  });
  const onSubmitPersonal = (data) => {
    setDisabledBtn(true);
    const formData = {
      idt_user: usr.idt_user,
      username: data.username,
      email: data.email,
      name_first: data.name_first,
      name_last: data.name_last,
    };
    axios
      .post(API_URL + "update-user-profile", formData, {
        headers: {
          Authorization: `Bearer ${storage.get("access_token")}`,
        },
      })
      .then((response) => {
        setDisabledBtn(false);
        if (response.data.success === true) {
          const res = response.data.data;
          storage.remove("user");
          storage.set(
            "user",
            CryptoJS.AES.encrypt(JSON.stringify(res), "user").toString()
          );
          navigate("/user-profile");
          toast.success("Profile has been updated", {
            position: "top-right",
            theme: "colored",
            transition: Slide,
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error("Profile failed to update.", {
            position: "top-right",
            theme: "colored",
            transition: Slide,
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((error) => {
        setDisabledBtn(false);
      });
  };

  return (
    <div className="page-content">
      <ToastContainer toastClassName="toaster" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0">Profile</h4>
              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  {/* <li className="breadcrumb-item">
                    <a href="#">Chat</a>
                  </li> */}
                  <li className="breadcrumb-item active">User</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xxl-3">
            <div className="card">
              <div className="card-body p-4">
                <div className="text-center">
                  <div className="profile-user position-relative d-inline-block mx-auto  mb-4">
                    <img
                      src={imagePreview ? imagePreview : avatar1}
                      className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                      alt="user-profile-image"
                      style={{ height: "9rem", width: "9rem" }}
                    />
                    <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                      <input
                        id="profile-img-file-input"
                        type="file"
                        name="photo_url"
                        className="profile-img-file-input"
                        onChange={handleImageChange}
                      />
                      <label
                        htmlFor="profile-img-file-input"
                        className="profile-photo-edit avatar-xs"
                      >
                        <span className="avatar-title rounded-circle bg-light text-body">
                          <i className="ri-camera-fill"></i>
                        </span>
                      </label>
                    </div>
                  </div>
                  <p className="fs-16 mb-1">
                    {usr.user_profile.name_first} {usr.user_profile.name_last}
                  </p>
                  <p className="text-muted fs-14 mb-1">{usr.email}</p>
                  <p className="fs-14 mb-1">Free Account</p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xxl-9">
            <div className="card">
              <div className="card-header">
                <ul
                  className="nav nav-tabs-custom rounded card-header-tabs border-bottom-0"
                  role="tablist"
                >
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      data-bs-toggle="tab"
                      href="#personalDetails"
                      role="tab"
                    >
                      <i className="fas fa-home"></i> Personal Details
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-bs-toggle="tab"
                      href="#changePassword"
                      role="tab"
                    >
                      <i className="far fa-user"></i> Change Password
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-bs-toggle="tab"
                      href="#privacy"
                      role="tab"
                    >
                      <i className="far fa-envelope"></i> Privacy Policy
                    </a>
                  </li>
                </ul>
              </div>
              <div className="card-body p-4">
                <div className="tab-content">
                  <div
                    className="tab-pane active"
                    id="personalDetails"
                    role="tabpanel"
                  >
                    <form onSubmit={handleSubmit(onSubmitPersonal)}>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="mb-3">
                            <label
                              htmlFor="firstnameInput"
                              className="form-label w-100"
                            >
                              First Name
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              name="name_first"
                              className="form-control"
                              value={formData.name_first}
                              {...register("name_first", {
                                required: true,
                              })}
                              onChange={handleInputChange}
                            />
                            {errors.name_first &&
                              errors.name_first.type === "required" && (
                                <p className="errorMsg text-danger fs-7">
                                  First Name is required.
                                </p>
                              )}
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="mb-3">
                            <label
                              htmlFor="lastnameInput"
                              className="form-label w-100"
                            >
                              Last Name
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              name="name_last"
                              className="form-control"
                              value={formData.name_last}
                              {...register("name_last", {
                                required: true,
                              })}
                              onChange={handleInputChange}
                            />
                            {errors.name_last &&
                              errors.name_last.type === "required" && (
                                <p className="errorMsg text-danger fs-7">
                                  Last Name is required.
                                </p>
                              )}
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="mb-3">
                            <label
                              htmlFor="emailInput"
                              className="form-label w-100"
                            >
                              Username
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              name="username"
                              className="form-control"
                              value={formData.username}
                              {...register("username", {
                                required: true,
                                validate: validateUsername,
                                minLength: 4,
                                maxLength: 20,
                              })}
                              onChange={handleInputChange}
                            />
                            {errors.username &&
                              errors.username.type === "required" && (
                                <p className="errorMsg text-danger fs-7">
                                  Username is required.
                                </p>
                              )}
                            {errors.username &&
                              errors.username.min === "minLength" && (
                                <p className="errorMsg text-danger fs-7">
                                  Username min 4 character.
                                </p>
                              )}
                            {errors.username &&
                              errors.username.type === "maxLength" && (
                                <p className="errorMsg text-danger fs-7">
                                  Username max 20 character.
                                </p>
                              )}
                            {errors.username &&
                              errors.username.type === "validate" && (
                                <p className="errorMsg text-danger fs-7">
                                  Username not contain special characters.
                                  except .-_
                                </p>
                              )}
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="mb-3">
                            <label
                              htmlFor="emailInput"
                              className="form-label w-100"
                            >
                              Email
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="email"
                              name="email"
                              className="form-control"
                              value={formData.email}
                              {...register("email", {
                                required: true,
                                validate: validateEmail,
                                pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                              })}
                              onChange={handleInputChange}
                            />
                            {errors.email &&
                              errors.email.type === "required" && (
                                <p className="errorMsg text-danger fs-7">
                                  Email is required.
                                </p>
                              )}
                            {errors.email &&
                              errors.email.type === "pattern" && (
                                <p className="errorMsg text-danger fs-7">
                                  Email is not valid.
                                </p>
                              )}
                            {errors.email &&
                              errors.email.type === "validate" && (
                                <p className="errorMsg text-danger fs-7">
                                  Email has already taken.
                                </p>
                              )}
                          </div>
                        </div>

                        {/* <div className="col-lg-6">
                          <div className="mb-3">
                            <label
                              htmlFor="phonenumberInput"
                              className="form-label"
                            >
                              Phone Number
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="phonenumberInput"
                              placeholder="Enter your phone number"
                              value="+(1) 987 6543"
                            />
                          </div>
                        </div> */}

                        <div className="col-lg-12">
                          <div className="hstack gap-2 justify-content-end">
                            <button
                              type="submit"
                              className={
                                disabledBtn
                                  ? "btn btn-success disabled"
                                  : "btn btn-success"
                              }
                            >
                              Update
                            </button>
                            <button type="button" className="btn btn-danger">
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="tab-pane" id="changePassword" role="tabpanel">
                    <form action="javascript:void(0);">
                      <div className="row g-2">
                        <div className="col-lg-4">
                          <div>
                            <label
                              htmlFor="oldpasswordInput"
                              className="form-label w-100"
                            >
                              Old Password
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="password"
                              className="form-control"
                              id="oldpasswordInput"
                              placeholder="Enter current password"
                            />
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <div>
                            <label
                              htmlFor="newpasswordInput"
                              className="form-label w-100"
                            >
                              New Password
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="password"
                              className="form-control"
                              id="newpasswordInput"
                              placeholder="Enter new password"
                            />
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <div>
                            <label
                              htmlFor="confirmpasswordInput"
                              className="form-label w-100"
                            >
                              Confirm Password
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="password"
                              className="form-control"
                              id="confirmpasswordInput"
                              placeholder="Confirm password"
                            />
                          </div>
                        </div>

                        <div className="col-lg-12">
                          <div className="mb-3">
                            <a
                              href="javascript:void(0);"
                              className="link-orange text-decoration-underline"
                            >
                              Forgot Password ?
                            </a>
                          </div>
                        </div>

                        <div className="col-lg-12">
                          <div className="text-end">
                            <button type="submit" className="btn btn-success">
                              Change Password
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>

                  <div className="tab-pane" id="privacy" role="tabpanel">
                    <form>
                      <div id="newlink">
                        <div id="1">
                          <div className="row">
                            <h5 className="card-title text-decoration-underline mb-3">
                              Delete This Account:
                            </h5>
                            <p className="text-muted">
                              Go to the Data & Privacy section of your profile
                              Account. Scroll to "Your data & privacy options."
                              Delete your Profile Account. Follow the
                              instructions to delete your account :
                            </p>
                            <div>
                              <input
                                type="password"
                                className="form-control"
                                id="passwordInput"
                                placeholder="Enter your password"
                                value="make@321654987"
                                style={{ maxWidth: "265px" }}
                              />
                            </div>
                            <div className="hstack gap-2 mt-3">
                              <a
                                href="javascript:void(0);"
                                className="btn btn-soft-danger"
                              >
                                Close & Delete This Account
                              </a>
                              <a
                                href="javascript:void(0);"
                                className="btn btn-light"
                              >
                                Cancel
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
