import React, { useState, useRef, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const SearchChatDropdown = ({
  onHandleNavigation,
  onSearchChatResults,
  onCurrentChatIndex,
  onSearchChatTerm,
  onHandleChatSearch,
  onHandleKeyDown,
  onHandleClearSearch,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 0);
  };

  const handleOutsideClick = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  const handleNavigation = (data) => {
    onHandleNavigation(data);
  };

  const handleChatSearch = (e) => {
    onHandleChatSearch(e);
  };

  const handleKeyDown = (e) => {
    onHandleKeyDown(e);
  };

  const handleClearSearch = (e) => {
    onHandleClearSearch(e);
    if (inputRef.current) {
      inputRef.current.value = ""; // Kosongkan input tanpa menggunakan state
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen]);

  return (
    <div ref={dropdownRef}>
      <Dropdown show={isOpen} onToggle={() => {}}>
        <Dropdown.Toggle
          className="dropdown d-flex btn-icon btn-topbar btn-ghost-warning"
          onClick={toggleDropdown}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-search icon-sm"
          >
            <circle cx="11" cy="11" r="8"></circle>
            <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
          </svg>
        </Dropdown.Toggle>

        <Dropdown.Menu
          className="dropdown-menu dropdown-menu-md"
          align="end"
          style={{ padding: "10px", width: "270px" }}
        >
          <div className="d-flex">
            <div className="app-search" style={{ padding: 0 }}>
              <div className="position-relative">
                <input
                  ref={inputRef}
                  type="text"
                  defaultValue={onSearchChatTerm} // Menggunakan defaultValue untuk input
                  onChange={handleChatSearch}
                  onKeyDown={handleKeyDown}
                  className="form-control"
                  placeholder="Search here..."
                />
                <span className="mdi mdi-magnify search-widget-icon"></span>
                {inputRef.current?.value && (
                  <span
                    onClick={handleClearSearch}
                    className="mdi mdi-close-circle search-widget-icon search-widget-icon-close cursor-pointer"
                    id="search-close-options"
                  ></span>
                )}
              </div>
            </div>
            <div className="d-flex ms-2" style={{ flexDirection: "column" }}>
              <button
                onClick={() => handleNavigation("up")}
                disabled={
                  onSearchChatResults.length === 0 || onCurrentChatIndex === 0
                }
                className="btn btn-link p-0"
                style={{ height: "18px", margin: "-2px" }}
              >
                <FaChevronUp />
              </button>
              <button
                onClick={() => handleNavigation("down")}
                disabled={
                  onSearchChatResults.length === 0 ||
                  onCurrentChatIndex === onSearchChatResults.length - 1
                }
                className="btn btn-link p-0"
                style={{ height: "18px" }}
              >
                <FaChevronDown />
              </button>
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default SearchChatDropdown;
